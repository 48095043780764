import React, { useContext, useEffect, useLayoutEffect } from 'react';

const noop = () => {};

export const PageContext = React.createContext({});

/**
 * @param {string} [className]
 * @param {string} [title]
 */
function usePage({ className, title }) {
  const { setTitle } = useContext(PageContext);

  useLayoutEffect(
    () => {
      if (!className) return noop;

      document.body.classList.add(className);

      return () => document.body.classList.remove(className);
    },
    [className, title],
  );

  useEffect(() => {
    if (!title) return noop;

    setTitle(title);

    return () => setTitle('');
  }, [title]);
}

export default usePage;

import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getBankIncomeAccount = createAsyncAction(
  'GET_BANK_INCOME_ACCOUNT_REQUEST',
  'GET_BANK_INCOME_ACCOUNT_SUCCESS',
  'GET_BANK_INCOME_ACCOUNT_FAIL',
)();

export function getBankIncomeAccountAsync() {
  return (dispatch, getState) => {
    if (getState().bank.incomeAccountLoaded) {
      return Promise.resolve();
    }

    dispatch(getBankIncomeAccount.request());
    return ApiClient.bank.getIncomeAccount()
      .then(({ data }) => dispatch(getBankIncomeAccount.success(data.bankIncomeAccount)))
      .catch((error) => dispatch(getBankIncomeAccount.failure(error)));
  };
}

export const getBanks = createAsyncAction(
  'GET_BANKS_REQUEST',
  'GET_BANKS_SUCCESS',
  'GET_BANKS_FAIL',
)();

export function getBanksAsync() {
  return (dispatch, getState) => {
    if (getState().bank.loaded) {
      return Promise.resolve();
    }

    dispatch(getBanks.request());
    return ApiClient.bank.getBanks()
      .then(({ data }) => dispatch(getBanks.success(data)))
      .catch((error) => dispatch(getBanks.failure(error)));
  };
}

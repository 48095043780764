import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { color, transition } from '../styles/mixins';

const UiProgressBar = styled.div`
  width: 100%;
  height: 3px;
  overflow: hidden;
  pointer-events: none;
  
  > .ui-pb-line {
    width: 100%;
    height: 100%;
    background-color: ${color.primary};
    transition: opacity ${transition.normal}, transform 200ms ease;
    //transition-property: opacity, transform;
    will-change: opacity, transform;
    
    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
    }
  }
`;

class ProgressBar extends PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { active, progress } = nextProps;

    if (process.env.NODE_ENV === 'development' && typeof progress !== 'undefined' && (progress < 0 || progress > 100)) {
      console.error(`[ProgressBar] Progress out of range (0-100): ${progress}`);
    }

    if (!active) {
      return {
        progress: 0,
        auto: false,
      };
    }

    if (typeof progress === 'undefined' && !prevState.auto) {
      return {
        progress: 40,
        auto: true,
      };
    }

    if (typeof progress !== 'undefined' && progress !== prevState.progress) {
      return {
        progress: Math.max(0, Math.min(100, progress)),
        auto: false,
      };
    }

    return null;
  }

  ref = React.createRef();
  timeoutId = 0;

  state = {
    auto: false,
    progress: 0,
  };

  componentDidMount() {
    const { auto } = this.state;

    if (auto) {
      this.autoIncrement();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { auto } = this.state;

    if (!auto) {
      clearTimeout(this.timeoutId);
    }

    if (auto && !prevState.auto) {
      this.autoIncrement();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  autoIncrement = () => {
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(
      () => {
        if (this.state.auto) {
          this.increment();
          this.autoIncrement();
        }
      },
      200,
    );
  };

  increment = () => {
    this.setState((state) => ({
      // eslint-disable-next-line no-restricted-properties
      progress: state.progress + (0.15 * Math.pow(1 - Math.sqrt(100 - state.progress), 2)),
    }));
  };

  handleEnter = (node) => {
    node.style.transform = 'translate(-100%, 0)';
  };

  handleEntering = (node) => {
    const { progress } = this.state;

    node.style.transform = `translate(${progress - 100}%, 0)`;
  };

  handleExiting = (node) => {
    node.style.transform = 'translate(0, 0)';
  };

  render() {
    const { className, active } = this.props;
    const { progress } = this.state;

    return (
      <UiProgressBar className={className}>
        <CSSTransition
          classNames="ui-pb-line"
          timeout={300}
          in={active}
          onEnter={this.handleEnter}
          onEntering={this.handleEntering}
          onExiting={this.handleExiting}
          mountOnEnter
          unmountOnExit
        >
          <div
            ref={this.ref}
            className="ui-pb-line"
            style={{ transform: `translate(${progress - 100}%, 0)` }}
          />
        </CSSTransition>
      </UiProgressBar>
    );
  }
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  progress: PropTypes.number,
};

export default ProgressBar;

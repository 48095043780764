import classnames from 'classnames';
import React, { forwardRef, memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, transition } from '../styles/mixins';

const UiCheckbox = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  &:hover,
  &.focus {
    .box {
      // border-color: ${color.black.rgba(0.3)};
    }
  }

  &:active {
    .box {
      // border-color: ${color.black.rgba(0.5)};
    }
  }

  &.checked {
    .box {
      background-color: var(--ui-on-white-action-primary, #E60028);

      > .check-icon {
        transform: scale(1);
      }
    }
    .label {
      opacity: 1;
    }
  }

  &.indeterminate {
    .box {
      background-color: #fff;

      > .indeterminate-icon {
        transform: scale(1);
      }
    }
  }

  &.disabled {
    cursor: not-allowed;

    .input {
      cursor: not-allowed;
    }

    .box {
      border-color: ${color.black.rgba(0.05)};
    }

    .label {
      opacity: .1;
    }
  }

  &.size-s {
    .box {
      width: 14px;
      height: 14px;

      > .check-icon,
      > .indeterminate-icon {
        width: 6px;
        height: 6px;
        top: 3px;
        left: 3px;
      }

      > .indeterminate-icon {
        width: 8px;
        height: 8px;
        top: 2px;
        left: 2px;
      }
    }
  }

  &.size-m {
    .box {
      width: 18px;
      height: 18px;

      > .check-icon {
        width: 8px;
        height: 8px;
        top: 4px;
        left: 4px;
      }

      > .indeterminate-icon {
        width: 10px;
        height: 10px;
        top: 3px;
        left: 3px;
      }
    }
  }

    &.size-l {
      .box {
        width: 24px;
        height: 24px;

        > .check-icon {
          width: 13px;
          height: 13px;
          top: 4px;
          left: 5px;
        }

        > .indeterminate-icon {
          width: 10px;
          height: 10px;
          top: 3px;
          left: 3px;
        }
      }
      .label {
        margin-left: 10px;
      }
    }

  &.design-squared {
    .box {
      border-radius: 4px;
    }
  }

  &.design-rounded {
    .box {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1.4px solid #A7AFBC;

      > .check-icon,
      > .indeterminate-icon {
        width: 8px;
        height: 8px;
        top: 5px;
        left: 5px;
      }

      > .indeterminate-icon {
        width: 10px;
        height: 10px;
        top: 3px;
        left: 3px;
      }
    }

    .label {
      opacity: 1;
    }

    &.checked {
      .box {
        background-color: #001C3D;
        border: 1px solid rgba(0,0,0,0.08);
      }
    }
  }

  &.label-align-right {
    .label {
      margin-left: 6px;
      margin-right: 0;
    }
  }

  &.label-align-left {
    .label {
      margin-right: 14px;
      margin-left: 0;
    }
  }

  .box {
    flex: 0 0 auto;
    box-sizing: border-box;
    position: relative;
    display: block;
    border: 1px solid ${color.black.rgba(0.08)};
    background-color: #fff;
    transition: ${transition.fast};
    transition-property: border-color, background-color;

    > .check-icon,
    > .indeterminate-icon {
      position: absolute;
      display: block;
      transform: scale(0);
      opacity: 1;
      transition: ${transition.fast};
      transition-property: transform, opacity;
    }

    > .check-icon {
      background: url("data:image/svg+xml,%3Csvg%20width%3D%2213%22%20height%3D%2210%22%20viewBox%3D%220%200%2013%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M12.6824%200.268948C13.0861%200.645782%2013.1079%201.27857%2012.7311%201.68232L5.73109%209.18232C5.53291%209.39466%205.2522%209.51034%204.96195%209.49928C4.67171%209.48822%204.40061%209.35151%204.21917%209.1247L0.219168%204.1247C-0.125842%203.69344%20-0.0559202%203.06414%200.375342%202.71913C0.806604%202.37413%201.4359%202.44405%201.78091%202.87531L5.05827%206.97202L11.269%200.317685C11.6458%20-0.0860652%2012.2786%20-0.107886%2012.6824%200.268948Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E") center center no-repeat;
      background-size: 100%;
    }

    > .indeterminate-icon {
      border-radius: 3px;
      background-color: var(--ui-on-white-action-primary, #E60028);
    }
  }

  .input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    box-shadow: none;
    -webkit-appearance: none;
    border: 0;
    outline: 0;
    background: #FFF;
    margin: 0;
  }

  .label {
    font-family: inherit;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    //color: #585858;
    margin-left: 6px;
    //margin-top: 2px;
    color: #000000;
    opacity: .655;
    transition: opacity ${transition.fast};

    //(88-255) / (0-255) = 0.655
  }
`;

const Group = styled.div`
  ${UiCheckbox} + ${UiCheckbox} {
    margin-top: 10px;
  }
`;

const Checkbox = memo(
  forwardRef((props, ref) => {
    const {
      className,
      children,
      checked,
      indeterminate,
      name,
      disabled,
      size,
      onChange,
      onFocus,
      onBlur,
      design,
      labelAlign,
      ...attributes
    } = props;
    const [focus, setFocus] = useState(false);
    const handleFocus = useCallback((e) => {
      setFocus(true);
      onFocus?.(e);
    }, []);
    const handleBlur = useCallback((e) => {
      setFocus(false);
      onBlur?.(e);
    }, []);

    if (indeterminate && checked && process.env.NODE_ENV === 'development') {
      console.warn('[Checkbox] Can\'t be checked and indeterminate at the same type.');
    }

    const cls = classnames(
      className,
      `size-${size}`,
      `design-${design}`,
      `label-align-${labelAlign}`,
      { checked, indeterminate, disabled, focus },
    );

    return (
      <UiCheckbox
        ref={ref}
        className={cls}
      >
        {children && labelAlign === 'left' && (<span className="label">{children}</span>)}
        <span className="box">
          <input
            type="checkbox"
            className="input"
            name={name}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...attributes}
          />
          <div className="check-icon" />
          <div className="indeterminate-icon" />
        </span>
        {children && labelAlign === 'right' && (<span className="label">{children}</span>)}
      </UiCheckbox>
    );
  }),
);

Checkbox.Group = Group;

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  design: PropTypes.oneOf(['squared', 'rounded']),
  labelAlign: PropTypes.oneOf(['right', 'left']),
};

Checkbox.defaultProps = {
  size: 'm',
  design: 'squared',
  labelAlign: 'right',
};

export default Checkbox;

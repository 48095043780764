import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color, transition } from '../styles/mixins';
import ArrowIcon from './assets/collapse-directed-arrow.svg';
import ArrowIconL from './assets/collapse-directed-arrow-l.svg';

const UiDirectedArrow = styled(ArrowIcon)`
  display: block;
  width: 9px;
  height: 6px;
  transition: transform ${transition.normal};
  transform-origin: 4.5px 2.5px;

  path {
    fill-opacity: 1;
  }

  &.size-l {
    width: 13px;
    height: 8px;
    transform-origin: 6.5px 3.5px;
  }

  &.color-red path {
    fill: ${color.error};
  }

  &.color-green path {
    fill: ${color.success};
  }

  &.color-black path {
    fill: ${color.black};
  }

  &.color-gray path {
    fill: #979797;
    //fill: ${color.black};
    //fill-opacity: .3;
  }

  &.direction-up {
    transform: rotate(180deg);
  }

  &.direction-down {
    transform: rotate(0);
  }

  &.direction-right {
    transform: rotate(-90deg);
  }

  &.direction-left {
    transform: rotate(90deg);
  }
`;

const CollapseDirectedArrow = (props) => {
  const { className, size, color, direction } = props;
  const cls = classnames(className, `direction-${direction}`, `color-${color}`, `size-${size}`);

  return <UiDirectedArrow className={cls} as={size === 'l' ? ArrowIconL : ArrowIcon} />;
};

CollapseDirectedArrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['red', 'green', 'black', 'gray']),
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

CollapseDirectedArrow.defaultProps = {
  color: 'gray',
  direction: 'down',
};

export default CollapseDirectedArrow;

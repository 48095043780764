import { createTheme } from '@material-ui/core';

export const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        // backgroundColor: lightBlue.A200,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        display: 'none',
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
      daysHeader: {
        maxHeight: '44px',
      },
      dayLabel: {
        fontSize: '13px',
        height: '36px',
        lineHeight: '36px',
        margin: '6px 6px 0',
        textTransform: 'capitalize',

        '@media(max-width: 430px)': {
          width: '8.3725vw',
          height: '8.3725vw',
          lineHeight: '8.3725vw',
          margin: '1.39525vw 1.39525vw 0',
        },
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: '12px',
        minHeight: '239px',
        '@media(max-width: 430px)': {
          marginTop: '2.7906vw',
          minHeight: '55.58139vw',
        },
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: '0',
        maxWidth: '336px',
        minHeight: '295px', // 283
        margin: 'auto',
        '@media(max-width: 430px)': {
          minHeight: '68.6vw', // 295px
        },
      },
    },
    MuiPickersDay: {
      day: {
        margin: '0 6px 12px',
        fontSize: '16px',
        // color: lightBlue.A700,
        '@media(max-width: 430px)': {
          width: '8.3725vw',
          height: '8.3725vw',
          margin: '0 1.39525vw 2.7906vw',
        },
      },
      daySelected: {
        backgroundColor: '',
        border: '1px solid #E60028',
        color: '#000',
        fontWeight: 'normal',

        '&:hover': {
          backgroundColor: '',
          color: '#000',
        },
        // backgroundColor: lightBlue["400"],
      },
      dayDisabled: {
        // color: lightBlue["100"],
      },
      current: {
        color: '#E60028',
        // color: lightBlue["900"],
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        minWidth: '0',
      },
    },
    MuiDialog: {
      paper: {
        margin: '16px',
      },
      paperWidthSm: {
        maxWidth: '359px',
      },
    },
    MuiTypography: {
      body2: {
        fontSize: '16px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '8px 0',
      },
    },
  },
});

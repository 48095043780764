import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../../../../ui-kit';

function TimerButton({ end, disabled, ...btnProps }) {
  const [elapsed, setElapsed] = useState(60 * 1000);
  const timer = useRef(0);
  const tick = useCallback(() => {
    if (end - Date.now() < 0) {
      clearInterval(timer.current);
    }
    setElapsed(end - Date.now());
  }, [end]);

  useEffect(() => {
    tick();
    timer.current = setInterval(tick, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, [end]);

  const seconds = (elapsed / 1000).toFixed(0);

  return (
    <Button disabled={disabled || seconds > 0} {...btnProps}>
      {`Отправить код повторно${seconds > 0 ? ` (${seconds} сек)` : ''}`}
    </Button>
  );
}

export default memo(TimerButton);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getInsurancesProductsAsync } from '../../../../actions/insurances-products';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import { useAsyncStatus } from '../../../common/hooks';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import ProductCardButton from './ProductCardButton';
import ProductInfoModal from './ProductInfoModal';

const StyledLink = styled.a`
  display: block;
  text-align: center;
  color: #3E72DC;
  font-size: 14px;
  font-family: Roboto;
  line-height: 16px;
  margin-top: 20px;
`;

const rosbankLink = 'https://www.rosbank-auto.ru/prolongaciya-kasko/?utm_source=rosbank&utm_medium=mobile_app&utm_campaign=tips&utm_content=insurance-section';
const getUniqueKey = (item, i) => item.insuranceId + item.insuranceName + i;

function InsurancesProductsMainPage({ match }) {
  const dispatch = useDispatch();
  const { creditId } = match.params;
  const [modalConfig, setModalConfig] = useState(null);
  const insurancesProductsById = useSelector((state) => state.insurancesProducts.byId);
  const insurancesProducts = insurancesProductsById[creditId] ?? [];
  const hasCasco = useMemo(() => insurancesProducts.some(({ insuranceId }) => insuranceId === 1 || insuranceId === 1001), [insurancesProducts]);
  const { loading } = useAsyncStatus((state) => state.insurancesProducts.getInsurancesProductsFetchStatus);

  const handleOpenModal = useCallback((config) => {
    setModalConfig({ ...config, creditId });
  }, [creditId]);

  const handleCloseModal = useCallback(() => {
    setModalConfig(null);
  }, []);

  useEffect(() => {
    if (!insurancesProducts.length) {
      dispatch(getInsurancesProductsAsync({ contractNumber: creditId }));
    }
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
  }, []);

  return (
    <LoaderOrChildren loading={loading}>
      <div className="page-wrapper">
        {insurancesProducts.map((item, i) => (
          <ProductCardButton key={getUniqueKey(item, i)} data={item} onClick={handleOpenModal} />
        ))}
        {hasCasco && (
          <StyledLink target="_blank" href={rosbankLink}>
            Узнайте, как уведомить банк <br /> о пролонгации полиса
          </StyledLink>
        )}
      </div>

      <ProductInfoModal config={modalConfig} onClose={handleCloseModal} />
    </LoaderOrChildren>
  );
}

export default InsurancesProductsMainPage;

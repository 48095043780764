import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import { creditsSelector } from '../../selectors/credits';
import { CREDIT_CLOSED_FLAG } from '../../utils/constants';
import useUser from '../common/hooks/useUser';
import CreditDetails from './credit-details/CreditDetails';
import CreditList from './credit-list/CreditList';

const Credits = () => {
  const credits = useSelector(creditsSelector);
  const zeroActiveCredits = useMemo(() => credits.every((credit) => credit.is_closed === CREDIT_CLOSED_FLAG), [credits]);
  const user = useUser();

  if (!user.authenticated || !user.operator_id) {
    return <Redirect to="/auth" />;
  }

  return (
    <Switch>
      <Route path="/credits/:status(active|closed)" component={CreditList} />
      <Route path="/credits/:creditId" component={CreditDetails} />
      {credits.length === 1 && <Redirect to={`/credits/${credits[0].contract_number}`} />}
      <Redirect to={`/credits/${zeroActiveCredits ? 'closed' : 'active'}`} />
    </Switch>
  );
};

export default memo(Credits);

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { getBanksAsync } from '../../../../../actions/bank';
import { banksSelector } from '../../../../../selectors/bank';
import { Input, Loader } from '../../../../../ui-kit';
import BottomSheet from '../../../../common/BottomSheet';
import FailedToLoadData from '../../../../common/FailedToLoadData';
import { useAsyncStatus } from '../../../../common/hooks';
import DomHolder from '../../../../common/utils/DomHolder';

const highlight = (name, text) => {
  const escaped = text.toUpperCase().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const html = name.toUpperCase().replace(escaped, '<span class="highlighted">$&</span>');

  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};

const getSnapPoints = ({ maxHeight }) => [maxHeight * 0.95, maxHeight * 0.95];

function ChooseBicModal({ active, onClose, onChooseBic }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const inputRef = useRef();
  const banks = useSelector(banksSelector);
  const { loading, error } = useAsyncStatus((state) => state.bank.fetchStatus);

  const filteredList = useMemo(() => (
    search.length > 2 ? banks.filter(({ bic, bank_name }) => (
      bic.startsWith(search) || bank_name.toLowerCase().includes(search.toLowerCase())
    )) : banks
  ), [banks, search]);
  const notFound = useMemo(() => banks.length > 0 && filteredList.length === 0, [banks, filteredList]);

  const handleSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  }, []);

  const getBanksList = useCallback(() => {
    dispatch(getBanksAsync());
  }, []);

  const handleSpringEnd = (e) => {
    if (e.type === 'OPEN' && inputRef.current?.focus) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (active) {
      if (!banks.length) {
        getBanksList();
      }

      setSearch('');
    }
  }, [active]);

  return (
    <BottomSheet
      open={active}
      onDismiss={onClose}
      snapPoints={getSnapPoints}
      initialFocusRef={inputRef}
      onSpringEnd={handleSpringEnd}
    >
      <DomHolder>
        {active && (
          <div className="choose-bic-modal-content">
            <div className="title">БИК банка получателя</div>
            <Input
              ref={inputRef}
              className="search-input"
              value={search}
              onChange={handleSearch}
              search
              design="v2"
            />

            {loading && <Loader className="loader" />}

            {error && (
              <FailedToLoadData
                backAction="goBack"
                loading={loading}
                onRetry={getBanksList}
              />
            )}

            {notFound && (
              <p className="not-found-description">
                Нам не&nbsp;удалось найти банк или филиал по&nbsp;вашему запросу. Попробуйте ввести другие данные
              </p>
            )}

            <div
              className={classnames('list-wrapper', { 'not-found': notFound })}
            >
              {banks.length > 0 && filteredList.map((bank) => (
                <button key={`${bank.bic}-${bank.bank_name}`} className="list-item" onClick={() => onChooseBic(bank)}>
                  <span className="bic-number">{search.length > 2 ? highlight(bank.bic, search) : bank.bic}</span>
                  <p className="bank-name">{search.length > 2 ? highlight(bank.bank_name, search) : bank.bank_name}</p>
                </button>
              ))}
            </div>
          </div>
        )}
      </DomHolder>
    </BottomSheet>
  );
}

export default ChooseBicModal;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Hint } from '../../ui-kit';

function EmptyDataBlock(props) {
  const { className, message } = props;

  return (
    <div className={classnames('empty-data-block', className)}>
      <Hint type="info">{message}</Hint>
    </div>
  );
}

EmptyDataBlock.propTypes = {
  className: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default memo(EmptyDataBlock);

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { parse } from 'qs';
import { useUser } from '../common/hooks';
import AgreementModal from './AgreementModal';
import SignInForm from './SignInForm';

function Auth(props) {
  const user = useUser();

  if (user.authenticated) {
    const { contractnumber } = parse(props.location.search, { ignoreQueryPrefix: true });
    const creditId = contractnumber ? `/${contractnumber}` : '';
    return <Redirect to={`/credits${creditId}`} />;
  }

  return (
    <div className="page-auth">
      <div className="auth-container">
        <div className="auth-wrap">
          <div className="auth-content">
            <Switch>
              <Route path="/auth/agreement" component={AgreementModal} exact />
              <Route path="/auth" component={SignInForm} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;

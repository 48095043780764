import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { showInsurancesProductsError } from '../../../../actions/insurances-products';
import { Button, Portal } from '../../../../ui-kit';
import ErrorIcon from '../../../../assets/svg/status/status-error-v2.svg';
import { transition } from '../../../../ui-kit/styles/mixins';
import postToNativeApp from '../../../../utils/postToNativeApp';
import PageTitle from '../../../common/PageTitle';

const UiInformer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #FFFFFF;
  outline: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px 24px;

  .page-wrapper {
    padding: 21px 0 0;
  }

  &.ui-informer-transition {
    &-enter {
      opacity: 0;
      transform: translateY(-100%);
    }
    &-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: ${transition.normal};
      transition-property: opacity, transform;
    }

    &-exit {
      transform: translateY(0%);
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transform: translateY(-50%);
      transition: ${transition.normal};
      transition-property: opacity, transform;
    }
  }
`;

const StyledInformerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ withMarginAuto }) => (withMarginAuto ? 'auto' : '0')};

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;
  }
  .description {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #8B95A4;
    margin: 8px 0 24px;
  }
  .reload-button {
    width: max-content;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;
  }
`;

const StyledTitle = styled.h1`
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 8px;
  text-align: center;
  line-height: 24px;
  color: #1E2025;
`;

const StyledParagraph = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  color: #647081;
`;

const StyledButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 48px !important;
  text-transform: unset;
  margin-bottom: 12px;
  line-height: 20px !important;
  font-weight: 500 !important;
  &:last-of-type {
    margin-bottom: 0;
  }
  &.design-secondary {
    background-color: #EDEFF1 !important;
    border: none !important;
  }
`;

const Informer = ({ active, config, creditId, loading }) => {
  const { title, description, withGotoInquiries, type, onReload, endpointName } = config;
  const history = useHistory();
  const dispatch = useDispatch();
  const transitionProps = {
    classNames: 'ui-informer-transition',
    timeout: 300,
    in: active,
    mountOnEnter: true,
    unmountOnExit: true,
  };

  const onGotoInquiries = useCallback(() => {
    postToNativeApp({ type: 'titleChanged', payload: { chapter: 'INQUIRIES' } });
    dispatch(showInsurancesProductsError({}));
    history.push(`/credits/${creditId}/operation-log?reload=force`);
  }, [creditId]);

  const onClose = useCallback(() => {
    postToNativeApp({ type: 'close' });
    history.push(`/credits/${creditId}`);
    dispatch(showInsurancesProductsError({}));
    // setTimeout(() => history.push(`/credits/${creditId}`), 300); // removes the flicker before moving to another page, but the modal closes without animation
  }, [creditId]);

  useEffect(() => {
    if (active) {
      postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    }
  }, [active]);

  return (
    <Portal id="__informer_container">
      <CSSTransition {...transitionProps}>
        <UiInformer className={classnames('ui-informer', { simple: type === 'failedLoadData', 'car-info': endpointName === 'sendCarInfo' })}>
          <PageTitle title="Страховые продукты" onBackClick={onClose} />

          {type === 'failedLoadData' ? (
            <StyledInformerBody className="informer-body">
              <div className="title">Не удалось загрузить данные</div>
              <div className="description">
                Попробуйте обновить страницу<br />или зайти позже
              </div>
              <Button
                className="reload-button"
                design="grey"
                size="m"
                onClick={onReload}
                loading={loading}
              >
                Обновить
              </Button>
            </StyledInformerBody>
          ) : (
            <>
              <StyledInformerBody withMarginAuto>
                <ErrorIcon />
                <StyledTitle>{title}</StyledTitle>
                <StyledParagraph dangerouslySetInnerHTML={{ __html: description }} />
              </StyledInformerBody>

              <StyledButtonGroup>
                {withGotoInquiries && (
                  <StyledButton design="red" size="m" onClick={onGotoInquiries}>
                    Перейти в журнал обращений
                  </StyledButton>
                )}
                <StyledButton design={withGotoInquiries ? 'secondary' : 'red'} size="m" onClick={onClose}>Вернуться к кредиту</StyledButton>
              </StyledButtonGroup>
            </>
          )}
        </UiInformer>
      </CSSTransition>
    </Portal>
  );
};

export default memo(Informer);

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitleProps } from '../../../../../actions/app';
import { Button } from '../../../../../ui-kit';
import postToNativeApp from '../../../../../utils/postToNativeApp';
import DownloadIcon from '../../../../../assets/svg/download-with-bg.svg';
import { downloadURI } from '../../../../../utils/downloadURI';
import HtmlText from '../../../../common/utils/HtmlText';
import { useAsyncStatus, useFixedFooter, useOpenNewPage, useQueryParams } from '../../../../common/hooks';
import AlertBlock from '../../../../common/AlertBlock';
import { getSupAgreementAsync, sendSupAgreementAsync } from '../../../../../actions/services';
import useServiceById from '../hooks/useServiceById';

const ConnectionPage = styled.div`
  height: 100%;
  z-index: 11;
  background-color: #FFFFFF;
  outline: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //padding-bottom: 12px;
`;

const StyledBody = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 40px 0;

  &.loading {
    opacity: 0.6;
    pointer-events: none;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;
    text-align: center;
  }

  .document-description {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    color: #647081;
  }

  .service-connection-wrapper,
  .download-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .service-connection-wrapper {
    .service-icon-wrapper {
      position: relative;
      width: auto;
      height: 72px;
    }
  }

  .document-download-wrapper {
    margin-top: 24px;
    min-width: 80px;

    .text {
      margin-top: 8px;
      color: #000C1A;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;

const StyledTitle = styled.h1`
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 8px;
  text-align: center;
  line-height: 24px;
  color: #000C1A;
`;

const StyledParagraph = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  color: #647081;
`;

const StyledButtonGroup = styled.div`
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 16px 0;

  .status-fatal-error.compact {
    margin-top: 12px;

    .alert-block-wrapper {
      padding: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  text-transform: unset;
  line-height: 20px !important;
  font-weight: 500 !important;
  margin-bottom: 24px;

  &.ui-button {
    height: auto;
    min-height: 48px;
    padding: 14px 24px;
    color: #1E2025;

    .content {
      color: #FFFFFF;
    }
  }
`;

const ConnectionDocumentPage = ({ history, match: { params: { creditId, serviceKey } } }) => {
  const dispatch = useDispatch();
  const [params] = useQueryParams();
  const [errorType, setErrorType] = useState('');

  const creditsById = useSelector((state) => state.credits.byId);
  const finProtectRegResult = useSelector((state) => state.services.finProtectRegResult);
  const notSignedServices = useSelector((state) => state.services.notSignedServices);
  const supAgreement = useSelector((state) => state.services.supAgreementResult);
  const getSupAgreementStatus = useAsyncStatus((state) => state.services.getSupAgreementStatus);
  const sendSupAgreementStatus = useAsyncStatus((state) => state.services.sendSupAgreementStatus);

  const isSignedService = params.isSigned;
  const service = useServiceById(serviceKey);
  const notSignedService = notSignedServices.find(({ id }) => id === (service?.id ?? +serviceKey)) ?? {};
  const serviceId = service?.id ?? notSignedService.id ?? +serviceKey;
  const serviceConfig = service ?? notSignedService ?? {};
  const { name, cost } = serviceConfig;
  const { requestId, request_document_mobile_url } = finProtectRegResult || {};
  const isOption = serviceId !== 234 || serviceId !== 240;

  const footerRef = useRef();
  useFixedFooter(footerRef, 'page-content', []);

  const [loading, setLoading] = useState(false);

  const handleDocumentClick = useCallback(() => {
    if (isSignedService) {
      const configToSend = {
        contractNumber: creditId,
        hasFacsimile: 0,
        name,
        id: serviceId,
      };
      return dispatch(getSupAgreementAsync(configToSend));
    }
    return downloadURI(request_document_mobile_url, 'statement.pdf');
  }, [creditId, name, serviceId, request_document_mobile_url, isSignedService]);

  const onSubmit = useCallback(() => {
    if (isSignedService) {
      dispatch(sendSupAgreementAsync({ id: notSignedService?.id, requestId: notSignedService?.requestId }));
    } else {
      setLoading(true);

      const configToSend = {
        contractNumber: creditId,
        name,
        id: serviceId,
        cost,
        requestId,
        currency: creditsById[creditId].contract_currency,
      };

      postToNativeApp({
        type: 'goToServicePayment',
        payload: configToSend,
      });
    }
  }, [isSignedService, serviceConfig, creditsById, notSignedService]);

  useEffect(() => {
    if (getSupAgreementStatus.error) setErrorType('get');
  }, [getSupAgreementStatus.error]);

  useEffect(() => {
    if (sendSupAgreementStatus.error) setErrorType('send');
  }, [sendSupAgreementStatus.error]);

  useEffect(() => {
    if (getSupAgreementStatus.success) {
      downloadURI(supAgreement.request_document_mobile_url, 'sample_additional_agreement.pdf');
      if (errorType === 'get') setErrorType('');
    }
  }, [getSupAgreementStatus.success]);

  useEffect(() => {
    if (sendSupAgreementStatus.success) {
      history.push(`/credits/${creditId}/services/${serviceKey}/code-verification`);
      if (errorType === 'send') setErrorType('');
    }
  }, [sendSupAgreementStatus.success]);

  useOpenNewPage(isSignedService ? 'close' : 'goBack');

  useEffect(() => {
    dispatch(setPageTitleProps({ onBackClick: () => history.push(`/credits/${creditId}`) }));

    return () => {
      dispatch(setPageTitleProps({}));
    };
  }, []);

  return (
    <ConnectionPage>
      <StyledBody className={classnames('page-content', { loading: sendSupAgreementStatus.loading || loading })}>
        <div className="service-connection-wrapper">
          <div className="service-icon-wrapper">
            <img
              srcSet={`/services/services-with-bg/${serviceId}.png 1x, /services/services-with-bg/${serviceId}@2x.png 2x`}
              src={`/services/services-with-bg/${serviceId}.png`}
              alt={`service-${serviceId}-icon`}
              className="service-icon"
            />
          </div>
          <StyledTitle>{isSignedService ? 'Подпишите документ' : name}</StyledTitle>
          <HtmlText className="document-description" tag="p">
            {isSignedService ?
              `Ознакомьтесь и подпишите допсоглашение по ${isOption ? 'опции' : 'услуге'}<br />«${name}»` :
              `Ознакомьтесь с документом на подключение ${isOption ? 'опции' : 'услуги'}`}
          </HtmlText>

          <div className="document-download-wrapper" onClick={handleDocumentClick}>
            <button className="download-button">
              <DownloadIcon className="icon" />
              <StyledParagraph className="text">{isSignedService ? 'Допсоглашение' : 'Заявление'}</StyledParagraph>
            </button>
          </div>
        </div>
      </StyledBody>

      <StyledButtonGroup ref={footerRef}>
        {errorType === 'get' && (
          <AlertBlock
            text="Не удалось загрузить файл. Попробуйте повторить попытку"
            status="warning"
            margin="12px 0 24px"
            compact
          />
        )}
        {errorType === 'send' && (
          <AlertBlock
            text={sendSupAgreementStatus.error?.error_code === 3 ? sendSupAgreementStatus.error?.error_description : 'Не удалось загрузить данные. Попробуйте повторить попытку'}
            status="fatal-error"
            margin="24px 0"
            compact
          />
        )}

        <StyledButton
          design="red"
          size="m"
          loading={sendSupAgreementStatus.loading || loading}
          onClick={onSubmit}
        >
          {isSignedService ? 'Подписать' : 'Перейти к оплате'}
        </StyledButton>
      </StyledButtonGroup>
    </ConnectionPage>
  );
};

export default memo(ConnectionDocumentPage);

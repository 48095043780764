import classnames from 'classnames';
import React, { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Picker from 'rmc-picker/lib/Picker';
import styled from 'styled-components';
import { Button, Input } from '../../../ui-kit';
import BottomSheet from '../BottomSheet';
import useStateWithRef from '../hooks/useStateWithRef';

const StyledModal = styled(BottomSheet)`
  div[data-rsbs-footer] {
    box-shadow: none;
  }
  .wheel-picker-modal {
    margin-bottom: 0;

    .ui-wheel-picker {
      margin-top: 28px;
    }
  }
`;

function useCombinedRefs(...refs) {
  return (target) => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(target);
      } else {
        ref.current = target;
      }
    });
  };
}

const WheelPicker = memo(
  forwardRef(({
    initialValue, onChange, onSubmit, onFocus, setFocused, style, inputValue, suffix,
    className, pickerValues = [], disabled: disabledProp, onBlur, asyncStatus, ...inputProps
  }, ref) => {
    const [isActiveModal, setIsActiveModal] = useState(false);
    const inputRef = useRef();
    const contentRef = useRef();
    const [value, setValue, valueRef] = useStateWithRef(initialValue);

    const disabled = useMemo(() => {
      if (typeof disabledProp === 'function') {
        return disabledProp(value);
      }

      return disabledProp;
    }, [disabledProp, value]);

    const onCloseModal = useCallback(() => {
      setIsActiveModal(false);

      if (onBlur) {
        onBlur({ target: { name: inputProps.name } });
      }
    }, [onBlur, inputProps.name]);

    const handlePickerChange = useCallback((value) => {
      setValue(value);
    }, [initialValue]);

    const handleFocus = useCallback((e) => {
      e.nativeEvent.target.blur();
      setIsActiveModal(true);

      if (setFocused && inputProps.name) {
        setFocused((prev) => ({
          ...prev,
          [inputProps.name]: true,
        }));
      }

      if (onFocus) {
        onFocus(e);
      }
    }, [onFocus, setFocused, inputProps.name]);

    const handleSubmit = useCallback(() => {
      onSubmit(valueRef.current);
      if (!asyncStatus) {
        setTimeout(() => onCloseModal(), 0);
      }
    }, [onCloseModal, value, asyncStatus]);

    useEffect(() => {
      if (asyncStatus?.success) {
        onChange(valueRef.current);
        onCloseModal();
      }
      if (asyncStatus?.error) {
        onCloseModal();
      }
    }, [asyncStatus]);

    useEffect(() => {
      if (!isActiveModal) {
        setValue(initialValue);
      }
    }, [isActiveModal]);

    return (
      <>
        <Input
          suffix={suffix}
          onFocus={handleFocus}
          design="v2"
          readOnly
          ref={useCombinedRefs(inputRef, ref)}
          value={inputValue}
          className="ui-will-picker-input"
          {...inputProps}
        />
        <StyledModal
          open={isActiveModal}
          onDismiss={onCloseModal}
          expandOnContentDrag={false}
          loading={asyncStatus.loading}
          footer={(
            <Button
              design="red"
              type="button"
              onClick={handleSubmit}
              disabled={disabled || initialValue === value || !value}
              loading={asyncStatus.loading}
            >
              Готово
            </Button>
          )}
        >
          <div className={classnames('wheel-picker-modal', { loading: asyncStatus.loading })} ref={contentRef}>
            <span className="title">Выберите число месяца</span>

            <Picker
              style={{ flex: 1 }}
              className={classnames('ui-wheel-picker', className)}
              selectedValue={value}
              onValueChange={handlePickerChange}
            >
              {pickerValues.map(({ value, label }) => (
                <Picker.Item key={value} value={value}>
                  {label}
                </Picker.Item>
              ))}
            </Picker>
          </div>
        </StyledModal>
      </>
    );
  }),
);

export default WheelPicker;

import './styles/index.scss';
import 'core-js';
import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import App from './components/App';

moment.locale('ru');

ReactDOM.render(<App />, window.document.getElementById('app'));

console.log(`

 /$$$$$$$  /$$$$$$$         /$$$$$$  /$$   /$$ /$$$$$$$$ /$$$$$$
| $$__  $$| $$__  $$       /$$__  $$| $$  | $$|__  $$__//$$__  $$
| $$  \\ $$| $$  \\ $$      | $$  \\ $$| $$  | $$   | $$  | $$  \\ $$
| $$$$$$$/| $$$$$$$       | $$$$$$$$| $$  | $$   | $$  | $$  | $$
| $$__  $$| $$__  $$      | $$__  $$| $$  | $$   | $$  | $$  | $$
| $$  \\ $$| $$  \\ $$      | $$  | $$| $$  | $$   | $$  | $$  | $$
| $$  | $$| $$$$$$$/      | $$  | $$|  $$$$$$/   | $$  |  $$$$$$/
|__/  |__/|_______/       |__/  |__/ \\______/    |__/   \\______/

`);

console.log(`Version: ${process.env.VERSION}`);
console.log('05.09.2023 Исправление подачи реквизитов');

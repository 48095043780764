import { useSelector } from 'react-redux';

const selector = (state) => state.user.data;

/**
 * @returns {CurrentUserModel}
 */
function useUser() {
  return useSelector(selector);
}

export default useUser;

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color, transition } from '../styles/mixins';
import ArrowIcon from './assets/directed-arrow.svg';

const UiDirectedArrow = styled(ArrowIcon)`
  display: block;
  width: 12px;
  height: 8px;
  transition: transform ${transition.normal};
  transform-origin: 6px 4px;

  path {
    fill-opacity: 1;
  }

  &.color-red path {
    fill: ${color.error};
  }

  &.color-green path {
    fill: ${color.success};
  }

  &.color-black path {
    fill: ${color.black};
  }

  &.color-gray path {
    fill: ${color.black};
    fill-opacity: .3;
  }

  &.direction-up {
    transform: rotate(180deg);
  }

  &.direction-down {
    transform: rotate(0);
  }

  &.direction-right {
    transform: rotate(-90deg);
  }

  &.direction-left {
    transform: rotate(90deg);
  }
`;

const DirectedArrow = (props) => {
  const { className, color, direction } = props;
  const cls = classnames(className, `direction-${direction}`, `color-${color}`);

  return <UiDirectedArrow className={cls} />;
};

DirectedArrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['red', 'green', 'black', 'gray']),
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

DirectedArrow.defaultProps = {
  color: 'black',
  direction: 'down',
};

export default DirectedArrow;

import React from 'react';
import 'react-spring-bottom-sheet/dist/style.css';
import styled from 'styled-components';
import BottomSheet from '../../../common/BottomSheet';
import checkIcon from '../../../../assets/svg/icon-check.svg?url';

const Content = styled.div`
  margin: 0 16px 24px;

  .modal-title {
    margin-bottom: 12px;
    display: block;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #000C1A;
  }

  .description {
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;

    ul {
      margin: 12px 0 24px;

      li {
        list-style: none;
        position: relative;
        margin-left: 36px;
        padding: 12px 0;
        font-size: 16px;
        line-height: 20px;
        color: #000C1A;

        &::before {
          content: url('${checkIcon}');
          counter-increment: item;
          position: absolute;
          padding: 4px;
          width: 24px;
          height: 24px;
          left: -36px;
          color: #000C1A;
        }
      }
    }
  }
`;

const CategoryDocumentTypeModal = ({ modalConfig, onClose }) => (
  <BottomSheet
    open={!!modalConfig}
    onDismiss={onClose}
  >
    <Content>
      <span className="modal-title">
        {modalConfig?.category_document_type_code === 'collateralInfo' ? 'Пример запроса о залоге' : 'Пример запроса о кредите'}
      </span>
      <p className="description" dangerouslySetInnerHTML={{ __html: modalConfig?.category_document_type_description }} />
    </Content>
  </BottomSheet>
);

export default CategoryDocumentTypeModal;

import React from 'react';
import classnames from 'classnames';
import DomHolder from '../../../../common/utils/DomHolder';
import HtmlText from '../../../../common/utils/HtmlText';
import { useStatus } from '../hooks/useStatus';
import { getStatusIconComponent } from './getStatusIconComponent';

function ServicePageHeader({ service }) {
  const { id, name, fullDescription } = service ?? {};
  const status = useStatus(service);
  const StatusIcon = getStatusIconComponent(status?.type);

  return (
    <DomHolder>
      {!!service?.id && (
        <div className="service-header">
          <div className="service-icon-wrapper">
            <img
              src={`/services/${id}@3x.png`}
              alt={name}
              className="service-icon"
            />
          </div>

          <div className="service-title">
            <span className="name">{name}</span>
            <HtmlText className="description" tag="h1">{fullDescription}</HtmlText>
          </div>

          {!!status.type && (
            <div className={classnames('service-card-status', `_${status.type}`)}>
              <StatusIcon className="service-card-status-icon" />
              <span className="service-card-status-label">{status.label}</span>
            </div>
          )}
        </div>
      )}
    </DomHolder>
  );
}

export default ServicePageHeader;

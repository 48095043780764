import { useField } from 'formik';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Select } from '../../../../ui-kit';

function SelectField(props) {
  const [field, meta] = useField(props.name);

  return (
    <Select
      {...field}
      {...props}
      state={meta.error && meta.touched ? 'invalid' : undefined}
    />
  );
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(SelectField);

import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Hint } from '../../../../ui-kit';
import { formatCurrency } from '../../../../utils/format';
import LabelValue from '../../../../ui-kit/label-value/LabelValue';
import { sendLogs } from '../../../../utils/sendLogs';
import PageTitle from '../../../common/PageTitle';

const overdueDebtFields = [
  { label: 'Расчёт произведен на дату', param: 'actual_date' },
  { label: 'Просроченный основной долг', param: 'debt_overdue_sum_without_interest', type: 'amount' },
  { label: 'Просроченные проценты', param: 'debt_overdue_interest', type: 'amount' },
  { label: 'Штрафы', param: 'debt_penalty_sum', type: 'amount' },
  { label: 'Госпошлина', param: 'legal_tax_sum', type: 'amount' },
];

function CreditOverdueDebt() {
  const { creditId } = useParams();
  const creditsById = useSelector((state) => state.credits.byId);
  const { additionalInfo } = creditsById[creditId];

  useEffect(() => {
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
  }, []);

  return (
    <div className="page-credit-overdue-debt">
      <PageTitle title="Просроченная задолженность" />
      {overdueDebtFields.filter(({ param }) => typeof additionalInfo[param] !== 'undefined').map(({ label, param, type }) => {
        const rawValue = additionalInfo[param];
        const value = type === 'amount' ? formatCurrency(rawValue) : rawValue;
        return <LabelValue key={`${label}-${value}`} label={label} value={value} />;
      })}
      <Hint type="info" className="overdue-hint">
        Погашение просроченной задолженности производится не позднее следующего рабочего дня после поступления денежных средств на счёт
      </Hint>
    </div>
  );
}

export default memo(CreditOverdueDebt);

import { createSelector } from 'reselect';

export const banksSelector = createSelector(
  (state) => state.bank.byId,
  (byId) => Object.keys(byId).map((id) => byId[id]),
  // .sort((a, b) => {
  //   if (+a.bic > +b.bic) {
  //     return 1;
  //   }
  //   if (+a.bic < +b.bic) {
  //     return -1;
  //   }
  //   return 0;
  // }),
);

import '../utils/element-closest.polyfill';
import { hot } from 'react-hot-loader/root';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { parse } from 'qs';
import { setVersion, toggleConfirmLeaveModal, showNotification, setRequestChannel } from '../actions/app';
import { unauthorized } from '../actions/auth';
import { getCreditsAsync } from '../actions/credits';
import { requestEnd, requestStart } from '../actions/requests';
import { materialTheme } from '../constants/mui-theme';
import { chapterToPathDictionary, navigationItems } from '../constants/navigation';
import { ApiClient } from '../utils/api-client';
import postToNativeApp from '../utils/postToNativeApp';
import Auth from './auth/Auth';
import FailedToLoadDataScreen from './common/FailedToLoadDataScreen';
import WebFontLoader from './common/utils/WebFontLoader';
import i18n from '../i18n';
import store from '../store';
import Credits from './credits/Credits';
import { authUserViaTokenAsync } from '../actions/user';
import LoaderOrChildren from './common/utils/LoaderOrChildren';
import { useUser } from './common/hooks';

const fontConfig = {
  google: {
    families: [
      'Roboto:400,500,700:latin,cyrillic',
      'PT Mono:400:latin,cyrillic',
    ],
  },
};

const theme = {
  primary: '#ff4c7c',
  black: '#000000',
  error: '#E40038',
  success: '#06cf7f',
  info: '#3d5afe',
  warning: '#f5a623',
};

ApiClient.onRequest = () => {
  store.dispatch(requestStart());
};

ApiClient.onResponse = () => {
  setTimeout(() => store.dispatch(requestEnd()), 50);
};

ApiClient.onUnauthorized = () => {
  store.dispatch(unauthorized());
};

ApiClient.onError = (error) => {
  const notification = {
    type: 'error',
    message: error.message ?? error.description ?? JSON.stringify(error),
    timeout: 5000,
  };

  store.dispatch(showNotification(notification));
};

function getUserConfirmation(message, callback) {
  // TODO: user confirmation
  console.log(message);
  callback(false);
}

// TODO: Suspense on every page?
// TODO: 404

function AuthGuardWrap(props) {
  const user = useUser();
  const dispatch = useDispatch();
  const history = useHistory();
  // const historyCounterRef = useRef(0);
  const location = useLocation();
  const locationPathnameRef = useRef(location?.pathname);
  const match = useRouteMatch('/credits/:contractNumber/:chapter?/:subpage?');
  const matchParamsRef = useRef(match?.params ?? {});
  const firstOpenedPathnameRef = useRef('/credits');
  const [loaded, setLoaded] = useState(false);
  const { creditsById, authViaTokenFetchStatus, getCreditsFetchStatus } = useSelector((state) => ({
    creditsById: state.credits.byId,
    authViaTokenFetchStatus: state.user.authViaTokenFetchStatus,
    getCreditsFetchStatus: state.credits.fetchStatus,
  }));
  const appElement = document.getElementById('app');

  useEffect(() => {
    const authParams = parse(props.location.search, { ignoreQueryPrefix: true });
    const {
      authUrl, operatorId, token, contractnumber: contractNumber = '',
      chapter, version = '0', channel, requestId, id: serviceId, name,
    } = authParams;
    const lcChannel = channel?.toLowerCase();

    dispatch(setRequestChannel(lcChannel));
    window.sessionStorage.removeItem('isWeb');

    if (lcChannel === 'ios') {
      window.sessionStorage.setItem('isIos', 'ios');
      appElement.classList.add('is-ios');
    } else {
      window.sessionStorage.removeItem('isIos');
      appElement.classList.remove('is-ios');
      if (lcChannel === 'web') {
        window.sessionStorage.setItem('isWeb', 'true');
      }
    }

    if (!user.authenticated) {
      const sessionAuthUrl = window.sessionStorage.getItem('authUrl');
      const sessionOperatorId = window.sessionStorage.getItem('operatorId');
      const sessionToken = window.sessionStorage.getItem('token');

      dispatch(setVersion(version));

      if ((operatorId && authUrl) || token) {
        if (token) {
          window.sessionStorage.setItem('token', token);
          dispatch(authUserViaTokenAsync({ token }));
        } else if (operatorId && authUrl) {
          window.sessionStorage.setItem('authUrl', authUrl);
          window.sessionStorage.setItem('operatorId', operatorId);
          dispatch(authUserViaTokenAsync(authParams));
        }

        if (chapter) {
          window.sessionStorage.setItem('chapter', chapter);
          window.sessionStorage.setItem('contractNumber', contractNumber);
        } else {
          window.sessionStorage.removeItem('chapter');
          window.sessionStorage.removeItem('contractNumber');
        }

        if (contractNumber && requestId && serviceId && name) {
          window.sessionStorage.setItem('notSignedSupServiceParams', JSON.stringify({
            contractNumber,
            requestId,
            id: serviceId,
            name,
          }));
        } else {
          window.sessionStorage.removeItem('notSignedSupServiceParams');
        }
      } else if (sessionAuthUrl && sessionOperatorId) {
        const authParamsFromSessionStorage = {
          authUrl: sessionAuthUrl,
          operatorId: sessionOperatorId,
        };
        dispatch(authUserViaTokenAsync(authParamsFromSessionStorage));
      } else if (sessionToken) {
        const authTokenFromSessionStorage = {
          token: sessionToken,
        };
        dispatch((authUserViaTokenAsync(authTokenFromSessionStorage)));
      } else {
        history.push('/auth');
      }
    }

    const handleMessage = (message) => {
      try {
        const msg = typeof message === 'string' ? JSON.parse(message) : message;
        const { subpage, contractNumber } = matchParamsRef.current;
        const finServicesUrl = `/credits/${contractNumber}/services`;
        const currentLocation = locationPathnameRef.current;

        if (msg?.type === 'goBack') {
          if (locationPathnameRef.current === '/auth' || !subpage) {
            postToNativeApp({ type: 'close' });
          } else {
            switch (currentLocation) {
              case `${finServicesUrl}/code-verification`:
                dispatch(toggleConfirmLeaveModal(true));
                break;
              case `${finServicesUrl}/service-details`:
                history.push(`${finServicesUrl}`);
                break;
              case `${finServicesUrl}/service-connection`:
                history.push(`${finServicesUrl}/service-details`);
                break;
              case `${finServicesUrl}/document-signing`:
              case `${finServicesUrl}/successful-signing`:
                postToNativeApp({ type: 'close' });
                break;
              case `/credits/${contractNumber}/submit-requisites/confirmation`:
                history.push(`/credits/${contractNumber}/submit-requisites/form`);
                break;
              default:
                history.goBack();
            }
          }
        }
      } catch (e) {
        console.warn('JSNativeBridge.postMessage received invalid JSON argument \'message\'\n', e);
      }
    };

    if (lcChannel === 'web') {
      window.addEventListener('message', ({ data }) => handleMessage(data));
    }

    window.JSNativeBridge = {
      postMessage: handleMessage,
    };

    setLoaded(true);
  }, []);

  useEffect(() => {
    matchParamsRef.current = match?.params ?? {};
  }, [match]);

  useEffect(() => {
    // historyCounterRef.current++;
    locationPathnameRef.current = location?.pathname;
  }, [location]);

  useEffect(() => {
    if (user.authenticated && user.operator_id) {
      dispatch(getCreditsAsync(user.operator_id));
    }
  }, [user.authenticated, user.operator_id]);

  useEffect(() => {
    if (getCreditsFetchStatus.success) {
      const chapter = window.sessionStorage.getItem('chapter');
      if (!chapter) return;

      const contractNumber = window.sessionStorage.getItem('contractNumber') || Object.keys(creditsById)[0];
      const navItem = navigationItems.find(({ to }) => to === chapterToPathDictionary[chapter]);

      if (navItem && !navItem.isHidden(creditsById[contractNumber])) {
        const pathname = `/credits/${contractNumber}${navItem.to}`;
        firstOpenedPathnameRef.current = pathname;
        history.push(pathname);
      } else {
        history.push(`/credits/${contractNumber}`);
      }
    }
  }, [getCreditsFetchStatus.success]);

  if (!loaded) return null;

  return (
    <>
      <LoaderOrChildren loading={authViaTokenFetchStatus.loading || (user.authenticated && getCreditsFetchStatus.initial)} className="auth-loader">
        <Switch>
          <Route path="/credits" component={Credits} />
          <Route path="/auth" component={Auth} />
          <Redirect to="/credits" />
        </Switch>
      </LoaderOrChildren>

      <FailedToLoadDataScreen />
    </>
  );
}

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <MuiThemeProvider theme={materialTheme}>
                {/*<WebFontLoader config={fontConfig}>*/}
                  <Suspense fallback={<div />}>
                    <Route path="/" component={AuthGuardWrap} />
                  </Suspense>
                {/*</WebFontLoader>*/}
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default hot(App);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styled, { createGlobalStyle } from 'styled-components';
import OnClickOutside from '../click-outside/OnClickOutside';
import Portal from '../portal/Portal';
import { color, resetButton, transition } from '../styles/mixins';
import CloseIcon from './assets/close.svg';
import LogoIcon from '../../static/new-svg-logo.svg';

const GlobalStyle = createGlobalStyle`
  .ui-modal-open {
    overflow: hidden;

    .modal-padding-container {
      padding-right: ${(p) => p.scrollbarWidth}px;
    }
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(8, 9, 10, 0.6);

  &.ui-modal-transition {
    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
      transition: opacity ${transition.normal};
    }
    &-exit{
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transition: opacity ${transition.normal};
    }
`;

const Content = styled.div`
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  width: calc(100% - 48px);
  // margin: 10vh auto; // IE11 flex bug workaround
  margin: 24px;
  padding: 24px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 16px 32px 0 ${color.black.rgba(0.09)};
  will-change: transform;

  .modal-content {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    word-break: break-word;

    color: #585858;

    a {
      color: #55B3F7;
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;

    .ui-button {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &:focus {
    outline: 0;
  }
`;

const Header = styled.div`
  font-family: inherit;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Body = styled.div`
  font-family: inherit;
  font-size: 18px;
  line-height: 1.67;
`;

const CloseButton = styled.button`
  ${resetButton};
  display: block;
  position: absolute;
  top: 20px;
  right: 30px;

  .close-icon {
    display: block;
  }
`;

const UiModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 0;
  z-index: 101;

  .ui-modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.ui-modal-transition {
    &-enter {
      ${Backdrop} {
        opacity: 0;
      }
      ${Content} {
        opacity: 0;
        transform: translateY(-15px) rotate(0.0001deg);
      }
    }
    &-enter-active {
      ${Backdrop} {
        opacity: 1;
        transition: opacity ${transition.normal};
      }
      ${Content} {
        opacity: 1;
        transform: translateY(0) rotate(0.0001deg);
        transition: ${transition.normal};
        transition-property: opacity, transform;
      }
    }
    &-exit{
      ${Backdrop} {
        opacity: 1;
      }
      ${Content} {
        opacity: 1;
        transform: scale(1);
      }
    }
    &-exit-active {
      ${Backdrop} {
        opacity: 0;
        transition: opacity ${transition.normal};
      }
      ${Content} {
        opacity: 0;
        transform: scale(0.95);
        transition: ${transition.normal};
        transition-property: opacity, transform;
      }
    }
  }
`;

let scrollbarWidth;

function useCombinedRefs(...refs) {
  return (target) => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(target);
      } else {
        ref.current = target;
      }
    });
  };
}

class Modal extends PureComponent {
  static getScrollbarWidth() {
    if (typeof scrollbarWidth === 'undefined') {
      const scrollDiv = document.createElement('div');
      scrollDiv.style.top = '-9999px';
      scrollDiv.style.width = '50px';
      scrollDiv.style.height = '50px';
      scrollDiv.style.overflow = 'scroll';
      document.body.appendChild(scrollDiv);
      scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
    }

    return scrollbarWidth;
  }

  timeoutId = 0;
  element = React.createRef();
  content = React.createRef();
  prevActiveElement = null;

  componentDidMount() {
    const { active } = this.props;

    if (active) {
      this.onShow();
    }
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props;

    if (active !== prevProps.active) {
      if (active) {
        this.onShow();
      } else {
        this.onHide();
      }
    }
  }

  componentWillUnmount() {
    this.onHide(true);
  }

  onShow() {
    const scrollbarWidth = Modal.getScrollbarWidth();

    clearTimeout(this.timeoutId);

    document.body.classList.add('ui-modal-open');
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    this.element.current.style.paddingRight = `${scrollbarWidth}px`;

    if (document.activeElement) {
      this.prevActiveElement = document.activeElement;
    }

    this.content.current.focus();
  }

  onHide(immediately) {
    clearTimeout(this.timeoutId);

    const hide = () => {
      document.body.classList.remove('ui-modal-open');
      document.body.style.paddingRight = '';

      if (immediately) return;

      this.element.current.style.paddingRight = '';

      if (this.prevActiveElement) {
        try {
          this.prevActiveElement.focus();
        } catch {
          if (process.env.NODE_ENV === 'development') {
            console.warn('[Modal] Error occurred on saved element focus', this.prevActiveElement);
          }
        }
      }
    };

    if (immediately) {
      hide();
      return;
    }

    this.timeoutId = setTimeout(hide, 300);
  }

  handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.props.onClose();
    }
  };

  handleClose = () => this.props.onClose();

  handleClickOutside = () => {
    if (this.props.withOnClickOutside) {
      this.props.onClose();
    }
  };

  render() {
    const { className, header, active, children, withLogo, withCloseIcon } = this.props;
    const scrollbarWidth = Modal.getScrollbarWidth();
    const transitionProps = {
      classNames: 'ui-modal-transition',
      timeout: 300,
      in: active,
      mountOnEnter: true,
      unmountOnExit: true,
    };

    return (
      <>
        <GlobalStyle scrollbarWidth={scrollbarWidth} />

        <Portal id="__modal_container">
          <CSSTransition {...transitionProps}>
            <UiModal ref={this.element} className={className}>
              <OnClickOutside onClickOutside={this.handleClickOutside} active={this.props.active}>
                {(ref) => (
                  <Content className="ui-modal-body" ref={useCombinedRefs(this.content, ref)} tabIndex="0" onKeyDown={this.handleKeyDown}>
                    {withLogo && <LogoIcon className="logo-icon" />}

                    {header && <Header className="ui-modal-header" dangerouslySetInnerHTML={{ __html: header }} />}

                    {withCloseIcon && (
                      <CloseButton onClick={this.handleClose} tabIndex="-1">
                        <CloseIcon className="close-icon" />
                      </CloseButton>
                    )}

                    <Body>
                      {children}
                    </Body>
                  </Content>
                )}
              </OnClickOutside>
            </UiModal>
          </CSSTransition>
        </Portal>

        <Portal id="__modal_backdrop_container">
          <CSSTransition {...transitionProps}>
            <Backdrop onClick={this.handleClose} />
          </CSSTransition>
        </Portal>

      </>
    );
  }
}

Modal.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  withCloseIcon: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  withOnClickOutside: PropTypes.bool,
};

Modal.defaultProps = {
  withCloseIcon: false,
  withOnClickOutside: false,
};

export default Modal;

import classnames from 'classnames';
import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, transition } from '../styles/mixins';

const UiFormControl = styled.div`
  margin-top: 42px;

  &.has-label {
    margin-top: 20px;

    .label {
      font-family: inherit;
      font-size: 13px;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      color: #000C1A;
      margin-bottom: 8px;
      transition: opacity ${transition.fast};
      will-change: opacity;
    }

    .bigger-line-height {
      display: inline-block;
      padding: 2px 0 0;
    }

    .required-flag {
      margin-left: .25em;
      font-weight: 400;
    }
  }

  &.compact {
    margin-top: 24px;
  }

  &.semiCompact {
    margin-top: 30px;
  }

  &.noMarginTop {
    margin-top: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &.highlighted .label {
    opacity: 1;
  }

  &.v2 {
    .label {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-transform: none;
    }
  }

  .content {
    min-height: 24px;
    font-family: inherit;
    font-size: 18px;
    line-height: 24px;
    color: ${color.black};

    input::placeholder {
      font-size: 14px;
      line-height: 16px;
      color: #A6AAB1;
    }
  }
`;

export const FormControlContext = createContext();
const { Provider } = FormControlContext;

const FormControl = (props) => {
  const { label, children, className, noMarginTop, compact, semiCompact, design = 'v1' } = props;
  const [highlighted, setHighlighted] = useState(false);
  const context = useMemo(() => ({ highlighted, setHighlighted }), [highlighted, setHighlighted]);

  return (
    <UiFormControl
      className={classnames('ui-form-control', className, design, { 'has-label': label, noMarginTop, compact, semiCompact, highlighted })}
    >
      {
        label && (<div className="label">{label}</div>)
      }
      <div className="content">
        <Provider value={context}>
          {children}
        </Provider>
      </div>
    </UiFormControl>
  );
};

FormControl.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  compact: PropTypes.bool,
  semiCompact: PropTypes.bool,
  children: PropTypes.node,
};

export default FormControl;

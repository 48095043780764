import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../styles/mixins';

export const H1 = styled.h1`
  font-family: Roboto;
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  color: ${color.black};
  margin: 0;

  ${(p) => p.bold && `
    font-weight: 700;
  `}
`;

H1.propTypes = {
  bold: PropTypes.bool,
};

export const H2 = styled.h2`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: ${color.black};
  margin: 0;

  ${(p) => p.bold && `
    font-weight: 700;
  `}
`;

export const H4 = styled.h4`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: ${color.black};
  margin: 0;

  ${(p) => p.bold && `
    font-weight: 700;
  `}

    ${(p) => p.gray && `
    color: #979797;
  `}
`;

H2.propTypes = {
  bold: PropTypes.bool,
};

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { getCredits } from '../actions/credits';
import * as actions from '../actions/user';
import { asyncReducerFactory } from '../utils/redux-utils';

const initialState = {
  authenticated: false,
  login: '',
  requestSmsAvailable: true,
};

const userDataReducer = createReducer({ ...initialState })
  .handleAction(actions.authUserViaToken.success, (state, { payload, meta }) => {
    if (meta.needAuth) {
      return {
        ...state,
        ...payload,
        authenticated: false,
      };
    }
    return {
      ...state,
      ...payload,
      authenticated: true,
    };
  })
  .handleAction(actions.authUserViaAgreementConfirmation.success, (state, { payload }) => ({
    ...state,
    ...payload,
    authenticated: true,
  }))
  .handleAction(actions.authUserViaAgreementConfirmation.failure, (state) => ({
    ...state,
    authenticated: false,
  }))
  .handleAction(actions.userSmsResendRequestNotAvailable, (state) => ({
    ...state,
    requestSmsAvailable: false,
  }))
  .handleAction(actions.userSmsResendRequestAvailable, (state) => ({
    ...state,
    requestSmsAvailable: true,
  }));

const creditsLoadedByOperatorIdReducer = createReducer({})
  .handleAction(getCredits.success, (state, { meta }) => ({
    ...state,
    [meta.operatorId]: true,
  }));

//todo место для обработки автризации через смс

export default combineReducers({
  data: userDataReducer,
  creditsLoadedByOperatorId: creditsLoadedByOperatorIdReducer,
  authViaAgreementConfirmationFetchStatus: asyncReducerFactory(actions.authUserViaAgreementConfirmation),
  authViaTokenFetchStatus: asyncReducerFactory(actions.authUserViaToken),
});

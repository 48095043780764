import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import moment from 'moment';
import { asyncReducerFactory, setDataById } from '../utils/redux-utils';
import * as actions from '../actions/credits';
import normalize from '../utils/normalize';

// function renameKey(arr, key = '_id', newKey = 'id') {
//   return arr.map((item) => {
//     const newKeyValue = item[key];
//     return { ...item, [newKey]: newKeyValue };
//   });
// }

const creditReducer = createReducer({})
  .handleAction(actions.getCredits.success, (state, { payload }) => ({ ...state, ...normalize(payload, 'contract_number') }))
  .handleAction(actions.getCredits.failure, (state, { payload }) => ({ ...state, ...normalize(payload.data ?? [], 'contract_number') }))
  .handleAction(actions.getCreditPaymentSchedule.success, (state, { payload, meta }) => {
    const { contractNumber } = meta;
    const paymentSchedule = payload
      .map(({
        payment_number: number, payment_date: date, payment_sum: sum, payment_debt_sum: debtSum, payment_percent_sum: percentSum,
      }) => ({ number, date, sum, debtSum, percentSum }))
      .sort((a, b) => moment(b, 'DD.MM.YYYY').format('x') - moment(a, 'DD.MM.YYYY').format('x'));
    return setDataById(state, contractNumber, 'paymentSchedule', paymentSchedule);
  })
  .handleAction(actions.getCreditRepaymentHistory.success, (state, { payload, meta }) => {
    const { contractNumber } = meta;
    const repaymentsByDate = payload.reduce((acc, item) => {
      const date = item.operdate;
      delete item.operdate;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    const repaymentsSortedArray = Object.keys(repaymentsByDate)
      .sort((a, b) => moment(b, 'DD.MM.YYYY').format('x') - moment(a, 'DD.MM.YYYY').format('x'))
      .map((date) => ({ date, repayments: repaymentsByDate[date] }));
    return setDataById(state, contractNumber, 'repaymentHistory', repaymentsSortedArray);
  })
  .handleAction(actions.getCreditCardOperations.success, (state, { payload, meta }) => {
    const { contractNumber } = meta;
    const cardOperationsByDate = payload.data.reduce((acc, item) => {
      const date = item.transaction_date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push({
        date,
        name: item.transaction_name,
        amount: item.transaction_amount,
        place: item.transaction_place,
      });
      return acc;
    }, {});
    const cardOperationsSortedArray = Object.keys(cardOperationsByDate)
      .sort((a, b) => moment(b, 'DD.MM.YYYY').format('x') - moment(a, 'DD.MM.YYYY').format('x'))
      .map((date) => ({ date, operations: cardOperationsByDate[date] }));
    return setDataById(state, contractNumber, 'cardOperations', cardOperationsSortedArray);
  })
  .handleAction(actions.getCreditOperationLogAppeals.success, (state, { payload }) => {
    const newState = { ...state };

    const getLinks = (item) => {
      const { request_subtype_code, request_user_document_mobile_url_list, request_user_document_mobile_url } = item;
      if (
        !item ||
        (request_subtype_code === 'FINPROTECTPURCHASE' && !request_user_document_mobile_url_list) ||
        (request_subtype_code !== 'FINPROTECTPURCHASE' && !request_user_document_mobile_url)
      ) {
        return {};
      }

      const { url: downloadLink } = request_subtype_code !== 'FINPROTECTPURCHASE' ?
        { url: request_user_document_mobile_url } :
        (request_user_document_mobile_url_list.find(({ documentType }) => documentType === 'REQUEST') ?? {});
      const { url: additionalDownloadLink } = !request_user_document_mobile_url_list ? {} :
        (request_user_document_mobile_url_list.find(({ documentType }) => documentType === 'SUPAGR') ?? {});

      return {
        downloadLink: `<a href='${downloadLink}' download>Загрузить</a>`,
        additionalDownloadLink: additionalDownloadLink && `<a href='${additionalDownloadLink}' download>Загрузить</a>`,
      };
    };

    const logArrayByContractNumber = payload.data.reduce((acc, item) => {
      const { contract_number: id } = item;
      if (!acc[id]) {
        acc[id] = [];
      }
      acc[id].push(item);
      return acc;
    }, {});

    Object.keys(state).forEach((id) => {
      let operationLogAppeals = [];

      if (logArrayByContractNumber[id]) {
        const operationLogByDate = logArrayByContractNumber[id].reduce((acc, item) => {
          const { request_date: date } = item;
          const { downloadLink, additionalDownloadLink } = getLinks(item);

          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push({
            orderNo: item.order_no,
            contractNumber: item.contract_number,
            contractTypeCode: item.contract_type_code,
            requestId: item.request_id,
            date: item.request_date,
            name: item.request_name,
            status: item.status,
            stage: item.stage,
            earlyRepayDate: item.early_repay_date, // coming soon in rusfinance api
            earlyRepaySum: item.early_repay_sum, // coming soon in rusfinance api
            wayOfGetting: item.way_of_getting, // coming soon in rusfinance api
            wayOfGettingName: item.way_of_getting_name, // coming soon in rusfinance api
            additionalParameters: item.additional_parameters, // coming soon in rusfinance api
            creditDetailsUrl: item.credit_details_url, // why html in their example?
            subtypeCode: item.request_subtype_code,
            requestStateCode: item.request_state_code,
            requestName: item.request_name,
            isGoToChatAvailable: item.is_go_to_chat_available,
            requestUserDocumentMobileUrl: item.request_user_document_mobile_url,
            requestUserDocumentMobileUrlList: item.request_user_document_mobile_url_list,
            downloadLink,
            additionalDownloadLink,
            // result: item.result,
            acceptedAmount: item.accepted_amount,
          });
          return acc;
        }, {});
        operationLogAppeals = Object.keys(operationLogByDate)
          .sort((a, b) => moment(b, 'DD.MM.YYYY').format('x') - moment(a, 'DD.MM.YYYY').format('x'))
          .map((date) => ({ date, log: operationLogByDate[date] }));
      }

      newState[id] = {
        ...newState[id],
        operationLogAppeals,
      };
    });

    return newState;
  })
  .handleAction(actions.getCreditRequisites.success, (state, { payload, meta }) => {
    const { contractNumber } = meta;
    return setDataById(state, contractNumber, 'requisites', payload);
  })
  .handleAction(actions.getCreditRemittanceAmount.success, (state, { payload, meta }) => {
    const { contractNumber } = meta;
    const {
      account_rest_fact: accountRestFact,
      error_message: errorMessage,
      remittance_condition_passed: remittanceConditionPassedRaw,
    } = payload;
    const remittanceConditionPassed = remittanceConditionPassedRaw === '0' ? false : !!remittanceConditionPassedRaw;
    const modifiedPayload = { accountRestFact, errorMessage, remittanceConditionPassed };
    return setDataById(state, contractNumber, 'remittanceAmount', modifiedPayload);
  })
  .handleAction(actions.getCreditRemittanceAmount.failure, (state, { meta }) => {
    const { contractNumber } = meta;
    const modifiedPayload = { remittanceConditionPassed: 0, errorMessage: 'Сервис не доступен. Для уточнения информации обратитесь в центр обслуживания клиентов' };
    return setDataById(state, contractNumber, 'remittanceAmount', modifiedPayload);
  })
  .handleAction(actions.getCreditRemittanceTypes.success, (state, { payload, meta }) => {
    const { contractNumber } = meta;
    const modifiedPayload = payload.map(({ remittanceTypeCode: value, remittanceTypeName: label }) => ({ value, label }));
    return setDataById(state, contractNumber, 'remittanceTypes', modifiedPayload);
  })
  .handleAction(actions.getCreditRemittanceAccounts.success, (state, { payload, meta }) => {
    const { contractNumber } = meta;
    const modifiedPayload = payload.map((account) => ({ value: account, label: account }));
    return setDataById(state, contractNumber, 'remittanceAccounts', modifiedPayload);
  })
  .handleAction(actions.confirmCreditRemittanceRequest.success, (state, { payload, meta }) => {
    const { contractNumber } = meta;
    return { ...state, [contractNumber]: { ...state[contractNumber], remittanceConfirmData: { ...payload } } };
  });

const earlyRepaySumReducer = createReducer({})
  .handleAction(actions.getEarlyRepaySumByDate.success, (state, { payload }) => payload);

export default combineReducers({
  byId: creditReducer,
  earlyRepaySum: earlyRepaySumReducer,
  fetchStatus: asyncReducerFactory(actions.getCredits),
  paymentScheduleFetchStatus: asyncReducerFactory(actions.getCreditPaymentSchedule),
  repaymentHistoryFetchStatus: asyncReducerFactory(actions.getCreditRepaymentHistory),
  operationLogAppealsFetchStatus: asyncReducerFactory(actions.getCreditOperationLogAppeals),
  cardOperationsFetchStatus: asyncReducerFactory(actions.getCreditCardOperations),
  requisitesFetchStatus: asyncReducerFactory(actions.getCreditRequisites),
  remittanceAmountFetchStatus: asyncReducerFactory(actions.getCreditRemittanceAmount),
  remittanceTypesFetchStatus: asyncReducerFactory(actions.getCreditRemittanceTypes),
  remittanceAccountsFetchStatus: asyncReducerFactory(actions.getCreditRemittanceAccounts),
  remittanceCreateRequestFetchStatus: asyncReducerFactory(actions.createCreditRemittanceRequest),
  remittanceConfirmRequestFetchStatus: asyncReducerFactory(actions.confirmCreditRemittanceRequest),
  getEarlyRepaySumByDateStatus: asyncReducerFactory(actions.getEarlyRepaySumByDate),
});

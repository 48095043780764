import React, { forwardRef } from 'react';
import { useFormControl } from './useFormControl';

function withFormControl(WrappedComponent) {
  const WithFormControl = forwardRef((props, ref) => {
    const fcProps = useFormControl(props);

    return (
      <WrappedComponent
        ref={ref}
        {...props}
        {...fcProps}
      />
    );
  });

  WithFormControl.displayName = `WithFormControl(${getDisplayName(WrappedComponent)})`;

  return WithFormControl;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withFormControl;

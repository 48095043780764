import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import BottomSheet from '../../../../common/BottomSheet';
import { Button } from '../../../../../ui-kit';
import { useAsyncStatus, useFixedFooter } from '../../../../common/hooks';
import AlertBlock from '../../../../common/AlertBlock';
import { formatDate } from '../../../../../utils/format';
import HtmlText from '../../../../common/utils/HtmlText';
import DomHolder from '../../../../common/utils/DomHolder';

const descriptionByServiceId = {
  234: 'Досрочное погашение с услугой «Свобода платежа» возможно в любой рабочий день без заявления при наличии средств на&nbsp;счёте обслуживания автокредита',
  224: 'Для досрочного погашения кредита после активации опции подайте новую заявку с учётом нового размера платежа',
  default: 'Для досрочного погашения кредита после активации опции подайте новую заявку с учётом новой даты платежа',
};

const CancelEarlyRepaymentContent = ({ submitData, onClose, onSubmit }) => {
  const creditInfo = useSelector((state) => state.credits.byId)[submitData?.contractNumber] ?? {};
  const finProtectRegistrationStatus = useAsyncStatus((state) => state.services.finProtectRegistrationStatus);
  const formattedEarlyPaymentDate = formatDate(creditInfo.additionalInfo?.early_payment_date);

  const footerRef = useRef();
  useFixedFooter(footerRef, 'cancellation-application-wrapper', [finProtectRegistrationStatus.error]);

  const handleSubmit = useCallback(() => {
    onSubmit(submitData);
  }, [submitData]);

  return (
    <div className="cancellation-application-wrapper">
      <div className={classnames('content-wrapper', { loading: finProtectRegistrationStatus.loading })}>
        <h1 className="title">Заявка на досрочное погашение будет отменена</h1>
        <p className="description">
          {`Ваша активная заявка на досрочное погашение на ${formattedEarlyPaymentDate} отменится, если продолжите.`}
        </p>
        <HtmlText className="description second">{descriptionByServiceId[submitData?.id] ?? descriptionByServiceId.default}</HtmlText>
      </div>

      <div className="footer-button-wrapper" ref={footerRef}>
        {finProtectRegistrationStatus.error && (
          <AlertBlock text="Не удалось загрузить данные.<br />Попробуйте повторить попытку" status="fatal-error" margin="0 0 28px" />
        )}

        <Button
          className="footer-button continue"
          design="red"
          size="m"
          loading={finProtectRegistrationStatus.loading}
          onClick={handleSubmit}
        >
          Продолжить
        </Button>
        <Button
          className="footer-button return"
          design="secondary"
          size="m"
          type="button"
          disabled={finProtectRegistrationStatus.loading}
          onClick={onClose}
        >
          Вернуться назад
        </Button>
      </div>
    </div>
  );
};

const CancelEarlyRepaymentModal = ({ submitData, onClose, onSubmit }) => (
  <BottomSheet
    open={!!submitData}
    onDismiss={onClose}
  >
    <DomHolder>
      {!!submitData && <CancelEarlyRepaymentContent submitData={submitData} onClose={onClose} onSubmit={onSubmit} />}
    </DomHolder>
  </BottomSheet>
);

export default CancelEarlyRepaymentModal;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import LabelValue from '../label-value/LabelValue';

const UiRepaymentItemHeader = styled.div`
  position: relative;
  padding: 8px 0 8px 28px;

  .icon {
    position: absolute;
    top: 0;
    left: 4px;
    bottom: 0;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 6px;
      bottom: 0;
      width: 2px;
      background-image: linear-gradient(rgba(218, 218, 218, 0.3) 33%, rgba(0,0,0,0) 0%);
      background-position: right;
      background-size: 2px 9px;
      background-repeat: repeat-y;
      transform: translateX(0.5px);
      //border-right: 2px dashed rgba(218, 218, 218, 0.3);
    }
    &:after {
      content: '';
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #DADADA;
      transform: scale(.6);
    }
  }

  .date-with-amount {
    display: flex;
    align-items: center;
    font-weight: 400;
    padding-top: 8px;
  }

  .date {
    margin-right: 34px;
  }

  &.active {
    .icon {
      &:after {
        transform: scale(1);
        background-color: #000;
      }
    }
  }
`;

function PaymentScheduleItemHeader(props) {
  const { className, active, date, amount } = props;

  return (
    <UiRepaymentItemHeader className={classnames('repayment-item-header', className, { active })}>
      <div className="icon" />
      <div className="date-with-amount">
        <div className="date">
          <LabelValue label="Дата" value={date} />
        </div>
        <div className="amount">
          <LabelValue label="Сумма платежа" value={amount} />
        </div>
      </div>
    </UiRepaymentItemHeader>
  );
}

PaymentScheduleItemHeader.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PaymentScheduleItemHeader;

import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { showServicesInformer } from '../../../../actions/services';
import DownloadIcon from '../../../../assets/svg/download-with-bg.svg';
import { Button, Portal } from '../../../../ui-kit';
import ErrorIcon from '../../../../assets/svg/status/status-error-v2.svg';
import SuccessIcon from '../../../../assets/svg/status/status-success-v2.svg';
import { transition } from '../../../../ui-kit/styles/mixins';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import PageTitle from '../../../common/PageTitle';

const UiInformer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #FFFFFF;
  outline: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px 24px;

  .page-wrapper {
    padding: 21px 0 0;
  }

  &.ui-informer-transition {
    &-enter {
      opacity: 0;
      transform: translateY(-100%);
    }
    &-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: ${transition.normal};
      transition-property: opacity, transform;
    }
  }
`;

const StyledInformerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ withMarginAuto }) => (withMarginAuto ? 'auto' : '0')};

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;
  }
  .description {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #8B95A4;
    margin: 8px 0 24px;
  }
  .reload-button {
    width: max-content;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;
  }
  .download-link {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      margin-top: 8px;
      color: #000C1A;
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

const StyledTitle = styled.h1`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  color: #000C1A;
  margin-top: 24px;
  max-width: 280px;
`;

const StyledParagraph = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  color: #647081;
  max-width: 280px;

  margin-top: 8px;
`;

const StyledButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 48px !important;
  text-transform: unset;
  margin-bottom: 12px;
  line-height: 20px !important;
  font-weight: 500 !important;

  &:last-of-type {
    margin-bottom: 0;
  }
  &.design-secondary {
    background-color: #E5E7EA !important;
    border: none !important;
  }
`;

const ServicesInformer = ({ creditId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const config = useSelector((state) => state.services.servicesInformerConfig);
  const {
    active, title, descriptions, pageTitle, buttons, type = 'error', downloadLink, downloadLabel,
  } = config;

  const transitionProps = {
    classNames: 'ui-informer-transition',
    timeout: 300,
    in: active,
    mountOnEnter: true,
    unmountOnExit: true,
  };

  const onGotoInquiries = useCallback(() => {
    postToNativeApp({ type: 'titleChanged', payload: { chapter: 'INQUIRIES' } });
    history.push(`/credits/${creditId}/operation-log?reload=force`);
    dispatch(showServicesInformer({}));
  }, [creditId]);

  const onClose = useCallback(() => {
    postToNativeApp({ type: 'close' });
    history.push(`/credits/${creditId}`);
    dispatch(showServicesInformer({}));
  }, [creditId]);

  const onClickByAction = {
    onClose,
    onGotoInquiries,
  };

  useEffect(() => {
    if (active) {
      postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
      sendLogs({
        '': 'Open new page',
        title,
      });
    }
  }, [active]);

  return (
    <Portal id="__informer_container">
      <CSSTransition {...transitionProps}>
        <UiInformer className="ui-informer">
          {!!pageTitle && <PageTitle title={pageTitle} onBackClick={onClose} />}

          <StyledInformerBody withMarginAuto>
            {type === 'success' ? <SuccessIcon /> : <ErrorIcon />}
            <StyledTitle>{title}</StyledTitle>
            {!!descriptions?.length && descriptions.map((description) => (
              <StyledParagraph key={description} dangerouslySetInnerHTML={{ __html: description }} />
            ))}
            {downloadLink && (
              <a href={downloadLink} download className="download-link">
                <DownloadIcon className="icon" />
                <span className="text">{downloadLabel ?? 'Скачать'}</span>
              </a>
            )}
          </StyledInformerBody>

          {buttons?.length > 0 && (
            <StyledButtonGroup>
              {buttons.map(({ design, label, onClick, action }) => (
                <StyledButton key={label} design={design} size="l" onClick={onClick ?? onClickByAction[action]}>
                  {label}
                </StyledButton>
              ))}
            </StyledButtonGroup>
          )}
        </UiInformer>
      </CSSTransition>
    </Portal>
  );
};

//TODO проверить стили

export default memo(ServicesInformer);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import DomHolder from './utils/DomHolder';

class CollapsibleErrorHint extends PureComponent {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    message: PropTypes.string,
  };

  height = 0;

  handleEnter = (node) => {
    this.height = node.offsetHeight;
    node.style.height = '0px';
  };

  handleEntering = (node) => {
    node.style.height = `${this.height}px`;
  };

  handleEntered = (node) => {
    node.style.height = null;
  };

  handleExit = (node) => {
    node.style.height = `${this.height}px`;
  };

  handleExiting = (node) => {
    node.style.height = '0px';
  };

  handleExited= (node) => {
    node.style.height = '0px';
  };

  render() {
    const { active, message } = this.props;

    return (
      <CSSTransition
        classNames="collapsible-error-hint"
        in={active}
        timeout={600}
        mountOnEnter
        unmountOnExit
        onEnter={this.handleEnter}
        onEntering={this.handleEntering}
        onEntered={this.handleEntered}
        onExit={this.handleExit}
        onExiting={this.handleExiting}
        onExited={this.handleExited}
      >
        <div className="collapsible-error-hint">
          <div className="collapsible-error-hint-content">
            <DomHolder>
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </DomHolder>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default CollapsibleErrorHint;

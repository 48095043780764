import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import DomHolder from '../../components/common/utils/DomHolder';

class FileInputItem extends PureComponent {
  static propTypes = {
    in: PropTypes.bool,
    children: PropTypes.node,
  };

  height = 0;

  handleEnter = (node) => {
    this.height = node.offsetHeight;
    node.style.height = '0px';
  };

  handleEntering = (node) => {
    node.style.height = `${this.height}px`;
  };

  handleEntered = (node) => {
    node.style.height = null;
  };

  handleExit = (node) => {
    node.style.height = `${this.height}px`;
  };

  handleExiting = (node) => {
    node.style.height = '0px';
  };

  handleExited= (node) => {
    node.style.height = '0px';
  };

  render() {
    const { in: active, children } = this.props;

    return (
      <CSSTransition
        classNames="file-input-item"
        in={active}
        timeout={600}
        mountOnEnter
        unmountOnExit
        onEnter={this.handleEnter}
        onEntering={this.handleEntering}
        onEntered={this.handleEntered}
        onExit={this.handleExit}
        onExiting={this.handleExiting}
        onExited={this.handleExited}
      >
        <div className="file-input-item">
          <div className="file-input-item-content">
            <DomHolder>
              {children}
            </DomHolder>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default FileInputItem;

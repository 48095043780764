import classnames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import ChevronIcon from '../../../../assets/svg/chevron-right.svg';
import { Button } from '../../../../ui-kit';
import postToNativeApp from '../../../../utils/postToNativeApp';
import BottomSheet from '../../../common/BottomSheet';
import CollapsibleElement from '../../../common/CollapsibleElement';
import DomHolder from '../../../common/utils/DomHolder';
import HtmlText from '../../../common/utils/HtmlText';

function RateInfoModalContent({ config, creditId }) {
  const history = useHistory();
  const { reasonName, reasonDescription, name, description, dropdownBlock = [] } = config ?? {};
  const [openedDropdowns, setOpenedDropdowns] = useState({});
  const dropdownsFilteredList = useMemo(() => dropdownBlock.filter(({ title, desc }) => (
    title && desc
  )), [dropdownBlock]);

  const toggleDropdown = useCallback((i) => {
    setOpenedDropdowns((prev) => ({ ...prev, [i]: !prev[i] }));
  }, [openedDropdowns]);

  const handleClick = useCallback((section) => {
    let route = '';
    switch (section) {
      case 'FIN_PROTECTION':
        route = 'services';
        break;
      case 'KASKO_PROLONGATION':
        route = 'insurances-products';
        break;
      default:
        route = '';
    }

    postToNativeApp({ type: 'titleChanged', payload: { chapter: section } });
    history.push({ pathname: `/credits/${creditId}/${route}`, state: { backClickRoute: `/credits/${creditId}` } });
  }, [creditId]);

  return (
    <div className="rate-info-modal-content">
      <HtmlText tag="span" className="modal-title">{reasonName ?? name}</HtmlText>
      <HtmlText tag="p" className="modal-description">{reasonDescription ?? description}</HtmlText>

      {!!dropdownsFilteredList.length && (
        <div className="dropdowns-list">
          {dropdownsFilteredList.map(({ title, desc, button }, i) => (
            <div
              key={title + desc}
              className={classnames('dropdown-wrapper', { _opened: openedDropdowns[i] })}
            >
              <button className="dropdown-toggle-button" onClick={() => toggleDropdown(i)}>
                <HtmlText tag="span">{title}</HtmlText>
                <ChevronIcon className="chevron-icon" />
              </button>

              <CollapsibleElement active={!!openedDropdowns[i]}>
                <div className="dropdown-content">
                  <HtmlText tag="p" className="dropdown-desc">{desc}</HtmlText>
                  {button && (
                    <Button className="dropdown-button" design="grey" size="sm" onClick={() => handleClick(button)}>
                      Перейти в раздел
                    </Button>
                  )}
                </div>
              </CollapsibleElement>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function RateInfoModal({ config, creditId, onClose }) {
  return (
    <BottomSheet
      open={!!config}
      onDismiss={onClose}
    >
      <DomHolder>
        {!!config && <RateInfoModalContent key={JSON.stringify(config)} config={config} creditId={creditId} />}
      </DomHolder>
    </BottomSheet>
  );
}

export default RateInfoModal;

export default class Backend {
  static type = 'backend';

  // @ts-ignore
  type = 'backend';

  init() {
    // this.services = services;
    // this.options = Object.assign({}, options, defaults);
  }

  read(language, namespace, callback) {
    import(`./locales/${language}/${namespace}.json`).then((module) => callback(null, module));
  }

  create(languages, namespace, key, fallbackValue) {
    throw new Error('Not implemented');
  }
}

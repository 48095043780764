export function customFileValidate(values, uploadDocumentTypes, setError, maxGroupSize = 20000000) {
  const errors = {};
  if (uploadDocumentTypes) {
    const sumFileSize = Object.keys(uploadDocumentTypes).reduce(
      (sum, fieldName) => sum + values[fieldName].reduce((sum, { fileSize, fileError }) => sum + (!fileError ? fileSize : 0), 0),
      0,
    );
    if (sumFileSize > maxGroupSize) {
      Object.keys(uploadDocumentTypes).forEach((fieldName) => {
        if (values[fieldName].length > 0) {
          const error = 'Общий размер всех файлов не должен превышать 20 МБ';
          errors[fieldName] = error;
          setError({ type: 'error', label: error });
        }

        if (values[fieldName].some(({ fileError }) => fileError && !fileError.startsWith('Ошибка преобразования файла'))) {
          errors[fieldName] = `Проверьте, пожалуйста, размер и формат прикреплённых файлов. ${errors[fieldName]}`;
        }
      });
      return errors;
    }

    setError({});

    Object.entries(uploadDocumentTypes).forEach(([fieldName, fieldConfig]) => {
      if (fieldConfig.required && !values[fieldName].length) {
        errors[fieldName] = fieldConfig.requiredErrorMessage;
      }
      if (values[fieldName].length > fieldConfig.maxFiles) {
        errors[fieldName] = `Превышено максимальное количество файлов (${values[fieldName].length} / ${fieldConfig.maxFiles})`;
      }
      if (values[fieldName].some(({ fileError }) => fileError)) {
        if (values[fieldName].filter(({ fileError }) => fileError).every(({ fileError }) => !fileError.startsWith('Ошибка преобразования файла'))) {
          errors[fieldName] = 'Проверьте, пожалуйста, размер и формат прикреплённых файлов';
        } else {
          errors[fieldName] = 'Произошла ошибка загрузки одного или нескольких файлов';
        }
      }
    });
  }
  // if (values.customerComment && values.customerComment.length > 4000) {}
  return errors;

}

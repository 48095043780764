import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Redirect, useParams, useRouteMatch } from 'react-router';
import CreditCardOperations from './card-operations/CreditCardOperations';
import InsurancesProductsPage from './insurances-products/InsurancesProductsPage';
import CreditMain from './main/CreditMain';
import RateHistoryPage from './rate-history/RateHistoryPage';
import CreditRequisites from './requisites/CreditRequisites';
import ServicesSection from './services/ServicesSection';
import CreditSubmitRequisites from './submit-requisites/CreditSubmitRequisites';
import CreditRepaymentHistory from './repayment-history/CreditRepaymentHistory';
import CreditPaymentSchedule from './schedule/CreditPaymentSchedule';
import CreditOperationLog from './operation-log/CreditOperationLog';
import CreditOverdueDebt from './overdue-debt/CreditOverdueDebt';
import EarlyPayment from './early-payment/EarlyPayment';
import OrderDocsPage from './order-docs/OrderDocsPage';

const CreditDetails = () => {
  const { creditId } = useParams();
  const credits = useSelector((state) => state.credits.byId);
  const { path } = useRouteMatch();

  if (!credits[creditId]) {
    return <Redirect to="/credits" />;
  }

  return (
    <Switch>
      <Route path={path} exact component={CreditMain} />
      <Route path={`${path}/requisites`} component={CreditRequisites} />
      <Route path={`${path}/submit-requisites/:step`} component={CreditSubmitRequisites} />
      <Route path={`${path}/services`} component={ServicesSection} />
      <Route path={`${path}/overdue-debt`} component={CreditOverdueDebt} />
      <Route path={`${path}/repayment-history`} component={CreditRepaymentHistory} />
      <Route path={`${path}/payment-schedule`} component={CreditPaymentSchedule} />
      <Route path={`${path}/operation-log`} component={CreditOperationLog} />
      <Route path={`${path}/card-operations`} component={CreditCardOperations} />
      <Route path={`${path}/insurances-products`} component={InsurancesProductsPage} />
      <Route path={`${path}/rate-history`} component={RateHistoryPage} />
      <Route path={`${path}/early-payment`} component={EarlyPayment} />
      <Route path={`${path}/order-docs`} component={OrderDocsPage} />
      <Redirect to={path} />
    </Switch>
  );
};

export default CreditDetails;

import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const translate = keyframes`
  0% {
    transform: translateY(0%);
  }

  51.4% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(100%);
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  48.6% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
`;

const spinnerLoader = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
  display: block;
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  margin: auto;

  &.v1 {
    background-color: #000;
    animation: ${rotate} 1.07s cubic-bezier(.25, .1, .25, 1) infinite;
    will-change: transform;

    .red-block {
      height: 50%;
      background-color: #E60028;
      animation: ${translate} 1.07s cubic-bezier(.25, .1, .25, 1) infinite;
      will-change: transform;
    }

    .white-line {
      position: relative;
      z-index: 1;
      width: 75%;
      height: 10%;
      background-color: #fff;
      margin: -5% auto 0;
    }
  }

  &.v2 {
    width: ${(p) => p.size};
    height: ${(p) => p.size};

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: ${(p) => p.size.replace('px', '') - (+(p.size.replace('px', '')) / 10) * 2}px;
      height: ${(p) => p.size.replace('px', '') - (+(p.size.replace('px', '')) / 10) * 2}px;;
      margin: ${(p) => +(p.size.replace('px', '')) / 10}px;
      border-radius: 50%;
      animation: ${spinnerLoader} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-width: ${(p) => +(p.size.replace('px', '')) / 10}px;
      border-style: solid;
      border-color: #1E2025 transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
`;

const RosbankLoader = ({ className, size, design }) => (
  <StyledLoader className={classnames(className, design)} size={size}>
    {design === 'v1' ? (
      <>
        <div className="red-block" />
        <div className="white-line" />
      </>
    ) : (
      <>
        <div />
        <div />
        <div />
        <div />
      </>
    )}

  </StyledLoader>
);

RosbankLoader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  design: PropTypes.oneOf(['v1', 'v2']),
};

RosbankLoader.defaultProps = {
  size: '40px',
  design: 'v2',
};

export default RosbankLoader;

import moment from 'moment';
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, withFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import RosbankLoader from '../../../../ui-kit/icons/RosbankLoader';
import LabelValue from '../../../../ui-kit/label-value/LabelValue';
import { Button, FormControl, Hint } from '../../../../ui-kit';
import compose from '../../../../utils/compose';
import postToNativeApp from '../../../../utils/postToNativeApp';
import DateInputField from '../../../common/forms/fields/DateInputField';
import MoneyInput from '../../../common/forms/MoneyInput';
import withQueryParams from '../../../common/hoc/withQueryParams';
import useMounted from '../../../common/hooks/useMounted';
import useUser from '../../../common/hooks/useUser';
import { getEarlyRepaySumByDateAsync } from '../../../../actions/credits';
import { formatCurrency } from '../../../../utils/format';
import { getEarlyPartialRepaymentCalcAsync, sendEarlyPartialRepaymentRequestAsync } from '../../../../actions/early-payment';
import { useAsyncStatus } from '../../../common/hooks';
import AlertIcon from '../../../../assets/svg/alert-icon.svg';
import WarningIcon from '../../../../assets/svg/status/status-warning-new.svg';
import ReloadIcon from '../../../../assets/svg/reload.svg';
import FatalErrorScreen from '../../../common/FatalErrorScreen';

const StyledButton = styled(Button)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: unset;
  width: 80%;
  color: #1E2025 !important;
  margin-bottom: 25px;
`;

const StyledParagraph = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  //color: #6E7279;
  margin-top: 12px;
  color: #6D717A;
`;

const StyledReloadIcon = styled(ReloadIcon)`margin-left: 13px;`;

const toUnmasked = (value) => parseFloat((typeof value === 'string' ? value : `${value}`).replace(/ /g, '').replace(',', '.'));

const validationSchema = yup.object().shape({
  calcSum: yup
    .string()
    .max(16, 'Превышена максимальная длина!')
    .test('isZero', 'Пожалуйста, введите сумму, отличную от нуля', (value) => value === undefined || toUnmasked(value || 0) !== 0),
  perDate: yup.object().required(),
});

function downloadURI(uri, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  link.click();
}

const autoCreditText = `При недостаточности денежных средств досрочное погашение осуществляется в&nbsp;сумме остатка на&nbsp;счёте после погашения ежемесячного платежа.<br /><br />
                Частичное досрочное погашение кредита не&nbsp;освобождает от&nbsp;уплаты следующих ежемесячных платежей в&nbsp;соответствующие расчётные периоды, указанные в&nbsp;графике платежей`;

const otherCreditText = `Я уведомлен(а) о том, что :<br /><br />
                • В случае несвоевременного поступления денежных средств на&nbsp;счёт, в&nbsp;погашение кредита направляется только сумма ежемесячного платежа<br /><br />
                • В случае своевременного поступления денежных средств в&nbsp;сумме, отличной от&nbsp;указанной выше, в&nbsp;погашение кредита в&nbsp;указанную дату будет направлена вся сумма, поступившая на&nbsp;счёт<br /><br />
                • Частичное досрочное погашение кредита не&nbsp;освобождает от&nbsp;уплаты следующих ежемесячных платежей в&nbsp;соответствующие расчётные периоды, указанные в&nbsp;графике платежей`;

const configForFields = [
  {
    label: 'Номер договора',
    key: 'contract_number',
  },
  {
    label: 'Дата досрочного погашения',
    key: 'early_payment_date',
  },
  {
    label: 'Текущий ежемесячный платеж',
    key: 'monthly_payment',
    type: 'amount',
  },
  {
    label: 'Сумма просроченной задолженности',
    key: 'earlyRepaySum',
    resolveF: (
      {
        legal_tax_sum: legalTaxSum,
        debt_overdue_sum_without_interest: debtOverdueSumWithoutInterest,
        debt_overdue_interest: debtOverdueInterest,
        debt_penalty_dd_sum: debtPenaltyDdSum,
      },
    ) => {
      const sum = debtOverdueSumWithoutInterest + debtOverdueInterest + legalTaxSum + debtPenaltyDdSum;
      return sum >= 0 ? sum : 0;
    },
    isVisible: () => true,
    type: 'amount',
  },
];
const EarlyPartialPayment = (props) => {
  const {
    creditId,
    additionalAlert,
    values,
    isValid,
    updateParams,
    params,
    partialRepaymentCalcErrorMessage,
    setPartialRepaymentCalcErrorMessage,
    setInformerProps,
  } = props;
  const user = useUser();
  const { operator_id: operatorId } = user;
  const isMountedRef = useMounted();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [step, setStep] = useState('calc'); //calc|send
  const [loading, setLoading] = useState(false);
  const [LoadingOrErrorStatus, setLoadingOrErrorStatus] = useState(false);
  const [calcedData, setCalcedData] = useState({});
  const [showCalculatedData, setShowCalculatedData] = useState(false);
  const [selectedDate, setSelectedDate] = useState({ needTouch: false, date: null });
  const [shownUrlMissingInformer, setShownUrlMissingInformer] = useState(false);
  const sendEarlyPartialRepaymentStatus = useAsyncStatus((state) => state.earlyPayment.sendRarlyPartialRepaymentFetchStatus);
  const sessionToken = window.sessionStorage.getItem('token');
  const earlyRepaySum = useSelector((state) => state.credits.earlyRepaySum);
  const getEarlyRepaySumByDateStatus = useAsyncStatus((state) => state.credits.getEarlyRepaySumByDateStatus);

  const {
    credit,
    earlyPartialRepaymentCalc,
    sendRarlyPartialRepayment,
    earlyPartialRepaymentDocumentLink,
    ferAvailability,
    earlyRepayAttemptsCount,
  } = useSelector((state) => ({
    credit: state.credits.byId[creditId],
    earlyPartialRepaymentCalc: state.earlyPayment.earlyPartialRepaymentCalc[creditId] || JSON.parse(localStorage.getItem(`earlyPartialRepaymentResponse-${creditId}`)) || {},
    ferAvailability: state.earlyPayment.ferAvailability[creditId] || JSON.parse(localStorage.getItem(`getEarlyRepaySumByDate-${creditId}`)) || {},
    sendRarlyPartialRepayment: state.earlyPayment.sendRarlyPartialRepayment[creditId] || {},
    earlyPartialRepaymentDocumentLink: state.earlyPayment.sendRarlyPartialRepayment[creditId]?.request_user_document_mobile_url,
    earlyRepayAttemptsCount: state.earlyPayment.ferAvailability[creditId]?.early_repay_attempts_count,
  }));

  const {
    availableOnRequestDates,
    isRepaymentDebtOverdue,
    isAvailable,
    availableWithoutRequestDates,
    isRepaymentAnyday,
    hasOverdue,
    accountHoldListText,
    isFullBlock,
    calc_fer_date_without_request: calcFerDateWithoutRequest = 0,
    monthly_payment: monthlyPayment,
    partialEarlyRepaymentTermsChangeTypeCode,
  } = ferAvailability;

  const {
    additionalInfo,
    additionalInfo: {
      early_payment_date: earlyPaymentDate,
      rest_sum_with_hold: restSumWithHold,
      legal_tax_sum: legalTaxSum = 0,
      // debt_overdue_interest: debtOverdueInterest = 0, debt_overdue_sum_without_interest: debtOverdueSumWithoutInterest,
      // debt_overdue_sum: sum = 0, debt_penalty_dd_sum: debtPenaltyDdSum = 0,
    },
    contract_type_code: type,
  } = credit;

  const startInterval = () => {
    const intervalId = setInterval(() => {
      const earlyPartialRepaymentResponseDate = localStorage.getItem(`earlyPartialRepaymentResponseDate-${creditId}`);
      let secondsDifference = (new Date() - Number(earlyPartialRepaymentResponseDate)) / 1000;
      if (earlyPartialRepaymentResponseDate && secondsDifference > 600) {
        setShowCalculatedData(false);
        localStorage.removeItem(`earlyPartialRepaymentResponse-${creditId}`);
        localStorage.removeItem(`earlyPartialRepaymentResponseDate-${creditId}`);
        setCalcedData({});
        if (availableWithoutRequestDates.length > 1) {
          updateParams();
        }
      }

      const getEarlyRepaySumByDateDate = localStorage.getItem(`getEarlyRepaySumByDateDate-${creditId}`);
      secondsDifference = (new Date() - Number(getEarlyRepaySumByDateDate)) / 1000;
      if (getEarlyRepaySumByDateDate && secondsDifference > 600) {
        localStorage.removeItem(`getEarlyRepaySumByDate-${creditId}`);
        localStorage.removeItem(`getEarlyRepaySumByDateDate-${creditId}`);
      }

      if (!localStorage.getItem(`earlyPartialRepaymentResponse-${creditId}`) && !localStorage.getItem(`getEarlyRepaySumByDate-${creditId}`)) {
        clearInterval(intervalId);
      }
    }, 5000);
  };

  const debtOverdueTotalSum = useMemo(() => (earlyRepaySum.overdueSumTotal ?? 0) + legalTaxSum, [legalTaxSum, earlyRepaySum]);

  const isRepaymentAvailable = isAvailable === 1 && availableWithoutRequestDates?.length > 0 && typeof isRepaymentAnyday !== 'undefined' && !isRepaymentDebtOverdue;

  const notificationText = useMemo(
    () => {
      switch (type) {
        case 'A':
          return autoCreditText;
        case 'D':
        case 'P':
          return otherCreditText;
        case 'C':
        default:
          return null;
      }
    }, [type],
  );

  const sumToPay = useMemo(() => {
    const calced = (toUnmasked(values.calcSum) + debtOverdueTotalSum + monthlyPayment) - restSumWithHold;
    return calced < 0 ? 0 : calced;
  }, [values.calcSum, calcedData]);

  const handleSubmit = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (!loading) {
      const calcSum = toUnmasked(props.values.calcSum);

      const actualSumToSend = availableWithoutRequestDates?.length < 1 ?
        calcSum : (calcSum + monthlyPayment);
      // (calcSum + monthlyPayment + debtOverdueSumWithoutInterest + debtOverdueInterest + debtPenaltyDdSum + legalTaxSum);
      if (actualSumToSend > 0) {
        const earlyPaymentDateToSend = isRepaymentAvailable ? (selectedDate.date || params.perDate) : availableOnRequestDates?.[0];
        if (earlyPaymentDateToSend) {
          updateParams({ ...params, earlyPaymentAmount: props.values.calcSum, perDate: earlyPaymentDateToSend });
          setLoading(true);
          startInterval();
          dispatch(getEarlyPartialRepaymentCalcAsync(creditId, earlyPaymentDateToSend, actualSumToSend, partialEarlyRepaymentTermsChangeTypeCode, operatorId));
        }
      }
    }
  };

  useEffect(() => {
    if (partialRepaymentCalcErrorMessage) {
      props.setFieldError('calcSum', earlyPartialRepaymentCalc.details.error_message);
      setStep('calc');
    }
  }, [partialRepaymentCalcErrorMessage]);

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    const calcSum = toUnmasked(props.values.calcSum);
    if (earlyPartialRepaymentCalc.successed) {
      setLoading(false);
      setCalcedData(earlyPartialRepaymentCalc);
      if (localStorage.getItem(`earlyPartialRepaymentResponse-${creditId}`)) {
        setShowCalculatedData(true);
      }

      const sumFieldErrors = ['Введенная сумма недостаточна для выполнения ЧДП', 'Произошла ошибка при выполнении операции', 'Итоговая сумма равна или больше всей задолженности', 'Итоговая сумма равна или больше всей задолженности. Воспользуйтесь разделом «Полное досрочное погашение».'];
      if (sumFieldErrors.indexOf((earlyPartialRepaymentCalc.details?.error_message || '').trim()) > -1) {
        setPartialRepaymentCalcErrorMessage(earlyPartialRepaymentCalc.details.error_message);
        return;
      }

      if (((earlyPartialRepaymentCalc.details && earlyPartialRepaymentCalc.details.is_per_prev_submitted) ||
        (earlyPartialRepaymentCalc.details && earlyPartialRepaymentCalc.details.is_fer_prev_submitted)) &&
        calcSum && !Number.isNaN(calcSum)) {
        updateParams({
          ...params,
          earlyPaymentDate: availableOnRequestDates || earlyPartialRepaymentCalc.availableOnRequestDates,
          earlyPaymentAmount: props.values.calcSum,
          prevSubmitted: true,
        });

        // setModalConfig({
        //   opened: true,
        //   title: 'Уважаемый клиент!',
        //   content: `На текущий месяц заявление на досрочное погашение на "${earlyPartialRepaymentCalc.details.early_payment_date}" Банком уже зарегистрировано. Для изменения суммы или отзыва данного заявления просьба обратиться в&nbsp;<a href="https://www.rusfinancebank.ru/ru/network.html">Отделение Банка</a> или позвонить по&nbsp;телефону в&nbsp;<a href="https://www.rusfinancebank.ru/ru/contacts.html">Центр обслуживания клиентов</a>`,
        //   action: 'goBack',
        //   withCloseIcon: false,
        // });
      } else {
        if (!calcSum || Number.isNaN(calcSum)) return;
        updateParams({ ...params, earlyPaymentAmount: props.values.calcSum });
      }
      setStep('send');
    }

    if (!earlyPartialRepaymentCalc.processLoading) {
      setLoading(false);
    }
  }, [earlyPartialRepaymentCalc.processLoading]);

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    if (sendRarlyPartialRepayment.success) {
      // dispatch(getCreditsAsync(operatorId, true)); //TODO check if something is broken
      // setChecked(false);
      setInformerProps((prev) => ({
        ...prev,
        active: true,
        paragraph: 'Отследить статус заявки вы&nbsp;можете в&nbsp;журнале обращений',
        type: 'success',
      }));
    } else if (sendRarlyPartialRepayment.status === 403 && sendRarlyPartialRepayment.error_message === 'Forbidden') {
      setInformerProps((prev) => ({
        ...prev,
        active: true,
        paragraph: 'Досрочное погашение временно недоступно. Пожалуйста, повторите попытку позднее',
      }));
    } else if (!sendRarlyPartialRepayment.success && sendRarlyPartialRepayment.failure) {
      setInformerProps((prev) => ({
        ...prev,
        active: true,
        paragraph: sendRarlyPartialRepayment.failure,
        type: 'error',
      }));
    }
    setLoading(false);
  }, [sendRarlyPartialRepayment?.processLoading]);

  // после открытия графика при возврате назад должна открываться форма в том же состоянии, что и была до открытия графика
  useEffect(() => {
    if (Object.keys(earlyPartialRepaymentCalc).length === 0) {
      handleSubmit();
    }
  }, []);

  useEffect(() => {
    if (sendEarlyPartialRepaymentStatus.success && earlyPartialRepaymentDocumentLink) {
      const link = document.createElement('a');
      link.download = 'new_schedule.pdf';
      link.href = earlyPartialRepaymentDocumentLink;
      link.click();
    }
  }, [sendEarlyPartialRepaymentStatus.success]);

  useEffect(() => {
    setLoadingOrErrorStatus(getEarlyRepaySumByDateStatus.loading || getEarlyRepaySumByDateStatus.error?.status_code === 408);
  }, [getEarlyRepaySumByDateStatus]);

  useEffect(() => {
    if (calcedData.new_payment_graphic_url) {
      setShownUrlMissingInformer(false);
    }
  }, [calcedData.new_payment_graphic_url]);

  const sendRequest = useCallback(() => {
    setLoading(true);
    const calcSum = toUnmasked(props.values.calcSum);
    dispatch(sendEarlyPartialRepaymentRequestAsync(creditId, operatorId, calcedData.details.terms_change_type_code, availableOnRequestDates, calcSum));
  }, [creditId, operatorId, calcedData, availableOnRequestDates]);

  const downloadSchedule = useCallback(() => {
    if (!calcedData.new_payment_graphic_url) {
      setShownUrlMissingInformer(true);
      return;
    }
    downloadURI(calcedData.new_payment_graphic_url, 'early-repayment-schedule.pdf');
  }, [calcedData.new_payment_graphic_url]);

  const getAdditionalInfo = useCallback(() => {
    if (isAvailable === 1) {
      if (availableOnRequestDates?.length > 0 && !availableWithoutRequestDates?.length &&
        !ferAvailability.early_repay_sum && earlyRepayAttemptsCount > 0) {
        return `
          Погашение ранее даты платежа по&nbsp;графику возможно при оформлении
          услуги &laquo;Свобода платежа&raquo; в&nbsp;разделе &laquo;Услуги и опции&raquo;
        `;
      }

      if (availableWithoutRequestDates?.length > 0 && !isRepaymentDebtOverdue) {
        if (isRepaymentAnyday === 1) {
          return `
        По договору подключен сервис «Свобода платежа».
        Частичное досрочное погашение доступно без&nbsp;предварительного уведомления Банка в&nbsp;любой рабочий день.
        Расчёт производится до&nbsp;${availableWithoutRequestDates[availableWithoutRequestDates.length - 1]} включительно.
        <br/><br/>
        Денежные средства необходимо перевести самостоятельно на&nbsp;счёт, открытый в&nbsp;РОСБАНК Авто.
        Списание произойдёт не&nbsp;позднее следующего рабочего дня после зачисления денежных средств на&nbsp;счёт
      `;
        }
        return `
        Частичное досрочное погашение доступно без&nbsp;предварительного
        уведомления Банка до&nbsp;${calcFerDateWithoutRequest} включительно.
        <br/><br/>
        Денежные средства необходимо перевести самостоятельно на&nbsp;счёт, открытый в&nbsp;РОСБАНК Авто.
        Списание произойдёт не&nbsp;позднее следующего рабочего дня после зачисления денежных средств на&nbsp;счёт.
        <br/><br/>
        После указанной даты частичное досрочное погашение возможно только по&nbsp;заявлению и&nbsp;в&nbsp;дату платежа по&nbsp;кредиту
      `;
      }
      if (isRepaymentDebtOverdue === 1) {
        return `
        Частичное досрочное погашение доступно без предварительного уведомления Банка в&nbsp;любой сумме.
        <br/><br/>
        Погашение просроченной задолженности произойдёт не позднее следующего рабочего дня после зачисления денежных средств на счёт
      `;
      }
    }
    return `
      Частичное досрочное погашение доступно без&nbsp;предварительного уведомления Банка до&nbsp;${calcFerDateWithoutRequest} включительно.
      Обратите внимание, что&nbsp;денежные средства в&nbsp;любой сумме необходимо перевести самостоятельно на&nbsp;счёт, открытый в&nbsp;РОСБАНК Авто.
      Списание произойдёт не&nbsp;позднее следующего рабочего дня после зачисления денежных средств на&nbsp;счёт.
      После указанной даты досрочное погашение возможно только по&nbsp;заявлению и&nbsp;в&nbsp;дату платежа по&nbsp;кредиту
    `;
  }, [isAvailable, availableWithoutRequestDates, isRepaymentAnyday, isRepaymentDebtOverdue, additionalInfo, calcFerDateWithoutRequest]);

  const goToPayment = useCallback((e) => {
    e.preventDefault();
    const { account_number, contract_currency: currency } = additionalInfo;
    const configToSend = {
      account_number,
      currency,
      sum: sumToPay,
    };

    postToNativeApp({
      type: 'goToPayment',
      payload: configToSend,
    });
  }, [sumToPay, additionalInfo]);

  const shouldDisableDate = useCallback((day) => !(availableWithoutRequestDates?.length > 0 ? availableWithoutRequestDates : availableOnRequestDates).includes(day.format('DD.MM.YYYY')), [availableWithoutRequestDates]);

  const handleChangeDate = (changes) => {
    const date = changes.format('DD.MM.YYYY');

    setSelectedDate((prev) => {
      if (prev.date !== date) {
        setShowCalculatedData(false);
        return { needTouch: false, date };
      }

      return prev;
    });

    if (hasOverdue === 1 && !localStorage.getItem(`getEarlyRepaySumByDate-${creditId}`)) {
      dispatch(getEarlyRepaySumByDateAsync(creditId, date));
    }
  };

  const updateDateInParams = useCallback((date) => {
    setSelectedDate({ needTouch: false, date });
    updateParams({ ...params, perDate: date });

    if (hasOverdue === 1 && !localStorage.getItem(`getEarlyRepaySumByDate-${creditId}`)) {
      setShowCalculatedData(false);
      dispatch(getEarlyRepaySumByDateAsync(creditId, date));
    }
  }, [hasOverdue, creditId]);

  const usingAvailableOnRequestDates = useMemo(() => (
    availableOnRequestDates?.length === 1 && (availableWithoutRequestDates?.length < 1 || !availableWithoutRequestDates?.[0])
  ), [availableOnRequestDates, availableWithoutRequestDates]);

  // const usingAvailableWithoutRequestDates = useMemo(() => (
  //   availableWithoutRequestDates?.length === 1 && (availableOnRequestDates?.length < 1 || !availableOnRequestDates[0])
  // ), [availableOnRequestDates, availableWithoutRequestDates]);

  useEffect(() => {
    if (usingAvailableOnRequestDates) {
      updateDateInParams(availableOnRequestDates[0]);
    } else if (availableWithoutRequestDates?.[0] && hasOverdue === 1 && !params.perDate) {
      updateDateInParams(availableWithoutRequestDates[0]);
    }

    // else if (usingAvailableWithoutRequestDates || hasOverdue === 1) {
    //   updateDateInParams(availableWithoutRequestDates[0]);
    // }
  }, [availableOnRequestDates, availableWithoutRequestDates, hasOverdue]);

  useEffect(() => {
    if (params.perDate) {
      setSelectedDate({ needTouch: false, date: params.perDate });
    }
  }, []);

  return (
    <div className="page-early-payment">
      {additionalAlert && (
        <div className="alert-block compact">
          <div className="alert-block-title">
            <div className="alert-icon-with-text">
              <AlertIcon className="alert-icon" />
              <span className="alert-block-title-text" dangerouslySetInnerHTML={{ __html: getAdditionalInfo() }} />
            </div>
          </div>
        </div>
      )}
      {(!additionalAlert || isRepaymentAvailable || (isAvailable === 1 && availableOnRequestDates?.length > 0 &&
        !ferAvailability.early_repay_sum && earlyRepayAttemptsCount > 0)) && (
        <Form onSubmit={handleSubmit}>
          {LoadingOrErrorStatus ? (
            <div className="loader-wrapper">
              {getEarlyRepaySumByDateStatus.loading && (
              <>
                <RosbankLoader className="loader" size="25px" />
                <p className="loader-description">Загружаем</p>
              </>
              )}
              {getEarlyRepaySumByDateStatus.error?.status_code === 408 && (
              <>
                <div className="hint hint-partial-early-payment">
                  <WarningIcon />
                  <p>Не удалось загрузить данные</p>
                </div>
                <StyledButton
                  design="invisible"
                  size="m"
                  onClick={() => dispatch(getEarlyRepaySumByDateAsync(creditId, selectedDate.date))}
                >
                  Повторить попытку
                  <StyledReloadIcon />
                </StyledButton>
              </>
            )}
            </div>
          ) : (
            configForFields.filter((item) => {
              const { isVisible = () => true, requiredParams = [], resolveF } = item;
              if (!resolveF) return typeof item.key !== 'undefined';
              return isVisible(additionalInfo) && requiredParams.every((param) => typeof additionalInfo[param] !== 'undefined');
            }).map((item) => {
              const { label, resolveF, type, key } = item;

              const value = (() => {
                if (resolveF) return resolveF(additionalInfo);

                switch (key) {
                  case 'monthly_payment': return monthlyPayment;
                  case 'early_payment_date': return availableOnRequestDates?.[0];
                  default: return additionalInfo[key];
                }
              })();

              if ((key === 'early_payment_date' && isRepaymentAvailable && !usingAvailableOnRequestDates) || // && !usingAvailableWithoutRequestDates) ||
              (key === 'earlyRepaySum' && !hasOverdue)) {
                return null;
              }

              if (key === 'earlyRepaySum' && earlyRepaySum.overdueSumTotal) {
                return (
                  <LabelValue key={label + value} label={label} value={formatCurrency(earlyRepaySum.overdueSumTotal)} />
                );
              }

              return (
                <LabelValue key={label + value} label={label} value={type === 'amount' ? formatCurrency(value) : value} />
              );
            })
          )}

          {!usingAvailableOnRequestDates && ( //!usingAvailableWithoutRequestDates && (
          <FormControl className="early-partial-payment-date-picker" noMarginTop label="Дата досрочного погашения">
            <DateInputField name="perDate" shouldDisableDate={shouldDisableDate} onChange={handleChangeDate} />
          </FormControl>
          )}

          <FormControl label="Сумма досрочного погашения" noMarginTop>
            <Field name="calcSum">
              {({ field, meta, form }) => (
                <>
                  <MoneyInput
                    {...field}
                    getInputRef={inputRef}
                    placeholder="Введите сумму платежа"
                    disabled={loading || LoadingOrErrorStatus}
                    onChange={(e) => {
                      setShowCalculatedData(false);
                      form.setStatus('onceChanged');
                      field.onChange(e);
                      setPartialRepaymentCalcErrorMessage('');
                    }}
                  />
                  { (meta.error || partialRepaymentCalcErrorMessage) && field.value !== '' && (
                  <Hint type="error" className="field-error">
                    {meta.error ?? partialRepaymentCalcErrorMessage}
                  </Hint>
                  )}
                </>
              )}
            </Field>
          </FormControl>
          <Button
            className="request-submit"
            design="red"
            loading={loading}
            size="l"
            disabled={!isValid || !values.calcSum || LoadingOrErrorStatus ||
          ((selectedDate.needTouch || showCalculatedData) && !!calcedData.new_payment_graphic_url) || showCalculatedData}
          >
            Рассчитать новый график
          </Button>
          {step === 'send' && !Number.isNaN(sumToPay) && values.calcSum && showCalculatedData && (
          <>
            <div className="repayment-info-block-wrapper">
              <div className="repayment-info-block-row">
                <div className="repayment-info-block-label">Общая сумма к оплате</div>
                <div className="repayment-info-block-value">
                  {formatCurrency(toUnmasked(values.calcSum) + debtOverdueTotalSum + monthlyPayment)}
                </div>
              </div>
              <div className="repayment-info-block-row">
                <div className="repayment-info-block-label">Остаток на счёте</div>
                <div className="repayment-info-block-value">{formatCurrency(restSumWithHold)}</div>
              </div>
              <div className="repayment-info-block-row">
                <div className="repayment-info-block-label">Сумма к оплате с учетом остатка</div>
                <div className="repayment-info-block-value">{formatCurrency(sumToPay)}</div>
              </div>
            </div>

            {!!calcedData.new_payment_sum && !!calcedData.details.monthly_payment_start_date && (
              <>
                <div className="repayment-info-block-label">
                  <span className="center">
                    Изменение графика платежей
                  </span>
                </div>

                <div className="repayment-info-block-wrapper">
                  {type === 'A' && calcedData?.details?.payments[0]?.payment_sum && (
                    <div className="repayment-info-block-row">
                      <div className="repayment-info-block-label">Ежемесячный платёж</div>
                      <div className="repayment-info-block-value">{formatCurrency(calcedData.new_payment_sum)}</div>
                    </div>
                  )}
                  {type === 'A' && (
                    <>
                      <div className="repayment-info-block-row">
                        <div className="repayment-info-block-label">Дата следующего платежа</div>
                        <div className="repayment-info-block-value">{calcedData.details.monthly_payment_start_date}</div>
                      </div>

                      <div className="repayment-info-block-row">
                        <div className="repayment-info-block-label">Срок возврата кредита</div>
                        <div className="repayment-info-block-value">{calcedData.details.new_schedule_close_date}</div>
                      </div>

                      <Button
                        className="download-schedule-button"
                        design="tertiary"
                        size="l"
                        type="button"
                        onClick={downloadSchedule}
                      >
                        Скачать график
                      </Button>

                      {!calcedData.new_payment_graphic_url && shownUrlMissingInformer && (
                        <div className="alert-block-title download-url">
                          <div className="alert-icon-with-text download-url">
                            <WarningIcon className="alert-icon download-url" />
                            <span className="alert-block-title-text download-url">Не удалось загрузить данные. Попробуйте сделать повторный расчёт нового графика</span>
                          </div>
                        </div>
                        // <div className="hint hint-partial-early-payment non-margin">
                        //   <WarningIcon />
                        //   <p>Не удалось загрузить данные. Попробуйте сделать повторный расчёт нового графика</p>
                        // </div>
                      )}
                    </>
                  )}
                  {type !== 'A' && calcedData?.details?.last_payment_date && (
                    <div className="repayment-info-block-row">
                      <div className="repayment-info-block-label">Новая дата последнего платежа</div>
                      <div className="repayment-info-block-value">{calcedData.details.last_payment_date}</div>
                    </div>
                  )}
                </div>
              </>
            )}

            {notificationText && (
              <div className="alert-block">
                <div className="alert-block-title">
                  <div className="alert-icon-with-text">
                    <AlertIcon className="alert-icon" />
                    <span className="alert-block-title-text" dangerouslySetInnerHTML={{ __html: notificationText }} />
                  </div>
                </div>
              </div>
            )}
            {ferAvailability?.ferAvailabilityMessage && (
              <div className="alert-block">
                <div className="alert-block-title">
                  <div className="alert-icon-with-text">
                    <WarningIcon className="warning-icon" />
                    <span className="alert-block-title-text">
                      {ferAvailability.ferAvailabilityMessage}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {calcedData.details && accountHoldListText && accountHoldListText !== '' && (
              <div className="alert-block">
                <div className="alert-block-title">
                  <div className="alert-icon-with-text">
                    <WarningIcon className="warning-icon" />
                    <span className="alert-block-title-text">
                      {accountHoldListText}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {calcedData.details && calcedData.details.error_message && calcedData.details.error_message !== '' && (
              <div className="alert-block">
                <div className="alert-block-title">
                  <div className="alert-icon-with-text">
                    <WarningIcon className="warning-icon" />
                    <span className="alert-block-title-text">
                      {calcedData.details.error_message}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
          )}
        </Form>
      )}
      {step === 'send' && !Number.isNaN(sumToPay) && values.calcSum && showCalculatedData && (
        <>
          {isRepaymentAvailable && sessionToken && (
            <Button
              className="with-margin-top send-application-btn"
              design="red"
              disabled={isFullBlock}
              size="l"
              onClick={goToPayment}
            >
              Перейти к оплате
            </Button>
          )}
          {!isRepaymentAvailable && (
            <Button
              className="with-margin-top send-application-btn"
              design="red"
              loading={loading || sendEarlyPartialRepaymentStatus.loading}
              disabled={isFullBlock || (calcedData.details && calcedData.details.error_message && calcedData.details.error_message !== '')}
              size="l"
              onClick={sendRequest}
            >
              Отправить заявку
            </Button>
          )}

          {((isRepaymentAvailable && sessionToken) || !isRepaymentAvailable) && (
          <StyledParagraph>
            Нажимая «{isRepaymentAvailable && sessionToken && 'Перейти к оплате'}{!isRepaymentAvailable && 'Отправить заявку'}»,
            я подтверждаю, что ознакомлен с новым графиком платежей
          </StyledParagraph>
          )}

        </>
      )}
    </div>
  );
};

export default compose(
  withQueryParams,
  withFormik({
    mapPropsToValues: ({ params }) => ({
      calcSum: params.earlyPaymentAmount || '',
      perDate: params.perDate ? moment(params.perDate, 'DD.MM.YYYY') : null,
    }),
    mapPropsToErrors: ({ params }) => ({
      calcSum: params.earlyPaymentAmount ? null : 'no calc sum entered',
      perDate: params.perDate ? null : 'no per date chosen',
    }),
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
  }),
)(EarlyPartialPayment);

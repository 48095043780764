import React from 'react';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { Input } from '../index';
import CustomToolbar from './CustomToolbar';
import CalendarIcon from './assets/calendar.svg';

const DatepickerTextField = ({
  value, InputProps = {}, inputProps = {}, onClick, onKeyDown, design, placeholder, label, disabled,
}) => {
  const filteredProps = {
    value, ...InputProps, ...inputProps, onClick, onKeyDown, design, label, disabled,
  };
  return (
    <Input
      suffix={<CalendarIcon className="ui-datepicker-icon" />}
      placeholder={placeholder || 'Выберите дату для расчёта суммы'}
      withoutResetBtn
      {...filteredProps}
    />
  );
};

const DatePicker = (props) => (
  <MuiDatePicker
    {...props}
    autoOk
    okLabel={null}
    cancelLabel={null}
    format="DD.MM.YYYY"
    ToolbarComponent={CustomToolbar}
    TextFieldComponent={DatepickerTextField}
  />
);

export default DatePicker;

import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { resetInsurancesProducts, showInsurancesProductsError } from '../../../../actions/insurances-products';
import FatalErrorScreen from '../../../common/FatalErrorScreen';
import { useAsyncStatus } from '../../../common/hooks';
import usePage from '../../../common/hooks/usePage';
import PageTitle from '../../../common/PageTitle';
import ApplicationAcceptedPage from './ApplicationAcceptedPage';
import CarInfoPage from './CarInfoPage';
import Informer from './Informer';
import InsurancesProductsMainPage from './InsurancesProductsMainPage';
import SendPolicyPage from './SendPolicyPage';

function InsurancesProductsPage({ match: { params: { creditId } }, history, location }) {
  const { path } = useRouteMatch();
  usePage({ className: 'insurances-products' });
  const isIos = window.sessionStorage.getItem('isIos');
  const insurancesProductsError = useSelector((state) => state.insurancesProducts.error);
  const hasBackButton = useMemo(() => !window.sessionStorage.getItem('chapter'), []);
  const dispatch = useDispatch();
  const { loading: errorLoading } = useAsyncStatus((state) => state.insurancesProducts[insurancesProductsError.asyncStatusReducerName]);
  const [backClickRoute, setBackClickRoute] = useState('');

  const handleBackClick = useCallback(() => {
    if (backClickRoute) {
      history.push(backClickRoute);
    } else {
      history.goBack();
    }
  }, [backClickRoute]);

  useEffect(() => {
    if (location.state?.backClickRoute) {
      setBackClickRoute(location.state.backClickRoute);
    }

    return () => {
      dispatch(showInsurancesProductsError({}));
      dispatch(resetInsurancesProducts({}));
    };
  }, []);

  return (
    <div className="insurances-products-wrapper">
      <PageTitle title="Страховые продукты" onBackClick={handleBackClick} />

      <div className={classnames('insurances-products-content', { 'is-ios': isIos })}>
        <Switch>
          <Route
            path={path}
            exact
            render={(props) => (
              <InsurancesProductsMainPage
                {...props}
              />
            )}
          />

          <Route
            path={`${path}/send-policy/:insuranceId`}
            render={(props) => (
              <SendPolicyPage
                {...props}
              />
            )}
          />

          <Route
            path={`${path}/application-accepted`}
            render={(props) => (
              <ApplicationAcceptedPage
                {...props}
                setBackClickRoute={setBackClickRoute}
              />
            )}
          />

          <Route
            path={`${path}/car-info/:insuranceId`}
            render={(props) => (
              <CarInfoPage
                {...props}
              />
            )}
          />

          <Route
            path={`${path}/car-info-change/:insuranceId`}
            render={(props) => (
              <CarInfoPage
                {...props}
              />
            )}
          />

          <Redirect to={path} />
        </Switch>
      </div>
      <Informer
        active={insurancesProductsError.type === 'informer' || insurancesProductsError.type === 'failedLoadData'}
        config={insurancesProductsError}
        hasBackButton={hasBackButton}
        creditId={creditId}
        loading={errorLoading}
      />
      {insurancesProductsError.type === 'fatal' && (
        <FatalErrorScreen onReload={insurancesProductsError.onReload} creditId={creditId} loading={errorLoading} />
      )}
    </div>
  );
}

export default InsurancesProductsPage;

import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  calcNextPaymentDateAsync,
  finProtectActivationAsync,
  sendPrePaymentScheduleAsync,
} from '../../../../../actions/services';
import { useAsyncStatus, useFixedFooter, useOpenNewPage, useUser } from '../../../../common/hooks';
import { downloadURI } from '../../../../../utils/downloadURI';
import { formatDate, moneyFormatWithTrailingZeroStripIfInteger } from '../../../../../utils/format';
import { Button } from '../../../../../ui-kit';
import LabelValue from '../../../../../ui-kit/label-value/LabelValue';
import AlertBlock from '../../../../common/AlertBlock';
import MoneyInput from '../../../../common/forms/MoneyInput';
import WheelPicker from '../../../../common/forms/WheelPicker';
import HtmlText from '../../../../common/utils/HtmlText';
import useServiceById from '../hooks/useServiceById';
import ActivationConditionsModal from '../modals/ActivationConditionsModal';
import CancelEarlyRepaymentModal from '../modals/CancelEarlyRepaymentModal';
import AdditionalInfoList from '../utils/AdditionalInfoList';
import ServicePageHeader from '../utils/ServicePageHeader';
import InfoIcon from '../../../../../assets/svg/service-statuses/info.svg';
import ChevronIcon from '../../../../../assets/svg/chevron-right.svg';
import DownloadIcon from '../../../../../assets/svg/download-with-bg.svg';
import CalendarIcon from '../../../../../assets/svg/routing-logos/calendar.svg';

const wheelPickerDays = Array(30).fill({}).map((value, i) => ({ value: i + 1, label: i + 1 }));
const toUnmaskedMoneyValue = (value) => parseFloat((value ?? '').replace(/ /g, '').replace(',', '.')) || 0;
const servicesIdsWithNextPaymentDate = [222, 223, 224, 225];

function ServiceActivationPage({ match }) {
  const dispatch = useDispatch();
  const { params: { creditId, serviceKey } } = match;
  const user = useUser();
  const footerRef = useRef();
  const windowInitialHeight = useRef(0);

  const [footerStyles, setFooterStyles] = useState({});
  const [selectedDay, setSelectedDay] = useState('');
  const [newInstallmentAmount, setNewInstallmentAmount] = useState('0');
  const [hasNewAmountError, setHasNewAmountError] = useState();
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [cancelModalData, setCancelModalData] = useState(null);

  const creditsById = useSelector((state) => state.credits.byId);
  const calculatedNextPaymentDate = useSelector((state) => state.services.calculatedNextPaymentDate);
  const prePaymentScheduleResult = useSelector((state) => state.services.prePaymentScheduleResult);

  const finProtectActivationStatus = useAsyncStatus((state) => state.services.finProtectActivationStatus);
  const calcNextPaymentDateStatus = useAsyncStatus((state) => state.services.calcNextPaymentDateStatus);
  const {
    success: prePaymentScheduleSuccess, ...sendPrePaymentScheduleStatus
  } = useAsyncStatus((state) => state.services.sendPrePaymentScheduleStatus);

  const credit = creditsById[creditId];
  const currentService = useServiceById(serviceKey) ?? {};
  const serviceId = currentService.id;
  const { installment_fixed_day: installmentFixedDay } = credit.additionalInfo ?? {};
  const pageLoading = finProtectActivationStatus.loading || calcNextPaymentDateStatus.loading || sendPrePaymentScheduleStatus.loading;
  const requestError = finProtectActivationStatus.error || calcNextPaymentDateStatus.error || sendPrePaymentScheduleStatus.error;
  useFixedFooter(footerRef, 'services-and-options-content', [prePaymentScheduleSuccess, requestError]);

  const unmaskedNewInstallmentAmount = useMemo(() => toUnmaskedMoneyValue(newInstallmentAmount), [newInstallmentAmount]);

  const prePaymentScheduleData = useMemo(() => { // to hide data after unmount
    const isNewValue = Number.parseInt(unmaskedNewInstallmentAmount, 10) !== prePaymentScheduleResult.sendingAmount;

    if (prePaymentScheduleSuccess && !isNewValue) {
      return prePaymentScheduleResult;
    }

    return {};
  }, [prePaymentScheduleSuccess, prePaymentScheduleResult, unmaskedNewInstallmentAmount]);

  const activationConditionList = useMemo(() => (
    currentService.activationConditionList?.filter(({ conditionList = [] }) => (
      conditionList.some(({ conditionIconName, conditionText }) => conditionIconName && conditionText)
    )) ?? []
  ), [currentService.activationConditionList]);

  const nextPaymentDate = useMemo(() => {
    switch (serviceId) {
      case 222:
        return calcNextPaymentDateStatus.success ? (calculatedNextPaymentDate ?? '') : '';
      case 223:
        return currentService.skipPaymentNextDueDate ?? '';
      case 224:
        return prePaymentScheduleData.nextPaymentDate ?? '';
      case 225:
        return currentService.repaymentHolidayNextDueDate ?? '';
      default:
        return '';
    }
  }, [currentService, calculatedNextPaymentDate, prePaymentScheduleData, calcNextPaymentDateStatus]);

  const moneyInputHintText = useMemo(() => {
    const { minInstallmentAmount = 0, maxInstallmentAmount = 0, installmentAmountStep = 0 } = currentService;
    const defaultText = `Введите сумму от&nbsp;${moneyFormatWithTrailingZeroStripIfInteger(minInstallmentAmount)}
    до&nbsp;${moneyFormatWithTrailingZeroStripIfInteger(maxInstallmentAmount)},\n кратную ${installmentAmountStep}`;

    if (!unmaskedNewInstallmentAmount) {
      return defaultText;
    }
    if (unmaskedNewInstallmentAmount < minInstallmentAmount || unmaskedNewInstallmentAmount > maxInstallmentAmount) {
      setHasNewAmountError(true);
      return defaultText.split(',')[0];
    }
    if (unmaskedNewInstallmentAmount % installmentAmountStep !== 0) {
      setHasNewAmountError(true);
      return `Введите сумму, кратную ${installmentAmountStep ?? 0}`;
    }

    setHasNewAmountError(false);
    return defaultText;
  }, [currentService, unmaskedNewInstallmentAmount]);

  const handleChangeMoneyInput = (e) => {
    setNewInstallmentAmount(e.target.value);
  };

  const handleDownloadDocument = () => {
    downloadURI(prePaymentScheduleData.request_document_mobile_url, 'Новый график.pdf');
  };

  const handleToggleModal = () => {
    setIsActiveModal((prev) => !prev);
  };

  const handleBlur = useCallback((e) => {
    const value = toUnmaskedMoneyValue(e.target.value);
    if (!value) {
      setHasNewAmountError(true);
    }
  }, []);

  const handleChooseDay = useCallback((value) => {
    setSelectedDay(value);
  }, []);

  const handleCalcNextPaymentDate = useCallback((newInstallmentFixedDay) => {
    dispatch(calcNextPaymentDateAsync({ contractNumber: creditId, newInstallmentFixedDay }));
  }, [creditId]);

  const handleSendPrePaymentSchedule = useCallback(() => {
    const config = {
      contractNumber: creditId,
      newInstallmentAmount: Number.parseInt(unmaskedNewInstallmentAmount, 10),
      serviceName: currentService.name,
    };

    dispatch(sendPrePaymentScheduleAsync(config));
  }, [unmaskedNewInstallmentAmount, currentService.name]);

  const handleSendActivationRequest = useCallback((data) => {
    dispatch(finProtectActivationAsync(data));
  }, []);

  const handleActivateService = useCallback(() => {
    const { id, skipPaymentNextDueDate, repaymentHolidayNextDueDate, name, isActiveEarlyRepaymentRequest } = currentService;
    const config = {
      contractNumber: creditId,
      serviceName: name,
      operatorId: user.operator_id,
      serviceId: id,
      ...(skipPaymentNextDueDate && { skipPaymentNextDueDate }),
      ...(repaymentHolidayNextDueDate && { repaymentHolidayNextDueDate }),
      ...(typeof isActiveEarlyRepaymentRequest !== 'undefined' && { isActiveEarlyRepaymentRequest }),
      ...((id === 223 || id === 225) && { nextPaymentDate }),
      ...(id === 222 && { newInstallmentDay: selectedDay }),
      ...(id === 224 && { newInstallmentAmount: unmaskedNewInstallmentAmount }),
    };

    if (isActiveEarlyRepaymentRequest) {
      setCancelModalData(config);
    } else {
      handleSendActivationRequest(config);
    }
  }, [creditId, currentService, user, nextPaymentDate, unmaskedNewInstallmentAmount, selectedDay]);

  useOpenNewPage();

  useEffect(() => {
    windowInitialHeight.current = window.innerHeight;
    const isWeb = window.sessionStorage.getItem('isWeb') === 'true';

    if (isWeb) { //to raise the button above the mobile keyboard
      const listener = (event) => {
        const { height } = event.target;
        const keyboardHeight = Math.ceil(Math.abs(height - windowInitialHeight.current));
        const isNeedToTranslate = keyboardHeight > 100 && windowInitialHeight.current > height;

        setFooterStyles({ paddingBottom: isNeedToTranslate ? keyboardHeight + 12 : 12 });
        if (isNeedToTranslate) {
          window.scrollTo({
            top: keyboardHeight,
            behavior: 'smooth',
          });
        }
      };

      window.visualViewport.addEventListener('resize', listener);

      return () => {
        window.visualViewport.removeEventListener('resize', listener);
      };
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (prePaymentScheduleSuccess || calcNextPaymentDateStatus.success) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [prePaymentScheduleSuccess, calcNextPaymentDateStatus.success]);

  return (
    <div className={classnames('service-activation-page', { loading: pageLoading })}>
      <ServicePageHeader service={currentService} />
      <AdditionalInfoList serviceId={serviceId} additionalInfo={currentService.additionalInfo} />

      {serviceId === 222 && installmentFixedDay && (
        <LabelValue label="Текущий день платежа" value={`${installmentFixedDay} число`} />
      )}
      {serviceId === 222 && (
        <WheelPicker
          initialValue={selectedDay || installmentFixedDay}
          inputValue={selectedDay}
          onChange={handleChooseDay}
          disabled={(value) => value === installmentFixedDay}
          onSubmit={handleCalcNextPaymentDate}
          pickerValues={wheelPickerDays}
          asyncStatus={calcNextPaymentDateStatus}
          label="Укажите новый день платежа"
          suffix={<CalendarIcon className="ui-datepicker-icon" />}
        />
      )}

      {serviceId === 224 && (
        <div className="money-input-wrapper">
          <MoneyInput
            label="Новая сумма платежа"
            design="v2"
            decimalSeparator=","
            decimalScale={2}
            value={newInstallmentAmount}
            hasError={hasNewAmountError}
            onChange={handleChangeMoneyInput}
            onBlur={handleBlur}
          />
          <HtmlText
            className={classnames('money-input-hint', { 'has-error': hasNewAmountError })}
            tag="p"
          >
            {moneyInputHintText}
          </HtmlText>
        </div>
      )}

      {((serviceId === 222 && nextPaymentDate) || (serviceId === 224 && prePaymentScheduleData.preLastPaymentDate)) && (
        <span className="sub-title">Изменение графика платежей</span>
      )}

      {servicesIdsWithNextPaymentDate.includes(serviceId) && nextPaymentDate && (
        <LabelValue label="Дата следующего платежа" value={formatDate(nextPaymentDate)} />
      )}

      {serviceId === 224 && prePaymentScheduleData.preLastPaymentDate && (
        <LabelValue label="Дата последнего платежа" value={formatDate(prePaymentScheduleData.preLastPaymentDate)} />
      )}

      {serviceId === 224 && prePaymentScheduleData.request_document_mobile_url && (
        <button className="document-download-button" onClick={handleDownloadDocument}>
          <DownloadIcon className="icon" />
          <p className="label">Скачать новый график</p>
        </button>
      )}

      {serviceId === 222 && currentService.activationAvailableMessage && (
        <AlertBlock text={currentService.activationAvailableMessage} status="warning" margin="12px 0" />
      )}

      {servicesIdsWithNextPaymentDate.includes(serviceId) && !!activationConditionList.length && (
        <button className="activation-condition-button" onClick={handleToggleModal}>
          <div className="left-content">
            <InfoIcon className="info-icon" />
            <span className="label">Условия активации</span>
          </div>
          <ChevronIcon className="chevron-icon" />
        </button>
      )}

      <div className="page-footer" ref={footerRef} style={footerStyles}>
        {requestError && (
          <AlertBlock text="Не удалось загрузить данные.<br />Попробуйте повторить попытку" status="fatal-error" margin="12px 0 24px" />
        )}

        {serviceId === 224 && !prePaymentScheduleSuccess && (
          <Button
            design="red"
            loading={sendPrePaymentScheduleStatus.loading}
            disabled={!unmaskedNewInstallmentAmount || hasNewAmountError}
            onClick={handleSendPrePaymentSchedule}
          >
            Рассчитать новый график
          </Button>
        )}
        {(serviceId !== 224 || prePaymentScheduleSuccess) && (
          <Button
            design="red"
            onClick={handleActivateService}
            disabled={serviceId === 222 && !nextPaymentDate}
            loading={finProtectActivationStatus.loading}
          >
            Активировать
          </Button>
        )}
        {(serviceId === 224 && prePaymentScheduleSuccess) && (
          <p className="payment-decrease-activation-desc">
            Нажимая «Активировать», я подтверждаю, что&nbsp;ознакомлен с новым графиком платежей
          </p>
        )}

      </div>

      <ActivationConditionsModal
        active={isActiveModal}
        onClose={handleToggleModal}
        activationConditionList={activationConditionList}
        activationRestrictionText={currentService.activationRestrictionText}
        loading={pageLoading}
      />

      <CancelEarlyRepaymentModal
        submitData={cancelModalData}
        onClose={() => setCancelModalData(null)}
        onSubmit={handleSendActivationRequest}
      />
    </div>
  );
}

export default ServiceActivationPage;

import { createAsyncAction, createAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showFailedToLoadData } from './app';

export const authUserViaToken = createAsyncAction(
  'USER_TOKEN_AUTH_REQUEST',
  'USER_TOKEN_AUTH_SUCCESS',
  'USER_TOKEN_AUTH_FAIL',
)();

export const authUserViaAgreementConfirmation = createAsyncAction(
  'USER_AGREEMENT_CONFIRMATION_AUTH_REQUEST',
  'USER_AGREEMENT_CONFIRMATION_AUTH_SUCCESS',
  'USER_AGREEMENT_CONFIRMATION_AUTH_FAIL',
)();

export const userSmsResendRequestAvailable = createAction('SMS_RESEND_AVAILABLE')();
export const userSmsResendRequestNotAvailable = createAction('SMS_RESEND_NOT_AVAILABLE')();

export function authUserViaTokenAsync(credentials) {
  return (dispatch) => {
    dispatch(authUserViaToken.request());

    ApiClient.user.loginViaToken(credentials)
      .then(({ data }) => {
        dispatch(authUserViaToken.success(data, { needAuth: false }));
        dispatch(showFailedToLoadData({}));
      })
      .catch((error) => {
        if (error.response?.data?.login) {
          const { login } = error.response.data;
          return dispatch(authUserViaToken.success({ login }, { needAuth: true }));
        }
        dispatch(showFailedToLoadData({
          active: true,
          onRetry: () => dispatch(authUserViaTokenAsync(credentials)),
          asyncStatusReducerName: 'authViaTokenFetchStatus',
          reducersNS: 'user',
          withPageTitle: false,
        }));
        return dispatch(authUserViaToken.failure(error));
      });
  };
}

export function authViaAgreementConfirmationAsync(smsCode) {
  return (dispatch) => {
    dispatch(authUserViaAgreementConfirmation.request());

    ApiClient.user.loginViaAgreementConfirmation(smsCode)
      .then(({ data }) => dispatch(authUserViaAgreementConfirmation.success(data)))
      .catch((error) => dispatch(authUserViaAgreementConfirmation.failure(error)));
  };
}

export function userSmsResendRequestAsync() {
  return (dispatch) => {
    dispatch(userSmsResendRequestNotAvailable());
    ApiClient.utils.requestForNewSms()
      .then(() => {
        setTimeout(() => {
          dispatch(userSmsResendRequestAvailable());
        }, 30000);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(userSmsResendRequestAvailable());
        }, 30000);
      });
  };
}

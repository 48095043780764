import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import * as yup from 'yup';
import { Button } from '../../../../ui-kit';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import CollapsibleElement from '../../../common/CollapsibleElement';
import InputField from '../../../common/forms/fields/InputField';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import Tab from '../../../../ui-kit/tabs/Tab';
import Tabs from '../../../../ui-kit/tabs/Tabs';
import AlertBlock from '../../../common/AlertBlock';
import Radio from '../../../../ui-kit/radio/Radio';
import RadioGroup from '../../../../ui-kit/radio/RadioGroup';
import AlertIcon from '../../../../assets/svg/alert-icon-16x16.svg';
import CategoryDocumentTypeModal from './CategoryDocumentTypeModal';
import OrderDocsInformer from './OrderDocsInformer';
import { useAsyncStatus, useFixedFooter, useUser } from '../../../common/hooks';
import { sendDocRequestAsync } from '../../../../actions/order-docs';

const alertText = (code, categoryDocumentTypesLength) => {
  if (code === 'POST' && !categoryDocumentTypesLength) {
    return 'Изготовление документа занимает до&nbsp;3&nbsp;рабочих дней. Срок доставки зависит от&nbsp;региона';
  }
  if (code === 'POST' && categoryDocumentTypesLength) {
    return 'Изготовление документа занимает до&nbsp;5&nbsp;рабочих дней. Срок доставки зависит от&nbsp;региона';
  }
  if (code === 'CHAT') {
    return 'Изготовление документа занимает до&nbsp;5&nbsp;рабочих дней. Вам придёт уведомление по&nbsp;готовности документа';
  }
  return 'Документ будет готов сразу после отправки заявки';
};

const useValidationSchema = (wayOfGetting, categoryDocumentTypes) => useMemo(() => (
  yup.object().shape({
    wayOfGettingCode: yup.string().required(),
    ...(wayOfGetting?.some((way) => way.code === 'POST') ? {
      addressTypeCode: yup.string().when('wayOfGettingCode', {
        is: 'POST',
        then: yup.string().required(),
      }),
      addressText: yup.string(),
    } : null),
    ...(categoryDocumentTypes?.length ? {
      categoryDocumentTypeCode: yup.string().required(),
      clientComment: yup.string().required(),
    } : null),
  })
), [wayOfGetting, categoryDocumentTypes]);

const useInitial = (wayOfGetting, addresses, categoryDocumentTypes) => useMemo(() => ({
  wayOfGettingCode: wayOfGetting?.some((way) => way.code === 'INFOBANK') ? 'INFOBANK' : 'CHAT',
  ...(wayOfGetting?.some((way) => way.code === 'POST') ? {
    addressTypeCode: addresses?.length === 1 ? addresses[0].addressTypeCode : 'FACT',
    addressText: addresses?.length === 1 ? addresses[0].addressText :
      addresses.find(({ addressTypeCode }) => addressTypeCode === 'FACT').addressText,
  } : null),
  ...(categoryDocumentTypes?.length ? {
    categoryDocumentTypeCode: 'loanServicingInfo',
    clientComment: '',
  } : null),
}), [wayOfGetting, addresses, categoryDocumentTypes]);

const OrderDocsRequest = (props) => {
  const { match: { params: { creditId, type } }, fetchStatus } = props;
  const dispatch = useDispatch();
  const user = useUser();
  const [informerActive, setInformerActive] = useState(false);
  const [modalConfig, setModalConfig] = useState(null);

  const sendDocRequestFetchStatus = useAsyncStatus((state) => state.orderDocs.sendDocRequestFetchStatus);
  const footerRef = useRef();
  useFixedFooter(footerRef, 'order-docs-content', [sendDocRequestFetchStatus.error]);

  const currentDoc = useSelector((state) => state.orderDocs.byId)[type] ?? {};
  const creditsById = useSelector((state) => state.credits.byId);
  const credit = creditsById[creditId];
  const { wayOfGetting, category_document_type: categoryDocumentTypes } = currentDoc;
  const addresses = useMemo(() => (
    user.addresses.reduce((acc, { address_type_code: addressTypeCode, value }) => {
      if (value) {
        acc.push({
          addressTypeCode, label: addressTypeCode === 'REG' ? 'Адрес регистрации' : 'Фактический адрес', addressText: value,
        });
      }

      return acc;
    }, [])
  ), [user.addresses]);

  const validationSchema = useValidationSchema(wayOfGetting, categoryDocumentTypes);
  const initial = useInitial(wayOfGetting, addresses, categoryDocumentTypes);

  const onCloseModal = useCallback(() => {
    setModalConfig(null);
  }, []);

  const handleSubmit = useCallback(({ addressText, addressTypeCode, ...values }) => {
    const { document_type_name: documentTypeName, request_type_short_name: requestTypeShortName } = currentDoc;
    const data = {
      requestChannel: 'RBMOBILE',
      operatorId: user.operator_id,
      contractGid: credit.additionalInfo.contract_gid,
      contractNumber: creditId,
      requestTypeShortName,
      documentTypeName,

      ...(values.wayOfGettingCode === 'POST' ? { addressText, addressTypeCode } : null),
      ...values,
    };

    dispatch(sendDocRequestAsync(data));
  }, [credit, creditId, currentDoc, user.operator_id]);

  useEffect(() => {
    if (sendDocRequestFetchStatus.success || sendDocRequestFetchStatus.error?.status === 403) {
      setInformerActive(true);
    }
  }, [sendDocRequestFetchStatus.success, sendDocRequestFetchStatus.error]);

  useEffect(() => {
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'goBack' } });
  }, []);

  return (
    <LoaderOrChildren loading={fetchStatus.initial || fetchStatus.loading}>
      <div
        className="order-docs-request-wrapper"
      >
        <Formik
          initialValues={initial}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {({ values, setFieldValue, setValues, isValid }) => {
            const handleChangeAddress = (e) => {
              const { value } = e.target;
              setValues({
                ...values,
                addressTypeCode: value,
                addressText: addresses.find(({ addressTypeCode }) => addressTypeCode === value)?.addressText,
              }, false);
            };

            const handleChangeTab = (fieldName, tab) => {
              setFieldValue(fieldName, tab);
            };

            return (
              <Form className="order-docs-form">
                <div className={classnames('form-content', { loading: sendDocRequestFetchStatus.loading })}>
                  <div className="credit-info">
                    <h2 className="order-doc-value">{currentDoc.document_type_name}</h2>
                    <span className="credit-number">{`Договор №${creditId}`}</span>
                  </div>

                  {!!categoryDocumentTypes?.length && (
                    <div className="requested-information-wrapper">
                      <h2 className="title">Какая информация нужна?</h2>

                      <Tabs
                        tab={values.categoryDocumentTypeCode}
                        onChange={(tab) => handleChangeTab('categoryDocumentTypeCode', tab)}
                        className="requested-information-tabs"
                      >
                        {categoryDocumentTypes.map((info) => (
                          <Tab
                            key={info.category_document_type_code}
                            id={info.category_document_type_code}
                            title={info.category_document_type_name}
                            className="tab-content"
                          >
                            <InputField
                              name="clientComment"
                              textarea
                              design="v2"
                              label="Опишите суть запроса"
                              maxLength={300}
                            />
                            <button className="example-request-button" onClick={() => setModalConfig(info)} type="button">
                              <AlertIcon className="alert-icon" />
                              Пример запроса
                            </button>
                          </Tab>
                        ))}
                      </Tabs>
                    </div>
                  )}

                  <div className="get-doc-method-wrapper">
                    <h2 className="title">Способ получения</h2>
                    <Tabs
                      tab={values.wayOfGettingCode}
                      onChange={(tab) => handleChangeTab('wayOfGettingCode', tab)}
                      className="get-doc-method-tabs"
                    >
                      {wayOfGetting?.map((way) => (
                        <Tab
                          key={way.code}
                          id={way.code}
                          title={way.name}
                          className="tab-content"
                        >
                          {way.code === 'POST' && !!addresses.length && (
                            addresses.length > 1 ? (
                              <RadioGroup value={values.addressTypeCode} onChange={handleChangeAddress}>
                                {addresses.map((address) => (
                                  <Radio
                                    key={address.addressTypeCode}
                                    className="address"
                                    value={address.addressTypeCode}
                                    childrenAlign="left"
                                  >
                                    <span className="address-title">{address.label}</span>
                                    {address.addressText}
                                  </Radio>
                                ))}
                              </RadioGroup>
                            ) : (
                              <p className="address-wrapper">
                                {addresses[0].label}
                                <span className="only-one-address">{addresses[0].addressText}</span>
                              </p>
                            )
                          )}
                          <AlertBlock text={alertText(way.code, categoryDocumentTypes.length)} margin="12px 0" />
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                </div>

                <div className="sign-in-footer" ref={footerRef}>
                  <CollapsibleElement active={!!sendDocRequestFetchStatus.error && sendDocRequestFetchStatus.error?.status !== 403}>
                    <AlertBlock text="Не удалось загрузить данные.<br />Попробуйте повторить попытку" status="fatal-error" margin="0" />
                  </CollapsibleElement>

                  <Button
                    className="sign-in-submit"
                    design="red"
                    size="l"
                    type="submit"
                    loading={sendDocRequestFetchStatus.loading}
                    disabled={!isValid}
                  >
                    Заказать документ
                  </Button>
                </div>

                <OrderDocsInformer
                  creditId={creditId}
                  active={informerActive}
                  docMethod={values.wayOfGettingCode}
                  sendDocRequestFetchStatus={sendDocRequestFetchStatus}
                />
              </Form>
            );
          }}
        </Formik>

        <CategoryDocumentTypeModal
          modalConfig={modalConfig}
          onClose={onCloseModal}
        />
      </div>
    </LoaderOrChildren>
  );
};

export default OrderDocsRequest;

import { getLogs } from './getLogs';

export default function postToNativeApp(msg) {
  const jsonMsg = JSON.stringify(msg);
  const isWeb = window.sessionStorage.getItem('isWeb') === 'true';

  try {
    if (isWeb) {
      const opener = window.opener || window.parent;
      opener.postMessage(jsonMsg, '*');
    }
    if (window.AndroidJSBridge?.postMessage) {
      window.AndroidJSBridge.postMessage(jsonMsg);
    }
    if (window.webkit?.messageHandlers?.IosJSBridge?.postMessage) {
      window.webkit.messageHandlers.IosJSBridge.postMessage(jsonMsg);
    }

    if (msg.type !== 'sendLogs') {
      const logs = getLogs({
        name: 'postMessage',
        ...msg,
        payload: JSON.stringify(msg.payload),
      });
      postToNativeApp(logs);
    }
  } catch (e) {
    console.warn('postToNativeApp failed', e);
  }
}

import classnames from 'classnames';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import styled from 'styled-components';
import {
  getInsurancesProductsAsync,
  getUploadDocumentTypesAsync,
  sendInsuranceProlongationAsync,
} from '../../../../actions/insurances-products';
import { Button, Input } from '../../../../ui-kit';
import FormControl from '../../../../ui-kit/form-control/FormControl';
import FormFooter from '../../../../ui-kit/form-footer/FormFooter';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import AlertBlock from '../../../common/AlertBlock';
import CollapsibleElement from '../../../common/CollapsibleElement';
import CheckboxField from '../../../common/forms/fields/CheckboxField';
import FileInputField from '../../../common/forms/fields/FileInputField';
import InputField from '../../../common/forms/fields/InputField';
import MoneyInputField from '../../../common/forms/fields/MoneyInputField';
import RadioButtonField from '../../../common/forms/fields/RadioButtonField';
import WheelDatePickerField from '../../../common/forms/fields/WheelDatePickerField';
import { useAsyncStatus } from '../../../common/hooks';
import useValidate from '../../../common/hooks/useValidate';
import DomHolder from '../../../common/utils/DomHolder';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import ChooseHeadCompanyModal from './ChooseHeadCompanyModal';
import { customFileValidate } from '../../../../utils/customFileValidate';
import FormikEffect from '../../../common/forms/FormikEffect';
import InfoIcon from '../../../../assets/svg/question-mark.svg';
import InfoModal from './InfoModal';

const beneficiaryOptions = [
  { label: 'Клиент', value: 'CLIENT' },
  { label: 'ПАО РОСБАНК', value: 'BANK' },
  { label: 'Иное', value: 'OTHER' },
];
const pledgeeOptions = [
  { label: 'ПАО РОСБАНК', value: 'BANK' },
  { label: 'Иное', value: 'OTHER' },
];
const paymentTypeOptions = [
  { label: 'Единовременно', value: 'SINGLE_PAYMENT' },
  { label: 'В рассрочку', value: 'BY_INSTALLMENTS' },
];

const toUnmaskedMoneyValue = (value) => parseFloat((value ?? '').replace(/ /g, '').replace(',', '.')) || 0;

const subtractYears = (years) => new Date(+moment().subtract(years, 'y').startOf('year').format('x'));
const addYears = (years) => new Date(+moment().add(years, 'y').endOf('year').format('x'));
const diffBetweenDates = (firstDate, secondDate) => moment(firstDate, 'DD.MM.YYYY').diff(moment(secondDate, 'DD.MM.YYYY'), 'days');
const getDateErrorByType = (errorType) => {
  switch (errorType) {
    case 'outOfRange':
      return 'Проверьте введённую дату';
    case 'invalidDate':
    case 'incorrectLength':
      return 'Неправильный формат даты';
    case 'lessThanStartDate':
      return 'Дата окончания действия полиса должна быть позже даты начала';
    case 'nextPaymentDatelessThanStartDate':
      return 'Дата следующего взноса рассрочки должна быть позже даты начала действия полиса';
    case 'moreThanExpirationDate':
      return 'Дата следующего взноса рассрочки должна быть ранее даты окончания действия полиса';
    case 'policyExpired':
      return 'Истёк срок действия полиса';
    case 'paymentExpired':
      return 'Истёк срок оплаты. Предоставьте квитанцию об оплате текущего взноса и укажите дату будущего взноса';
    case 'specifyDate':
      return 'Укажите дату второго взноса рассрочки согласно договору страхования';
    default:
      return '';
  }
};

const hasDateError = (name, errors, touched, focused) => (
  [
    'outOfRange', 'invalidDate', 'lessThanStartDate',
    'nextPaymentDatelessThanStartDate', 'moreThanExpirationDate', 'policyExpired',
    'paymentExpired', 'specifyDate',
  ].includes(errors[name]) ||
    !!(errors[name] === 'incorrectLength' && !focused[name])
);

const validationSchema = yup.object().shape({
  beneficiary: yup.string().required(),
  pledgee: yup.string().required(),
  paymentType: yup.string().required(),
  insuranceCompanyRsId: yup.string().required(),
  insuranceStartDate: yup.string()
    .required()
    .test(
      'invalidDate',
      'invalidDate',
      (value) => moment(value, 'DD.MM.YYYY').format('x') !== 'Invalid date',
    )
    .test(
      'incorrectLength',
      'incorrectLength',
      (value) => value.length === 10,
    )
    .test(
      'outOfRange',
      'outOfRange',
      (value) => (
        value.length < 10 || moment(value, 'DD.MM.YYYY')
          .isBetween(subtractYears(3), addYears(1), undefined, '[]')),
    ),
  insuranceExpirationDate: yup.string()
    .required()
    .test(
      'invalidDate',
      'invalidDate',
      (value) => moment(value, 'DD.MM.YYYY').format('x') !== 'Invalid date',
    )
    .test(
      'incorrectLength',
      'incorrectLength',
      (value) => value.length === 10,
    )
    .test(
      'lessThanStartDate',
      'lessThanStartDate',
      function (value) {
        const { insuranceStartDate } = this.options.parent;
        return value.length < 10 || moment(value, 'DD.MM.YYYY').isAfter(moment(insuranceStartDate, 'DD.MM.YYYY'));
      },
    )
    .test(
      'policyExpired',
      'policyExpired',
      (value) => (
        value.length < 10 || moment(value, 'DD.MM.YYYY')
          .isSameOrAfter(Date.now())),
    )
    .test(
      'outOfRange',
      'outOfRange',
      function (value) {
        const { insuranceStartDate } = this.options.parent;
        return (
          value.length < 10 ||
          (moment(value, 'DD.MM.YYYY').isAfter(Date.now()) && diffBetweenDates(value, insuranceStartDate) >= 27 &&
          moment(value, 'DD.MM.YYYY').isBetween(subtractYears(1), addYears(3), undefined, '[]'))
        );
      },
    ),
  insurancePolicyNumber: yup.string().required(),
  sumInsured: yup.string()
    .required()
    .test('null', 'startsWithNull', (value) => value.length === 0 || toUnmaskedMoneyValue(value) !== 0) //!value.startsWith('0'))
    .test('length', 'lengthError', (value) => toUnmaskedMoneyValue(value).toString().length <= 11),
  contributionAmount: yup.string()
    .required()
    .test('null', 'startsWithNull', (value) => value.length === 0 || toUnmaskedMoneyValue(value) !== 0) //!value.startsWith('0'))
    .test('length', 'lengthError', (value) => toUnmaskedMoneyValue(value).toString().length <= 11),
  insuranceNextPaymentDate: yup.string()
    .test(
      'customRequired',
      'Обязательное поле',
      function (value) {
        const { insuranceExpirationDate, paymentType, allDuesPaid } = this.options.parent;
        if ((allDuesPaid && insuranceExpirationDate) || paymentType !== 'BY_INSTALLMENTS' || value) {
          return true;
        }
        return false;
      },
    )
    .test(
      'invalidDate',
      'invalidDate',
      (value) => (
        value.length > 0 ? moment(value, 'DD.MM.YYYY').format('x') !== 'Invalid date' : true
      ),
    )
    .test(
      'incorrectLength',
      'incorrectLength',
      (value) => value.length < 1 || value.length === 10,
    )
    .test(
      'outOfRange',
      'outOfRange',
      (value) => (
        value.length > 0 ? (value.length < 10 || moment(value, 'DD.MM.YYYY')
          .isBetween(subtractYears(0), addYears(1), undefined, '[]')) : true
      ),
    )
    .test(
      'nextPaymentDatelessThanStartDate',
      'nextPaymentDatelessThanStartDate',
      function (value) {
        const { insuranceStartDate, paymentType } = this.options.parent;
        return paymentType !== 'BY_INSTALLMENTS' || value.length < 10 || //value === insuranceStartDate ||
          moment(value, 'DD.MM.YYYY').isAfter(moment(insuranceStartDate, 'DD.MM.YYYY'));
      },
    )
    .test(
      'moreThanExpirationDate',
      'moreThanExpirationDate',
      function (value) {
        const { insuranceExpirationDate, paymentType } = this.options.parent;
        return paymentType !== 'BY_INSTALLMENTS' || value.length < 10 ||
          moment(insuranceExpirationDate, 'DD.MM.YYYY').isAfter(moment(value, 'DD.MM.YYYY'));
      },
    )
    .test(
      'paymentExpired',
      'paymentExpired',
      function (value) {
        const { paymentType } = this.options.parent;
        return paymentType !== 'BY_INSTALLMENTS' || value.length < 10 || moment(value, 'DD.MM.YYYY')
          .isSameOrAfter(Date.now());
      },
    )
    .test(
      'specifyDate',
      'specifyDate',
      function (value) {
        const { insuranceStartDate, paymentType } = this.options.parent;
        return paymentType !== 'BY_INSTALLMENTS' || value.length < 10 || diffBetweenDates(value, insuranceStartDate) >= 27;
      },
    ),
  allDuesPaid: yup.bool(),
});

const UIInfoButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  padding: 0;
`;

const InfoButton = ({ type, onClick }) => (
  <UIInfoButton className="info-button" data-type={type} type="button" onClick={onClick}><InfoIcon /></UIInfoButton>
);

function SendPolicyPage({ match, history, location }) {
  const { creditId, insuranceId } = match.params;
  const { isCarInfoActual = true } = location.state ?? {};
  const dispatch = useDispatch();
  const [mainHint, setMainHint] = useState({});
  const [activeModal, setActiveModal] = useState(false);
  const [focused, setFocused] = useState({});
  const [infoModalType, setInfoModalType] = useState('');
  const insuranceNextPaymentDateRef = useRef(null);
  const isCascoInsurance = insuranceId === '1' || insuranceId === '1001';

  const uploadDocumentTypes = useSelector((state) => state.insurancesProducts.uploadDocumentTypes);
  const [uploadDocumentTypesToSend, setUploadDocumentTypesToSend] = useState(uploadDocumentTypes);

  const headCompanies = useSelector((state) => state.insurancesProducts.headCompanies);
  const insurancesProductsById = useSelector((state) => state.insurancesProducts.byId);
  const insurancesProducts = insurancesProductsById[creditId] ?? [];
  const fileFields = useMemo(() => (
    uploadDocumentTypes ? Object.keys(uploadDocumentTypes).reduce((acc, key) => { acc[key] = []; return acc; }, {}) : {}
  ), [uploadDocumentTypes]);
  const insuranceProduct = useMemo(() => (
    insurancesProducts.find((item) => item.insuranceId === +insuranceId)), [insurancesProducts, insuranceId]);
  const {
    loading: getDocumentTypesLoading,
    success: getDocumentTypesSuccess,
  } = useAsyncStatus((state) => state.insurancesProducts.uploadDocumentTypesFetchStatus);
  const {
    loading: sendInsuranceProlongLoading,
    success: sendInsuranceProlongSuccess,
    error: sendInsuranceProlongError,
  } = useAsyncStatus((state) => state.insurancesProducts.sendInsuranceProlongationFetchStatus);

  const initialValues = useMemo(() => {
    let cascoConfig = {};

    if (isCascoInsurance) {
      cascoConfig = {
        beneficiary: '',
        pledgee: '',
        paymentType: '',
        insuranceCompanyRsId: '',
        insuranceStartDate: '',
        insuranceExpirationDate: '',
        insurancePolicyNumber: '',
        sumInsured: '0',
        contributionAmount: '0',
        insuranceNextPaymentDate: '',
        allDuesPaid: false,
      };
    }

    return {
      contractNumber: creditId,
      ...fileFields,
      ...cascoConfig,
      customerComment: '',
    };
  }, [creditId, isCascoInsurance, fileFields]);

  const customValidate = useCallback((values) => (
    customFileValidate(values, uploadDocumentTypesToSend, setMainHint)
  ), [uploadDocumentTypesToSend]);

  const validate = useValidate(validationSchema, customValidate, { focused });

  const handlePaymentTypeChange = useCallback(({ paymentType }) => {
    const filteredKeys = Object.keys(uploadDocumentTypes).filter((type) => ((!paymentType || paymentType !== 'BY_INSTALLMENTS') ? type !== 'PAYMENTDOCUMENTSCAN' : type));
    setUploadDocumentTypesToSend(isCascoInsurance ? filteredKeys.reduce((acc, item) => {
      acc[item] = uploadDocumentTypes[item];
      return acc;
    }, {}) : uploadDocumentTypes);
  }, [uploadDocumentTypes, isCascoInsurance]);

  const handleSubmit = useCallback((values) => {
    const { insuranceId, insuranceName, interestRateWithoutInsurance, pledgeNumber } = insuranceProduct;
    const {
      insuranceStartDate, insuranceExpirationDate, insuranceNextPaymentDate, contributionAmount, sumInsured, allDuesPaid, ...restValues
    } = values;

    const documentsData = Object.values(restValues).filter((value) => !!value?.[0]?.fileTypeCode).flat();
    const filteredValues = { ...restValues };

    Object.keys(uploadDocumentTypes).forEach((fileTypeCode) => delete filteredValues[fileTypeCode]);

    const dataToSend = {
      requestChannel: 'RBMOBILE',
      insuranceId,
      insuranceName,
      interestRateWithoutInsurance,
      documentsData,
      ...(isCascoInsurance && { isCarInfoActual: +isCarInfoActual }),
      ...(isCarInfoActual ? { isCarInfoActual: +isCarInfoActual } : null),
      ...(isCascoInsurance ? { pledgeNumber } : null),
      ...(filteredValues),
      ...(insuranceStartDate ? { insuranceStartDate } : null),
      ...(insuranceExpirationDate ? { insuranceExpirationDate } : null),
      ...(insuranceNextPaymentDate || allDuesPaid ?
        { insuranceNextPaymentDate: (allDuesPaid ? insuranceExpirationDate : insuranceNextPaymentDate) } : null),
      ...(contributionAmount ? { contributionAmount: toUnmaskedMoneyValue(contributionAmount) } : null),
      ...(sumInsured ? { sumInsured: toUnmaskedMoneyValue(sumInsured) } : null),
    };

    dispatch(sendInsuranceProlongationAsync(dataToSend));
  }, [insuranceProduct, uploadDocumentTypesToSend, creditId, isCarInfoActual, isCascoInsurance]);

  const hasErrors = useCallback(
    (touched, errors) => Object.entries(uploadDocumentTypesToSend).some(([fieldName]) => errors[fieldName] && touched[fieldName]),
    [uploadDocumentTypesToSend],
  );

  const onCloseModal = useCallback((isInfoModal) => {
    if (!isInfoModal) setActiveModal(false);
    else setInfoModalType('');
  }, []);

  const handleChooseCompany = useCallback((setFieldValue, id) => {
    setFieldValue('insuranceCompanyRsId', id);
    setActiveModal(false);
  }, []);

  const handleChangePolicyNumber = useCallback((e, setFieldValue) => {
    const { value } = e.target;
    const pattern = /[^а-яА-Яa-zA-Z0-9-()\\\/|#№.]+/g;
    if (value.length <= 30) {
      setFieldValue('insurancePolicyNumber', value.replace(pattern, ''));
    }
  }, []);

  const handleOpenInfoModal = useCallback((e) => {
    const type = e.currentTarget.getAttribute('data-type');
    setInfoModalType(type);
  }, []);

  const handleChangeAllDuesPaid = useCallback((values, setFieldValue, setFieldError) => {
    if (!values.allDuesPaid) {
      setFieldValue('insuranceNextPaymentDate', '');
      setFieldError('insuranceNextPaymentDate', false);
    } else if (insuranceNextPaymentDateRef.current) {
      setTimeout(() => insuranceNextPaymentDateRef.current.focus(), 100);
    }
  }, []);

  useEffect(() => {
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
    if (!insurancesProducts.length) {
      dispatch(getInsurancesProductsAsync({ contractNumber: creditId }));
    }
    dispatch(getUploadDocumentTypesAsync(isCarInfoActual ? 'INSURANCE' : 'INSURANCE_PTS'));
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'goBack' } });
  }, []);

  useEffect(() => {
    if (getDocumentTypesSuccess) {
      const filteredKeys = Object.keys(uploadDocumentTypes).filter((type) => (isCascoInsurance ? type !== 'PAYMENTDOCUMENTSCAN' : type));
      setUploadDocumentTypesToSend(isCascoInsurance ? filteredKeys.reduce((acc, item) => {
        acc[item] = uploadDocumentTypes[item];
        return acc;
      }, {}) : uploadDocumentTypes);
    }
  }, [getDocumentTypesSuccess, isCascoInsurance]);

  useEffect(() => {
    if (sendInsuranceProlongSuccess) {
      history.push({ pathname: `/credits/${creditId}/insurances-products/application-accepted`, state: { pageType: 'insurance' } });
    }
  }, [sendInsuranceProlongSuccess]);

  return (
    <LoaderOrChildren loading={getDocumentTypesLoading}>
      <div className="page-wrapper send-policy-page">
        {uploadDocumentTypesToSend && (
          <Formik initialValues={initialValues} validate={isCascoInsurance ? validate : customValidate} onSubmit={handleSubmit}>
            {({
              errors, touched,
              setFieldValue, values, isValid, submitCount, setFieldError,
            }) => {
              // const allFilesUploaded = useMemo(() => (
              //   Object.keys(uploadDocumentTypesToSend).every((key) => values[key]?.length > 0)
              // ), [values, uploadDocumentTypesToSend]);
              const isContributionAmountLessThanSumInsured = useMemo(() => (
                isCascoInsurance ? toUnmaskedMoneyValue(values.contributionAmount) < toUnmaskedMoneyValue(values.sumInsured) : true
              ), [values.contributionAmount, values.sumInsured, isCascoInsurance]);
              const allFieldsRequiredError = useMemo(() => {
                const error = { type: 'error', label: '' };
                if (!isValid && submitCount) {
                  error.label = isCascoInsurance ? 'Чтобы продолжить, необходимо заполнить все данные' : 'Чтобы продолжить, необходимо прикрепить все требуемые файлы';
                }
                return error;
              }, [isValid, isCascoInsurance, submitCount]);

              return (
                <Form>
                  <FormikEffect onChange={handlePaymentTypeChange} effectDeps={['paymentType']} />
                  <div className="form-container">
                    {isCascoInsurance && (
                      <>
                        <h2 className="title">Информация о страховом полисе</h2>

                        <FormControl
                          label="Порядок оплаты страховой премии"
                          className={classnames('form-control', { _error: !!errors.paymentType && !!touched.paymentType })}
                        >
                          <RadioButtonField name="paymentType" options={paymentTypeOptions} />
                        </FormControl>

                        <FormControl className="form-control">
                          <Input
                            value={headCompanies[values.insuranceCompanyRsId]?.name ?? ''}
                            label="Страховая компания"
                            design="v2"
                            state={errors.insuranceCompanyRsId && touched.insuranceCompanyRsId ? 'invalid' : undefined}
                            onFocus={(e) => {
                              e.nativeEvent.target.blur();
                              setActiveModal(true);
                            }}
                            readOnly
                          />
                        </FormControl>

                        <FormControl className="form-control">
                          <InputField
                            name="insurancePolicyNumber"
                            label="Номер страхового полиса"
                            design="v2"
                            onChange={(e) => handleChangePolicyNumber(e, setFieldValue)}
                          />
                        </FormControl>

                        <FormControl className="form-control">
                          <WheelDatePickerField
                            name="insuranceStartDate"
                            label="Начало действия полиса"
                            placeholder="дд.мм.гггг"
                            withMask
                            hasError={hasDateError('insuranceStartDate', errors, touched, focused)}
                            setFocused={setFocused}
                            datePickerProps={{
                              minDate: subtractYears(3),
                              maxDate: addYears(1),

                            }}
                          />
                          <CollapsibleElement
                            className="date-error"
                            active={hasDateError('insuranceStartDate', errors, touched, focused)}
                          >
                            <p className="input-hint error">
                              {getDateErrorByType(errors.insuranceStartDate)}
                            </p>
                          </CollapsibleElement>
                        </FormControl>

                        <FormControl className="form-control">
                          <WheelDatePickerField
                            name="insuranceExpirationDate"
                            label="Окончание действия полиса"
                            placeholder="дд.мм.гггг"
                            withMask
                            hasError={hasDateError('insuranceExpirationDate', errors, touched, focused)}
                            setFocused={setFocused}
                            datePickerProps={{
                              minDate: subtractYears(1),
                              maxDate: addYears(3),
                            }}
                          />
                          <CollapsibleElement
                            className="date-error"
                            active={hasDateError('insuranceExpirationDate', errors, touched, focused)}
                          >
                            <p className="input-hint error">
                              {getDateErrorByType(errors.insuranceExpirationDate)}
                            </p>
                          </CollapsibleElement>
                        </FormControl>

                        <FormControl className="form-control">
                          <MoneyInputField
                            name="sumInsured"
                            label="Страховая сумма"
                            design="v2"
                            decimalSeparator=","
                            decimalScale={2}
                            hasError={errors.sumInsured === 'lengthError' || (errors.sumInsured === 'startsWithNull' && touched.sumInsured)}
                          />
                          <CollapsibleElement
                            className="money-error"
                            active={errors.sumInsured === 'lengthError' ||
                              ((errors.sumInsured === 'startsWithNull' || !toUnmaskedMoneyValue(values.sumInsured)) && !!touched.sumInsured)}
                          >
                            <p className="input-hint error">
                              {errors.sumInsured === 'lengthError' && 'Проверьте введённую сумму'}
                              {(errors.sumInsured === 'startsWithNull' ||
                                (!!touched.sumInsured && !toUnmaskedMoneyValue(values.sumInsured))) && 'Страховая сумма должна быть больше нуля'}
                            </p>
                          </CollapsibleElement>
                        </FormControl>

                        <FormControl className="form-control">
                          <MoneyInputField
                            name="contributionAmount"
                            label="Страховая премия"
                            design="v2"
                            decimalSeparator=","
                            decimalScale={2}
                            hasError={(!!errors.contributionAmount ||
                              (!isContributionAmountLessThanSumInsured && !!touched.sumInsured)) && !!touched.contributionAmount}
                          />
                          <CollapsibleElement
                            className="money-error"
                            active={(errors.contributionAmount === 'lengthError' ||
                              errors.contributionAmount === 'startsWithNull' ||
                              !isContributionAmountLessThanSumInsured ||
                              !toUnmaskedMoneyValue(values.contributionAmount)) && !!touched.contributionAmount}
                          >
                            <p className="input-hint error">
                              {errors.contributionAmount === 'lengthError' && 'Проверьте введённую сумму'}
                              {(errors.contributionAmount === 'startsWithNull' ||
                                (!!touched.contributionAmount && !toUnmaskedMoneyValue(values.contributionAmount))) && 'Страховая премия должна быть больше нуля'}
                              {!isContributionAmountLessThanSumInsured && !!touched.sumInsured && !errors.contributionAmount && 'Страховая премия должна быть меньше страховой суммы'}
                            </p>
                          </CollapsibleElement>
                          {values.paymentType === 'BY_INSTALLMENTS' && (
                            <span className="input-hint">
                              Укажите сумму текущего взноса
                            </span>
                          )}
                        </FormControl>

                        {values.paymentType === 'BY_INSTALLMENTS' && (
                          <FormControl className="form-control">
                            <WheelDatePickerField
                              name="insuranceNextPaymentDate"
                              label="Оплатить следующий взнос до"
                              placeholder="дд.мм.гггг"
                              disabled={values.allDuesPaid}
                              withMask
                              hasError={hasDateError('insuranceNextPaymentDate', errors, touched, focused)}
                              setFocused={setFocused}
                              datePickerProps={{
                                minDate: subtractYears(0),
                                maxDate: addYears(1),
                              }}
                              ref={insuranceNextPaymentDateRef}
                            />
                            <CollapsibleElement
                              className="date-error"
                              active={hasDateError('insuranceNextPaymentDate', errors, touched, focused) && !values.allDuesPaid && !!values.insuranceNextPaymentDate}
                              transitionDuration={0}
                            >
                              <p className="input-hint error">
                                {getDateErrorByType(errors.insuranceNextPaymentDate)}
                              </p>
                            </CollapsibleElement>
                          </FormControl>
                        )}

                        {values.paymentType === 'BY_INSTALLMENTS' && (
                          <FormControl compact>
                            <CheckboxField
                              name="allDuesPaid"
                              design="rounded"
                              labelAlign="left"
                              className="dues-paid-checkbox"
                              onChange={() => handleChangeAllDuesPaid(values, setFieldValue, setFieldError)}
                            >
                              Все взносы рассрочки оплачены
                            </CheckboxField>
                          </FormControl>
                        )}

                        <FormControl
                          label={(<>Залогодержатель <InfoButton type="pledgee" onClick={handleOpenInfoModal} /></>)}
                          className={classnames('form-control', { _error: !!errors.pledgee && !!touched.pledgee })}
                        >
                          <RadioButtonField name="pledgee" options={pledgeeOptions} />
                        </FormControl>

                        <FormControl
                          label={(<>Выгодоприобретатель <InfoButton type="beneficiary" onClick={handleOpenInfoModal} /></>)}
                          className={classnames('form-control', { _error: !!errors.beneficiary && !!touched.beneficiary })}
                        >
                          <RadioButtonField name="beneficiary" options={beneficiaryOptions} />
                        </FormControl>
                      </>
                    )}

                    <h2 className="title">{isCascoInsurance ? 'Документы' : 'Информация о страховом полисе'}</h2>

                    <p className="description">
                      Вы можете прикрепить файлы в&nbsp;форматах jpeg, jpg, png, pdf
                      размером до&nbsp;5&nbsp;МБ. Общий размер всех файлов не&nbsp;должен превышать 20&nbsp;МБ
                    </p>

                    {Object.entries(uploadDocumentTypesToSend)
                      .map(([fieldName, { label, maxFiles }]) => (
                        <FormControl
                          key={fieldName}
                          design="v2"
                          label={label}
                          className={classnames('form-control', { _error: !!touched[fieldName] && values[fieldName]?.length < 1 })}
                        >
                          <FileInputField
                            name={fieldName}
                            maxFiles={maxFiles}
                            placeholder="Прикрепить файлы"
                            design="v2"
                            multiple={false}
                          />
                        </FormControl>
                      ))}

                    <FormControl className="form-control">
                      <InputField
                        name="customerComment"
                        label="Комментарий"
                        design="v2"
                        textarea
                      />
                    </FormControl>

                    <CollapsibleElement active={!!mainHint.label || !!allFieldsRequiredError.label}>
                      <DomHolder>
                        {(!!mainHint.label || !!allFieldsRequiredError.label) && (
                          <AlertBlock
                            text={mainHint.label || allFieldsRequiredError.label}
                            status={mainHint.type || allFieldsRequiredError.type}
                            margin="0"
                          />
                        )}
                      </DomHolder>
                    </CollapsibleElement>
                  </div>

                  <FormFooter offset="xs">
                    <Button
                      type="submit"
                      design="red"
                      // disabled={hasErrors(touched, errors) || !isValid || !allFilesUploaded || !isContributionAmountLessThanSumInsured}
                      loading={sendInsuranceProlongLoading || !!sendInsuranceProlongError}
                      size="l"
                    >
                      Отправить
                    </Button>
                  </FormFooter>
                  <ChooseHeadCompanyModal
                    active={activeModal}
                    onClose={onCloseModal}
                    onChooseCompany={(id) => handleChooseCompany(setFieldValue, id)}
                  />
                  <InfoModal
                    onClose={() => onCloseModal(true)}
                    type={infoModalType}
                  />
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </LoaderOrChildren>
  );
}

export default SendPolicyPage;

import classnames from 'classnames';
import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, transition } from '../styles/mixins';
import { RadioContext } from './RadioGroup';

const UiCheckbox = styled.label`
  display: flex;
  cursor: pointer;

  &:hover {
    .box {
      border-color: ${color.black.rgba(0.1)};
    }
  }

  &:active {
    .box {
      border-color: rgba(0, 0, 0, .3);
    }
  }

  &.disabled {
    cursor: not-allowed;

    .input {
      cursor: not-allowed;
    }

    .box {
      border-color: ${color.black.rgba(0.08)};

      > .check-icon {
        opacity: .2;
      }
    }

    .label {
      opacity: .1;
    }
  }

  .box {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #A7AFBC;
    transition: border-color ${transition.fast};
    min-width: 20px;

    > .check-icon {
      display: block;
      width: 10px;
      height: 10px;
      margin: 4px 0 0 4px;
      border-radius: 50%;
      background-color: #001C3D;
      transform: scale(0);
      opacity: 1;
      transition: ${transition.fast};
      transition-property: transform, opacity;
    }
  }

  .input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    box-shadow: none;
    -webkit-appearance: none;
    border: 0;
    outline: 0;
    background: #FFF;
    margin: 0;

    &:checked + .check-icon {
      transform: scale(1);
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: #647081;
    opacity: 1;
    transition: opacity ${transition.fast};
    margin-${(props) => (props.$childrenAlign === 'left' ? 'right' : 'left')}: 12px;
  }
`;

const Radio = forwardRef((props, ref) => {
  const {
    className, children, childrenAlign = 'right', value, checked, name, disabled, onChange,
  } = props;
  const context = useContext(RadioContext);

  return (
    <UiCheckbox
      ref={ref}
      className={classnames(className, { disabled, checked: context ? context.value === value : checked })}
      $childrenAlign={childrenAlign}
    >
      {children && childrenAlign === 'left' && (<span className="label">{children}</span>)}
      <span className="box">
        <input
          type="radio"
          className="input"
          value={value}
          name={context ? context.name : name}
          checked={context ? context.value === value : checked}
          disabled={disabled}
          onChange={context ? context.onChange : onChange}
        />
        <div className="check-icon" />
      </span>
      {children && childrenAlign === 'right' && (<span className="label">{children}</span>)}
    </UiCheckbox>
  );
});

Radio.displayName = 'Radio';

Radio.propTypes = {
  value: PropTypes.any.isRequired,
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  childrenAlign: PropTypes.oneOf(['left', 'right']),
};

export default Radio;

import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import * as actions from '../actions/early-payment';
import { asyncReducerFactory } from '../utils/redux-utils';

const earlyFullRepaymentCalc = createReducer({})
  .handleAction(actions.getEarlyFullRepaymentCalc.request, (state, { payload }) => ({
    ...state,
    [payload]: { ...state, processLoading: true, successed: null },
  }))
  .handleAction(actions.getEarlyFullRepaymentCalc.success, (state, { payload, meta }) => ({
    ...state,
    [meta]: { processLoading: false, ...payload, successed: true },
  }))
  .handleAction(actions.getEarlyFullRepaymentCalc.failure, (state, { payload, meta }) => ({
    ...state,
    [meta]: { processLoading: false, ...payload, successed: false },
  }))
  .handleAction(actions.resetEarlyRepayment, (state, { payload }) => ({
    ...state,
    [payload]: { },
  }));

const sendRarlyFullRepayment = createReducer({})
  .handleAction(actions.sendEarlyFullRepaymentRequest.request, (state, { payload }) => ({
    ...state,
    [payload]: { processLoading: true, successed: null },
  }))
  .handleAction(actions.sendEarlyFullRepaymentRequest.success, (state, { payload, meta }) => ({
    ...state,
    [meta]: { processLoading: false, ...payload, successed: true },
  }))
  .handleAction(actions.sendEarlyFullRepaymentRequest.failure, (state, { payload, meta }) => ({
    ...state,
    [meta]: { processLoading: false, ...payload, successed: false },
  }))
  .handleAction(actions.resetEarlyRepayment, (state, { payload }) => ({
    ...state,
    [payload]: { },
  }));

const earlyPartialRepaymentCalc = createReducer({})
  .handleAction(actions.getEarlyPartialRepaymentCalc.request, (state, { payload }) => ({
    ...state,
    [payload]: { processLoading: true, successed: null },
  }))
  .handleAction(actions.getEarlyPartialRepaymentCalc.success, (state, { payload, meta }) => ({
    ...state,
    [meta]: { processLoading: false, ...payload, successed: true },
  }))
  .handleAction(actions.getEarlyPartialRepaymentCalc.failure, (state, { payload, meta }) => ({
    ...state,
    [meta]: { processLoading: false, ...payload, successed: false },
  }))
  .handleAction(actions.resetEarlyRepayment, (state, { payload }) => ({
    ...state,
    [payload]: { },
  }));

const sendRarlyPartialRepayment = createReducer({})
  .handleAction(actions.sendEarlyPartialRepaymentRequest.request, (state, { payload }) => ({
    ...state,
    [payload]: { processLoading: true, successed: null },
  }))
  .handleAction(actions.sendEarlyPartialRepaymentRequest.success, (state, { payload, meta }) => ({
    ...state,
    [meta]: { processLoading: false, ...payload, successed: true },
  }))
  .handleAction(actions.sendEarlyPartialRepaymentRequest.failure, (state, { payload, meta }) => ({
    ...state,
    [meta]: { processLoading: false, ...payload, successed: false },
  }))
  .handleAction(actions.resetEarlyRepayment, (state, { payload }) => ({
    ...state,
    [payload]: { },
  }));

const ferAvailability = createReducer({})
  .handleAction(actions.getFerAvailability.request, (state, { payload }) => ({
    ...state,
    [payload]: { ...state[payload], processLoading: true },
  }))
  .handleAction(actions.getFerAvailability.success, (state, { payload, meta }) => ({
    ...state,
    [meta]: { processLoading: false, ...payload },
  }))
  .handleAction(actions.getFerAvailability.failure, (state, { payload, meta }) => ({
    ...state,
    [meta]: { processLoading: false, ...payload },
  }))
  .handleAction(actions.resetEarlyRepayment, (state, { payload }) => ({
    ...state,
    [payload]: { },
  }));

export default combineReducers({
  earlyFullRepaymentCalc,
  sendRarlyFullRepayment,
  earlyPartialRepaymentCalc,
  sendRarlyPartialRepayment,
  ferAvailability,
  sendRarlyFullRepaymentFetchStatus: asyncReducerFactory(actions.sendEarlyFullRepaymentRequest),
  sendRarlyPartialRepaymentFetchStatus: asyncReducerFactory(actions.sendEarlyPartialRepaymentRequest),
  getEarlyPartialRepaymentCalcStatus: asyncReducerFactory(actions.getEarlyPartialRepaymentCalc),
  getEarlyFullRepaymentCalcStatus: asyncReducerFactory(actions.getEarlyFullRepaymentCalc),
  getFerAvailabilityStatus: asyncReducerFactory(actions.getFerAvailability),
});

import { createAsyncAction, createAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showFailedToLoadData } from './app';

export const getEarlyFullRepaymentCalc = createAsyncAction(
  'GET_EARLY_FULL_REPAYMENT_CALC_REQUEST',
  'GET_EARLY_FULL_REPAYMENT_CALC_SUCCESS',
  'GET_EARLY_FULL_REPAYMENT_CALC_FAIL',
)();

export const sendEarlyFullRepaymentRequest = createAsyncAction(
  'SEND_EARLY_FULL_REPAYMENT_REQUEST',
  'SEND_EARLY_FULL_REPAYMENT_SUCCESS',
  'SEND_EARLY_FULL_REPAYMENT_FAIL',
)();

export const getEarlyPartialRepaymentCalc = createAsyncAction(
  'GET_EARLY_PARTIAL_REPAYMENT_CALC_REQUEST',
  'GET_EARLY_PARTIAL_REPAYMENT_CALC_SUCCESS',
  'GET_EARLY_PARTIAL_REPAYMENT_CALC_FAIL',
)();

export const sendEarlyPartialRepaymentRequest = createAsyncAction(
  'SEND_EARLY_PARTIAL_REPAYMENT_REQUEST',
  'SEND_EARLY_PARTIAL_REPAYMENT_SUCCESS',
  'SEND_EARLY_PARTIAL_REPAYMENT_FAIL',
)();

export const getFerAvailability = createAsyncAction(
  'GET_FER_AVAILABILITY_REQUEST',
  'GET_FER_AVAILABILITY_SUCCESS',
  'GET_FER_AVAILABILITY_FAIL',
)();

export const resetEarlyRepayment = createAction('RESET_EARLY_REPAYMENT')();

export function getEarlyFullRepaymentCalcAsync(contractNumber, operatorId, ferDate) {
  return (dispatch) => {
    dispatch(getEarlyFullRepaymentCalc.request(contractNumber));
    return ApiClient.contracts.getEarlyFullRepaymentCalc(contractNumber, operatorId, ferDate)
      .then(({ data }) => {
        localStorage.setItem(`earlyFullRepaymentResponse-${contractNumber}`, JSON.stringify({ ...data, processLoading: false, successed: true }));
        localStorage.setItem(`earlyFullRepaymentResponseDate-${contractNumber}`, Number(new Date()));
        dispatch(getEarlyFullRepaymentCalc.success({ ...data, ferDate }, contractNumber));
        dispatch(showFailedToLoadData({}));
      })
      .catch((error) => {
        if (error.status !== 403 || (error.status === 403 && error.error_message !== 'Forbidden')) {
          dispatch(showFailedToLoadData({
            active: true,
            onRetry: () => dispatch(getEarlyFullRepaymentCalcAsync(contractNumber, operatorId, ferDate)),
            asyncStatusReducerName: 'getEarlyFullRepaymentCalcStatus',
            reducersNS: 'earlyPayment',
            withPageTitle: true,
            title: 'Досрочное погашение',
          }));
        }
        dispatch(getEarlyFullRepaymentCalc.failure(error, contractNumber));
      });
  };
}

export function sendEarlyFullRepaymentRequestAsync(contractNumber, operatorId, ferSum, ferDate) {
  return (dispatch, getState) => {
    const { requestChannel } = getState().app;
    dispatch(sendEarlyFullRepaymentRequest.request(contractNumber));
    const data = { contractNumber, operatorId, ferSum, ferDate, requestChannel };

    return ApiClient.contracts.sendEarlyFullRepaymentRequest(data)
      .then(({ data }) => {
        dispatch(sendEarlyFullRepaymentRequest.success(data, contractNumber));
        dispatch(showFailedToLoadData({}));
      })
      .catch((error) => {
        if (error.status !== 403 || (error.status === 403 && error.error_message !== 'Forbidden')) {
          dispatch(showFailedToLoadData({
            active: true,
            onRetry: () => dispatch(sendEarlyFullRepaymentRequestAsync(data)),
            asyncStatusReducerName: 'sendRarlyFullRepaymentFetchStatus',
            reducersNS: 'earlyPayment',
            withPageTitle: true,
            title: 'Досрочное погашение',
          }));
        }
        dispatch(sendEarlyFullRepaymentRequest.failure(error, contractNumber));
      });
  };
}

export function getEarlyPartialRepaymentCalcAsync(contractNumber, perDate, calcSum, termsChangeTypeCode, operatorId) {
  return (dispatch) => {
    dispatch(getEarlyPartialRepaymentCalc.request(contractNumber));
    return ApiClient.contracts.getEarlyPartialRepaymentCalc({ contractNumber, perDate, calcSum, termsChangeTypeCode, operatorId })
      .then(({ data }) => {
        const oldEarlyPartialRepaymentResponse = JSON.parse(localStorage.getItem(`earlyPartialRepaymentResponse-${contractNumber}`));
        localStorage.setItem(`earlyPartialRepaymentResponse-${contractNumber}`, JSON.stringify({
          ...oldEarlyPartialRepaymentResponse, ...data, processLoading: false, successed: true,
        }));
        localStorage.setItem(`earlyPartialRepaymentResponseDate-${contractNumber}`, Number(new Date()));
        dispatch(getEarlyPartialRepaymentCalc.success(data, contractNumber));
        dispatch(showFailedToLoadData({}));
      })
      .catch((error) => {
        if (error.status !== 403 || (error.status === 403 && error.error_message !== 'Forbidden')) {
          dispatch(showFailedToLoadData({
            active: true,
            onRetry: () => dispatch(getEarlyPartialRepaymentCalcAsync(contractNumber, perDate, calcSum, termsChangeTypeCode, operatorId)),
            asyncStatusReducerName: 'getEarlyPartialRepaymentCalcStatus',
            reducersNS: 'earlyPayment',
            withPageTitle: true,
            title: 'Досрочное погашение',
          }));
        }
        dispatch(getEarlyPartialRepaymentCalc.failure(error, contractNumber));
      });
  };
}

export function sendEarlyPartialRepaymentRequestAsync(contractNumber, operatorId, termsChangeTypeCode, perDate, calcSum) {
  return (dispatch, getState) => {
    const { requestChannel } = getState().app;
    dispatch(sendEarlyPartialRepaymentRequest.request(contractNumber));
    const data = { contractNumber, operatorId, termsChangeTypeCode, perDate, calcSum, requestChannel };
    return ApiClient.contracts.sendEarlyPartialRepaymentRequest(data)
      .then(({ data }) => {
        dispatch(sendEarlyPartialRepaymentRequest.success(data, contractNumber));
        dispatch(showFailedToLoadData({}));
      })
      .catch((error) => {
        if (error.status !== 403 || (error.status === 403 && error.error_message !== 'Forbidden')) {
          dispatch(showFailedToLoadData({
            active: true,
            onRetry: () => (
              dispatch(sendEarlyPartialRepaymentRequestAsync(data))
            ),
            asyncStatusReducerName: 'sendRarlyPartialRepaymentFetchStatus',
            reducersNS: 'earlyPayment',
            withPageTitle: true,
            title: 'Досрочное погашение',
          }));
        }
        dispatch(sendEarlyPartialRepaymentRequest.failure(error, contractNumber));
      });
  };
}

export function getFerAvailabilityAsync(contractNumber) {
  return (dispatch) => {
    dispatch(getFerAvailability.request(contractNumber));
    return ApiClient.contracts.getFerAvailability(contractNumber)
      .then(({ data }) => {
        const oldEarlyPartialRepaymentResponse = localStorage.getItem(`earlyPartialRepaymentResponse-${contractNumber}`);
        localStorage.setItem(`earlyPartialRepaymentResponse-${contractNumber}`, JSON.stringify({
          ...oldEarlyPartialRepaymentResponse, ...data, processLoading: false, successed: true,
        }));
        dispatch(getFerAvailability.success(data, contractNumber));
        dispatch(showFailedToLoadData({}));
      })
      .catch((error) => {
        if (error.status !== 403 || (error.status === 403 && error.error_message !== 'Forbidden')) {
          dispatch(showFailedToLoadData({
            active: true,
            onRetry: () => dispatch(getFerAvailabilityAsync(contractNumber)),
            asyncStatusReducerName: 'getFerAvailabilityStatus',
            reducersNS: 'earlyPayment',
            withPageTitle: true,
            title: 'Досрочное погашение',
          }));
        }
        dispatch(getFerAvailability.failure(error, contractNumber));
      });
  };
}

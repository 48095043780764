import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styled, { css } from 'styled-components';
import CollapsibleElement from '../../components/common/CollapsibleElement';
import { useFormControl } from '../form-control/useFormControl';
import { color, transition } from '../styles/mixins';
import ResetIcon from './assets/remove.svg';
import SearchIcon from './assets/search-v2.svg';
import ErrorIcon from './assets/error-icon.svg';

const inputAffix = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  > svg {
    display: block;
  }
`;

const UiInputWrap = styled.div`
  display: inline-flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: flex-end;

  .char-counter {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #647081;
    padding-top: 8px;

    span {
      color: #000C1A;
    }
  }

  .collapsible-element {
    margin-top: 0!important;
  }
`;

const UiInput = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;

  &.v1 {
    .ui-input {
      box-sizing: border-box;
      display: inline-block;
      width: 100%;
      height: 48px;
      line-height: 46px;
      font-family: inherit;
      font-size: 16px;
      border-radius: 8px;
      border: 1px solid var(--ui-on-white-inputs, #DADADA);
      background: #fff;
      font-weight: 400;
      color: ${color.black};
      box-shadow: none;
      outline: 0;
      margin: 0;
      padding: 0 16px 2px;
      transition: ${transition.fast};
      transition-property: background-color, border-color, color, box-shadow;

      &::placeholder {
        font-family: inherit;
        font-size: 13px;
        line-height: 46px;
        color: #A6AAB1;
      }

      &::-moz-placeholder {
        line-height: 48px;
      }

      &::-moz-focus-inner {
        border: 0;
        padding: 0;
        outline: 0;
      }

      &::-ms-clear,
      &::-ms-reveal {
        display: none;
      }

      &:hover {
        border-color: ${color.black.rgba(0.5)};
      }

      &[disabled] {
        border-color: ${color.black.rgba(0.08)};
        background-color: ${color.black.rgba(0.05)};
        cursor: not-allowed;
      }

      &[type="search"] {
        font-family: inherit;
        font-size: 16px;
        line-height: 20px;
        border-radius: 8px;
        border-color: ${color.black.rgba(0)};
        background-color: ${color.black.rgba(0.05)};
        -webkit-appearance: textfield;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }

        &::placeholder {
          font-family: inherit;
          font-size: 13px;
          color: #A6AAB1;
        }

        &:hover {
          background-color: ${color.black.rgba(0.07)};
        }

        &:focus {
          box-shadow: 0 11px 40px 0 ${color.black.rgba(0.08)};
          background-color: ${color.black.rgba(0)};
        }
      }

      &.size-s {
        border-radius: 6px;
        //height: 30px;
        //
        //&::-moz-placeholder {
        //  line-height: 30px;
        //}
      }

      &.size-m {
        border-radius: 8px;
        //height: 40px;
        //
        //&::-moz-placeholder {
        //  line-height: 40px;
        //}
      }

      &.size-l {
        border-radius: 10px;
        //height: 48px;
        //
        //&::-moz-placeholder {
        //  line-height: 48px;
        //}
      }

      &.textarea {
        height: 120px;
        padding: 12px 16px;
        resize: none;
        font-size: 16px;
        line-height: 24px;
      }

      &.has-prefix {
        padding-left: 44px;
      }

      &.has-suffix {
        padding-right: 44px;
      }

      &.state-valid {
        border-color: ${color.success};
      }

      &.state-invalid {
        color: ${color.error};
        //border-color: ${color.error};
      }
    }
  }

  &.has-api-error {
    .ui-input {
      color: ${color.error};
    }
  }

  .ui-input-prefix {
    ${inputAffix};
    left: 14px;
  }

  .ui-input-suffix {
    ${inputAffix};
    right: 14px;
    pointer-events: none;
    z-index: 3;
  }

  &.v2 {
    position: relative;
    height: 58px;
    background: #E5E7EA;
    border-radius: 8px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    z-index: 1;

    &.with-currency::after {
      content: ' ₽';
      position: absolute;
      left: 17px;
      bottom: 8px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      color: #000C1A;
      z-index: 10;
    }

    &.money-input {
      .ui-input {
        font-weight: 700;
      }
    }

    &.textarea {
      min-height: 58px;
      height: max-content;
      align-items: flex-start;

      .reset-button {
        margin-top: 21px;
      }
    }

    &.disabled {
      opacity: 0.4;
    }

    .search-icon {
      margin-right: 8px;
    }

    .ui-input-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      flex: 1;
      position: relative;
      z-index: 2;
      min-height: 58px;
    }

    .reset-button {
      border: none;
      background: none;
      width: 20px;
      height: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .input-label {
      position: absolute;
      z-index: 2;
      transition: transform 150ms ease-out, font-size 150ms ease-out;
      font-size: 16px;
      line-height: 20px;
      color: #647081;
      pointer-events: none;

      top: 19px;
      display: flex;
      align-items: center;
      height: 20px;
    }

    .ui-input {
      position: relative;
      border: none;
      box-sizing: border-box;
      background: #E5E7EA;
      display: inline-block;
      width: 100%;
      font-size: 18px;
      line-height: 22px;
      caret-color: #E40038;
      color: #000C1A;
      height: 22px;
      border-radius: 4px;
      padding: 1px 2px; //reset mobile safari styles

      &::placeholder {
        font-size: 18px;
        line-height: 22px;
        color: transparent;
      }

      &.textarea {
        //height: max-content;
        //margin: 17px 0px;
        resize: none;
      }
    }

    &.active-label {
      .ui-input-container {
        padding-right: 10px;
      }

      .input-label {
        font-size: 14px;
        line-height: 16px;
        transform: translateY(-11px);
      }

      .ui-input {
        transform: translateY(10px);

        &::placeholder {
          color: #8B95A4;
        }

        &.textarea {
          margin: 15px 0;
        }
      }
    }

    &.focused {
      .input-label {
        color: #000C1A;
      }
    }

    &.state-invalid {
      .input-label , .ui-input, &.with-currency::after, .ui-input::placeholder {
        color: ${color.error};
      }
    }

    .suffix-calendar {
      pointer-events: unset;
      height: 24px;

      .suffix-button {
        border: none;
        background-color: transparent;
        padding: 0;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

function useCombinedRefs(...refs) {
  return (target) => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(target);
      } else {
        ref.current = target;
      }
    });
  };
}

const toUnmaskedMoneyValue = (value) => (
  parseFloat((value ?? '').replace(/ /g, '').replace(',', '.')) ?? 0
);

const Input = forwardRef((props, ref) => {
  const {
    className,
    size,
    state,
    prefix,
    suffix,
    textarea,
    design,
    label,
    onFocus,
    onBlur,
    withoutResetBtn,
    search,
    isMoneyInput,
    suffixCls,
    maxLength,
    zeroAllowed,
    ...attributes
  } = props;
  const cls = classnames('ui-input', `size-${size}`, `state-${state}`, {
    'has-prefix': !!prefix,
    'has-suffix': !!suffix,
    textarea,
  });
  const InputComponent = textarea ? 'textarea' : 'input';
  const [activeLabel, setActiveLabel] = useState(!!label && !!props.placeholder);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();

  const handleClickOnWrapper = () => inputRef.current?.focus();

  const handleChange = useCallback((value) => {
    const { onChange } = props;

    if (onChange) {
      onChange({
        target: { name: props.name, value, focus: () => {} },
      }, value);
    }
  }, [props]);

  const handleFocus = useCallback((e) => {
    if (label) setActiveLabel(true);
    setFocused(true);

    if (isMoneyInput && toUnmaskedMoneyValue(props.value) === 0) {
      handleChange(' ₽');
    }

    if (onFocus) {
      onFocus(e);
    }
  }, [onFocus, isMoneyInput, props.value]);

  const handleBlur = useCallback((e) => {
    if (label) setActiveLabel(!!props.value || !!props.placeholder);
    setFocused(false);

    if (isMoneyInput && !props.value) {
      handleChange('0 ₽');
    }

    if (onBlur) {
      onBlur(e);
    }
  }, [props.value, onBlur]);

  const handleReset = useCallback((e) => {
    e.preventDefault();
    handleChange('');
  }, [handleChange]);

  const fcProps = useFormControl({
    ...props,
    onFocus: handleFocus,
    onBlur: handleBlur,
  });

  useEffect(() => {
    if (label && props.value && !activeLabel) {
      setActiveLabel(true);
    }
  }, [label, props.value, activeLabel]);

  useLayoutEffect(() => {
    if (textarea && inputRef.current) {
      inputRef.current.style.height = '25px';
      inputRef.current.style.height = `${inputRef.current.scrollHeight < 93 ? inputRef.current.scrollHeight + 1 : 93}px`;
    }
  }, [props.value, textarea]);

  if (design === 'v1') {
    return (
      <UiInput className={classnames('v1', className)}>
        {
          prefix && (<div className="ui-input-prefix">{prefix}</div>)
        }
        <InputComponent ref={ref} className={cls} {...attributes} {...fcProps} />
        {
          suffix && (<div className="ui-input-suffix">{suffix}</div>)
        }
      </UiInput>
    );
  }

  return (
    <UiInputWrap onClick={handleClickOnWrapper}>
      <UiInput
        className={classnames(design, className, `state-${state}`, {
          'active-label': activeLabel,
          focused,
          textarea,
          disabled: props.disabled,
          'with-currency': isMoneyInput && !props.value && focused,
          'money-input': isMoneyInput,
        })}
      >
        {search && <SearchIcon className="search-icon" />}
        <div className="ui-input-container">
          <label className="input-label" htmlFor={props.name}>{label}</label>
          <InputComponent
            id={props.name}
            ref={useCombinedRefs(inputRef, ref)}
            className={cls}
            {...(textarea ? { maxLength } : null)}
            {...attributes}
            {...fcProps}
          />
        </div>
        {!withoutResetBtn && props.value && focused ? (
          <button type="button" className="reset-button" onMouseDown={handleReset}>
            <ResetIcon />
          </button>
        ) : (
          <>
            {(suffix || (state === 'invalid' && !!props.value?.length)) && (
              <div className={classnames('ui-input-suffix', suffixCls)}>
                {state === 'invalid' && !suffix ? <ErrorIcon /> : suffix}
              </div>
            )}
          </>
        )}
      </UiInput>

      {textarea && (//focused}>
        <CollapsibleElement active={focused}>
          <div className="char-counter">
            <span>{props.value.length}</span>/{maxLength}
          </div>
        </CollapsibleElement>
      )}
    </UiInputWrap>
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  state: PropTypes.oneOf(['default', 'valid', 'invalid']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  textarea: PropTypes.bool,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  design: PropTypes.oneOf(['v1', 'v2']),
  label: PropTypes.string,
  withoutResetBtn: PropTypes.bool,
  search: PropTypes.bool,
  isMoneyInput: PropTypes.bool,
  suffixCls: PropTypes.string,
  maxLength: PropTypes.number,
  zeroAllowed: PropTypes.bool,
};

Input.defaultProps = {
  size: 'm',
  type: 'text',
  state: 'default',
  design: 'v1',
  maxLength: 200,
  zeroAllowed: true,
};

export default Input;

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LabelValue } from '../../../../ui-kit';
import { getCreditRequisitesAsync } from '../../../../actions/credits';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import EmptyDataBlock from '../../../common/EmptyDataBlock';
import { useUser } from '../../../common/hooks';
import PageTitle from '../../../common/PageTitle';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';

const fieldsConfig = {
  // contract_date: { label: '' },
  recipient: { label: 'Получатель платежа' },
  beneficiary_bank: { label: 'Банк получателя' },
  bank_address: { label: 'Адрес' },
  bank_bic: { label: 'БИК' },
  bank_inn: { label: 'ИНН' },
  bank_kpp: { label: 'КПП' },
  correspondent_account: { label: 'Корр/счёт' },
  personal_account: { label: 'Л/счёт Заемщика' },
  payer: { label: 'Плательщик' },
  payment_purpose: { label: 'Назначение платежа' },
};

const CreditRequisites = (props) => {
  const dispatch = useDispatch();
  const user = useUser();
  const { match: { params: { creditId } } } = props;
  const { creditsById, requisitesFetchStatus } = useSelector((state) => ({
    creditsById: state.credits.byId,
    requisitesFetchStatus: state.credits.requisitesFetchStatus,
  }));
  const credit = creditsById[creditId];
  const { requisites, requisites: { contract_date: contractDate, contract_number: contractNumber, url } = {} } = credit;

  useEffect(() => {
    const { operator_id: operatorId } = user;
    dispatch(getCreditRequisitesAsync(creditId, operatorId));
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
  }, []);

  return (
    <div className="page page-credit-requisites">
      <PageTitle title="Реквизиты" />
      <LoaderOrChildren loading={requisitesFetchStatus.loading}>
        {credit.requisites && (
          <>
            <a href={url} download className="credit-info">
              <div className="credit-info-label">Кредитный договор</div>
              <div className="credit-info-value">№ {contractNumber} от {contractDate}</div>
            </a>
            <div className="credit-requisites-list">
              {Object.keys(fieldsConfig).map((key) => (
                <LabelValue key={key} label={fieldsConfig[key].label} value={requisites[key]} />
              ))}
            </div>
          </>
        )}
        {!credit.requisites && <EmptyDataBlock message="Реквизиты по данному кредитному договору недоступны" />}
      </LoaderOrChildren>
    </div>
  );
};

export default memo(CreditRequisites);

import * as classnames from 'classnames';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import React, { useMemo, useState, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { moneyFormat } from '../../utils/format';
import { color, transition } from '../styles/mixins';
import ArrowRight from '../../assets/svg/nav-menu/arrow-right.svg';
import NecCredit from '../../assets/svg/credit-logos/nec-credit.svg';
import CarCredit from '../../assets/svg/credit-logos/car-credit.svg';
import CreditCardCredit from '../../assets/svg/credit-logos/card-credit.svg';
import AlertIcon from '../../assets/svg/alert-icon.svg';
import Tooltip from '../tooltip/Tooltip';
import useNextPayment from '../../components/common/hooks/useNextPayment';

const popperModifiers = { offset: { offset: '0px, 10px' }, preventOverflow: { priority: ['left', 'right'] }, hide: { enabled: false }, flip: { enabled: false } };

const UiCreditListItem = styled(Link)`
  display: flex;
  position: relative;
  margin: 0 16px;
  padding-bottom: 16px;
  padding-top: 16px;

  .info-icon {
    position: absolute;
    left: -40px;
    top: -5px;
    cursor: pointer;

    svg {
      display: block;
    }

    .fill-colored-el {
      transition: fill ${transition.normal};
    }

    &.active {
      .fill-colored-el {
        fill: #FEAA06;
      }
    }
  }

  .alert-block {
    display: block;
    margin: 16px 0 13px;
    //padding: 10px 16px;
    border-radius: 4px;
    background-color: #E9E9E9;

    .label-value {
      padding: 0 16px 12px;
    }
  }
  .alert-block-title  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;

    .alert-icon-with-text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .alert-icon {
      margin-left: -2px;
      margin-right: 8px;
      flex-shrink: 0;

      .fill-colored-el {
        fill: #FEAA06;
      }
    }
  }
  .alert-block-title-text {
    flex-shrink: 1;
    display: inline-block;
    color: var(--text-trend-color, #585858);
    font-size: 13px;
    line-height: 15px;
    margin-right: 5px;
    padding-bottom: 1px;
  }

  .credit-type {
    font-weight: bold;
    font-size: 12px;
    line-height: 26px;
    margin-bottom: 11px;
    text-transform: uppercase;
    color: #979797;
  }

  .credit-debt {
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    margin-top: -5px;
    color: ${color.black};
  }

  .credit-number {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 7px;
    color: #979797;
  }

  .arrow-right {
      display: block;
      position: absolute;
      right: -6px;
      width: 26px;
      height: 26px;
      background-size: 100%;
      pointer-events: none;
  }

  .credit-list-item-content {
      padding-left: 14px;

      .credit-details {
        position: relative;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 6px;

        .credit-details-label {
          color: #979797;
          padding-right: 4px;
        }
        .credit-details-value {
          color: #000000;
        }
      }
  }

  &.closed {
    .credit-type-icon {
      path {
        fill: #979797;
      }
    }
  }

  &.withoutLabel {
    .credit-number {
      margin-bottom: 15px;
    }
    .next-payment {
      margin-bottom: 12px
    }
    .pay-until {
      margin-bottom: 8px;
    }
  }
`;

const contentDebit = 'Пополните счёт в Росбанке не позднее 13:00 мск назначенной даты в графике платежей кредита. Денежные средства будут списываться автоматически';
const contentNoDebit = 'В приложении РОСБАНК Онлайн выберите меню Оплата услуг и переводы → Оплата услуг → Разное → Росбанк Авто (ООО «Русфинанс Банк») и переведите средства с Вашего счёта в ПАО РОСБАНК на счёт Кредита в Росбанк Авто';

let incrementingUid = 0;

const CreditLogo = (props) => {
  const { type } = props;

  let IconComponent;

  switch (type) {
    case 'A':
      IconComponent = CarCredit;
      break;
    case 'C':
      IconComponent = CreditCardCredit;
      break;
    case 'D':
    default:
      IconComponent = NecCredit;
      break;
    case 'P':
      IconComponent = NecCredit;
      break;
  }

  return <IconComponent className="credit-type-icon" />;
};

const CreditListItem = (props) => {
  const {
    children,
    credit,
    withLabel = true,
    withIcon = false,
    // debitCredit = false,
    showDebt = false,
  } = props;

  const { t } = useTranslation();
  const uid = useMemo(() => incrementingUid++, []);
  const { sum: nextPaymentSum, date: nextPaymentDate } = useNextPayment(credit);

  const {
    contract_number: creditNumber,
    contract_type_code: type,
    additionalInfo,
    is_direct_debit: debitCredit,
    is_assignment: isAssignment = false
  } = credit;
  const closed = credit.is_closed === 1;

  const label = useMemo(
    () => {
      switch (type) {
        case 'A':
          return 'Автокредит';
        case 'C':
          return 'Кредитная карта';
        case 'D':
        default:
          return 'Нецелевой кредит';
        case 'P':
          return 'Потребительский кредит';
      }
    },
    [type],
  );

  const [tooltipOpened, setTooltipOpened] = useState(false);

  const toggleTooltipOpened = useCallback(() => setTooltipOpened((tooltipOpened) => !tooltipOpened), []);

  const contractDebt = useMemo(() => additionalInfo?.[type === 'C' ? 'total_debt' : 'debt_outstanding_sum'] || 0, [credit]);

  return (
    <UiCreditListItem
      as={!withLabel ? 'div' : undefined}
      to={`/credits/${creditNumber}`}
      className={classnames('credit-item-block', { closed, withoutLabel: !withLabel })}
    >
      <CreditLogo type={type} />
      {withLabel && <ArrowRight className="arrow-right" />}
      <div className="credit-list-item-content">
        {withLabel && <div className="credit-type">{label}</div>}
        <div className="credit-debt">{moneyFormat(contractDebt)}</div>
        <div className="credit-number">{creditNumber}</div>
        {!closed ? (
          <>
            {!!nextPaymentSum && nextPaymentSum !== 0 && !(credit.is_debt_overdue_sum && !credit.is_data_actual) && (!additionalInfo.date_closed || !moment(additionalInfo.date_closed, 'DD.MM.YYYY').isBefore(moment(new Date()).startOf('day'))) && (
              <div className="credit-details next-payment">
                <span className="credit-details-label">Следующий платеж:</span>
                <span className="credit-details-value">{moneyFormat(nextPaymentSum)}</span>
              </div>
            )}
            {nextPaymentDate && !(credit.is_debt_overdue_sum && !credit.is_data_actual) && (
              <div className="credit-details pay-until">
                {withIcon && ((isAssignment && showDebt) || (credit.is_debt_overdue_sum && showDebt && !isAssignment)) && (
                  <Tooltip
                    opened={tooltipOpened}
                    content={debitCredit ? contentDebit : contentNoDebit}
                    placement="bottom-end"
                    onClickOutside={toggleTooltipOpened}
                    ignoreClass={`info-icon-${uid}`}
                    popperModifiers={popperModifiers}
                  >
                    {(ref) => (
                      <div ref={ref} className={classnames('info-icon', `info-icon-${uid}`, { active: tooltipOpened })}>
                        <AlertIcon
                          className={classnames('alert-icon', { active: tooltipOpened })}
                          onClick={toggleTooltipOpened}
                        />
                      </div>
                    )}
                  </Tooltip>
                )}
                {(!additionalInfo.date_closed || !moment(additionalInfo.date_closed, 'DD.MM.YYYY').isBefore(moment(new Date()).startOf('day'))) && (
                  <>
                    <span className="credit-details-label">Оплатить до:</span>
                    <span className="credit-details-value">{nextPaymentDate}</span>
                  </>
                )}
              </div>
            )}
            {credit.is_debt_overdue_sum && showDebt && !isAssignment && (
              <div className="alert-block">
                {/*<Link to={`${url}/overdue-debt`} className="alert-block">*/}
                <div className="alert-block-title">
                  <div className="alert-icon-with-text">
                    <AlertIcon className="alert-icon" />
                    <span className="alert-block-title-text">Просроченная задолженность</span>
                  </div>
                  {/*<ArrowRight className="arrow-right-icon" />*/}
                </div>
              </div>
            )}
            {isAssignment && showDebt && (
              <div className="alert-block">
                {/*<Link to={`${url}/overdue-debt`} className="alert-block">*/}
                <div className="alert-block-title">
                  <div className="alert-icon-with-text">
                    <AlertIcon className="alert-icon" />
                    <span className="alert-block-title-text">Договор уступлен</span>
                  </div>
                  {/*<ArrowRight className="arrow-right-icon" />*/}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="credit-details">
            <span className="credit-details-label">Статус:</span>
            <span className="credit-details-value">{t('closed')}</span>
          </div>
        )}
        {children}
      </div>
    </UiCreditListItem>
  );
};

export default memo(CreditListItem);

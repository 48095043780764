import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export default function FormikEffect({ effectDeps, onChange, onValid }) {
  const context = useFormikContext();
  const { values, isValid } = context;
  const valuesForEffectDeps = effectDeps ? effectDeps.map((fieldName) => values[fieldName]) : [context];
  const valuesForOnValidEffect = effectDeps ? effectDeps.map((fieldName) => values[fieldName]) : [values];

  useEffect(() => {
    if (onChange) {
      onChange(values);
    }
  }, [...valuesForEffectDeps, onChange]);

  useEffect(() => {
    if (isValid && onValid) {
      onValid(values);
    }
  }, [...valuesForOnValidEffect, isValid]);

  return null;
}

import React, { memo } from 'react';
import { Modal } from '../../ui-kit';

const ActionSheet = ({ active, onClose, header, children, footer, withLogo }) => (
  <Modal header={header} className="action-sheet" onClose={onClose} active={active} withLogo={withLogo} withOnClickOutside>
    <p className="description" dangerouslySetInnerHTML={{ __html: children }} />

    {footer}
  </Modal>
);

export default memo(ActionSheet);

import classnames from 'classnames';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const UiNavigationSquareLink = styled(Link)`
  width: 104px;
  height: 86px;
  background-color: #001C3D;
  border-radius: 6px;
  display: inline-block;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: top;
  padding: 12px;

  .icon-wrapper {
    display: flex;
    align-items: center;
    height: 22px;
    margin-bottom: 8px;

    &.white-icons {
      svg path {
        fill: #FFFFFF;
      }
      svg.without-fill path {
        fill: none;
      }
    }

    svg {
      display: block;
      max-width: 22px;
      max-height: 22px;
    }
  }

  .text-wrapper {
    flex-grow: 1;
    color: #FFFFFF;
    white-space: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    display: flex;
  }

  .button-block-content-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const NavigationSquareLink = (props) => {
  const { icon: Icon, text, to, asButton, onClick, whiteIcons } = props;
  const formattedProps = useMemo(() => (
    asButton ? { onClick, type: 'button', as: 'button' } : { to }
  ), [asButton, onClick, to]);

  return (
    <UiNavigationSquareLink className="navigation-square-link" {...formattedProps}>
      <div className="button-block-content-wrapper">
        <div className={classnames('icon-wrapper', { 'white-icons': whiteIcons })}>
          <Icon />
        </div>
        <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </UiNavigationSquareLink>
  );
};

NavigationSquareLink.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.node.isRequired,
  ]),
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  asButton: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NavigationSquareLink;

import React, { useEffect, useRef } from 'react';
import { BottomSheet as ReactSpringBottomSheet } from 'react-spring-bottom-sheet';
import DomHolder from './utils/DomHolder';
import getBottomSheetSnapPoints from './utils/getBottomSheetSnapPoints';

// function throttle(timer) {
//   let queuedCallback;
//   return (callback) => {
//     if (!queuedCallback) {
//       timer(() => {
//         const cb = queuedCallback;
//         queuedCallback = null;
//         cb();
//       });
//     }
//     queuedCallback = callback;
//   };
// }
//
// const throttledWrite = throttle(requestAnimationFrame);
// const throttledRead = throttle(requestPostAnimationFrame);

function BottomSheetContent({ children }) {
  const initialYPositionRef = useRef(null);

  useEffect(() => {
    const bottomSheetHeader = document.querySelector('[data-rsbs-header="true"]');

    bottomSheetHeader.addEventListener('pointerdown', (event) => {
      initialYPositionRef.current = event.clientY;
    });
    bottomSheetHeader.addEventListener('pointermove', (event) => {
      if (initialYPositionRef.current > event.clientY) {
        event.stopImmediatePropagation();
      }
    });
  }, []);

  return children || null;
}

function BottomSheet({ expandOnContentDrag = true, snapPoints, children, loading, onDismiss, ...props }) {
  const handleClose = () => {
    if (!loading && onDismiss) {
      onDismiss();
    }
  };

  return (
    <ReactSpringBottomSheet
      sibling={( // fix pressing buttons under backdrop in iOS
        <div
          data-rsbs-backdrop="true"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            return handleClose();
          }}
        />
      )}
      blocking={false}
      {...props}
      onDismiss={handleClose}
      expandOnContentDrag={expandOnContentDrag}
      snapPoints={snapPoints ?? getBottomSheetSnapPoints}
    >
      <DomHolder>
        {props.open && (
          <BottomSheetContent>{children}</BottomSheetContent>
        )}
      </DomHolder>
    </ReactSpringBottomSheet>
  );
}

export default BottomSheet;

import React from 'react';
import BottomSheet from '../../../../common/BottomSheet';
import DomHolder from '../../../../common/utils/DomHolder';
import HtmlText from '../../../../common/utils/HtmlText';

const ListItem = ({ conditionIconName, conditionText }) => (
  <div key={conditionIconName} className="list-item-wrapper">
    <img
      src={`/services/service-benefits/${conditionIconName}.svg`}
      alt={conditionIconName}
      className="list-item-icon"
    />
    <HtmlText className="item-description" tag="span">{conditionText}</HtmlText>
  </div>
);

function ActivationConditionsModal({ active, onClose, activationConditionList, activationRestrictionText }) {
  return (
    <BottomSheet
      open={active}
      onDismiss={onClose}
    >
      <DomHolder>
        {active && (
          <div className="activation-conditions-modal">
            <h1 className="modal-title">Условия активации</h1>

            <div className="activation-conditions-list">
              {activationConditionList?.map((list) => (
                list.conditionList.filter((item) => item.conditionIconName && item.conditionText)
                  .map((item) => (
                    <ListItem key={item.conditionIconName} {...item} />
                  ))
              ))}
            </div>

            {activationRestrictionText && (
              <div className="modal-footer">
                <span className="modal-footer-title">Ограничения по активации</span>
                <HtmlText className="modal-footer-description" tag="span">{activationRestrictionText}</HtmlText>
              </div>
            )}
          </div>
        )}
      </DomHolder>
    </BottomSheet>
  );
}

export default ActivationConditionsModal;

import { createAction } from 'typesafe-actions';

export const showNotification = createAction('APP_NOTIFICATION_SHOW')();
export const hideNotification = createAction('APP_NOTIFICATION_HIDE')();
export const setVersion = createAction('APP_SET_VERSION')();
export const toggleConfirmLeaveModal = createAction('APP_TOGGLE_CONFIRM_LEAVE_MODAL')();
export const setCompanyId = createAction('APP_SET_COMPANY_ID')();
export const showFailedToLoadData = createAction('APP_SHOW_FAILED_TO_LOAD_DATA')();
export const setPageTitleProps = createAction('APP_SET_PAGE_TITLE_PROPS')();
export const setRequestChannel = createAction('APP_SET_REQUEST_CHANNEL')();

import moment from 'moment';

export function getLogs(config, isError) {
  const time = moment().utcOffset(180).format('DD.MM.YYYY HH:mm:ss:SS');

  const logs = Object.entries(config)
    .reduce((acc, [key, value], i) => (
      typeof value === 'undefined' ? acc : `${acc}${i ? ', ' : ''}${key}${key ? ':' : ''} ${value}`
    ), '');
  const result = `[${time}+3:00]${isError ? ' ERROR' : ''} - ${logs};`;

  console.log(result);

  return { type: 'sendLogs', payload: { text: result } };
}

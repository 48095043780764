import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { showFailedToLoadData } from '../../actions/app';
import { Portal } from '../../ui-kit';
import { transition } from '../../ui-kit/styles/mixins';
import postToNativeApp from '../../utils/postToNativeApp';
import FailedToLoadData from './FailedToLoadData';
import PageTitle from './PageTitle';
import DomHolder from './utils/DomHolder';

const UiInformer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #FFFFFF;
  outline: 0;
  padding: 0 16px 24px;

  .page-wrapper {
    padding: 21px 0 0;
  }

  &.ui-informer-transition {
    &-enter {
      opacity: 0;
      transform: translateY(-100%);
    }
    &-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: ${transition.normal};
      transition-property: opacity, transform;
    }
  }
`;

const crediiIdFromUrlRegex = /\/credits\/(\d+)(?:\/\w+)?/;

const FailedToLoadDataScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    active, onRetry, title = 'Мои кредиты', asyncStatusReducerName, reducersNS, withPageTitle = true,
  } = useSelector((state) => state.app.failedToLoadDataConfig);
  const { loading, success } = useSelector((state) => state[reducersNS]?.[asyncStatusReducerName]) ?? {};
  const creditId = location.pathname.match(crediiIdFromUrlRegex)?.[1];

  const transitionProps = {
    classNames: 'ui-informer-transition',
    timeout: 300,
    in: active,
    mountOnEnter: true,
    unmountOnExit: true,
  };

  const onClose = useCallback(() => {
    postToNativeApp({ type: 'close' });
    history.push(`/credits${title && creditId ? `/${creditId}` : ''}`);
    dispatch(showFailedToLoadData({}));
  }, [title, creditId]);

  useEffect(() => {
    if (success) {
      dispatch(showFailedToLoadData({}));
    }
  }, [success]);

  useEffect(() => {
    if (active) {
      postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    }
  }, [active]);

  useEffect(() => () => dispatch(showFailedToLoadData({})), []);

  return (
    <Portal id="__informer_container">
      <CSSTransition {...transitionProps}>
        <DomHolder>
          {active && (
            <UiInformer className={classnames('ui-informer')}>
              {withPageTitle && <PageTitle title={title} onBackClick={onClose} />}

              <FailedToLoadData loading={loading} onRetry={onRetry} fullHeight={!withPageTitle} />
            </UiInformer>
          )}
        </DomHolder>
      </CSSTransition>
    </Portal>
  );
};

export default memo(FailedToLoadDataScreen);

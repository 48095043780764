import React from 'react';
import { formatDate, moneyFormatWithTrailingZeroStripIfInteger, percentFormat } from '../../../../utils/format';
import ChevronIcon from '../../../../assets/svg/chevron-right.svg';
import HtmlText from '../../../common/utils/HtmlText';

function RateHistoryItem({ onCLick, item }) {
  const { ratePrevValue, rateValue, rateSetDate, reasonName, installmentAmount, reasonDescription } = item ?? {};
  const installmentAmountNumber = +(installmentAmount?.replace(',', '.'));

  return (
    <button className="rate-history-item-button" onClick={() => onCLick(item)} disabled={!reasonName && !reasonDescription}>
      <div className="item-row _top">
        <div className="percentage-change">
          {ratePrevValue && (
            <>
              <span className="prev-percent">{percentFormat(ratePrevValue)}</span>
              <span className="arrow"> → </span>
            </>
          )}
          {percentFormat(rateValue)}
        </div>
        <span className="rate-date">{formatDate(rateSetDate)}</span>
      </div>

      {!!reasonName && (
        <div className="item-row _middle">
          <HtmlText tag="span" className="reason-name">{reasonName ?? ''}</HtmlText>
          <ChevronIcon className="icon" />
        </div>
      )}

      <div className="item-row _bottom">
        <span className="installment-amount">
          Ежемесячный платёж — {moneyFormatWithTrailingZeroStripIfInteger(installmentAmountNumber)}
        </span>
      </div>
    </button>
  );
}

export default RateHistoryItem;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, resetButton, transition } from '../styles/mixins';

const UiPage = styled.button`
  ${resetButton};
  flex: 0 0 auto;
  display: block;
  width: auto;
  height: 30px;
  padding: 0 5px;
  margin: 0 3px;
  border-radius: 4px;
  font-family: inherit;
  font-size: 16px;
  text-align: center;
  color: ${color.black.rgba(0.5)};
  transition: ${transition.fast};
  transition-property: background-color, color;

  &:hover {
    color: ${color.primary};
  }
  
  &:active {
    color: ${color.primary.darken(0.1)};
  }

  &.active {
    background-color: ${color.primary};
    color: #fff;
  }
  
  > .ui-page-inner {
    display: inline-block;
    min-width: 20px;
    color: inherit;
  }
`;

const Page = memo(({ page, active, onClick }) => (
  <UiPage className={active ? 'active' : ''} onClick={() => onClick(page)}>
    <span className="ui-page-inner">{page + 1}</span>
  </UiPage>
));

Page.propTypes = {
  page: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Page;

import React from 'react';
import { IMaskMixin } from 'react-imask';
import { Input } from '../../../ui-kit';

const MaskInputOld = IMaskMixin(({ inputRef, ...props }) => (
  <Input
    ref={inputRef}
    {...props}
  />
));

export const PhoneInput = (props) => (
  <MaskInputOld
    mask="+{7} (000) 000-00-00"
    placeholder="+7 (000) 000-00-00"
    {...props}
  />
);

export default MaskInputOld;

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import usePrevious from '../../../common/hooks/usePrevious';
import { getStatusIconComponent } from './utils/getStatusIconComponent';
import { useStatus } from './hooks/useStatus';
import ChevronIcon from '../../../../assets/svg/chevron-right.svg';
import DeleteIcon from '../../../../assets/svg/swipe-delete.svg';

const handleImgError = ({ currentTarget }) => {
  currentTarget.onerror = null;
  currentTarget.src = '/services/default.png 1x, /services/default@2x.png 2x';
  currentTarget.srcSet = '/services/default.png';
};

function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

function ServiceCard({ service, design, onClick, canBeDeleted, onRefuseClick, isRefuseModalActive }) {
  const { id, name, description } = service;
  const status = useStatus(service);
  const StatusIcon = getStatusIconComponent(status?.type);

  const [shift, setShift] = useState(0);
  const wrapperElRef = useRef();
  const initialShift = useRef(0);
  const initialDirRef = useRef();

  const swipeableOptions = useMemo(() => ({
    trackTouch: isTouchDevice() ? canBeDeleted : false,
    trackMouse: false, //isTouchDevice() ? false : canBeDeleted,
    onSwiping: (eventData) => {
      if (!initialDirRef.current) {
        initialDirRef.current = eventData.dir;
      }

      if (initialDirRef.current === 'Up' || initialDirRef.current === 'Down') return;
      if (eventData.deltaX < 0) {
        setShift(Math.max(initialShift.current + eventData.deltaX, -56));
      } else {
        setShift(initialShift.current + eventData.deltaX < 0 ? initialShift.current + eventData.deltaX : 0);
      }
    },
    onTap: () => {
      setShift(0);
      initialShift.current = shift;
    },
    onSwiped: (eventData) => {
      if (initialDirRef.current === 'Up' || initialDirRef.current === 'Down') {
        initialDirRef.current = null;
        return;
      }

      const shift = eventData.deltaX <= -56 ? -56 : 0;
      setShift(shift);
      initialDirRef.current = null;
      initialShift.current = shift;
    },
  }), [service, canBeDeleted]);

  const swipeHandlers = useSwipeable(swipeableOptions);

  const refPassthrough = useCallback((el) => {
    if (swipeHandlers) {
      swipeHandlers.ref(el);
      wrapperElRef.current = el;
    }
  }, [swipeHandlers]);

  const isRefuseModalActivePrev = usePrevious(isRefuseModalActive);
  useEffect(() => {
    if (!isRefuseModalActive && isRefuseModalActivePrev) {
      setShift(0);
      initialShift.current = 0;
    }
  }, [isRefuseModalActive]);

  return (
    <div className="service-card-wrapper">
      <button
        className={classnames('service-card', `design-${design}`, { 'with-status': !!status.type, 'swipe-started': shift < -4 })}
        onClick={onClick}
        {...swipeHandlers}
        style={{ transform: `translateX(${shift}px)` }}
        ref={refPassthrough}
      >
        <div className="service-card-icon-wrap">
          <img
            srcSet={`/services/${id}.png 1x, /services/${id}@2x.png 2x`}
            src={`/services/${id}.png`}
            alt={name}
            className="service-card-icon"
            onError={handleImgError}
          />
        </div>
        <div className="service-card-right-content">
          <div className="left-content-wrapper">
            <span className="service-card-description">{description}</span>
            <span className="service-card-name">{name}</span>
            {!!status.type && (
              <div className={classnames('service-card-status', `_${status.type}`)}>
                <StatusIcon className="service-card-status-icon" />
                <span className="service-card-status-label">{status.label}</span>
              </div>
            )}
          </div>

          {design === 'transparent' && <ChevronIcon className="chevron-icon" />}
        </div>
      </button>

      {canBeDeleted && (
        <button className="delete-background-button" onClick={onRefuseClick}>
          <DeleteIcon className="delete-icon" />
        </button>
      )}
    </div>
  );
}

ServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
  design: PropTypes.oneOf(['grey', 'transparent']),
  onClick: PropTypes.func,
  canBeDeleted: PropTypes.bool,
};

ServiceCard.defaultProps = {
  design: 'grey',
};

export default ServiceCard;

import { useEffect } from 'react';
import postToNativeApp from '../../../utils/postToNativeApp';
import { sendLogs } from '../../../utils/sendLogs';

export default function useOpenNewPage(backActionChanged = 'goBack') {
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
    postToNativeApp({ type: 'backActionChanged', payload: { action: backActionChanged } });
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
  }, []);
}

import { getLogs } from './getLogs';
import postToNativeApp from './postToNativeApp';

export function sendLogs(configs, isError) {
  const configsArray = Array.isArray(configs) ? configs : [configs];

  configsArray.forEach((config) => {
    postToNativeApp(getLogs(config, isError));
  });
}

import store from '../store';

const compareVersions = (currVer, needVer) => {
  const currParts = currVer.split('.');
  const needParts = needVer.split('.');

  for (let i = 0; i < needParts.length; i++) {
    const needPart = +needParts[i];
    const currPart = +currParts[i];

    if (currPart > needPart) return true;
    if (currPart < needPart) return false;
  }
  return true;
};

export const isCurrentVersionNewerThan = (version) => compareVersions(store.getState().app.version, version);

import { createReducer, createAction, getType } from 'typesafe-actions';

export function asyncReducerFactory(asyncAction) {
  return createReducer({ initial: true, loading: false, error: null, state: 'initial', success: false }, {
    [getType(asyncAction.request)]: (state) => ({
      initial: state.initial,
      loading: true,
      success: false,
      error: null,
      state: 'loading',
    }),

    [getType(asyncAction.success)]: () => ({
      initial: false,
      loading: false,
      success: true,
      error: null,
      state: 'loaded',
    }),

    [getType(asyncAction.failure)]: (_, action) => ({
      initial: false,
      loading: false,
      success: false,
      error: action.payload,
      state: 'error',
    }),
  });
}

export function tableReducerFactory(tableAction) {
  return createReducer({ ids: [], allIds: [], start: 0, end: 0, hasMore: true, first: true, last: true }, {
    [getType(tableAction.add)]: (state, action) => {
      const { payload, meta } = action;
      const { ids, hasMore } = payload;
      const allIds = meta.append ? [...state.allIds, ...ids] : ids;
      const start = meta.append ? state.allIds.length : 0;
      const end = allIds.length;
      const first = start === 0;

      return { ids, allIds, start, end, hasMore, first, last: true };
    },

    [getType(tableAction.prev)]: (state, action) => {
      if (state.start === 0) return state;

      const start = Math.max(0, state.start - action.payload); // action.payload === limit
      const end = start + action.payload;
      const ids = state.allIds.slice(start, end);
      const first = start === 0;
      const last = end === state.allIds.length;

      return { ...state, ids, start, end, first, last };
    },

    [getType(tableAction.next)]: (state, action) => {
      if (state.end === state.allIds.length) return state;

      const start = state.start + action.payload; // action.payload === limit
      const end = Math.min(start + action.payload, state.allIds.length);
      const ids = state.allIds.slice(start, end);
      const first = start === 0;
      const last = end === state.allIds.length;

      return { ...state, ids, start, end, first, last };
    },

    [getType(tableAction.first)]: (state, action) => {
      if (state.start === 0) return state;

      const start = 0;
      const end = Math.min(start + action.payload, state.allIds.length);
      const ids = state.allIds.slice(start, end);
      const first = start === 0;
      const last = end === state.allIds.length;

      return { ...state, ids, start, end, first, last };
    },
  });
}

export function setDataById(state, id, key, data) {
  return {
    ...state,
    [id]: {
      ...state[id],
      [key]: data,
      [`${key}Loaded`]: true,
    },
  };
}

export function createTableAction(addType, prevType, nextType, firstType) {
  return {
    add: createAction(addType)(),
    prev: createAction(prevType)(),
    next: createAction(nextType)(),
    first: createAction(firstType)(),
  };
}

import classnames from 'classnames';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getChangeRateHistoryListAsync } from '../../../../actions/rate-history';
import DocumentIcon from '../../../../assets/svg/document-icon.svg';
import ChevronIcon from '../../../../assets/svg/chevron-right.svg';
import Tab from '../../../../ui-kit/tabs/Tab';
import Tabs from '../../../../ui-kit/tabs/Tabs';
import { percentFormat } from '../../../../utils/format';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import FailedToLoadData from '../../../common/FailedToLoadData';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import RateHistoryItem from './RateHistoryItem';

const sortByDate = (a, b) => {
  const aDate = moment(a.rateSetDate, 'DD.MM.YYYY');
  const bDate = moment(b.rateSetDate, 'DD.MM.YYYY');

  if (aDate.isAfter(bDate)) {
    return -1;
  }
  if (aDate.isBefore(bDate)) {
    return 1;
  }
  return 0;
};

function RateHistoryMainPage({ match, fetchStatus, onOpenModal }) {
  const dispatch = useDispatch();
  const { creditId } = match.params;
  const creditsById = useSelector((state) => state.credits.byId);
  const credit = creditsById[creditId];
  const { percent_rate: percentRate, initial_interest_rate: initialInterestRate } = credit?.additionalInfo ?? {};
  const {
    loading: rateHistoryLoading, error: rateHistoryError, initial: rateHistoryInitial, success: rateHistorySuccess,
  } = fetchStatus;
  const { rateChangesNext = [], rateChangesPrev = [] } = useSelector((state) => state.rateHistory.rateHistoryList);
  const sortedRateChangesNext = useMemo(() => [...rateChangesNext].sort(sortByDate), [rateChangesNext]);
  const sortedRateChangesPrev = useMemo(() => [...rateChangesPrev].sort(sortByDate), [rateChangesPrev]);

  const [tab, setTab] = useState(rateChangesNext.length ? 'next' : 'prev');

  const getRateHistoryList = useCallback(() => {
    dispatch(getChangeRateHistoryListAsync({ contractNumber: creditId }));
  }, [creditId]);

  useEffect(() => {
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
  }, []);

  useEffect(() => {
    if (rateHistorySuccess && rateChangesNext.length && tab === 'prev') {
      setTab('next');
    }
  }, [rateHistorySuccess]);

  const tabHeader = (
    <div className="tab-header">
      <span className="heading">Изменение, причина</span>
      <span className="heading">Дата изменения</span>
    </div>
  );

  return (
    <LoaderOrChildren loading={rateHistoryInitial}>
      <div className="page-wrapper">
        {(!!percentRate || percentRate === 0 || !!initialInterestRate) && (
          <div className="percent-info-wrap">
            {(!!percentRate || percentRate === 0) && (
              <p className={classnames('current-percent-rate', { 'margin-bottom': !!initialInterestRate })}>
                <span className="percent">{percentFormat(percentRate)}</span>
                Текущая процентная ставка
              </p>
            )}

            {!!initialInterestRate && (
              <p className="initial-percent-rate">
                Процентная ставка по договору<br />
                при выдаче кредита — {percentFormat(initialInterestRate)}
              </p>
            )}
          </div>
        )}

        <Link to={`${match.url}/rules-changing-rates`} className="rules-link">
          <div className="left-content">
            <div className="icon-wrap"><DocumentIcon className="icon" /></div>
            <span className="link-title">Правила изменения ставки</span>
          </div>

          <ChevronIcon />
        </Link>

        <h2 className="title">История изменений</h2>
        {rateHistoryError || rateHistoryLoading ? (
          <FailedToLoadData loading={rateHistoryLoading} onRetry={getRateHistoryList} design="small" />
        ) : (
          <>
            {sortedRateChangesNext.length || sortedRateChangesPrev.length ? (
              <Tabs tab={tab} onChange={setTab} className="rate-history-tab-switch">
                <Tab
                  id="next"
                  title="Предстоящие"
                  className={classnames('rate-history-tab', '_next')}
                  tabBtnClassName={sortedRateChangesNext.length ? 'has-next' : ''}
                >
                  {sortedRateChangesNext.length ? (
                    <>
                      {tabHeader}
                      {sortedRateChangesNext.map((item, i) => (
                        <RateHistoryItem
                          key={`_next-${item.reasonName + item.rateSetDate + i}`}
                          item={item}
                          onCLick={onOpenModal}
                        />
                      ))}
                    </>
                  ) : (
                    <p className="empty-changes">
                      Предстоящих изменений<br />процентной ставки нет
                    </p>
                  )}
                </Tab>
                <Tab id="prev" title="Прошедшие" className={classnames('rate-history-tab', '_prev')}>
                  {sortedRateChangesPrev.length ? (
                    <>
                      {tabHeader}
                      {sortedRateChangesPrev.map((item, i) => (
                        <RateHistoryItem
                          key={`_prev-${i + item.reasonName}${item.rateSetDate}`}
                          item={item}
                          onCLick={onOpenModal}
                        />
                      ))}
                    </>
                  ) : (
                    <p className="empty-changes">
                      Изменений процентной<br />ставки не было
                    </p>
                  )}
                </Tab>
              </Tabs>
            ) : (
              <p className="empty-changes">
                Изменений процентной<br />ставки не было
              </p>
            )}
          </>
        )}
      </div>
    </LoaderOrChildren>
  );
}

export default RateHistoryMainPage;

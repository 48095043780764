import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Button } from '../../ui-kit';

const FailedToLoadData = ({ loading, onRetry, design, className, fullHeight }) => (
  <div className={classnames('failed-to-load-data', `design-${design}`, className, { 'full-height': fullHeight })}>
    <div className="informer-title">Не удалось загрузить</div>
    <div className="informer-description">
      Попробуйте обновить<br />или&nbsp;зайти позже
    </div>
    <Button
      className="reload-button"
      design="grey"
      size={design === 'small' ? 'sm' : 'm'}
      loading={loading}
      onClick={onRetry}
    >
      Обновить
    </Button>
  </div>
);

FailedToLoadData.propTypes = {
  design: PropTypes.oneOf(['standard', 'small']),
  loading: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
};

FailedToLoadData.defaultProps = {
  design: 'standard',
};

export default memo(FailedToLoadData);

import SuccessIcon from '../../../../../assets/svg/service-statuses/success.svg';
import CanceledIcon from '../../../../../assets/svg/service-statuses/canceled.svg';
import PendingIcon from '../../../../../assets/svg/service-statuses/pending.svg';
import BlockedIcon from '../../../../../assets/svg/service-statuses/blocked.svg';
import WarningIcon from '../../../../../assets/svg/service-statuses/warning.svg';
import InfoIcon from '../../../../../assets/svg/service-statuses/info.svg';

export const getStatusIconComponent = (type) => {
  switch (type) {
    case 'success':
      return SuccessIcon;
    case 'canceled':
      return CanceledIcon;
    case 'pending':
      return PendingIcon;
    case 'blocked':
      return BlockedIcon;
    case 'warning':
      return WarningIcon;
    case 'info':
      return InfoIcon;
    default:
      return null;
  }
};

import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import MoneyInput from '../MoneyInput';

function MoneyInputField({ hasError, onBlur, ...props }) {
  const [field, meta] = useField(props.name);

  const handleBlur = useCallback((e) => {
    field.onBlur(e);

    if (onBlur) {
      onBlur(e);
    }
  }, []);

  return (
    <MoneyInput
      {...field}
      {...props}
      onBlur={handleBlur}
      state={(meta.error && meta.touched) || hasError ? 'invalid' : undefined}
    />
  );
}

MoneyInputField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(MoneyInputField);

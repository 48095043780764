import React, { memo, useEffect, useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { Input } from '../../../ui-kit';

const MoneyInput = ({ value, onChange, hasError, ...props }) => {
  const inputRef = useRef();

  useEffect(() => {
    if (value.slice(-3, -2) === ',') {
      const caret = value.length - 2;
      setTimeout(() => {
        inputRef.current.setSelectionRange(caret, caret);
      }, 10);
    }
  }, [inputRef, value]);

  return (
    <NumericFormat
      decimalSeparator=","
      decimalScale={2}
      state={hasError ? 'invalid' : undefined}
      {...props}
      value={value}
      onChange={onChange}
      customInput={Input}
      thousandSeparator=" "
      suffix=" ₽"
      isMoneyInput
      inputMode="decimal"
      getInputRef={inputRef}
      allowNegative={false}
    />
  );
};
export default memo(MoneyInput);

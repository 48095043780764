import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { color, resetButton } from '../styles/mixins';
import ErrorIcon from './assets/alert-error.svg';
import InfoIcon from './assets/alert-info.svg';
import SuccessIcon from './assets/alert-success.svg';
import CloseIcon from './assets/close.svg';

const UiAlert = styled.div`
  position: relative;
  padding: 15px 60px 15px 20px;
  border-radius: 4px;
  
  ${(p) => p.simple && css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    border-radius: 0;

    .ui-alert-title,
    .ui-alert-close {
      display: none;
    }

    .ui-alert-message {
      font-family: inherit;
      font-size: 14px;
      line-height: 1.43;
      color: #fff;
    }
  `}

  &.type-success {
    background-color: ${color.success};
  }

  &.type-error {
    background-color: ${color.error};
  }

  &.type-warning {
    background-color: ${color.warning};
  }

  &.type-info {
    background-color: ${color.info};
  }
  
  .icon {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .ui-alert-title {
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    color: #fff;
    margin-bottom: 7px;
  }

  .ui-alert-message {
    font-family: inherit;
    font-size: 16px;
    line-height: 1.25;
    color: #fff;
  }

  .ui-alert-close {
    ${resetButton};
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 15px;
    right: 20px;
    
    .close-icon {
      display: block;
    }
  }
`;

UiAlert.defaultProps = {
  simple: false,
};

const icons = {
  error: <ErrorIcon className="icon" />,
  success: <SuccessIcon className="icon" />,
  warning: <InfoIcon className="icon" />,
  info: <InfoIcon className="icon" />,
};

function Alert(props) {
  const { type, className, title, message, simple, onClose } = props;

  return (
    <UiAlert simple={simple} className={classnames(className, `type-${type}`)}>
      {simple && icons[type]}
      {title && (
        <div className="ui-alert-title">
          {title}
        </div>
      )}
      <div className="ui-alert-message">
        {message}
      </div>
      {onClose && (
        <button className="ui-alert-close" type="button" onClick={onClose}>
          <CloseIcon className="close-icon" />
        </button>
      )}
    </UiAlert>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'warning', 'info']).isRequired,
  message: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  simple: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Alert;

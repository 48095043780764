import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/services';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const servicesByKeyReducer = createReducer({})
  .handleAction(actions.getServiceFinProtect.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload, 'key'), // key === `${service.id}_${shortType}_${service.statusCode?.toLowerCase()}`
  }))
  .handleAction(actions.getServiceFinProtect.failure, (state, { payload }) => ({
    ...state,
    ...normalize(payload.data ?? [], 'key'),
  }))
  .handleAction(actions.resetServices, () => ({}));

const notSignedServicesReducer = createReducer([])
  .handleAction(actions.getNotSignedSupAgreement.success, (state, { payload }) => (
    payload.notSignedSupAgrList.filter((service) => service.id && service.name && service.requestId).sort((a, b) => a.id - b.id)))
  .handleAction(actions.setNotSignedSupAgreement, (state, { payload }) => ([{ ...payload }]));

const servicesInformerConfigReducer = createReducer({})
  .handleAction(actions.showServicesInformer, (state, { payload }) => payload);

const getSupAgreementReducer = createReducer({})
  .handleAction(actions.getSupAgreement.request, () => ({}))
  .handleAction(actions.getSupAgreement.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

const finProtectRegistrationReducer = createReducer({})
  .handleAction(actions.finProtectRegistration.request, () => ({}))
  .handleAction(actions.finProtectRegistration.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }));
const calculatedNextPaymentDateReducer = createReducer('')
  .handleAction(actions.calcNextPaymentDate.success, (state, { payload }) => payload.nextPaymentDate);

const prePaymentScheduleReducer = createReducer({})
  .handleAction(actions.sendPrePaymentSchedule.success, (state, { payload, meta }) => ({
    ...payload, sendingAmount: meta.newInstallmentAmount,
  }));

const finProtectActivationReducer = createReducer({})
  .handleAction(actions.finProtectActivation.success, (state, { payload }) => payload);

const finProtectRefuseReducer = createReducer({})
  .handleAction(actions.finProtectRefuse.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

export default combineReducers({
  byKey: servicesByKeyReducer,
  notSignedServices: notSignedServicesReducer,
  servicesInformerConfig: servicesInformerConfigReducer,
  supAgreementResult: getSupAgreementReducer,
  finProtectRegResult: finProtectRegistrationReducer,
  calculatedNextPaymentDate: calculatedNextPaymentDateReducer,
  prePaymentScheduleResult: prePaymentScheduleReducer,
  finProtectActivationData: finProtectActivationReducer,
  finProtectRefuseData: finProtectRefuseReducer,
  fetchStatus: asyncReducerFactory(actions.getServiceFinProtect),
  getNotSignedSupAgreementStatus: asyncReducerFactory(actions.getNotSignedSupAgreement),
  getSupAgreementStatus: asyncReducerFactory(actions.getSupAgreement),
  sendSupAgreementStatus: asyncReducerFactory(actions.sendSupAgreement),
  getCheckFactorStatus: asyncReducerFactory(actions.getCheckFactor),
  sendCheckFactorStatus: asyncReducerFactory(actions.sendCheckFactor),
  finProtectRegistrationStatus: asyncReducerFactory(actions.finProtectRegistration),
  calcNextPaymentDateStatus: asyncReducerFactory(actions.calcNextPaymentDate),
  sendPrePaymentScheduleStatus: asyncReducerFactory(actions.sendPrePaymentSchedule),
  finProtectActivationStatus: asyncReducerFactory(actions.finProtectActivation),
  finProtectRefuseStatus: asyncReducerFactory(actions.finProtectRefuse),
});

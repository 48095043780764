import React, { memo, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router';
import { Button, Portal } from '../../../../ui-kit';
import { transition } from '../../../../ui-kit/styles/mixins';
import postToNativeApp from '../../../../utils/postToNativeApp';
import DomHolder from '../../../common/utils/DomHolder';
import PageTitle from '../../../common/PageTitle';
import AlertIcon from '../../../../assets/svg/status/status-error-v2.svg';
import SuccessIcon from '../../../../assets/svg/status/status-success-v2.svg';
import DownloadIcon from '../../../../assets/svg/download-with-bg.svg';
import { downloadURI } from '../../../../utils/downloadURI';

const UiInformer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background-color: #FFFFFF;
  outline: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 24px;

  &.ui-informer-transition {
    &-enter {
      opacity: 0;
      transform: translateY(-100%);
    }
    &-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: ${transition.normal};
      transition-property: opacity, transform;
    }
  }
`;

const StyledInformerBody = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 40px 0;

  &.with-download {
    justify-content: space-evenly;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;
    text-align: center;
  }

  .description {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #8B95A4;
    margin: 8px 0 24px;
  }

  .document-request-wrapper,
  .download-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .document-download-wrapper {
    width: 80px;

    .text {
      margin-top: 8px;
      color: #000C1A;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;

const StyledTitle = styled.h1`
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 8px;
  text-align: center;
  line-height: 24px;
  color: #000C1A;
`;

const StyledParagraph = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  color: #647081;

  span {
    display: block;
  }
`;

const StyledButtonGroup = styled.div`
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 16px 0;
`;

const StyledButton = styled(Button)`
  width: 100%;
  text-transform: unset;
  margin-bottom: 12px;
  line-height: 20px !important;
  font-weight: 500 !important;

  &.ui-button {
    height: auto;
    min-height: 48px;
    padding: 14px 24px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &.design-secondary {
    color: #1E2025;
    background-color: #EDEFF1 !important;
    border: none !important;
  }
`;
const OrderDocsInformer = ({ creditId, active, docMethod, sendDocRequestFetchStatus }) => {
  const history = useHistory();
  const transitionProps = {
    classNames: 'ui-informer-transition',
    timeout: 300,
    in: active,
    mountOnEnter: true,
    unmountOnExit: true,
  };

  const docUrls = useSelector((state) => state.orderDocs.docUrls.data);
  const onDownload = useCallback(() => {
    downloadURI(docUrls.request_document_mobile_url, 'doc_order.pdf');
  }, [docUrls]);

  const isError = !!sendDocRequestFetchStatus.error;

  const informerTitle = useMemo(() => {
    if (isError) {
      return 'Запрос отклонён';
    }
    if (docMethod === 'INFOBANK' && !isError) {
      return 'Документ готов';
    }
    return 'Заявка принята';
  }, [isError, docMethod]);

  const informerText = useMemo(() => {
    if (sendDocRequestFetchStatus.error?.error_description) {
      return sendDocRequestFetchStatus.error.error_description;
    }
    if (docMethod === 'INFOBANK') {
      return 'Скачайте и&nbsp;сохраните документ на&nbsp;телефон';
    }
    return 'Отследить статус заявки вы&nbsp;можете в&nbsp;журнале обращений';
  }, [isError, docMethod, sendDocRequestFetchStatus.error]);

  const onGoToDocs = useCallback(() => {
    postToNativeApp({ type: 'titleChanged', payload: { chapter: 'REQUEST_DOCUMENTS' } });
    history.push(`/credits/${creditId}/order-docs`);
  }, [creditId]);

  const onGoToOperationLog = useCallback(() => {
    postToNativeApp({ type: 'titleChanged', payload: { chapter: 'INQUIRIES' } });
    history.push(`/credits/${creditId}/operation-log`);
  }, [creditId]);

  const onClose = useCallback(() => {
    postToNativeApp({ type: 'close' });
    history.push(`/credits/${creditId}`);
  }, [creditId]);

  useEffect(() => {
    if (active) {
      postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    }
  }, [active]);

  return (
    <Portal id="__informer_container">
      <CSSTransition {...transitionProps}>
        <DomHolder>
          {active && (
            <UiInformer>
              <PageTitle title="Заказать документы" version="2" />
              <StyledInformerBody className={classnames({ 'with-download': docMethod === 'INFOBANK' })}>
                <div className="document-request-wrapper">
                  {isError ? (<AlertIcon />) : (<SuccessIcon />) }
                  <StyledTitle>{informerTitle}</StyledTitle>
                  <StyledParagraph>
                    <span dangerouslySetInnerHTML={{ __html: informerText }} />
                    {docMethod === 'CHAT' && !isError && <span><br />Вам придёт уведомление о&nbsp;возможности скачать документ в&nbsp;чате</span>}
                  </StyledParagraph>
                </div>

                {docMethod === 'INFOBANK' && !isError && (
                  <div className="document-download-wrapper">
                    <button className="download-button" onClick={onDownload}>
                      <DownloadIcon className="icon" />
                      <StyledParagraph className="text">Скачать документ</StyledParagraph>
                    </button>
                  </div>
                )}
              </StyledInformerBody>

              <StyledButtonGroup>
                <StyledButton
                  design="red"
                  size="m"
                  onClick={isError || docMethod === 'INFOBANK' ? onClose : onGoToOperationLog}
                >
                  {isError || docMethod === 'INFOBANK' ? 'Закрыть' : 'Перейти в журнал обращений'}
                </StyledButton>
                <StyledButton
                  design="secondary"
                  size="m"
                  type="button"
                  onClick={isError || docMethod === 'INFOBANK' ? onGoToDocs : onClose}
                >
                  {isError || docMethod === 'INFOBANK' ? 'Заказать другой документ' : 'Закрыть'}
                </StyledButton>
              </StyledButtonGroup>
            </UiInformer>
          )}
        </DomHolder>
      </CSSTransition>
    </Portal>
  );
};

export default memo(OrderDocsInformer);

/* eslint-disable linebreak-style */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Manager, Popper, Reference } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import styled, { createGlobalStyle, css } from 'styled-components';
import Portal from '../portal/Portal';
import OnClickOutside from '../click-outside/OnClickOutside';
import { color, transition } from '../styles/mixins';
import TailSIcon from './assets/tail-s.svg';
import TailLIcon from './assets/tail-l.svg';

const $sizes = {
  s: '8px',
  l: '16px',
};

const $placements = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
};

const GlobalContainerStyle = createGlobalStyle`
  #__tooltips-container {
    position: absolute;
    top: 0;
    left: 11px;
    right: 21px;
    z-index: 10;
    //width: calc(100vw - 32px);
    //// width: 100%;
    //margin: 0 16px;
  }
`;

const UiTooltip = styled.div`
  position: relative;

  ${Object.entries($placements).map(([$placement, $side]) => css`
    &.placement-${$placement} {
      ${Object.entries($sizes).map(([$size, $offset]) => css`
        &.size-${$size} {
          padding-${$side}: ${$offset};
        }
      `)}
    }
  `)}

  &.placement-top {
    transform-origin: center bottom;
  }
  &.placement-bottom {
    transform-origin: center top;
  }
  &.placement-left {
    transform-origin: right center;
  }
  &.placement-right {
    transform-origin: left center;
  }

  .ui-tooltip-content {
    font-family: inherit;
    font-size: 13px;
    line-height: 1.6;
    color: #585858;

    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    // box-shadow: 0 8px 34px 0 ${color.black.rgba(0.05)};
    // background-color: ${color.black};


    .size-s& {
      padding: 12px 16px;
    }

    .size-l& {
      padding: 25px 30px;
    }
  }

  .ui-tooltip-tail {
    position: absolute;
    // display: block;
    display: none;

    .size-s& {
      width: 26px;
      height: 6px;
    }

    .size-l& {
      width: 66px;
      height: 14px;
    }

    .placement-top& {
      bottom: 2px;
    }
    .placement-bottom& {
      top: 2px;
      transform: rotate(180deg);
    }
    .placement-left.size-s& {
      right: 2px;
      transform: matrix(0.00, 1.00, 1.00, 0.00, 10, 0);
    }
    .placement-left.size-l& {
      right: 2px;
      transform: matrix(0.00, 1.00, 1.00, 0.00, 26, 0);
    }
    .placement-right.size-s& {
      left: 2px;
      transform: matrix(0.00, 1.00, -1.00, 0.00, -10, 0);
    }
    .placement-right.size-l& {
      left: 2px;
      transform: matrix(0.00, 1.00, -1.00, 0.00, -26, 0);
    }

    > .tail-icon {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .ui-tooltip-title {
    font-family: inherit;
    font-size: 16px;
    line-height: 1.25;
    color: #fff;
    margin-bottom: 10px;
  }
`;

const UiTooltipWrap = styled.div`
  will-change: transform, opacity;

  &.ui-tooltip-wrap {
    &-enter .ui-tooltip {
      opacity: 0;
      transform: scale(0.75);
    }
    &-enter-active .ui-tooltip {
      opacity: 1;
      transform: scale(1);
      transition: ${transition.fast};
      transition-property: transform, opacity;
    }
    &-exit .ui-tooltip {
      opacity: 1;
      transform: scale(1);
    }
    &-exit-active .ui-tooltip {
      opacity: 0;
      transform: scale(0.75);
      transition: ${transition.fast};
      transition-property: transform, opacity;
    }
  }
`;

class Tooltip extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.func.isRequired,
      PropTypes.object.isRequired,
    ]).isRequired,
    opened: PropTypes.bool.isRequired,
    content: PropTypes.node.isRequired,
    title: PropTypes.node,
    size: PropTypes.oneOf(['s', 'l']),
    placement: PropTypes.string,
    className: PropTypes.string,
    popperModifiers: PropTypes.object,
    onClickOutside: PropTypes.func,
    ignoreClass: PropTypes.string,
  };

  static defaultProps = {
    placement: 'top',
    size: 's',
    onClickOutside: () => {},
    popperModifiers: { preventOverflow: { priority: ['left', 'right'] }, hide: { enabled: false }, flip: { enabled: false } },
  };

  renderRef = ({ ref }) => {
    const { children } = this.props;

    if (typeof children === 'function') {
      return children(ref);
    }

    if (React.isValidElement(children)) {
      return React.cloneElement(children, { ref });
    }

    throw new Error('[Tooltip] Unsupported children type');
  };

  renderPopper = ({ ref, style, placement, arrowProps }) => {
    const { opened, content, title, size, className } = this.props;

    return (
      <CSSTransition
        classNames="ui-tooltip-wrap"
        timeout={150}
        in={opened}
        mountOnEnter
        unmountOnExit
      >
        <UiTooltipWrap ref={ref} style={style} className={className}>
          <UiTooltip className={`ui-tooltip size-${size} placement-${placement}`}>
            <div className="ui-tooltip-content">
              {title && size === 'l' && <div className="ui-tooltip-title">{title}</div>}
              {content}
            </div>
            <div className="ui-tooltip-tail" ref={arrowProps.ref} style={arrowProps.style}>
              {size === 's' ? <TailSIcon className="tail-icon" /> : <TailLIcon className="tail-icon" />}
            </div>
          </UiTooltip>
        </UiTooltipWrap>
      </CSSTransition>
    );
  };

  render() {
    const { placement, opened, popperModifiers, onClickOutside, ignoreClass } = this.props;

    return (
      <Manager>
        <Reference>
          {this.renderRef}
        </Reference>
        <OnClickOutside onClickOutside={onClickOutside} active={opened} ignoreClass={ignoreClass}>
          {(ref) => (
            <Portal ref={ref} id="__tooltips-container">
              <GlobalContainerStyle />
              <Popper placement={placement} modifiers={popperModifiers}>
                {this.renderPopper}
              </Popper>
            </Portal>
          )}
        </OnClickOutside>
      </Manager>
    );
  }
}

export default Tooltip;

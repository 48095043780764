import React from 'react';
import { useOpenNewPage } from '../../../common/hooks';
import ServiceCard from './ServiceCard';
import useServices from './hooks/useServices';

function ArchivedServicesPage({ history, match }) {
  const { archived } = useServices();
  const isIos = window.sessionStorage.getItem('isIos');
  const { params: { creditId } } = match;

  const goToService = (service) => {
    const { id, restrictLink, key } = service;

    if (id === 1000) {
      const link = document.createElement('a');
      link.href = restrictLink;
      if (!isIos) link.target = '_blank';
      return link.click();
    }

    return history.push(`/credits/${creditId}/services/${key}`);
  };

  useOpenNewPage();

  return (
    <div className="page-wrapper">
      <div className="service-group _archived">
        <span className="list-title">Архив услуг и опций</span>

        <div className="service-group-list">
          {archived.map((service) => <ServiceCard onClick={() => goToService(service)} key={service.key} service={service} />)}
        </div>
      </div>
    </div>
  );
}

export default ArchivedServicesPage;

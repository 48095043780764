import moment from 'moment';

import CalendarIcon from '../assets/svg/routing-logos/calendar.svg';
import EarlyPaymentIcon from '../assets/svg/routing-logos/early-payment.svg';
import HistoryIcon from '../assets/svg/routing-logos/history.svg';
import OrderDocIcon from '../assets/svg/routing-logos/order-doc.svg';
import OperationLogIcon from '../assets/svg/routing-logos/operation-log.svg';
import InsuranceIcon from '../assets/svg/routing-logos/insurance-icon.svg';
import RequisitesIcon from '../assets/svg/routing-logos/requisites.svg';
import OptionsIcon from '../assets/svg/routing-logos/options.svg';
import PercentIcon from '../assets/svg/routing-logos/percent.svg';
// import SubmitRequisitesIcon from '../assets/svg/routing-logos/submit-requisites.svg';

export const navigationItems = [
  {
    to: '/card-operations',
    text: 'Операции по&nbsp;карте',
    icon: HistoryIcon,
    isHidden: ({ contract_type_code: type, is_assignment: isAssignment }) => type !== 'C' || isAssignment,
    getSortIndex: (isClosed, contractTypeCode) => (contractTypeCode === 'C' ? 0 : 10),
  },
  {
    to: '/early-payment',
    text: 'Досрочное погашение',
    icon: EarlyPaymentIcon,
    isHidden: ({
      contract_type_code: type,
      is_closed: closed,
      is_assignment: isAssignment,
    }) => (
      !((!closed && closed !== undefined) || isAssignment) || type === 'C'
    ),
    getSortIndex: (isClosed, contractTypeCode) => (contractTypeCode === 'A' && !isClosed ? 0 : 10),
  },
  {
    to: '/payment-schedule',
    text: 'График платежей',
    icon: CalendarIcon,
    isHidden: ({
      contract_type_code: type,
      is_closed: closed,
      is_assignment: isAssignment,
      additionalInfo: { date_closed: dateClosed },
    }) => (
      type === 'C' || closed || (dateClosed && moment(dateClosed, 'DD.MM.YYYY').isBefore(moment(new Date()).startOf('day'))) || isAssignment
    ),
    getSortIndex: (isClosed, contractTypeCode) => (contractTypeCode === 'A' && !isClosed ? 1 : 10),
  },
  {
    to: '/order-docs',
    text: 'Заказать документы',
    icon: OrderDocIcon,
    isHidden: ({ contract_type_code: type }) => type === 'C', // todo вернуть после согласования релиза
    getSortIndex: (isClosed, contractTypeCode) => {
      if (contractTypeCode === 'A' && !isClosed) {
        return 5;
      }
      if (['A', 'D', 'P'].includes(contractTypeCode) && isClosed) {
        return 1;
      }
      return 10;
    },
  },
  {
    to: '/repayment-history',
    text: 'История погашений',
    icon: HistoryIcon,
    isHidden: ({ contract_type_code: type, is_assignment: isAssignment }) => type === 'C' || isAssignment,
    getSortIndex: (isClosed, contractTypeCode) => {
      if (contractTypeCode === 'A' && !isClosed) {
        return 4;
      }
      if (['A', 'D', 'P'].includes(contractTypeCode) && isClosed) {
        return 0;
      }
      return 10;
    },
  },
  {
    to: '/rate-history',
    text: 'История ставки',
    icon: PercentIcon,
    isHidden: ({ contract_type_code: type, is_closed: closed, additionalInfo: { system_code: systemCode } }) => (
      type !== 'A' || closed || systemCode === 'PF'
    ),
    getSortIndex: (isClosed, contractTypeCode) => { //after repayment-history
      if (contractTypeCode === 'A' && !isClosed) {
        return 4;
      }
      if (['A', 'D', 'P'].includes(contractTypeCode) && isClosed) {
        return 0;
      }
      return 10;
    },
  },
  {
    to: '/operation-log',
    text: 'Журнал обращений',
    icon: OperationLogIcon,
    isHidden: ({ contract_type_code: type }) => type === 'C',
    getSortIndex: (isClosed, contractTypeCode) => {
      if (contractTypeCode === 'A' && !isClosed) {
        return 6;
      }
      if (['A', 'D', 'P'].includes(contractTypeCode) && isClosed) {
        return 4;
      }
      return 10;
    },
  },
  {
    to: '/insurances-products',
    text: 'Страховые продукты',
    icon: InsuranceIcon,
    isHidden: ({ contract_type_code: type, is_closed: closed }) => type !== 'A' || closed,
    getSortIndex: (isClosed, contractTypeCode) => (contractTypeCode === 'A' && !isClosed ? 3 : 10),
  },
  {
    to: '/requisites',
    text: 'Реквизиты',
    icon: RequisitesIcon,
    isHidden: ({ is_closed: closed, is_assignment: isAssignment }) => closed || isAssignment,
    getSortIndex: () => 10,
  },
  {
    to: '/submit-requisites/form',
    text: 'Подать реквизиты',
    icon: RequisitesIcon,
    isHidden: ({
      contract_type_code: type,
      is_closed: closed,
      credit_date: creditDate,
      additionalInfo: { account_number: accountNumber, rest_sum_with_hold: restSumWithHold, system_code: systemCode },
    }) => (
      !closed || restSumWithHold === 0 || systemCode === 'PF' ||
      !((['A', 'P'].includes(type) && `${accountNumber}`.startsWith('42301')) || (['P', 'D'].includes(type) && `${accountNumber}`.startsWith('40817'))) ||
      (new Date(creditDate.split('.').reverse().join('-')) < new Date(2014, 0, 1) && !`${accountNumber}`.startsWith('42301'))
    ),
    getSortIndex: (isClosed, contractTypeCode) => (contractTypeCode === 'A' && isClosed ? 2 : 10),
  },
  {
    to: '/services',
    text: 'Услуги и опции',
    icon: OptionsIcon,
    isHidden: ({ contract_type_code: type }) => type !== 'A',
    getSortIndex: (isClosed, contractTypeCode) => {
      if (contractTypeCode === 'A') {
        if (isClosed) {
          return 3;
        }
        return 2;
      }
      return 10;
    },
  },
];

export const chapterToPathDictionary = {
  CARD_OPERATION_HISTORY: '/card-operations',
  EARLY_REPAYMENT: '/early-payment',
  AMORTIZATION_SCHEDULE: '/payment-schedule',
  REQUEST_DOCUMENTS: '/order-docs',
  REPAYMENT_HISTORY: '/repayment-history',
  INQUIRIES: '/operation-log',
  KASKO_PROLONGATION: '/insurances-products',
  REQUEST_REQUISITES: '/requisites',
  SUBMIT_REFUND: '/submit-requisites/form',
  FIN_PROTECTION: '/services',
  RATE_CHANGE: '/rate-history',
};

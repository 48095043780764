import { createAsyncAction, createAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getServiceFinProtect = createAsyncAction(
  'GET_SERVICE_FIN_PROTECT_REQUEST',
  'GET_SERVICE_FIN_PROTECT_SUCCESS',
  'GET_SERVICE_FIN_PROTECT_FAIL',
)();

export const getServiceFinProtectActAvail = createAsyncAction(
  'GET_SERVICE_FIN_PROTECT_ACT_AVAIL_REQUEST',
  'GET_SERVICE_FIN_PROTECT_ACT_AVAIL_SUCCESS',
  'GET_SERVICE_FIN_PROTECT_ACT_AVAIL_FAIL',
)();

export const finProtectRefuse = createAsyncAction(
  'FIN_PROTECT_REFUSE_REQUEST',
  'FIN_PROTECT_REFUSE_SUCCESS',
  'FIN_PROTECT_REFUSE_FAIL',
)();

export const finProtectActivation = createAsyncAction(
  'FIN_PROTECT_ACTIVATION_REQUEST',
  'FIN_PROTECT_ACTIVATION_SUCCESS',
  'FIN_PROTECT_ACTIVATION_FAIL',
)();

export const prepaymentSchedule = createAsyncAction(
  'PREPAYMENT_SCHEDULE_REQUEST',
  'PREPAYMENT_SCHEDULE_SUCCESS',
  'PREPAYMENT_SCHEDULE_FAIL',
)();

export const getNotSignedSupAgreement = createAsyncAction(
  'GET_NOT_SIGNED_SUP_AGREEMENT_REQUEST',
  'GET_NOT_SIGNED_SUP_AGREEMENT_SUCCESS',
  'GET_NOT_SIGNED_SUP_AGREEMENT_FAIL',
)();

export const getSupAgreement = createAsyncAction(
  'GET_SUP_AGREEMENT_REQUEST',
  'GET_SUP_AGREEMENT_SUCCESS',
  'GET_SUP_AGREEMENT_FAIL',
)();

export const sendSupAgreement = createAsyncAction(
  'SEND_SUP_AGREEMENT_REQUEST',
  'SEND_SUP_AGREEMENT_SUCCESS',
  'SEND_SUP_AGREEMENT_FAIL',
)();

export const finProtectRegistration = createAsyncAction(
  'FIN_PROTECT_REGISTRATION_REQUEST',
  'FIN_PROTECT_REGISTRATION_SUCCESS',
  'FIN_PROTECT_REGISTRATION_FAIL',
)();

export const getCheckFactor = createAsyncAction(
  'GET_CHECK_FACTOR_REQUEST',
  'GET_CHECK_FACTOR_SUCCESS',
  'GET_CHECK_FACTOR_FAIL',
)();

export const sendCheckFactor = createAsyncAction(
  'SEND_CHECK_FACTOR_REQUEST',
  'SEND_CHECK_FACTOR_SUCCESS',
  'SEND_CHECK_FACTOR_FAIL',
)();

export const prepaymentScheduleReset = createAction('PREPAYMENT_SCHEDULE_RESET')();
export const servicesReset = createAction('SERVICES_RESET')();
export const setServiceConfig = createAction('SET_SERVICE_CONFIG')();

export function getServiceFinProtectAsync(creditId) {
  return (dispatch) => {
    dispatch(getServiceFinProtect.request(creditId));

    ApiClient.contracts.getServiceFinProtect(creditId)
      .then(({ data, status }) => dispatch(getServiceFinProtect.success(data, { status, creditId })))
      .catch((error) => dispatch(getServiceFinProtect.failure(error)));
  };
}

export function getServiceFinProtectActAvailAsync(creditId, serviceId) {
  return (dispatch) => {
    dispatch(getServiceFinProtectActAvail.request({ creditId, serviceId }));

    ApiClient.contracts.getServiceFinProtectActAvail(creditId, serviceId)
      .then(({ data }) => dispatch(getServiceFinProtectActAvail.success({ data, serviceId })))
      .catch((error) => dispatch(getServiceFinProtectActAvail.failure({ error, serviceId })));
  };
}

export function finProtectRefuseAsync(config) {
  return (dispatch) => {
    dispatch(finProtectRefuse.request());

    ApiClient.contracts.sendFinProtectRefuse(config)
      .then((data) => dispatch(finProtectRefuse.success(data)))
      .catch((error) => dispatch(finProtectRefuse.failure(error)));
  };
}

export function finProtectActivationAsync(config) {
  return (dispatch) => {
    dispatch(finProtectActivation.request());

    ApiClient.contracts.finProtectActivation(config)
      .then((data) => dispatch(finProtectActivation.success(data)))
      .catch((error) => dispatch(finProtectActivation.failure(error)));
  };
}

export function prePaymentScheduleAsync(config) {
  return (dispatch) => {
    dispatch(prepaymentSchedule.request());

    ApiClient.contracts.sendPrePaymentSchedule(config)
      .then((data) => dispatch(prepaymentSchedule.success(data)))
      .catch((error) => dispatch(prepaymentSchedule.failure(error)));
  };
}

export function getNotSignedSupAgreementAsync(contractNumber) {
  return (dispatch) => {
    dispatch(getNotSignedSupAgreement.request());

    ApiClient.contracts.getNotSignedSupAgreement({ contractNumber })
      .then(({ data }) => dispatch(getNotSignedSupAgreement.success(data)))
      .catch((error) => dispatch(getNotSignedSupAgreement.failure(error)));
  };
}

export function getSupAgreementAsync(params) {
  return (dispatch) => {
    dispatch(getSupAgreement.request());

    ApiClient.contracts.getSupAgreement(params)
      .then(({ data }) => dispatch(getSupAgreement.success(data)))
      .catch((error) => dispatch(getSupAgreement.failure(error)));
  };
}

export function sendSupAgreementAsync(params) {
  return (dispatch) => {
    dispatch(sendSupAgreement.request());

    ApiClient.contracts.sendSupAgreement(params)
      .then(({ data }) => dispatch(sendSupAgreement.success(data)))
      .catch((error) => dispatch(sendSupAgreement.failure(error)));
  };
}

export function finProtectRegistrationAsync(params) {
  return (dispatch) => {
    dispatch(finProtectRegistration.request());

    ApiClient.contracts.finProtectRegistration(params)
      .then(({ data }) => dispatch(finProtectRegistration.success(data)))
      .catch((error) => dispatch(finProtectRegistration.failure(error)));
  };
}

export function getCheckFactorAsync(params) {
  return (dispatch) => {
    dispatch(getCheckFactor.request());

    ApiClient.contracts.getCheckFactor(params)
      .then((data) => {
        dispatch(getCheckFactor.success(data));
      })
      .catch((error) => {
        dispatch(getCheckFactor.failure(error));
      });
  };
}

export function sendCheckFactorAsync(params) {
  return (dispatch) => {
    dispatch(sendCheckFactor.request());

    ApiClient.contracts.sendCheckFactor(params)
      .then(({ data }) => dispatch(sendCheckFactor.success(data)))
      .catch((error) => dispatch(sendCheckFactor.failure(error)));
  };
}

export const calcNextPaymentDate = createAsyncAction(
  'FINANCIAL_SERVICES_CALC_NEXT_PAYMENT_DATE_REQUEST',
  'FINANCIAL_SERVICES_CALC_NEXT_PAYMENT_DATE_SUCCESS',
  'FINANCIAL_SERVICES_CALC_NEXT_PAYMENT_DATE_FAIL',
)();

export function calcNextPaymentDateAsync(params) {
  return (dispatch) => {
    dispatch(calcNextPaymentDate.request());

    ApiClient.contracts.calcNextPaymentDate(params)
      .then(({ data }) => dispatch(calcNextPaymentDate.success(data, { params })))
      .catch((error) => dispatch(calcNextPaymentDate.failure(error)));
  };
}

export const resetCacheFinServices = createAction('RESET_CACHE_FIN_SERVICES')();

import classnames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import RightArrowIcon from '../../../../assets/svg/right-arrow-new.svg';
import WarningIcon from '../../../../assets/svg/status/status-warning-new.svg';

const getFormattedDate = (date) => {
  const formatDate = moment(date, 'DD.MM.YYYY').format('D MMMM YYYY');

  return formatDate === 'Invalid date' ? '' : formatDate;
};

function ProductCardButton({ onClick, data }) {
  const statusLabel = useMemo(() => {
    const { insuranceId, status, endDate, refuseDate } = data;
    switch (status) {
      case 'VALID':
      case 'FINISH':
        const validText = `Действует до ${getFormattedDate(endDate)}${insuranceId === 1 || insuranceId === 1001 ? '' : ', <br />пролонгация не требуется'}`;
        return <span className={classnames('product-status', { 'no-casco': insuranceId !== 1 && insuranceId !== 1001 })} dangerouslySetInnerHTML={{ __html: validText }} />;
      case 'REFUSE':
        const html = `Отказ от ${insuranceId === 1001 ? 'услуги ' : 'страхового продукта<br />'}${getFormattedDate(refuseDate)}`;
        return <span className="product-status" dangerouslySetInnerHTML={{ __html: html }} />;
      case 'EXPIRED':
        const expiredText = `Истёк срок действия ${insuranceId === 1001 ? 'услуги' : 'полиса'}${insuranceId === 1 || insuranceId === 1001 ? '' : ', <br />пролонгация не требуется'}`;
        return (
          <span className={classnames('product-status', { 'no-casco': insuranceId !== 1 && insuranceId !== 1001 })} dangerouslySetInnerHTML={{ __html: expiredText }} />
        );
      default:
        return '';
    }
  }, [data]);

  return (
    <button
      className={classnames('product-card-button', data.status?.toLowerCase())}
      onClick={() => onClick(data)}
    >
      <div className="card-header">
        <span className="product-name">{data.insuranceName}</span>
        <RightArrowIcon />
      </div>
      {statusLabel}
      {data.status !== 'VALID' && data.insuranceMessage?.trim() && (
        <div className="informer">
          <WarningIcon className="icon" />
          <span className="description" dangerouslySetInnerHTML={{ __html: data.insuranceMessage }} />
        </div>
      )}
    </button>
  );
}

export default ProductCardButton;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import CollapseDirectedArrow from './CollapseDirectedArrow';
import ArrowUp from './assets/arrow-up.svg';
import { color, transition } from '../styles/mixins';

const UiCollapsePanel = styled.div`
  //margin-top: 20px;
  //
  //&:first-child {
  //  margin-top: 0;
  //}

  &.size-s {
    font-size: 12px;

    .panel-header {
      min-height: 48px;
    }

    .panel-arrow {
      margin-top: 21px;
    }
  }

  &.size-l {
    font-size: 16px;

    .panel-header {
      font-weight: 500;
    }
  }

  .panel-header {
    display: flex;
    align-items: center;
    min-height: 52px;
    overflow: hidden;
    //font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color.black};
    //border-bottom: 1px solid ${color.black.rgba(0.08)};
    //padding-bottom: 20px;
    cursor: pointer;
  }

  .panel-header-wrap {
    flex: 1 1 0px;
    overflow: hidden;
    padding: 0 16px;
  }

  .panel-arrow {
    flex: 0 0 auto;
    align-self: flex-start;
    margin-top: 23px; //22
    margin-left: auto;
    margin-right: 20px;
  }

  .panel-content {
    &-enter-active {
      overflow: hidden;
      transition: height ${transition.normal};
    }
    &-exit-active {
      overflow: hidden;
      transition: height ${transition.normal};
    }
  }

  .panel-content-wrap {
    position: relative;
    overflow: hidden;
    padding-top: 6px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -25px;
      right: -25px;
      bottom: 0;
      z-index: -1;
      background-color: #F2F2F2;
      box-shadow: inset 0 4px 25px #BFBFBF;
      //box-shadow: inset 0 0 25px rgba(0,0,0,.1);
    }

    .label-value {
      margin: 24px 16px;
    }
  }

  .panel-close-btn {
    position: relative;
    padding: 0 0 16px; // to expand clickable zone
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
    color: #979797;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }

  .panel-close-btn-arrow {
    position: absolute;
    right: 32px;
    top: 50%;
    margin-top: -12px;
  }
`;

// TODO: keyboard support
class CollapsePanel extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    header: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    active: PropTypes.bool,
    size: PropTypes.oneOf(['s', 'l']),
    onClick: PropTypes.func,
  };

  static defaultProps = {
    size: 'l',
  };

  height = 0;

  handleEnter = (node) => {
    this.height = node.clientHeight;
    node.style.height = '0px';
  };

  handleEntering = (node) => {
    node.style.height = `${this.height}px`;
  };

  handleEntered = (node) => {
    node.style.height = null;
  };

  handleExit = (node) => {
    node.style.height = `${node.clientHeight}px`;
  };

  handleExiting = (node) => {
    node.style.height = '0px';
  };

  handleExited= (node) => {
    node.style.height = '0px';
  };

  handleClick = () => {
    const { id, onClick } = this.props;

    onClick(id);
  };

  render() {
    const { children, header, active, size } = this.props;
    const inactiveArrowDirection = size === 's' ? 'right' : 'down';

    return (
      <UiCollapsePanel className={`size-${size}`}>
        <div className="panel-header" onClick={this.handleClick}>
          <div className="panel-header-wrap">{header}</div>
          <CollapseDirectedArrow
            className="panel-arrow"
            size={size}
            direction={active ? 'up' : inactiveArrowDirection}
          />
        </div>
        <CSSTransition
          classNames="panel-content"
          timeout={300}
          in={active}
          mountOnEnter
          unmountOnExit
          onEnter={this.handleEnter}
          onEntering={this.handleEntering}
          onEntered={this.handleEntered}
          onExit={this.handleExit}
          onExiting={this.handleExiting}
          onExited={this.handleExited}
        >
          <div className="panel-content">
            <div className="panel-content-wrap">
              {children}
              <div className="panel-close-btn" onClick={this.handleClick}>
                <span>Скрыть</span>
                <ArrowUp className="panel-close-btn-arrow" />
              </div>
            </div>
          </div>
        </CSSTransition>
      </UiCollapsePanel>
    );
  }
}

export default CollapsePanel;

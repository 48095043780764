import { ellipsis } from 'polished';
import React, { forwardRef, useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { color, resetInput } from '../styles/mixins';
import Tag from '../tag/Tag';

const inputContainerStyle = {
  s: css`height: 24px;`,
  m: css`height: 34px;`,
  l: css`height: 44px;`,
};

const UiSelectTagInput = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  height: 100%;
  min-width: 100%;
  padding: 4px 0;
  margin: -2px;
  
  .ui-select-tag {
    flex: 0 0 auto;
    max-width: calc(100% - 4px);
    margin: 2px;
    
    > .ui-select-tag-label {
      ${ellipsis('100%')};
      flex: 1 1 0px;
      font-family: inherit;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #fff;
    }
  }
  
  .ui-select-input-container {
    box-sizing: border-box;
    position: relative;
    flex: 1 0 auto;
    max-width: 100%;
    cursor: text;
    padding: 0 2px;
    
    ${(p) => inputContainerStyle[p.size]}
    
    &:first-child {
      padding-left: 10px;
    }
  }
  
  .ui-select-input {
    ${resetInput};
    max-width: 100%;
    height: 100%;
    width: 5px;
    line-height: 1;
    font-size: 18px;
    font-family: inherit;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: ${color.black};
  }
  
  .ui-select-input-mirror {
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre;
    opacity: 0;
    pointer-events: none;
    line-height: 1;
    font-size: 18px;
    font-family: inherit;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: ${color.black};
  }
`;

const SelectTagInput = forwardRef((props, ref) => {
  const { tags, value, size, onKeyDown, onTagRemove, ...inputProps } = props;
  const inputRef = useRef();
  const inputMirrorRef = useRef();

  const handleInputContainerClick = (e) => {
    e.stopPropagation();
    inputRef.current.focus();
  };

  const handleTagRemoveClick = useCallback(
    (e, tag) => {
      e.stopPropagation();
      onTagRemove(tag);
    },
    [onTagRemove],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Backspace' && !value && tags.length > 0) {
        onTagRemove(tags[tags.length - 1]);
      }

      if (onKeyDown) {
        onKeyDown(e);
      }
    },
    [value, tags, onTagRemove, onKeyDown],
  );

  const tagNodes = useMemo(
    () => tags.map((tag, i) => (
      <Tag
        className="ui-select-tag"
        key={i.toString()}
        size={size}
        onRemove={(e) => handleTagRemoveClick(e, tag)}
      >
        <span className="ui-select-tag-label">{tag.label}</span>
      </Tag>
    )),
    [tags, handleTagRemoveClick],
  );

  useLayoutEffect(
    () => {
      const width = inputMirrorRef.current.clientWidth;
      inputRef.current.style.width = `${width || 5}px`;
    },
    [value],
  );

  return (
    <UiSelectTagInput ref={ref} size={size}>
      {tagNodes}
      <div className="ui-select-input-container" onClick={handleInputContainerClick}>
        <input
          ref={inputRef}
          className="ui-select-input"
          type="text"
          autoComplete="off"
          value={value}
          onKeyDown={handleKeyDown}
          {...inputProps}
        />
        <span ref={inputMirrorRef} className="ui-select-input-mirror">
          {value || '\u00A0'}
        </span>
      </div>
    </UiSelectTagInput>
  );
});

SelectTagInput.propTypes = {
  value: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired,
  ).isRequired,
  size: PropTypes.oneOf(['s', 'm', 'l']).isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onTagRemove: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
};

export default SelectTagInput;

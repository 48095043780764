import classnames from 'classnames';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, transition } from '../styles/mixins';

const UiRadioButton = styled.button`
  display: flex;
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.43;
  color: ${color.black};
  padding: 0 10px;
  border: solid 1px ${color.black.rgba(0.08)};
  background-color: ${color.black.rgba(0)};
  border-radius: 4px;
  outline: 0;
  box-shadow: none;
  margin: 0;
  cursor: pointer;
  transition: ${transition.fast};
  transition-property: border-color, background-color;

  &:hover {
    background-color: ${color.black.rgba(0.05)};
  }

  &.active {
    border: 1px solid ${color.primary};
    color: ${color.primary};
  }

  &.size-s {
    height: 30px;
  }

  &.size-m {
    height: 40px;
  }

  &.size-l {
    height: 50px;
  }

  &.v2 {
    padding: 8px 16px;
    height: 32px;
    background-color: #E5E7EA;
    border-radius: 26px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000C1A;
    border: none;
    transition: background-color ${transition.fast}, color ${transition.fast};

    &.active {
      border: none;
      background-color: #001C3D;
      color: #FFFFFF;
    }
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -5px;

  > ${UiRadioButton} {
    flex: 0 0 auto;
    margin: 0 5px;
  }
`;

const RadioButton = forwardRef((props, ref) => {
  const { className, size, active, children, onClick, design } = props;

  return (
    <UiRadioButton
      ref={ref}
      type="button"
      className={classnames(className, 'ui-radio-button', design, `size-${size}`, { active })}
      onClick={onClick}
    >
      {children}
    </UiRadioButton>
  );
});

RadioButton.Group = Group;

RadioButton.displayName = 'RadioButton';

RadioButton.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l']),
  className: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  design: PropTypes.oneOf(['v1', 'v2']),
};

RadioButton.defaultProps = {
  size: 'm',
  design: 'v2',
};

export default RadioButton;

import { useField } from 'formik';
import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../ui-kit';

const InputField = forwardRef(({ hasError, ...props }, ref) => {
  const [field, meta] = useField(props.name);

  return (
    <Input
      {...field}
      {...props}
      state={(meta.error && meta.touched) || hasError ? 'invalid' : undefined}
      ref={ref}
    />
  );
});

InputField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(InputField);

import { useField } from 'formik';
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import MaskInputOld from '../MaskInputOld';

const noop = () => {};

function MaskInputOldField(props) {
  const [field, meta] = useField(props.name);
  const handleAccept = useCallback((val, mask) => {
    field.onChange({
      target: {
        name: props.name,
        value: mask.unmaskedValue,
      },
    });
  }, []);

  return (
    <MaskInputOld
      {...field}
      {...props}
      state={meta.error && meta.touched ? 'invalid' : undefined}
      onAccept={handleAccept}
      onChange={noop}
    />
  );
}

MaskInputOldField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(MaskInputOldField);

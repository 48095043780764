/**
 * Normalize data for redux store
 * @param {Object[]} data
 * @param {string} [key = 'id']
 * @returns {*}
 */
export default function normalize(data, key = 'id') {
  const byId = {};
  // const allIds = new Array(data.length);

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const id = item[key];

    // allIds[i] = id;
    byId[id] = item;
  }

  return byId;
  // return { byId, allIds };
}

/* eslint-disable no-underscore-dangle */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormControlContext } from './FormControl';

export function useFormControl({ value, defaultValue, onFocus, onBlur }) {
  const _value = value || defaultValue; // imask workaround
  const context = useContext(FormControlContext);
  const [focus, setFocus] = useState(false);
  const handleFocus = useCallback(
    (e) => {
      setFocus(true);

      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );
  const handleBlur = useCallback(
    (e) => {
      setFocus(false);

      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur],
  );
  const props = useMemo(
    () => {
      if (!context) {
        return { onFocus, onBlur };
      }

      return {
        onFocus: handleFocus,
        onBlur: handleBlur,
      };
    },
    [handleFocus, handleBlur, !!context],
  );

  useEffect(
    () => {
      if (!context) {
        return;
      }

      const hasValue = Array.isArray(_value) ? _value.length > 0 : !!_value;

      if (!hasValue && !focus && !context.highlighted) {
        context.setHighlighted(true);
      }

      if ((hasValue || focus) && context.highlighted) {
        context.setHighlighted(false);
      }
    },
    [focus, _value, context],
  );

  useEffect(() => () => context && context.setHighlighted(false), []);

  return props;
}

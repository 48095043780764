import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { getCreditPaymentScheduleAsync } from '../../../../actions/credits';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import EmptyDataBlock from '../../../common/EmptyDataBlock';
import { useContractGid, useUser } from '../../../common/hooks';
import { formatCurrency } from '../../../../utils/format';
import Collapse from '../../../../ui-kit/collapse/Collapse';
import PaymentScheduleItemHeader from '../../../../ui-kit/payment-schedule-item-header/PaymentScheduleItemHeader';
import PeriodFilter from '../../../../ui-kit/period-filter/PeriodFilter';
import LabelValue from '../../../../ui-kit/label-value/LabelValue';
import usePeriodFilter from '../../../common/hooks/usePeriodFilter';
import PageTitle from '../../../common/PageTitle';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';

function CreditPaymentSchedule() {
  const dispatch = useDispatch();
  const { creditId } = useParams();
  const user = useUser();
  const contractGid = useContractGid(creditId);
  const [activePanels, setActive] = useState([]);
  const [dates, setDates] = useState([moment(), moment().add('10', 'year')]);
  const { creditsById, paymentScheduleFetchStatus } = useSelector((state) => ({
    creditsById: state.credits.byId,
    paymentScheduleFetchStatus: state.credits.paymentScheduleFetchStatus,
  }));
  const { paymentSchedule = [] } = creditsById[creditId];
  const filteredPaymentSchedule = usePeriodFilter(dates, paymentSchedule);
  const activePayment = useMemo(() => paymentSchedule.find((item) => moment(item.date, 'DD.MM.YYYY').isSameOrAfter(moment())) || {}, [paymentSchedule]);

  useEffect(() => {
    const { operator_id: operatorId } = user;
    dispatch(getCreditPaymentScheduleAsync(creditId, contractGid, operatorId));
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
  }, []);

  useEffect(() => {
    if (paymentSchedule.length > 0) {
      // moment(paymentSchedule[0].date, 'DD.MM.YYYY')
      setDates([moment(), moment(paymentSchedule[paymentSchedule.length - 1].date, 'DD.MM.YYYY')]);
    }
  }, [paymentScheduleFetchStatus.loading]);

  return (
    <div className="page page-credit-payment-schedule">
      <PageTitle title="График платежей" />
      <LoaderOrChildren loading={paymentScheduleFetchStatus.loading}>
        <PeriodFilter value={dates} onChange={setDates} inputSize="s" />
        {filteredPaymentSchedule.length > 0 && (
          <Collapse activePanels={activePanels} onChange={setActive}>
            {filteredPaymentSchedule
              .map(({ number, date, sum, debtSum, percentSum }) => (
                <Collapse.Panel
                  key={`${number}-${date}`}
                  id={`${number}-${date}`}
                  header={<PaymentScheduleItemHeader active={activePayment.date === date} date={moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY')} amount={formatCurrency(sum)} />}
                  size="l"
                >
                  <LabelValue label="Сумма по основному долгу" value={formatCurrency(debtSum)} />
                  <LabelValue label="Сумма процентов" value={formatCurrency(percentSum)} />
                </Collapse.Panel>
              ))}
          </Collapse>
        )}
        {filteredPaymentSchedule.length === 0 && <EmptyDataBlock message="В выбранном периоде нет платежей" />}
      </LoaderOrChildren>
    </div>
  );
}

export default memo(CreditPaymentSchedule);

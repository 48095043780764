import React, { memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../ui-kit';
import postToNativeApp from '../../../../utils/postToNativeApp';
import SuccessIcon from '../../../../assets/svg/status/status-success-v2.svg';

const StyledRoot = styled.div`
  height: 100%;

  .application-accepted-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    .center-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #1E2025;
        margin: 16px 0 8px;
      }

      .description {
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #6E7279;
      }
    }

    .action-button:first-of-type {
      margin-bottom: 12px;
    }
  }
`;

const descriptionsByPageType = {
  insurance: 'Заявка на предоставление информации по&nbsp;страховому полису принята. Отследить статус заявки вы&nbsp;можете в&nbsp;журнале обращений',
  carInfo: 'Заявка на изменение данных об&nbsp;автомобиле принята. Отследить статус заявки вы&nbsp;можете в&nbsp;журнале обращений',
};

const ApplicationAcceptedPage = ({ history, match: { params: { creditId } }, location: { state: { pageType } }, setBackClickRoute }) => {
  const hasBackButton = !window.sessionStorage.getItem('chapter');

  const closeWebView = useCallback(() => {
    postToNativeApp({ type: 'close' });
    if (hasBackButton) {
      history.push(`/credits/${creditId}`);
    }
  }, [history, creditId]);

  useEffect(() => {
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    if (setBackClickRoute) setBackClickRoute(`/credits/${creditId}`);

    return () => {
      if (setBackClickRoute) setBackClickRoute('');
    };
  }, []);

  const goToOperationLog = useCallback(() => {
    postToNativeApp({ type: 'titleChanged', payload: { chapter: 'INQUIRIES' } });
    history.push(`/credits/${creditId}/operation-log`);
  }, [history, creditId]);

  return (
    <StyledRoot>
      <div className="application-accepted-page">
        <div className="center-container">
          <SuccessIcon className="success-icon" />

          <h2 className="title">Заявка принята</h2>

          <p className="description" dangerouslySetInnerHTML={{ __html: descriptionsByPageType[pageType] }} />
        </div>

        <Button
          className="connect-button action-button"
          design="red"
          size="l"
          onClick={goToOperationLog}
        >
          Перейти в журнал обращений
        </Button>
        <Button
          className="go-back-button action-button"
          design="grey"
          size="l"
          onClick={closeWebView}
        >
          Вернуться к кредиту
        </Button>
      </div>
    </StyledRoot>
  );
};

export default memo(ApplicationAcceptedPage);

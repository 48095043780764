import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { LabelValue } from '../../../../ui-kit';
import NavigationSquareLink from '../../../../ui-kit/navigation-square-link/NavigationSquareLink';
import { moneyFormat, percentFormat } from '../../../../utils/format';
import postToNativeApp from '../../../../utils/postToNativeApp';
import BottomSheet from '../../../common/BottomSheet';
import DomHolder from '../../../common/utils/DomHolder';
import ClipIcon from '../../../../assets/svg/routing-logos/clip.svg';
import CarIcon from '../../../../assets/svg/routing-logos/car.svg';
import FlashIcon from '../../../../assets/svg/routing-logos/flash.svg';
import DocumentIcon from '../../../../assets/svg/routing-logos/document-icon.svg';
import StopIcon from '../../../../assets/svg/routing-logos/stop.svg';

const navigationItems = [
  {
    to: '/', //TODO change route
    text: 'Купить полис',
    icon: DocumentIcon,
    isHidden: ({ isBuyInsuranceAvailable, insuranceName, insuranceId }) => !isBuyInsuranceAvailable || insuranceName !== 'КАСКО' || insuranceId !== 1,
  },
  {
    to: '/send-policy',
    toCasco: '/car-info',
    text: 'Отправить полис',
    icon: ClipIcon,
    routeId: 'insuranceId',
    isHidden: ({ isSendInsuranceAvailable }) => !isSendInsuranceAvailable,
  },
  {
    to: '/', //TODO change route
    text: 'Страховой случай',
    icon: FlashIcon,
    isHidden: ({ isReportInsuranceEventAvailable }) => !isReportInsuranceEventAvailable,
  },
  {
    text: 'Отложить КАСКО',
    icon: StopIcon,
    asButton: true,
    onClick: (contractNumber) => {
      const contractNumberWithF = `${contractNumber}-Ф`;
      postToNativeApp({
        type: 'goToPenaltyPayment',
        payload: { contractNumber: contractNumberWithF },
      });
    },
    isHidden: ({ isPaymentPenaltyAvailable }) => !isPaymentPenaltyAvailable,
  },
  {
    to: '/car-info-change',
    text: 'Данные об авто',
    icon: CarIcon,
    routeId: 'insuranceId',
    isHidden: ({ isCarInfoAvailable }) => !isCarInfoAvailable,
  },
];

function ProductInfoModal({ onClose, config }) {
  const {
    insuranceName,
    insuranceDescription,
    status,
    startDate,
    endDate,
    companyName,
    policyNumber,
    insuranceAmount,
    insurancePremium,
    interestRateWithoutInsurance,
    insuranceId,
    creditId,
  } = config ?? {};
  const { url } = useRouteMatch();

  const isShowNavigation = useMemo(() => (
    config?.isBuyInsuranceAvailable || config?.isSendInsuranceAvailable ||
    config?.isReportInsuranceEventAvailable || config?.isPaymentPenaltyAvailable || config?.isCarInfoAvailable
  ), [config]);
  const filteredNavigationItems = useMemo(() => (
    navigationItems.filter(({ isHidden }) => !(typeof isHidden === 'function' && isHidden(config ?? {})))
  ), [config]);

  return (
    <BottomSheet
      open={!!config}
      onDismiss={onClose}
    >
      <DomHolder>
        {config && (
          <div className="product-info-modal-content">
            <h2 className="title">{insuranceName}</h2>
            <p className="description">{insuranceDescription}</p>

            {!!isShowNavigation && (
              <div className="navigation-menu horizontal-scroller-hider">
                <div className="horizontal-scroller">
                  {filteredNavigationItems.map(({ icon, text, to, toCasco, routeId, asButton, onClick }) => (
                    <NavigationSquareLink
                      key={`${to}-${text}`}
                      icon={icon}
                      text={text}
                      asButton={asButton}
                      onClick={() => onClick(creditId)}
                      to={`${url + ((insuranceId === 1 || insuranceId === 1001) && toCasco ? toCasco : to)}${routeId ? `/${config[routeId]}` : ''}`}
                    />
                  ))}
                </div>
              </div>
            )}

            <LabelValue
              className={['EXPIRED', 'REFUSE'].includes(status) && (insuranceId === 1 || status === 'REFUSE') ? '_error' : ''}
              label="Период действия"
              value={`${startDate} - ${endDate}`}
            />
            <LabelValue label="Страховая компания / аффилированное лицо" value={companyName} />
            <LabelValue label="Номер страхового полиса" value={policyNumber} />
            <LabelValue label="Страховая сумма" value={moneyFormat(insuranceAmount)} />
            <LabelValue label="Страховая премия" value={moneyFormat(insurancePremium)} />
            {interestRateWithoutInsurance !== 0 && (
              <LabelValue
                label="Ставка по кредиту при&nbsp;отказе от&nbsp;страховки"
                value={percentFormat(interestRateWithoutInsurance)}
              />
            )}
          </div>
        )}
      </DomHolder>
    </BottomSheet>
  );
}

export default ProductInfoModal;

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/rate-history';
import { asyncReducerFactory } from '../utils/redux-utils';

const rateHistoryListReducer = createReducer({})
  .handleAction(actions.getChangeRateHistoryList.success, (state, { payload }) => payload);

const rateConditionListReducer = createReducer({})
  .handleAction(actions.getChangeRateConditionList.success, (state, { payload }) => payload);

export default combineReducers({
  rateHistoryList: rateHistoryListReducer,
  rateConditionList: rateConditionListReducer,
  getChangeRateHistoryFetchStatus: asyncReducerFactory(actions.getChangeRateHistoryList),
  getChangeRateConditionListFetchStatus: asyncReducerFactory(actions.getChangeRateConditionList),
});

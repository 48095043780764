import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

const getFormattedError = (error) => {
  const { status, endpointName } = error;

  if (endpointName === 'headCompany') {
    return {};
  }

  switch (status) {
    case 204:
      return {
        type: 'informer',
        title: 'Страховые продукты отсутствуют',
        description: 'По данному договору отсутствует информация о страховых продуктах',
        ...error,
      };
    case 403:
      if (endpointName === 'insuranceProlongation' || endpointName === 'sendCarInfo') {
        return {
          type: 'informer',
          title: 'Операция отклонена',
          description: `У вас есть активная заявка на&nbsp;${endpointName === 'insuranceProlongation' ? 'предоставление информации по&nbsp;страховому полису' : 'изменение данных об&nbsp;автомобиле'}. Отследить статус заявки вы&nbsp;можете в&nbsp;журнале обращений`,
          withGotoInquiries: true,
          ...error,
        };
      }
      return { type: 'fatal', ...error };
    case 406:
      return { type: 'fatal', ...error };
    case 408:
      return { type: 'failedLoadData', ...error };
    case 500:
      return { type: 'fatal', ...error };
    default:
      return { type: 'fatal', ...error };
  }
};

export const getUploadDocumentTypes = createAsyncAction(
  'GET_UPLOAD_DOCUMENT_TYPES_REQUEST',
  'GET_UPLOAD_DOCUMENT_TYPES_SUCCESS',
  'GET_UPLOAD_DOCUMENT_TYPES_FAIL',
)();

export function getUploadDocumentTypesAsync(documentLoadType) {
  return (dispatch, getState) => {
    if (getState().credits.uploadDocumentTypes) {
      return Promise.resolve();
    }

    dispatch(getUploadDocumentTypes.request());
    return ApiClient.insurancesProducts.getUploadDocumentTypes({ documentLoadType })
      .then(({ data }) => {
        dispatch(getUploadDocumentTypes.success(data));
        dispatch(showInsurancesProductsError({}));
      })
      .catch((error) => {
        dispatch(getUploadDocumentTypes.failure(error));
        dispatch(showInsurancesProductsError(getFormattedError({
          ...error,
          endpointName: 'uploadType',
          onReload: () => dispatch(getUploadDocumentTypesAsync(documentLoadType)),
          asyncStatusReducerName: 'uploadDocumentTypesFetchStatus',
        })));
      });
  };
}

export const cascoProlongation = createAsyncAction(
  'CASCO_PROLONGATION_REQUEST',
  'CASCO_PROLONGATION_SUCCESS',
  'CASCO_PROLONGATION_FAIL',
)();

export function cascoProlongationAsync(params) {
  return (dispatch) => {
    dispatch(cascoProlongation.request());
    return ApiClient.insurancesProducts.cascoProlongation(params)
      .then(() => dispatch(cascoProlongation.success()))
      .catch((error) => dispatch(cascoProlongation.failure(error)));
  };
}

export const getInsurancesProducts = createAsyncAction(
  'INSURANCES_PRODUCTS_GET_INSURANCES_PRODUCTS_REQUEST',
  'INSURANCES_PRODUCTS_GET_INSURANCES_PRODUCTS_SUCCESS',
  'INSURANCES_PRODUCTS_GET_INSURANCES_PRODUCTS_FAIL',
)();

export function getInsurancesProductsAsync(params) {
  return (dispatch) => {
    dispatch(getInsurancesProducts.request());

    ApiClient.insurancesProducts.getInsurancesProducts(params)
      .then(({ data, status }) => {
        dispatch(getInsurancesProducts.success(data, params));
        if (status !== 200) {
          dispatch(showInsurancesProductsError(getFormattedError({ status })));
        } else {
          dispatch(showInsurancesProductsError({}));
        }
      })
      .catch((error) => {
        dispatch(getInsurancesProducts.failure(error));
        dispatch(showInsurancesProductsError(getFormattedError({
          ...error,
          onReload: () => dispatch(getInsurancesProductsAsync(params)),
          asyncStatusReducerName: 'getInsurancesProductsFetchStatus',
        })));
      });
  };
}

export const sendInsuranceProlongation = createAsyncAction(
  'INSURANCES_PRODUCTS_SEND_INSURANCE_PROLONGATION_REQUEST',
  'INSURANCES_PRODUCTS_SEND_INSURANCE_PROLONGATION_SUCCESS',
  'INSURANCES_PRODUCTS_SEND_INSURANCE_PROLONGATION_FAIL',
)();

export function sendInsuranceProlongationAsync(_data) {
  return (dispatch, getState) => {
    const { requestChannel } = getState().app;
    dispatch(sendInsuranceProlongation.request());
    const data = { ..._data, requestChannel };

    ApiClient.insurancesProducts.sendInsuranceProlongation(data)
      .then((response) => {
        dispatch(sendInsuranceProlongation.success(response, { data }));
        dispatch(showInsurancesProductsError({}));
      })
      .catch((error) => {
        dispatch(sendInsuranceProlongation.failure(error));
        dispatch(showInsurancesProductsError(getFormattedError({
          ...error,
          endpointName: 'insuranceProlongation',
          onReload: () => dispatch(sendInsuranceProlongationAsync(data)),
          asyncStatusReducerName: 'sendInsuranceProlongationFetchStatus',
        })));
      });
  };
}

export const getCarInfo = createAsyncAction(
  'INSURANCES_PRODUCTS_GET_CAR_INFO_REQUEST',
  'INSURANCES_PRODUCTS_GET_CAR_INFO_SUCCESS',
  'INSURANCES_PRODUCTS_GET_CAR_INFO_FAIL',
)();

export function getCarInfoAsync(data) {
  return (dispatch) => {
    dispatch(getCarInfo.request());

    ApiClient.insurancesProducts.getCarInfo(data)
      .then((response) => {
        dispatch(getCarInfo.success(response.data, data));
        dispatch(showInsurancesProductsError({}));
      })
      .catch((error) => {
        dispatch(getCarInfo.failure(error));
        dispatch(showInsurancesProductsError(getFormattedError({
          ...error,
          endpointName: 'getCarInfo',
          onReload: () => dispatch(getCarInfoAsync(data)),
          asyncStatusReducerName: 'getCarInfoFetchStatus',
        })));
      });
  };
}

export const sendCarInfo = createAsyncAction(
  'INSURANCES_PRODUCTS_SEND_CAR_INFO_REQUEST',
  'INSURANCES_PRODUCTS_SEND_CAR_INFO_SUCCESS',
  'INSURANCES_PRODUCTS_SEND_CAR_INFO_FAIL',
)();

export function sendCarInfoAsync(data) {
  return (dispatch, getState) => {
    const { requestChannel } = getState().app;
    dispatch(sendCarInfo.request());

    ApiClient.insurancesProducts.sendCarInfo({ ...data, requestChannel })
      .then((response) => {
        dispatch(sendCarInfo.success(response, { data }));
        dispatch(showInsurancesProductsError({}));
      })
      .catch((error) => {
        dispatch(sendCarInfo.failure(error));
        dispatch(showInsurancesProductsError(getFormattedError({
          ...error,
          endpointName: 'sendCarInfo',
          onReload: () => dispatch(sendCarInfoAsync(data)),
          asyncStatusReducerName: 'sendCarInfoFetchStatus',
        })));
      });
  };
}

export const getHeadCompany = createAsyncAction(
  'INSURANCES_PRODUCTS_GET_HEAD_COMPANY_REQUEST',
  'INSURANCES_PRODUCTS_GET_HEAD_COMPANY_SUCCESS',
  'INSURANCES_PRODUCTS_GET_HEAD_COMPANY_FAIL',
)();

export function getHeadCompanyAsync(data) {
  return (dispatch) => {
    dispatch(getHeadCompany.request());

    ApiClient.insurancesProducts.getHeadCompany(data)
      .then((response) => {
        dispatch(getHeadCompany.success(response, { data }));
        dispatch(showInsurancesProductsError({}));
      })
      .catch((error) => {
        dispatch(getHeadCompany.failure(error));
        dispatch(showInsurancesProductsError(getFormattedError({
          ...error,
          endpointName: 'headCompany',
          onReload: () => dispatch(sendCarInfoAsync(data)),
          asyncStatusReducerName: 'getHeadCompanyFetchStatus',
        })));
      });
  };
}

export const showInsurancesProductsError = createAction('INSURANCES_PRODUCTS_SHOW_INSURANCES_PRODUCTS_ERROR')();

export const resetInsurancesProducts = createAction('INSURANCES_PRODUCTS_RESET_INSURANCES_PRODUCTS')();

import { useRef } from 'react';
import { useSelector } from 'react-redux';
import usePrevious from './usePrevious';

const initial = { loading: false, error: null, state: 'initial', success: false, initial: true };

/**
 * @param {Function} selector
 * @param {boolean} holdDefaultInitialForFirstLoading
 * @returns {{ loading: boolean, error: any, state: 'initial'|'loading'|'loaded'|'error', success: boolean }}
 */
function useAsyncStatus(selector, holdDefaultInitialForFirstLoading) {
  const status = useSelector(selector);
  const ref = useRef(status);
  const value = ref.current === status ? initial : status;
  const prevValue = usePrevious(value);

  return prevValue?.initial && value.loading && holdDefaultInitialForFirstLoading ? { ...value, initial: true } : value;
}

export default useAsyncStatus;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WebFont from 'webfontloader';

function WebFontLoader({ children, config }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(
    () => {
      WebFont.load({
        ...config,
        active: () => setLoaded(true),
      });
    },
    [],
  );

  return loaded ? children : null;
}

WebFontLoader.propTypes = {
  config: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default WebFontLoader;

import { darken, mix, rgba } from 'polished';
import { css } from 'styled-components';

export const resetButton = css`
  font-family: inherit;
  font-weight: 400;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  border: none;
  outline: 0;
  background: none;
  cursor: pointer;
`;

export const resetInput = css`
  font-family: inherit;
  font-weight: 400;
  box-shadow: none;
  -webkit-appearance: none;
  border: 0;
  outline: 0;
  background: #fff;
  margin: 0;
  padding: 0;
`;

export const transition = {
  fast: '.15s cubic-bezier(.25, .1, .25, 1)',
  normal: '.3s cubic-bezier(.25, .1, .25, 1)',
  slow: '.6s cubic-bezier(.25, .1, .25, 1)',
};

export const color = {
  primary: colorFunctionFactory('primary'),
  black: colorFunctionFactory('black'),
  white: colorFunctionFactory('white'),
  error: colorFunctionFactory('error'),
  success: colorFunctionFactory('success'),
  info: colorFunctionFactory('info'),
  warning: colorFunctionFactory('warning'),
};

function colorFunctionFactory(name) {
  const colorFunction = (props) => props.theme[name];

  colorFunction.rgba = (alpha) => (props) => rgba(colorFunction(props), alpha);
  colorFunction.mix = (color, weight) => (props) => mix(weight, colorFunction(props), color);
  colorFunction.darken = (amount) => (props) => darken(amount, colorFunction(props));

  return colorFunction;
}

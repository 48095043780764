import { useField } from 'formik';
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../../../../ui-kit/date-picker/DatePicker';

function DateInputField(props) {
  const [field, meta, helpers] = useField(props.name);
  const handleChange = useCallback((value) => {
    helpers.setTouched(true);
    field.onChange({
      target: {
        name: props.name,
        value,
      },
    });
    if (props?.onChange) {
      props.onChange(value);
    }
  }, []);

  return (
    <DatePicker
      {...field}
      {...props}
      InputProps={{ state: meta.error && meta.touched ? 'invalid' : undefined }}
      onChange={handleChange}
    />
  );
}

DateInputField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default memo(DateInputField);

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import ChevronIcon from '../../../../assets/svg/chevron-right.svg';
import WarningIcon from '../../../../assets/svg/operation-log/warning-icon.svg';
import HtmlText from '../../../common/utils/HtmlText';

function OrderDocsMainPage({ match, fetchStatus }) {
  const orderDocs = useSelector((state) => state.orderDocs.byId);
  const orderDocsSortById = Object.values(orderDocs).sort((a, b) => {
    if (a.request_type_short_name === 'getAssignmentCertificate') return -1;
    if (b.request_type_short_name === 'getAssignmentCertificate') return 1;
    return a.request_type_id - b.request_type_id;
  });

  useEffect(() => {
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
  }, []);

  return (
    <LoaderOrChildren loading={fetchStatus.initial}>
      <div className="order-doc-list">
        {orderDocsSortById.map((doc) => (
          <Link key={doc.document_type_code} className="list-item-wrapper" to={`${match.url}/${doc.request_type_short_name}`}>
            <div className="list-item-content">
              <img
                src={`/order-docs/${doc.request_type_short_name}.svg`}
                alt={doc.request_type_short_name}
                className="order-doc-icon"
              />
              <div className="right-container">
                <HtmlText tag="span" className="list-item-label">{doc.document_type_name}</HtmlText>
                {doc.request_type_short_name === 'getAssignmentCertificate' && (
                  <span className="status-assignment">
                    <WarningIcon className="red-warning-icon" />
                    Договор уступлен
                  </span>
                )}
              </div>
            </div>
            <ChevronIcon className="chevron-icon" />
          </Link>
        ))}
      </div>
    </LoaderOrChildren>
  );
}

export default OrderDocsMainPage;

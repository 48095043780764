import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import { H2 } from '../../ui-kit';
import ArrowBackIcon from '../../assets/svg/arrow-back.svg';

function PageTitle(props) {
  const { className, title, onBackClick, version } = props;
  const history = useHistory();
  const handleBackClick = useCallback(() => {
    if (onBackClick) {
      onBackClick();
    } else {
      history.goBack();
    }
  }, [onBackClick]);
  const queryChapter = useMemo(() => window.sessionStorage.getItem('chapter'), []);

  if (queryChapter) {
    return null;
  }

  return (
    <div className={classnames('page-title', className, `version-${version}`)}>
      <div className="back-btn" onClick={handleBackClick}>
        <ArrowBackIcon />
      </div>
      <H2 bold>{title}</H2>
    </div>
  );
}

PageTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onBackClick: PropTypes.func,
  version: PropTypes.oneOf(['1', '2']), // temporarily until the margins throughout the project change
};

PageTitle.defaultProps = {
  version: '1',
};

export default memo(PageTitle);

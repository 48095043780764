import React, { useCallback, useMemo } from 'react';
import 'react-spring-bottom-sheet/dist/style.css';
import styled from 'styled-components';
import { Button } from '../../../../ui-kit';
import BottomSheet from '../../../common/BottomSheet';
import AlertBlock from '../../../common/AlertBlock';
import postToNativeApp from '../../../../utils/postToNativeApp';
import DownloadIcon from '../../../../assets/svg/download-with-bg-new.svg';
import { isCurrentVersionNewerThan } from '../../../../utils/isCurrentVersionNewerThan';
import DomHolder from '../../../common/utils/DomHolder';
import { formatCurrency, formatDate } from '../../../../utils/format';

const Content = styled.div`
  margin: 0 16px 24px;

  .modal-title {
    margin-bottom: 8px;
    display: block;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #000C1A;
  }

  .appeal-status {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;

    .status-label {
      margin-left: 8px;
    }

    svg {
      overflow: visible;
    }
  }

  .label-value {
    display: flex;
    flex-direction: column;
    padding: 12px 0;

    .label {
      font-size: 14px;
      line-height: 16px;
      color: #647081;
    }

    .value {
      margin-top: 4px;
      font-size: 16px;
      line-height: 20px;
      color: #000C1A;
    }
  }

  .sign-in-submit {
    margin-top: 24px;
  }

  .document-download-wrapper {
    display: flex;
    padding: 12px 0;

    .download {
      display: flex;
      align-items: center;
      margin-left: 24px;

      &:first-of-type {
        margin: 0;
      }

      .text {
        margin-left: 12px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #000C1A;
      }
    }
  }
`;

const LabelValue = ({ label, value }) => (
  <div className="label-value">
    <span className="label">{label}</span>
    <span className="value">{value}</span>
  </div>
);

const DownloadLink = ({ label, href }) => (
  <a href={href} download className="download">
    <DownloadIcon className="icon" />
    <span className="text">{label}</span>
  </a>
);

const fields = [
  { label: 'Номер договора', id: 'contractNumber' },
  { label: 'Дата обращения', id: 'date', format: (date) => formatDate(date) },
  { label: 'Планируемая сумма погашения', id: 'earlyRepaySum', needSubtypeCode: 'EARLYREPAYMENT', format: (sum) => formatCurrency(sum) },
  { label: 'Фактическая сумма погашения', id: 'acceptedAmount', needSubtypeCode: 'EARLYREPAYMENT', format: (sum) => formatCurrency(sum) },
  { label: 'Дата досрочного погашения', id: 'earlyRepayDate', needSubtypeCode: 'EARLYREPAYMENT', format: (date) => formatDate(date) },
  { label: 'Способ получения документа', id: 'wayOfGettingName', needSubtypeCode: 'DOCUMENT' },
];

const docCodesForAdditionalParams = [
  'FINPROTECTPURCHASE',
  'FINPROTECTACTIVATE',
  'FINPROTECTREFUSE',
  'CASCOPROLOGATION',
  'CHANGECARINFO',
  'MONEYREMITTANCE',
  'CHANGEPERSONALDATA',
  'CURRENTRATECHANGE',
];

const docCodesForDownload = [
  'EARLYREPAYMENT',
  'FINPROTECTPURCHASE',
  'FINPROTECTACTIVATE',
  'FINPROTECTREFUSE',
];

const OperationItemDetailsModal = ({ modalConfig, setGoToChatLoading, onClose }) => {
  const {
    name,
    color,
    icon,
    status,
    subtypeCode,
    wayOfGettingName,
    isGoToChatAvailable,
    requestStateCode,
    requestName,
    additionalParameters,
    requestUserDocumentMobileUrl,
    requestUserDocumentMobileUrlList,
  } = modalConfig ?? {};

  const isWeb = window.sessionStorage.getItem('isWeb') === 'true';
  const isNewVersion = isCurrentVersionNewerThan('6.2.0');

  const alertText = isGoToChatAvailable === 1 ? 'Документ готов, вы можете получить его в чате' : 'По готовности вам придёт уведомление, документ будет доступен в чате';
  const isShowAdditionalParams = docCodesForAdditionalParams.includes(subtypeCode);
  const urlsConfig = useMemo(() => {
    const config = {
      statement: '',
      supAgreement: '',
      agreement: '',
    };

    if (requestUserDocumentMobileUrl && docCodesForDownload.includes(subtypeCode)) {
      config.statement = requestUserDocumentMobileUrl;
    }
    if (subtypeCode === 'FINPROTECTPURCHASE' && requestUserDocumentMobileUrlList) {
      config.supAgreement = requestUserDocumentMobileUrlList?.find((item) => item.documentType === 'SUPAGR' && !!item.url)?.url ?? '';
      config.statement = requestUserDocumentMobileUrlList?.find((item) => (
        item.documentType === 'REQUEST' && !!item.url
      ))?.url ?? config.statement ?? '';
    }
    if (subtypeCode === 'MONEYREMITTANCE' && requestUserDocumentMobileUrl) {
      config.agreement = requestUserDocumentMobileUrl;
    }

    return config;
  }, [requestUserDocumentMobileUrl, requestUserDocumentMobileUrlList, subtypeCode]);

  const goToChat = useCallback(() => {
    postToNativeApp({
      type: 'goToChat',
    });
    onClose();
    setGoToChatLoading(true);
  }, []);

  return (
    <BottomSheet
      open={!!modalConfig}
      onDismiss={onClose}
    >
      <DomHolder>
        {!!modalConfig && (
          <Content>
            <span className="modal-title">{name}</span>
            {!!status && (
              <div className="appeal-status">
                {icon}
                <span className="status-label" style={{ color }}>{status}</span>
              </div>
            )}

            {fields.filter(({ id, needSubtypeCode }) => (
              !!modalConfig[id] && modalConfig[id] !== 'null' && (!needSubtypeCode || needSubtypeCode === subtypeCode)
            )).map(({ label, id, format }) => (
              <LabelValue key={id} label={label} value={format ? format(modalConfig[id]) : modalConfig[id]} />
            ))}

            {subtypeCode === 'DOCUMENT' && (
              !!isGoToChatAvailable || (wayOfGettingName === 'В чате' && !['COMPLETED', 'REJECTED'].includes(requestStateCode))) && (
              <AlertBlock text={alertText} margin="12px 0" />
            )}

            {!!additionalParameters && additionalParameters !== 'null' && ((
              subtypeCode === 'DOCUMENT' && requestName === 'Нетиповой документ') || isShowAdditionalParams) && (
                <LabelValue label="Доп. параметры обращения" value={additionalParameters} />
            )}

            {(urlsConfig.statement || urlsConfig.supAgreement || urlsConfig.agreement) && (
              <div className="document-download-wrapper">
                {urlsConfig.statement && <DownloadLink href={urlsConfig.statement} label="Заявление" />}
                {urlsConfig.supAgreement && <DownloadLink href={urlsConfig.supAgreement} label="Допсоглашение" />}
                {urlsConfig.agreement && <DownloadLink href={urlsConfig.agreement} label="Соглашение" />}
              </div>
            )}

            {subtypeCode === 'DOCUMENT' && !!isGoToChatAvailable && (isNewVersion || isWeb) && (
              <Button
                className="sign-in-submit"
                design="red"
                size="l"
                onClick={goToChat}
              >
                Перейти в чат
              </Button>
            )}
          </Content>
        )}
      </DomHolder>
    </BottomSheet>
  );
};

export default OperationItemDetailsModal;

import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import { Route } from 'react-router-dom';
import ServiceActionPage from './ServiceActionPage';
import ConnectionDocumentPage from './ConnectionDocumentPage';
import CodeVerificationPage from './CodeVerificationPage';
import ServiceRefusePage from './ServiceRefusePage';
import ServiceActivationPage from './ServiceActivationPage';

function ServiceActionsSection() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/connection-document`}
        exact
        render={(props) => <ConnectionDocumentPage {...props} />}
      />

      <Route
        path={`${path}/code-verification`}
        exact
        render={(props) => <CodeVerificationPage {...props} />}
      />

      <Route
        path={`${path}/activation`}
        exact
        render={(props) => <ServiceActivationPage {...props} />}
      />

      <Route
        path={`${path}/refuse`}
        exact
        render={(props) => <ServiceRefusePage {...props} />}
      />

      <Route
        path={path}
        exact
        render={(props) => <ServiceActionPage {...props} />}
      />

      <Redirect to={path} />
    </Switch>
  );
}

export default ServiceActionsSection;

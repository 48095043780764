import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/auth';

const initialState = {
  loading: false,
  error: null,
  authenticated: false,
  username: '',
};

const authReducer = createReducer(initialState)
  .handleAction(actions.login.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(actions.login.success, (state) => ({
    ...state,
    loading: false,
    authenticated: true,
  }))
  .handleAction(actions.login.failure, (state, { payload, meta }) => ({
    ...state,
    loading: false,
    authenticated: false,
    error: payload,
    username: meta.username,
  }))
  .handleAction(actions.unauthorized, (state) => ({
    ...state,
    authenticated: false,
  }));

export default authReducer;

const pageTitleHeight = 60;

export default function getBottomSheetSnapPoints({ maxHeight, minHeight }) {
  const withPageTitle = !window.sessionStorage.getItem('chapter');

  const halfScreen = (maxHeight - (withPageTitle ? pageTitleHeight : 0)) / 2;

  const moreThanHalfScreen = minHeight >= maxHeight - 20 - (withPageTitle ? pageTitleHeight : 0) ?
    maxHeight - 20 - (withPageTitle ? pageTitleHeight : 0) : minHeight;
  const totalHeight = minHeight < halfScreen ? halfScreen : moreThanHalfScreen;

  return [totalHeight, totalHeight];
}

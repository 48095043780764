import classnames from 'classnames';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import IMask from 'imask';
import { finProtectRefuseAsync } from '../../../../../actions/services';
import { Button, FormControl, LabelValue } from '../../../../../ui-kit';
import { formatCurrency } from '../../../../../utils/format';
import AlertBlock from '../../../../common/AlertBlock';
import CollapsibleElement from '../../../../common/CollapsibleElement';
import InputField from '../../../../common/forms/fields/InputField';
import { useAsyncStatus, useFixedFooter, useOpenNewPage, useUser } from '../../../../common/hooks';
import HtmlText from '../../../../common/utils/HtmlText';
import useServiceById from '../hooks/useServiceById';
import ChooseBicModal from '../modals/ChooseBicModal';

const validate = (values) => {
  const errors = {};
  const checkAccount = values.checkAccount.replace(/ /g, '');

  if (checkAccount) {
    const KS_PATTERN = /40817810|40820810|423\d{2}810|426\d{2}810/;
    const correctStart = ['40817810', '40820810', '423', '426'].some((num) => num.startsWith(checkAccount) || checkAccount.startsWith(num));
    if (!correctStart) {
      errors.checkAccount = 'Указан неверный формат счёта';
      return errors;
    }

    if (checkAccount.length > 5 && checkAccount.length <= 8 && !['8  ', '81 ', '810'].some((num) => checkAccount.padEnd(8).endsWith(num))) {
      errors.checkAccount = 'Указан неверный формат счёта';
      return errors;
    }

    if (checkAccount.length >= 8 && checkAccount.search(KS_PATTERN) === -1) {
      errors.checkAccount = 'Указан неверный формат счёта';
      return errors;
    }

    if (checkAccount.length === 20) {
      const bicRs = values.bic.toString().slice(-3) + checkAccount;
      const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
      const checksum = coefficients.reduce((sum, x, i) => sum + x * (bicRs[i] % 10), 0);
      if (checksum % 10 !== 0) {
        errors.checkAccount = 'Счёт не принадлежит выбранному вами банку. Проверьте правильность введённых данных';
        errors.bic = 'БИК не соответствует введенному вами счёту. Проверьте правильность введённых данных';
      }
    }
  }

  return errors;
};

const validationSchema = yup.object().shape({
  bic: yup.string(),
  checkAccount: yup.string(),
});

const initial = {
  bic: '',
  checkAccount: '',
};

const checkAccountMask = '00000 000 0 00000000000';

const toMasked = IMask.createPipe({
  mask: checkAccountMask,
});
const toUnmasked = IMask.createPipe({
  mask: checkAccountMask,
}, IMask.PIPE_TYPE.MASKED, IMask.PIPE_TYPE.UNMASKED);

const InputHint = ({ name, values, errors, isFocusedInput }) => {
  const hintText = useMemo(() => {
    if (name === 'checkAccount') {
      if (values.checkAccount?.length && errors.checkAccount) {
        return errors.checkAccount;
      }

      if (isFocusedInput) {
        return 'Укажите 20 цифр счёта в одном из форматов:<br />40817, 40820, 423ХХ, 426ХХ';
      }

      return '';
    }

    return errors[name] ?? '';
  }, [name, values, errors, isFocusedInput]);

  return (
    <CollapsibleElement active={!!hintText}>
      <HtmlText tag="p" className={classnames('input-hint', { error: errors[name] })}>
        {hintText}
      </HtmlText>
    </CollapsibleElement>
  );
};

function ServiceRefusePage({ match }) {
  const dispatch = useDispatch();
  const { params: { creditId, serviceKey } } = match;
  const user = useUser();
  const footerRef = useRef();
  const windowInitialHeight = useRef(0);

  const [footerStyles, setFooterStyles] = useState({});
  const [activeBicModal, setActiveBicModal] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [focusedCheckAccount, setFocusedCheckAccount] = useState(false);

  const finProtectRefuseStatus = useAsyncStatus((state) => state.services.finProtectRefuseStatus);

  const service = useServiceById(serviceKey) ?? {};
  const serviceId = service.id;
  const fullName = user.last_name && user.first_name ? `${user.last_name} ${user.first_name} ${user.patronymic ?? ''}`.trim() : '';

  useFixedFooter(footerRef, 'services-and-options-content', [finProtectRefuseStatus.error]);

  const handleSubmit = useCallback(({ bic, checkAccount }) => {
    const data = {
      contractNumber: creditId,
      operatorId: user.operator_id,
      isAccountClosed: 1,
      serviceId: service.id,
      serviceName: service.name,
      cancelingServiceName: service.name,
      packageName: service.packageName,
      packageId: service.packageId,
      recipientData: {
        fio: fullName,
        bankBic: bic,
        corrAccount: selectedBank.ks,
        bankName: selectedBank.bank_name,
        checkAccount,
      },
    };

    dispatch(finProtectRefuseAsync(data));
  }, [user, selectedBank, service]);

  const handleBicInputFocus = () => {
    setActiveBicModal(true);
  };

  const handleCheckAccountFocus = () => {
    setFocusedCheckAccount(true);
  };

  const handleCloseModal = () => {
    setActiveBicModal(false);
  };

  const handleChooseBic = useCallback((setFieldValue, bank) => {
    setFieldValue('bic', bank.bic);
    setActiveBicModal(false);
    setSelectedBank(bank);
  }, []);

  const handleChangeCheckAccount = useCallback((e, setFieldValue) => {
    setFieldValue('checkAccount', toUnmasked(e.target.value));
  }, []);

  useOpenNewPage();

  useEffect(() => {
    windowInitialHeight.current = window.innerHeight;
    const isWeb = window.sessionStorage.getItem('isWeb') === 'true';

    if (isWeb) { //to raise the button above the mobile keyboard
      const listener = (event) => {
        const { height } = event.target;
        const keyboardHeight = Math.ceil(Math.abs(height - windowInitialHeight.current));
        const isNeedToTranslate = keyboardHeight > 100 && windowInitialHeight.current > height;

        setFooterStyles({ paddingBottom: isNeedToTranslate ? keyboardHeight + 12 : 12 });
        if (isNeedToTranslate) {
          window.scrollTo({
            top: keyboardHeight,
            behavior: 'smooth',
          });
        }
      };

      window.visualViewport.addEventListener('resize', listener);

      return () => {
        window.visualViewport.removeEventListener('resize', listener);
      };
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (selectedBank) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [selectedBank]);

  return (
    <div className={classnames('service-refuse-page')}>
      <h1 className="title">
        Отказ от {serviceId === 234 || serviceId === 240 ? 'услуги' : 'опции'}<br />
        «{service.name ?? ''}»
      </h1>

      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnMount
      >
        {({ values, errors, setFieldError, setFieldValue, isValid }) => (
          <Form className="service-refuse-form">
            <AlertBlock
              text="Счёт по вашему договору закрыт. Чтобы вернуть деньги за неиспользуемый период, предоставьте реквизиты другого счёта, открытого на ваше имя"
              status="warning"
              margin="12px 0"
            />

            <LabelValue label="ФИО получателя" value={fullName} />

            <FormControl>
              <InputField
                name="bic"
                label="БИК банка получателя"
                design="v2"
                placeholder="123456789"
                onFocus={handleBicInputFocus}
                readOnly
              />
              <InputHint values={values} errors={errors} name="bic" />
            </FormControl>

            {selectedBank?.ks && <LabelValue label="Корр. счёт банка получателя" value={selectedBank.ks} />}
            {selectedBank?.bank_name && <LabelValue label="Банк получателя" value={selectedBank.bank_name} />}
            {selectedBank?.city && <LabelValue label="Город банка получателя" value={selectedBank.city} />}

            <FormControl>
              <InputField
                name="checkAccount"
                value={toMasked(values.checkAccount)}
                design="v2"
                placeholder="00000 000 0 00000000000"
                label="Счёт получателя"
                hasError={!!errors.checkAccount}
                onChange={(e) => handleChangeCheckAccount(e, setFieldValue)}
                disabled={!selectedBank?.bic}
                onBlur={(e) => {
                  setFocusedCheckAccount(false);
                  if (e.target.value.replace(/ /g, '').length !== 20) {
                    setFieldError('checkAccount', 'Счет может состоять только из 20 цифр');
                  }
                }}
                onFocus={handleCheckAccountFocus}
              />
              <InputHint values={values} errors={errors} name="checkAccount" isFocusedInput={focusedCheckAccount} />
            </FormControl>

            {(service.cancelRefundAmount || service.cancelRefundAmount === 0) && (
              <div className="refund-amount-wrapper">
                <span className="refund-amount-label">Сумма возврата</span>
                <span className="refund-amount-value">{formatCurrency(service.cancelRefundAmount)}</span>
              </div>
            )}

            <div className="page-footer" ref={footerRef} style={footerStyles}>
              {finProtectRefuseStatus.error && (
                <AlertBlock text="Не удалось загрузить данные.<br />Попробуйте повторить попытку" status="fatal-error" margin="16px 0" />
              )}
              <Button
                design="red"
                disabled={!isValid || !values.bic || !values.checkAccount}
                loading={finProtectRefuseStatus.loading}
              >
                Отправить заявку
              </Button>
            </div>

            <ChooseBicModal
              active={activeBicModal}
              onClose={handleCloseModal}
              onChooseBic={(bic) => handleChooseBic(setFieldValue, bic)}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ServiceRefusePage;

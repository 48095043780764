import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { navigationItems } from '../../../../constants/navigation';
import { formatCurrency } from '../../../../utils/format';
import { sendLogs } from '../../../../utils/sendLogs';
import PageTitle from '../../../common/PageTitle';
import CreditListItem from '../../../../ui-kit/credit-list-item/CreditListItem';
import CreditMainOverdueInfoBlock from './CreditMainOverdueInfoBlock';
import Collapse from '../../../../ui-kit/collapse/Collapse';
import LabelValue from '../../../../ui-kit/label-value/LabelValue';
import NavigationSquareLink from '../../../../ui-kit/navigation-square-link/NavigationSquareLink';

import AlertIcon from '../../../../assets/svg/alert-icon.svg';
import ArrowRight from '../../../../assets/svg/nav-menu/arrow-right.svg';
import CornerDownRight from '../../../../assets/svg/corner-down-right.svg';

const getDetailsConfigByContractTypeCode = (code) => {
  switch (code) {
    case 'C':
      return {
        card_number: { label: 'Номер карты' },
        card_status: { label: 'Статус карты' },
        // activate_date_to: { label: 'Срок действия карты' },
        expiry_date: { label: 'Срок действия карты' },
        credit_limit: { label: 'Кредитный лимит', resolveF: ({ credit_limit }) => formatCurrency(credit_limit), requiredParams: ['credit_limit'] },
        credit_rest_available: { label: 'Доступная сумма', resolveF: ({ credit_rest_available }) => formatCurrency(credit_rest_available), requiredParams: ['credit_rest_available'] },
        total_debt: { label: 'Общая задолженность', resolveF: ({ total_debt }) => formatCurrency(total_debt), requiredParams: ['total_debt'] },
      };
    case 'A':
    default:
      return {
        credit_dates: { label: 'Период кредита', resolveF: ({ credit_date, date_closed }) => `${credit_date} — ${date_closed}`, requiredParams: ['credit_date', 'date_closed'] },
        percent_rate: { label: 'Процентная ставка', resolveF: ({ percent_rate }) => `${percent_rate} %`, requiredParams: ['percent_rate'] },
        credit_sum: { label: 'Сумма кредита', resolveF: ({ credit_sum }) => formatCurrency(credit_sum), requiredParams: ['credit_sum'] },
        debt_outstanding_sum: {
          label: 'Остаток ссудной задолженности',
          hint: '(без учета просроченной задолженности, процентов и комиссии при наличии)',
          resolveF: ({ debt_outstanding_sum }) => formatCurrency(debt_outstanding_sum),
          requiredParams: ['debt_outstanding_sum'],
        },
        full_credit_rate: {
          label: 'Полная стоимость кредита',
          resolveF: ({ full_credit_rate }) => `${full_credit_rate} %`,
          requiredParams: ['full_credit_rate'],
        },
        penalty_rate: {
          label: 'Размер штрафов',
          resolveF: ({ penalty_rate }) => `${penalty_rate} %`,
          requiredParams: ['penalty_rate'],
        },
        credit_period: {
          label: 'Срок кредита в месяцах',
          // resolveF: ({ credit_date, date_closed }) => {
          //   const closed = moment(date_closed, 'DD.MM.YYYY');
          //   const opened = moment(credit_date, 'DD.MM.YYYY');
          //   return Math.round(moment.duration(closed.diff(opened)).asMonths());
          // },
        },
      };
  }
};

function CreditMain() {
  const { creditId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const [activePanels, setActive] = useState([]);
  const creditsById = useSelector((state) => state.credits.byId);
  const credit = creditsById[creditId];
  const {
    additionalInfo,
    is_debt_overdue_sum: isDebtOverdueSum,
    contract_type: contractType,
    contract_type_code: contractTypeCode,
    is_closed: isClosed,
    actual_date: actualDate,
  } = credit;
  const {
    account_number: accountNumber,
    rest_sum_with_hold: restSumWithHold,
    account_rest_sum: accountRestSum,
    // is_new_insurance_needed: isNewInsuranceNeeded,
  } = additionalInfo;
  // const newInsuranceLinkShown = contractTypeCode === 'A' && !isClosed && isNewInsuranceNeeded;
  const newInsuranceLinkShown = false; // TODO: remove when insurance is needed
  const onBackClick = useCallback(() => history.push(`/credits/${credit.is_closed ? 'closed' : 'active'}`), []);
  const detailsCfg = useMemo(() => getDetailsConfigByContractTypeCode(credit.contract_type_code), [credit.contract_type_code]);
  const creditDetailInfoItems = useMemo(() => Object.keys(detailsCfg)
    .filter((key) => {
      const { requiredParams = [], resolveF } = detailsCfg[key];
      if (!resolveF) return typeof additionalInfo[key] !== 'undefined';
      return requiredParams.every((param) => typeof additionalInfo[param] !== 'undefined');
    })
    .map((key) => {
      const { label, hint, resolveF } = detailsCfg[key];
      return { label, value: resolveF ? resolveF(additionalInfo) : additionalInfo[key], hint };
    }), [credit]);
  const detailsPanelHeader = useMemo(() => (<div className="details-panel-header">Детальная информация</div>), []);
  const filteredNavigationItems = useMemo(() => (
    navigationItems.filter(({ isHidden }) => !(typeof isHidden === 'function' && isHidden(credit)))
      .sort((a, b) => {
        const indexA = a.getSortIndex(isClosed, contractTypeCode);
        const indexB = b.getSortIndex(isClosed, contractTypeCode);

        if (indexA > indexB) return 1;
        if (indexA < indexB) return -1;
        return 0;
      })
  ), [credit]);

  const restSum = useMemo(() => (credit.contract_type_code === 'C' ? accountRestSum : restSumWithHold), [credit.contract_type_code]);

  const pageTitle = useMemo(() => (
    contractType === 'Автокредит' || contractType === 'Кредитная карта' ? contractType : `${contractType} кредит`
  ), [contractType]);

  useEffect(() => {
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
  }, []);

  return (
    <div className="page-credit-details-main">
      <div className="page-content">
        <PageTitle title={pageTitle} onBackClick={onBackClick} />
        {newInsuranceLinkShown && (
          <Link to={`${url}/insurance-prolong`} className="alert-block">
            <div className="alert-block-title align-icons-top">
              <div className="alert-icon-with-text">
                <AlertIcon className="alert-icon" />
                <span className="alert-block-title-text">Необходимо предоставить пролонгированный полис КАСКО на текущий год либо до конца срока действия кредита и квитанцию об оплате страховой премии</span>
              </div>
              <ArrowRight className="arrow-right-icon" />
            </div>
          </Link>
        )}
        <CreditMainOverdueInfoBlock
          url={url}
          additionalInfo={additionalInfo}
          isDebtOverdueSum={isDebtOverdueSum}
          actualDate={actualDate}
          credit={credit}
        />
        <CreditListItem credit={credit} withLabel={false} withIcon />
        <Collapse activePanels={activePanels} onChange={setActive}>
          <Collapse.Panel id="1" header={detailsPanelHeader} size="s">
            {creditDetailInfoItems.map((item) => <LabelValue key={`${item.label}-${item.value}`} {...item} />)}
          </Collapse.Panel>
        </Collapse>
        {accountNumber && (
          <div className="repayment-account">
            <div className="repayment-account-icon-wrap">
              <CornerDownRight />
            </div>
            <div className="repayment-account-data">
              <div className="repayment-account-label">Счет погашения</div>
              <div className="repayment-account-id">{accountNumber}</div>
              {(typeof restSum !== 'undefined') && <div className="repayment-account-balance">Остаток: {formatCurrency(restSum)}</div>}
            </div>
          </div>
        )}
        <div className="navigation-menu horizontal-scroller-hider">
          <div className="horizontal-scroller">
            {filteredNavigationItems.map(({ icon, text, to }) => (
              <NavigationSquareLink key={`${to}-${text}`} icon={icon} text={text} to={url + to} whiteIcons />
            ))}
          </div>
        </div>
      </div>
      <div className="page-footer">
        {`Дата актуальности данных ${actualDate}`}
      </div>
    </div>
  );
}

export default memo(CreditMain);

/* eslint-disable import/no-extraneous-dependencies */
import 'simplebar/dist/simplebar.min.css';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
import { color } from '../styles/mixins';

const UiScrollbar = styled(SimpleBar)`
  .simplebar-track {
    &.simplebar-vertical {
      width: 6px;

      .simplebar-scrollbar {
        right: 2px;
        width: 4px;

        &::before {
          left: 0;
          right: 0;
          border-radius: 3px;
          background-color: ${color.black};
        }

        &.simplebar-visible {
          &::before {
            opacity: .1;
          }
        }
      }
    }

    &.simplebar-horizontal {
      height: 6px;

      .simplebar-scrollbar {
        top: 0;
        height: 4px;

        &::before {
          border-radius: 3px;
          background-color: ${color.black};
        }

        &.simplebar-visible {
          &::before {
            opacity: .1;
          }
        }
      }
    }
  }
`;

const Scrollbar = forwardRef((props, ref) => {
  const { className, children, ...rest } = props;

  return (
    <UiScrollbar ref={ref} className={className} {...rest}>
      {children}
    </UiScrollbar>
  );
});

Scrollbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Scrollbar;

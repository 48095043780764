import * as classnames from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { animate, circ, quad } from '../../utils/animations';

export const useStyles = makeStyles({
  toolbar: {
    padding: '16px 0 12px',
    '@media(max-width: 430px)': {
      padding: '3.721vw 0 2.791vw',
    },
  },
  title: {
    fontSize: '26px',
    fontWeight: 'bold',
    lineHeight: '32px',
    padding: '0 16px',
    marginBottom: '22px',
    '@media(max-width: 430px)': {
      marginBottom: '5.1163vw',
    },
  },
  scrollWrapper: {
    position: 'relative',
    height: '36px',
    overflow: 'hidden',
    '&:last-child': {
      marginTop: '12px',
      '@media(max-width: 430px)': {
        marginTop: '2.791vw',
      },
    },
    '&::before, &::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: '3',
      height: '36px',
      width: '48px',
      background: 'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 73.17%)',
    },
    '&::after': {
      left: 'auto',
      right: '0',
      background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 73.17%)',
    },
  },
  yearsScrollContainer: {
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '-webkit-overflow-scrolling': 'touch',
    display: 'flex',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  monthsScrollContainer: {
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '-webkit-overflow-scrolling': 'touch',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& $scrollContent': {
      display: 'inline-flex',
      padding: '0 calc(50% - 45px)',
    },
  },
  scrollContent: {
    display: 'inline-flex',
    padding: '0 calc(50% - 34px)',
  },
  yearButton: {
    cursor: 'pointer',
    width: '68px',
    flex: '0 0 auto',
    padding: '5px 0',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: '16px',

    '@media(max-width: 430px)': {
      marginBottom: '3.721vw',
    },
  },
  monthButton: {
    cursor: 'pointer',
    width: '90px',
    flex: '0 0 auto',
    padding: '6px 0',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: '16px',

    '@media(max-width: 430px)': {
      marginBottom: '3.721vw',
    },
  },
  selectedItem: {
    fontSize: '20px',
    fontWeight: 'bold',
    position: 'relative',
    bottom: '1px',
  },
});

const YEARS = (() => {
  const years = [];
  const dateStart = moment().subtract(100, 'y');
  const dateEnd = moment().add(100, 'y');
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push(dateStart.format('YYYY'));
    dateStart.add(1, 'year');
  }
  return years;
})();

const CustomToolbar = (props) => {
  const { date, onChange } = props;

  const [selectedYear, setSelectedYear] = useState(date.format('YYYY'));
  const [selectedMonth, setSelectedMonth] = useState(date.format('MMMM'));
  const yearsContainerRef = useRef(null);
  const monthsContainerRef = useRef(null);
  const prevDateRef = useRef({ year: selectedYear, month: selectedMonth });

  useEffect(() => {
    setSelectedMonth(date.format('MMMM'));
  }, [date]);

  const handleYearSelect = useCallback((e) => {
    setSelectedYear(e.currentTarget.dataset.year);
    onChange(moment(date).year(e.currentTarget.dataset.year), false);
  }, [date]);
  const handleMonthSelect = useCallback((e) => {
    setSelectedMonth(e.currentTarget.dataset.month);
    onChange(moment(date).month(e.currentTarget.dataset.month), false);
  }, [date]);

  useLayoutEffect(() => {
    [yearsContainerRef.current, monthsContainerRef.current].forEach((container) => {
      const selectedItem = container.getElementsByClassName(classes.selectedItem)[0];
      container.scrollLeft = selectedItem.offsetLeft - (container.parentElement.clientWidth / 2) + (selectedItem.clientWidth / 2);
    });
  }, []);

  useEffect(() => {
    const container = prevDateRef.current.year !== selectedYear ? yearsContainerRef.current : monthsContainerRef.current;
    const selectedItem = container.getElementsByClassName(classes.selectedItem)[0];
    animate({
      duration: 150,
      timing: circ,
      draw: (progress) => {
        const prevScrollX = container.scrollLeft;
        const newScrollX = selectedItem.offsetLeft - (container.parentElement.clientWidth / 2) + (selectedItem.clientWidth / 2);
        container.scrollLeft = prevScrollX + ((newScrollX - prevScrollX) * progress);
      },
    });

    prevDateRef.current = { year: selectedYear, month: selectedMonth };
  }, [selectedYear, selectedMonth]);

  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <div className={classes.title}>Выбор даты</div>
      <div className={classes.scrollWrapper}>
        <div className={classes.yearsScrollContainer} ref={yearsContainerRef}>
          <div className={classes.scrollContent}>
            {YEARS.map((year) => (
              <div
                key={year}
                className={classnames(classes.yearButton, { [classes.selectedItem]: year === selectedYear })}
                data-year={year}
                onClick={handleYearSelect}
              >
                {year}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.scrollWrapper}>
        <div className={classes.monthsScrollContainer} ref={monthsContainerRef}>
          <div className={classes.scrollContent}>
            {moment.months().map((month) => (
              <div
                key={month}
                className={classnames(classes.monthButton, { [classes.selectedItem]: month === selectedMonth })}
                data-month={month}
                onClick={handleMonthSelect}
              >
                {month}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomToolbar;

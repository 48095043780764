import { useField } from 'formik';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import RadioButton from '../../../../ui-kit/radio-button/RadioButton';

function RadioButtonField({ name, options, disabled }) {
  const [field,, helpers] = useField(name);

  return useMemo(() => (
    <div className="radio-button-group-wrap horizontal-scroller-hider">
      <div className="radio-button-group horizontal-scroller">
        {options.map(({ label, value }) => (
          <RadioButton
            key={value}
            active={field.value === value}
            onClick={() => helpers.setValue(value)}
            disabled={disabled}
          >
            {label}
          </RadioButton>
        ))}
      </div>
    </div>
  ), [field.value, options]);
}

RadioButtonField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default RadioButtonField;

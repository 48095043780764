import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/insurances-products';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const uploadDocumentTypesReducer = createReducer(null)
  .handleAction(actions.getUploadDocumentTypes.success, (state, { payload }) => {
    const modifiedPayload = payload.reduce((acc, {
      file_type_code: fileTypeCode,
      file_type_name: fileTypeName,
      is_attachment_required: fileRequired = true,
      max_quantity: maxQuantity = 2,
      not_attached_error_message: notAttachedErrorMessage = 'Поле обязательно для заполнения!',
    }) => {
      // const isOther = fileTypeCode.toLowerCase() === 'other'; // TODO: remove when backend full config ready
      // acc[fileTypeCode] = {
      //   label:
      //   fileTypeName,
      //   required: isOther ? false : fileRequired,
      //   maxFiles: isOther ? 6 : maxQuantity,
      //   requiredErrorMessage: notAttachedErrorMessage,
      // }; // TODO: remove when backend full config ready

      acc[fileTypeCode] = {
        label: fileTypeName,
        required: fileRequired,
        maxFiles: maxQuantity,
        requiredErrorMessage: notAttachedErrorMessage,
      };
      return acc;
    }, {});
    return { ...modifiedPayload };
  });

const insurancesProductsReducer = createReducer({})
  .handleAction(actions.getInsurancesProducts.success, (state, { payload, meta }) => ({
    ...state,
    [meta.contractNumber]: payload.insurances,
  }))
  .handleAction(actions.resetInsurancesProducts, () => ({}));

const carInfoByIdReducer = createReducer({})
  .handleAction(actions.getCarInfo.success, (state, { payload, meta }) => ({
    ...state,
    [meta.contractNumber]: payload,
  }));

const headCompaniesReducer = createReducer({})
  .handleAction(actions.getHeadCompany.success, (state, { payload }) => normalize(payload.data.insCompanies));

const errorReducer = createReducer({})
  .handleAction(actions.showInsurancesProductsError, (state, { payload }) => payload);

export default combineReducers({
  byId: insurancesProductsReducer,
  uploadDocumentTypes: uploadDocumentTypesReducer,
  carInfoById: carInfoByIdReducer,
  error: errorReducer,
  headCompanies: headCompaniesReducer,
  uploadDocumentTypesFetchStatus: asyncReducerFactory(actions.getUploadDocumentTypes),
  cascoProlongationFetchStatus: asyncReducerFactory(actions.cascoProlongation),
  getInsurancesProductsFetchStatus: asyncReducerFactory(actions.getInsurancesProducts),
  sendInsuranceProlongationFetchStatus: asyncReducerFactory(actions.sendInsuranceProlongation),
  getCarInfoFetchStatus: asyncReducerFactory(actions.getCarInfo),
  sendCarInfoFetchStatus: asyncReducerFactory(actions.sendCarInfo),
  getHeadCompanyFetchStatus: asyncReducerFactory(actions.getHeadCompany),
});

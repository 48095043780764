import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import PageTitle from '../../../common/PageTitle';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import NoOperationsInformer from './NoOperationsInformer';
import useUser from '../../../common/hooks/useUser';
import { getCreditOperationLogAppealsAsync } from '../../../../actions/credits';
import OperationsGroupByDate from './OperationsGroupByDate';
import OperationItemDetailsModal from './OperationItemDetailsModal';
import { useAsyncStatus } from '../../../common/hooks';

function CreditOperationLog({ match: { params: { creditId } } }) {
  const dispatch = useDispatch();
  const user = useUser();
  const creditsById = useSelector((state) => state.credits.byId);
  const operationLogAppealsFetchStatus = useAsyncStatus((state) => state.credits.operationLogAppealsFetchStatus, true);
  const { operationLogAppeals } = creditsById[creditId];
  const isIos = window.sessionStorage.getItem('isIos');
  const [modalConfig, setModalConfig] = useState(null);
  const [goToChatLoading, setGoToChatLoading] = useState(false);

  const onCloseModal = useCallback(() => {
    setModalConfig(null);
  }, []);

  useEffect(() => {
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });

    dispatch(getCreditOperationLogAppealsAsync(user.operator_id));
  }, []);

  return (
    <div className="operation-log-wrapper">
      <PageTitle version="2" title="Журнал обращений" />
      <LoaderOrChildren loading={operationLogAppealsFetchStatus.loading || goToChatLoading}>
        <div className={classnames('operation-log-content', { 'is-ios': isIos })}>
          {operationLogAppeals?.length > 0 && operationLogAppeals.map((appeals) => (
            <OperationsGroupByDate key={appeals.date} setModalConfig={setModalConfig} {...appeals} />
          ))}
          <NoOperationsInformer active={operationLogAppeals?.length === 0} creditId={creditId} />
        </div>
      </LoaderOrChildren>

      <OperationItemDetailsModal
        modalConfig={modalConfig}
        onClose={onCloseModal}
        setGoToChatLoading={setGoToChatLoading}
      />
    </div>
  );
}

export default memo(CreditOperationLog);

import classnames from 'classnames';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AlertIcon from '../../assets/svg/alert-icon.svg';
import SuccessIcon from '../../assets/svg/status/status-success-v3.svg';
import PendingIcon from '../../assets/svg/status/status-pending-v2.svg';

const StyledAlertBlock = styled.div`
  display: block;
  margin: ${(props) => props.margin ?? '16px 0 13px'};
  border-radius: 6px;
  background-color: #F3F4F6;

  &.compact {
    margin-top: 0;
  }

  &.status-info {
    .alert-block-wrapper .alert-icon .fill-colored-el {
      fill: #A7AFBC;
    }
  }

  &.status-warning {
    .alert-block-wrapper .alert-icon {
      margin-right: 4px;

      .fill-colored-el {
        fill: #E59335;
      }
    }
  }

  &.status-error {
    .alert-block-wrapper .alert-icon .fill-colored-el {
      fill: #E40038;
    }
  }

  &.status-fatal-error {
    background-color: transparent;

    .alert-block-wrapper {
      padding-left: 0;
      padding-right: 0;

      .alert-block-text {
        font-size: 16px;
        line-height: 20px;
        color: #E40038;
      }

      .alert-icon {
        transform: rotate(180deg);

        .fill-colored-el {
          fill: #E40038;
        }
      }
    }
  }

  &.status-success {
    .alert-icon {
      max-width: 16px;
      max-height: 16px;
      margin-left: 0;
      top: 0;
      margin-right: 8px;
    }
  }

  &.status-pending {
    .alert-icon {
      max-width: 19.21px; // === 16px for path width
      max-height: 19.21px; // === 16px for path height
    }
  }

  .alert-block-wrapper  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;

    .alert-block-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      word-break: break-all;
    }
    .alert-block-text {
      flex-shrink: 1;
      display: inline-block;
      color: #000C1A;
      font-size: 14px;
      line-height: 16px;
      margin-right: 5px;
      padding-bottom: 1px;
      word-break: break-word;
    }

    svg {
      position: relative;
      top: -2px;
      margin-left: -2px;
      margin-right: 6px;
      flex-shrink: 0;
      width: 20.8px;
      height: 20.8px;

      .fill-colored-el {
        fill: #E59335;
      }
    }
  }
`;

function AlertBlock({ text, className, icon: iconProps, status, compact, margin }) {
  const IconByStatus = useMemo(() => {
    switch (status) {
      case 'success':
        return SuccessIcon;
      case 'pending':
        return PendingIcon;
      default:
        return AlertIcon;
    }
  }, [status]);

  const icon = iconProps || <IconByStatus className="alert-icon" />;

  return (
    <StyledAlertBlock className={classnames('ui-alert-block', className, `status-${status}`, { compact })} margin={margin}>
      <div className="alert-block-wrapper">
        <div className="alert-block-container">
          {icon}
          <span className="alert-block-text" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
    </StyledAlertBlock>
  );
}

AlertBlock.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  status: PropTypes.oneOf(['error', 'warning', 'info', 'success', 'pending', 'fatal-error']),
  compact: PropTypes.bool,
  margin: PropTypes.string,
};

AlertBlock.defaultProps = {
  className: '',
  status: 'info',
  compact: false,
};

export default AlertBlock;

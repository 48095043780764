import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AlertXsIcon from '../../assets/svg/alert-icon-xs.svg';
import { color } from '../styles/mixins';

const UiHint = styled.div`
  display: block;
  position: relative;
  padding: 11px 16px;
  border-radius: 4px;
  border: 1px solid transparent;
  font-family: inherit;
  font-size: 13px;
  line-height: 20px;

   ${(p) => p.type === 'advice' && css`
    padding: 4px 0 0 0;
   `}

  ${(p) => p.type === 'error' && css`
    background-color: ${color.error.rgba(0.05)};
    border-color: ${color.error.rgba(0.2)};
    color: ${color.error};
  `}

  ${(p) => p.type === 'info' && css`
    background-color: #E9E9E9;
    color: #585858;
    padding-left: 47px;

    .alert-icon {
      position: absolute;
      top: 14px;
      left: 18px;
    }
  `}
`;

const Hint = (props) => {
  const { type, children, className } = props;

  return (
    <UiHint className={className} type={type}>
      {type === 'info' && <AlertXsIcon className="alert-icon" />}
      {children}
    </UiHint>
  );
};

Hint.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'info']).isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Hint;

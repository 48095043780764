import { useMemo } from 'react';
import moment from 'moment';

function usePeriodFilter(period, array) {
  return useMemo(() => array.filter(({ date }) => {
    const momentDate = moment(date, 'DD.MM.YYYY');
    return (!period[0] || momentDate.isSameOrAfter(period[0])) && (!period[1] || momentDate.isSameOrBefore(period[1]));
  }), [period, array]);
}

export default usePeriodFilter;

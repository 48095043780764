import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { Loader } from '../icons';
import { resetInput } from '../styles/mixins';

const CODE_LENGTH = new Array(4).fill(0);

const UiCodeVerificationInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.state-invalid {
    color: #E40038;
  }

  &.disabled {
    .code-verification-cell {
      opacity: 0.8;
      color: #A3A5AE;
    }
  }

  .loader {
    margin-top: 12px;
    path {
      fill: #8B8C98;
    }
  }

  .error-message {
    font-size: 14px;
    line-height: 16px;
    color: #E40038;
  }

  .cells {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 224px;
    position: relative;
    font-family: inherit;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .code-verification-cell {
    width: 50px;
    height: 58px;
    background: #EBEBEF;
    border-radius: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }
  }

  .input {
    ${resetInput};
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%; // FF workaround
    font-size: 20px;
    text-align: center;
    background-color: transparent;
    width: calc((100% - 24px) / 4);
    caret-color: #E9041E;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
      outline: 0;
    }

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }
  }
`;

const CodeVerificationInput = (props) => {
  const {
    className, state, name, disabled, value, numeric, onChange, onBlur, submitForm, loading, errorMessage,
  } = props;
  const values = useMemo(() => value.split(''), [value]);
  const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
  const hideInput = !(values.length < CODE_LENGTH.length);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (value.length === CODE_LENGTH.length && !disabled) {
      submitForm?.();
    }
  }, [value, disabled, submitForm]);

  const handleClick = useCallback(() => inputRef.current.focus(), [inputRef.current]);

  const handleChange = useCallback((e) => {
    if (value.length >= CODE_LENGTH.length) return;

    /** @type {string} */
    let newValue = value + e.target.value;
    newValue = newValue.replace(/-/g, '');

    if (numeric) {
      newValue = newValue.replace(/\D/g, '');
    }

    onChange({
      target: {
        value: newValue.slice(0, CODE_LENGTH.length),
        name,
      },
    });
  }, [value, onChange, numeric]);

  const handleKeyUp = useCallback((e) => {
    if (e.key === 'Backspace') {
      onChange({ target: { value: value.slice(0, value.length - 1), name } });
    }
  }, [value, onChange]);

  const handleBlur = useCallback((e) => {
    onBlur?.(e);
  }, []);

  const renderCells = () => CODE_LENGTH.map((v, i) => {
    const index = ((CODE_LENGTH.length) + i);

    return (
      <div className="code-verification-cell" key={`${index}-${v}`}>
        {values[i]}
      </div>
    );
  });

  const cls = classnames('ui-code-verification-input', `state-${state}`, className, { disabled: disabled || loading });

  return (
    <UiCodeVerificationInput className={cls} onClick={handleClick}>
      <div className="cells">
        <input
          value=""
          name={name}
          ref={inputRef}
          disabled={disabled || loading}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          type={numeric ? 'number' : 'text'}
          className="input"
          style={{
            left: `calc((100% / 4  + 2px) * ${selectedIndex})`,
            opacity: hideInput ? 0 : 1,
          }}
        />
        {renderCells()}
      </div>
      {errorMessage && value.length === 4 && <span className="error-message">{errorMessage}</span>}
      {loading && <Loader className="loader" />}
    </UiCodeVerificationInput>
  );
};

CodeVerificationInput.displayName = 'CodeVerificationInput';

CodeVerificationInput.propTypes = {
  className: PropTypes.string,
  state: PropTypes.oneOf(['default', 'valid', 'invalid']),
  value: PropTypes.string,
  numeric: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func,
  errorMessage: PropTypes.string,
};

CodeVerificationInput.defaultProps = {
  state: 'default',
  numeric: true,
};

export default CodeVerificationInput;

export const mergeByProperty = (arrays, property = 'id') => {
  const arr = arrays.flatMap((item) => item);

  const obj = arr.reduce((acc, item) => ({
    ...acc,
    [item[property]]: { ...acc[item[property]], ...item },
  }), {});

  return Object.values(obj);
};

import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { getDocTypesAsync } from '../../../../actions/order-docs';
import { useAsyncStatus, useUser } from '../../../common/hooks';
import usePage from '../../../common/hooks/usePage';
import PageTitle from '../../../common/PageTitle';
import OrderDocsMainPage from './OrderDocsMainPage';
import OrderDocsRequest from './OrderDocsRequest';

function OrderDocsPage({ match: { params: { creditId } } }) {
  usePage({ className: 'order-docs' });
  const { path } = useRouteMatch();
  const isIos = window.sessionStorage.getItem('isIos');
  const dispatch = useDispatch();
  const user = useUser();
  const fetchDocAddressTypeStatus = useAsyncStatus((state) => state.orderDocs.fetchDocAddressTypeStatus, true);
  const fetchStatus = useAsyncStatus((state) => state.orderDocs.fetchStatus, true);

  useEffect(() => {
    const { operator_id: operatorId } = user;
    dispatch(getDocTypesAsync(operatorId, creditId));
  }, []);

  return (
    <div className="order-docs-wrapper">
      <PageTitle title="Заказать документы" version="2" />

      <div className={classnames('order-docs-content', { 'is-ios': isIos })}>
        <Switch>
          <Route
            path={path}
            exact
            render={(props) => (
              <OrderDocsMainPage {...props} fetchStatus={fetchStatus} />
            )}
          />

          <Route
            path={`${path}/:type?`}
            render={(props) => (
              <OrderDocsRequest fetchStatus={fetchDocAddressTypeStatus} {...props} />
            )}
          />
          <Redirect to={path} />
        </Switch>
      </div>
    </div>
  );
}

export default OrderDocsPage;

import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../../../utils/format';
import LabelValue from '../../../../ui-kit/label-value/LabelValue';
import AlertIcon from '../../../../assets/svg/alert-icon.svg';
import ArrowRight from '../../../../assets/svg/nav-menu/arrow-right.svg';

function CreditMainOverdueInfoBlock({ url, additionalInfo, isDebtOverdueSum, actualDate, credit }) {
  const {
    debt_overdue_sum: sum = 0,
    debt_overdue_sum_without_interest: sumWithoutInterest = 0,
    debt_overdue_interest: interest = 0,
    debt_penalty_sum: penaltySum = 0,
    legal_tax_sum: legalTaxSum = 0,
    is_data_actual: isDataActual = false,
  } = additionalInfo;
  const { is_assignment: isAssignment = false } = credit;
  const debtOverdueTotalSum = useMemo(() => sum + penaltySum + legalTaxSum, [additionalInfo]);
  const debtOverdueCalculatedSum = useMemo(() => sumWithoutInterest + interest + penaltySum + legalTaxSum, [additionalInfo]);
  const commonOverdueBlockShown = useMemo(() => (isDebtOverdueSum && (debtOverdueCalculatedSum === 0 || !isDataActual)), [additionalInfo]);
  const detailedOverdueBlockShown = useMemo(() => isDebtOverdueSum && debtOverdueCalculatedSum !== 0, [additionalInfo]);

  if (commonOverdueBlockShown && !isAssignment) {
    return (
      <div className="alert-block">
        <div className="alert-block-title">
          <div className="alert-icon-with-text">
            <AlertIcon className="alert-icon" />
            <span className="alert-block-title-text">Просроченная задолженность</span>
          </div>
        </div>
        <div className="alert-block-body">
          <p>
            У вас имеется просроченная задолженность.
            Для уточнения суммы по погашению просьба обращаться по телефону в Центр обслуживания клиентов.
          </p>
        </div>
      </div>
    );
  }
  if (isAssignment) {
    return (
      <div className="alert-block">
        <div className="alert-block-title">
          <div className="alert-icon-with-text">
            <AlertIcon className="alert-icon" />
            <span className="alert-block-title-text">Договор уступлен</span>
          </div>
        </div>
        <div className="alert-block-body">
          <p>
          В соответствии со ст. 382 ГК РФ право требования по кредитному договору, заключенному между Вами и Филиалом РОСБАНК Авто ПАО РОСБАНК, уступлено Банком третьему лицу. По всем интересующим Вас вопросам просьба обращаться по телефону в Центр обслуживания клиентов.
          </p>
        </div>
      </div>
    );
  }
  if (detailedOverdueBlockShown) {
    return (
      <Link to={`${url}/overdue-debt`} className="alert-block">
        <div className="alert-block-title">
          <div className="alert-icon-with-text">
            <AlertIcon className="alert-icon" />
            <span className="alert-block-title-text">Просроченная задолженность</span>
          </div>
          <ArrowRight className="arrow-right-icon" />
        </div>
        <LabelValue label="Сумма" value={formatCurrency(debtOverdueTotalSum)} />
        <LabelValue label="На дату" value={actualDate} />
      </Link>
    );
  }

  return null;
}

CreditMainOverdueInfoBlock.propTypes = {
  url: PropTypes.string.isRequired,
  additionalInfo: PropTypes.object.isRequired,
  isDebtOverdueSum: PropTypes.bool.isRequired,
  actualDate: PropTypes.string.isRequired,
};

export default memo(CreditMainOverdueInfoBlock);

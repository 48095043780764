import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const RadioContext = React.createContext();

const GroupElement = styled.div`
  // margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
`;

const UiRadioGroup = styled.div`

`;

function RadioGroup(props) {
  const { className, name, value, children, onChange } = props;
  const { Provider } = RadioContext;

  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        onChange({
          target: { name, value: e.target.value },
        }, e.target.value);
      }
    },
    [name, onChange],
  );

  const context = useMemo(
    () => ({ value, name, onChange: handleChange }),
    [value, name, handleChange],
  );

  return (
    <Provider value={context}>
      <UiRadioGroup className={className}>
        {React.Children.map(children, (child) => (
          <GroupElement>
            {child}
          </GroupElement>
        ))}
      </UiRadioGroup>
    </Provider>
  );
}

RadioGroup.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
};

export default RadioGroup;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { resetButton, transition, color } from '../styles/mixins';
import ArrowIcon from './assets/sort-arrow.svg';

const UiInfinitePagination = styled.div`
  display: flex;
  align-items: center;
  width: auto;

  .ui-pagination-start {
    ${resetButton};
    font-family: inherit;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${color.black.rgba(0.5)};
    transition: ${transition.fast};
    transition-property: color, opacity;
    
    &:hover {
      color: ${color.primary};
    }
    
    &:active {
      color: ${color.primary.darken(0.1)};
    }
    
    &[disabled] {
      cursor: not-allowed;
      opacity: .5;
      color: #c1c1c1;
      border-color: ${color.black.rgba(0.08)};
    }
  }
  
  .ui-pagination-btn-group {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  
  .ui-pagination-prev,
  .ui-pagination-next {
    ${resetButton};
    flex: 0 0 auto;
    display: block;
    box-sizing: border-box;
    position: relative;
    width: 30px;
    height: 30px;
    border: 1px solid ${color.black.mix('#fff', 0.08)};
    color: #c1c1c1;
    transition: ${transition.fast};
    transition-property: border-color, color, opacity;
    z-index: 0;
    
    &:hover {
      color: ${color.primary};
      border-color: ${color.primary};
      z-index: 1;
    }
    
    &:active {
      color: ${color.primary.darken(0.1)};
      border-color: ${color.primary.darken(0.1)};
      z-index: 1;
    }
    
    &[disabled] {
      cursor: not-allowed;
      opacity: .5;
      color: #c1c1c1;
      border-color: ${color.black.rgba(0.08)};
    }
    
    .arrow-icon {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  .ui-pagination-prev {
    border-radius: 4px 0 0 4px;
    
    .arrow-icon {
      transform: rotate(90deg);
      margin-left: 3px;
    }
  }

  .ui-pagination-next {
    border-radius: 0 4px 4px 0;
    margin-left: -1px;
  
    .arrow-icon {
      transform: rotate(-90deg);
      margin-left: 4px;
    }
  }
`;

function InfinitePagination(props) {
  const { className, hasPrev, hasNext, disabled, onFirst, onPrev, onNext } = props;

  return (
    <UiInfinitePagination className={className}>
      <button className="ui-pagination-start" disabled={disabled || !hasPrev} onClick={onFirst}>
        В начало
      </button>

      <div className="ui-pagination-btn-group">
        <button className="ui-pagination-prev" disabled={disabled || !hasPrev} onClick={onPrev}>
          <ArrowIcon className="arrow-icon" />
        </button>
        <button className="ui-pagination-next" disabled={disabled || !hasNext} onClick={onNext}>
          <ArrowIcon className="arrow-icon" />
        </button>
      </div>
    </UiInfinitePagination>
  );
}

InfinitePagination.propTypes = {
  className: PropTypes.string,
  hasPrev: PropTypes.bool,
  hasNext: PropTypes.bool,
  disabled: PropTypes.bool,
  onFirst: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default memo(InfinitePagination);

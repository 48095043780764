import { rgba } from 'polished';
import React, { forwardRef, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import Loader from '../icons/Loader';
import { color, transition } from '../styles/mixins';

const UiButton = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  font-family: inherit;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
  outline: 0;
  box-shadow: none;
  margin: 0;
  transition: ${transition.fast};
  transition-property: color, background-color, border-color, box-shadow;
  -webkit-appearance: none;

  &:first-letter {
    text-transform: uppercase;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
    outline: 0;
  }

  &:focus {
    box-shadow: 0 4px 8px 0 ${rgba('#000', 0.1)};
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.size-s {
    height: auto;
    min-height: 30px;
    font-size: 12px;
    line-height: 1.17;
    padding: 0 10px;
    border-radius: 6px;

    &.has-icon {
      padding-left: 4px;

      &.empty {
        padding-right: 4px;
      }
    }
  }

  &.size-sm {
    height: auto;
    min-height: 32px;
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  &.size-m {
    height: auto;
    min-height: 40px;
    font-size: 16px;
    padding: 0 24px;
  }

  &.size-l {
    height: auto;
    min-height: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 14px 24px;
    width: 100%;
  }

  &.with-margin-top {
    margin-top: 32px;
  }

  &.design-primary {
    border-color: ${color.primary};
    background-color: ${color.primary};
    color: #fff;

    &:hover {
      background-color: ${color.primary.darken(0.1)};
    }

    &:active,
    &.active {
      background-color: ${color.primary.darken(0.2)};
    }

    &[disabled] {
      color: ${color.black.rgba(0.3)};
      background-color: ${color.black.rgba(0.08)};
      border-color: ${color.black.rgba(0)};
      box-shadow: none;
    }
  }

  &.design-secondary {
    border-color: ${color.black.rgba(0.3)};
    background-color: ${color.black.rgba(0)};
    color: ${color.black};

    &:hover {
      border-color: ${color.black.rgba(0.3)};
      background-color: ${color.black.rgba(0.05)};
    }

    &:active,
    &.active {
      border-color: ${color.black.rgba(0.3)};
      background-color: ${color.black.rgba(0.1)};
    }

    &[disabled] {
      color: ${color.black.rgba(0.3)};
      background-color: ${color.black.rgba(0)};
      border-color: ${color.black.rgba(0.3)};
      box-shadow: none;
    }
  }

  &.design-tertiary {
    border-color: #979797;
    background-color: ${color.black.rgba(0)};
    color: ${color.black};

    &:hover {
      //border-color: ${color.black.rgba(0.08)};
      border-color: #979797;
      background-color: ${color.black.rgba(0.05)};
    }

    &:active,
    &.active {
      border-color: ${color.black.rgba(0.08)};
      background-color: ${color.black.rgba(0.1)};
    }

    &[disabled] {
      color: ${color.black.rgba(0.3)};
      background-color: ${color.black.rgba(0)};
      border-color: ${color.black.rgba(0.08)};
      box-shadow: none;
    }
  }

  &.design-invisible {
    border-color: transparent;
    background-color: transparent;
    color: ${color.black.rgba(0.5)};

    &:hover {
      border-color: transparent;
      background-color: transparent;
    }

    &:focus {
      box-shadow: none;
    }

    &:active,
    &.active {
      border-color: transparent;
      background-color: transparent;
    }

    &[disabled] {
      color: ${color.black.rgba(0.3)};
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
  }

  &.design-black {
    border-color: ${color.black};
    background-color: ${color.black};
    color: #fff;

    &:hover {
      background-color: ${color.black.darken(0.1)};
    }

    &:active,
    &.active {
      background-color: ${color.black.darken(0.2)};
    }

    &[disabled] {
      color: ${color.black.rgba(0.3)};
      background-color: ${color.black.rgba(0.08)};
      border-color: ${color.black.rgba(0)};
      box-shadow: none;
    }
  }

  &.design-red {
    background: var(--ui-on-white-action-primary, #E60028);
    color: #fff;

    &:not(.loading) {
      &[disabled] {
        color: #fff;
        background-color: #FF99B2;
        box-shadow: none;

        .content {
          opacity: 0.4;
        }
      }
    }
  }

  &.design-grey {
    background: #E5E7EA;
    color: #000C1A;
    border: none;

    &:focus {
      box-shadow: none;
    }
    /* Остальные цвета для серых кнопок пока отсутствуют */
  }

  &.loading {
    .content {
      opacity: 0;
    }
  }

  .no-upper {
    text-transform: none;
  }


  .content {
    display: inline-flex;
    align-items: center;
    opacity: 1;
    transition: opacity ${transition.fast};
    color: inherit;
    //font-size: 16px;
    //font-weight: 500;
    //line-height: 20px;
  }

  .icon-wrap {
    margin-right: 5px;
    transition: opacity ${transition.fast};

    &.alone {
      margin-right: 0;
    }

    [disabled]& {
      opacity: .3;
    }
  }

  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    &.loader {
      &-enter {
        opacity: 0;
      }

      &-enter-active {
        opacity: 1;
        transition: opacity ${transition.fast};
      }

      &-exit {
        opacity: 1;
      }

      &-exit-active {
        opacity: 0;
        transition: opacity ${transition.fast};
      }
    }
  }
`;

const Button = forwardRef((props, ref) => {
  const {
    className,
    size,
    design,
    children,
    disabled,
    loading,
    icon,
    active,
    tag,
    loaderIcon,
    onClick,
    ...attributes
  } = props;
  const cls = classnames(className, 'ui-button', `size-${size}`, `design-${design}`, {
    'has-icon': !!icon,
    empty: !children,
    loading,
    active,
  });
  const getChildrenToDisplay = (children) => {
    if (!children || typeof children !== 'string') return children;

    return children[0].toUpperCase() + children.slice(1).toLowerCase();
  };

  const handleClick = useCallback((e) => {
    if (!loading && onClick) {
      onClick(e);
    }
  }, [onClick, loading]);

  const childrenToDisplay = getChildrenToDisplay(children);

  return (
    <UiButton
      {...attributes}
      as={tag}
      ref={ref}
      className={cls}
      disabled={disabled || loading}
      loaderIcon={loaderIcon}
      onClick={handleClick}
    >
      <span className="content">
        {icon && <span className={classnames('icon-wrap', { alone: !children })}>{icon}</span>}
        {childrenToDisplay}
      </span>
      <CSSTransition
        in={loading}
        timeout={150}
        classNames="loader"
        mountOnEnter
        unmountOnExit
      >
        <div className="loader-container">
          <Loader loaderIcon={loaderIcon} />
        </div>
      </CSSTransition>
    </UiButton>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['s', 'sm', 'm', 'l']),
  design: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'invisible', 'black', 'red', 'grey']).isRequired,
  type: PropTypes.oneOf(['submit', 'button']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  active: PropTypes.bool,
  tag: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  size: 'l',
  type: 'submit',
};

export default Button;

import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getChangeRateHistoryList = createAsyncAction(
  'RATE_HISTORY_GET_CHANGE_RATE_HISTORY_LIST_REQUEST',
  'RATE_HISTORY_GET_CHANGE_RATE_HISTORY_LIST_SUCCESS',
  'RATE_HISTORY_GET_CHANGE_RATE_HISTORY_LIST_FAIL',
)();

export function getChangeRateHistoryListAsync(params) {
  return (dispatch) => {
    dispatch(getChangeRateHistoryList.request());

    ApiClient.rateHistory.getChangeRateHistoryList(params)
      .then(({ data }) => dispatch(getChangeRateHistoryList.success(data, params)))
      .catch((error) => dispatch(getChangeRateHistoryList.failure(error)));
  };
}

export const getChangeRateConditionList = createAsyncAction(
  'RATE_HISTORY_GET_CHANGE_RATE_CONDITION_LIST_REQUEST',
  'RATE_HISTORY_GET_CHANGE_RATE_CONDITION_LIST_SUCCESS',
  'RATE_HISTORY_GET_CHANGE_RATE_CONDITION_LIST_FAIL',
)();

export function getChangeRateConditionListAsync(params) {
  return (dispatch) => {
    dispatch(getChangeRateConditionList.request());

    ApiClient.rateHistory.getChangeRateConditionList(params)
      .then(({ data }) => dispatch(getChangeRateConditionList.success(data, params)))
      .catch((error) => dispatch(getChangeRateConditionList.failure(error)));
  };
}

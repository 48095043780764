import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2 } from '../typography/typography';

const UiFormGroup = styled.div`
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }

  .group-content {
    margin-top: 30px;
  }
`;

function FormGroup({ title, className, children }) {
  return (
    <UiFormGroup className={className}>
      {title && <H2>{title}</H2>}
      <div className="group-content">
        {children}
      </div>
    </UiFormGroup>
  );
}

FormGroup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
};

export default FormGroup;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { getHeadCompanyAsync } from '../../../../actions/insurances-products';
import { Button, Input, Loader } from '../../../../ui-kit';
import BottomSheet from '../../../common/BottomSheet';
import { useAsyncStatus } from '../../../common/hooks';
import DomHolder from '../../../common/utils/DomHolder';

const getSnapPoints = ({ maxHeight }) => [maxHeight * 0.95, maxHeight * 0.95];

function ChooseHeadCompanyModal({ active, onClose, onChooseCompany }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const inputRef = useRef();
  const headCompanies = useSelector((state) => state.insurancesProducts.headCompanies);
  const data = Object.values(headCompanies);
  const { loading, error } = useAsyncStatus((state) => state.insurancesProducts.getHeadCompanyFetchStatus);

  const filteredList = useMemo(() => (
    search.length > 2 ? data.filter(({ name, otherCompany }) => (
      name.toLowerCase().includes(search.toLowerCase()) || !!otherCompany
    )) : data
  ), [data, search]);

  const handleSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  }, []);

  const onReload = useCallback(() => {
    dispatch(getHeadCompanyAsync());
  }, []);

  useEffect(() => {
    if (active) {
      if (!data.length) {
        dispatch(getHeadCompanyAsync());
      }
      setSearch('');
      inputRef.current?.focus();
    }
  }, [active]);

  return (
    <BottomSheet
      open={active}
      onDismiss={onClose}
      snapPoints={getSnapPoints}
    >
      <DomHolder>
        {active && (
          <div className="choose-head-company-modal-content">
            <div className="title">Страховая компания</div>
            <Input
              ref={inputRef}
              className="search-input"
              value={search}
              onChange={handleSearch}
              search
              design="v2"
            />

            {loading && <Loader className="loader" />}

            {error && (
              <div className="error-wrapper">
                <div className="error-title">Не удалось загрузить данные</div>
                <div className="error-description">
                  Попробуйте обновить страницу<br />или зайти позже
                </div>
                <Button
                  className="reload-button"
                  design="grey"
                  size="m"
                  onClick={onReload}
                  loading={loading}
                >
                  Попробовать снова
                </Button>
              </div>
            )}

            {filteredList.length === 1 && filteredList[0].otherCompany === 1 && (
              <p className="not-find-description">
                Страховая компания по&nbsp;запросу не&nbsp;найдена. Проверьте введённые данные или&nbsp;выберите вариант&nbsp;«Иное»
              </p>
            )}

            <div className={classnames('list-wrapper', { 'not-found': filteredList.length === 1 && filteredList[0].otherCompany === 1 })}>
              {filteredList.map(({ id, name }) => (
                <button key={`${name}-${id}`} className="list-item" onClick={() => onChooseCompany(id)}>
                  {name}
                </button>
              ))}
            </div>
          </div>
        )}
      </DomHolder>
    </BottomSheet>
  );
}

export default ChooseHeadCompanyModal;

import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showFailedToLoadData } from './app';

export const getDocTypes = createAsyncAction(
  'GET_DOC_TYPES_REQUEST',
  'GET_DOC_TYPES_SUCCESS',
  'GET_DOC_TYPES_FAIL',
)();

export const getDocAddressType = createAsyncAction(
  'GET_DOC_ADDRESS_TYPE_REQUEST',
  'GET_DOC_ADDRESS_TYPE_SUCCESS',
  'GET_DOC_ADDRESS_TYPE_FAIL',
)();

export function getDocTypesAsync(operatorId, contractNumber, hasDocAddressTypeError = false) {
  return (dispatch, getState) => {
    const { requestChannel } = getState().app;

    const handleGetDocAddressType = () => (ApiClient.orderDocs.getDocAddressType())
      .then(({ data: addressType }) => {
        dispatch(getDocAddressType.success(addressType));
      })
      .catch((error) => {
        dispatch(getDocAddressType.failure(error));
        dispatch(showFailedToLoadData({
          active: true,
          onRetry: () => dispatch(getDocTypesAsync(operatorId, contractNumber, true)),
          asyncStatusReducerName: 'fetchDocAddressTypeStatus',
          reducersNS: 'orderDocs',
          withPageTitle: true,
          title: 'Заказать документы',
        }));
      });

    const getDocWayOfGettingAnsAddressType = (data) => {
      const failedDocWayOfGetting = [];

      const arrayOfPromises = data.reduce((acc, { request_type_short_name: requestTypeShortName }) => {
        acc.push(ApiClient.orderDocs.getDocWayOfGetting(requestTypeShortName));
        return acc;
      }, []);

      return Promise.allSettled(arrayOfPromises)
        .then((wayData) => {
          let areAllSuccessfullyFetched = true;
          wayData.forEach(({ status, value: { data: item } = {} }, i) => {
            data[i].wayOfGetting = item;

            if (status === 'rejected') {
              failedDocWayOfGetting.push(data[i]);
              areAllSuccessfullyFetched = false;
            }
          });

          if (!areAllSuccessfullyFetched) {
            dispatch(showFailedToLoadData({
              active: true,
              onRetry: () => dispatch(getDocTypesAsync(operatorId, contractNumber)),
              asyncStatusReducerName: 'fetchStatus',
              reducersNS: 'orderDocs',
              withPageTitle: true,
              title: 'Заказать документы',
            }));
            return dispatch(getDocTypes.failure({ data, failedDocWayOfGetting }, { operatorId }));
          }

          dispatch(showFailedToLoadData({}));
          dispatch(getDocTypes.success(data, { operatorId }));

          return handleGetDocAddressType();
        })
        .catch((error) => {
          dispatch(getDocTypes.failure(error));
        });
    };

    dispatch(getDocAddressType.request(operatorId));

    if (hasDocAddressTypeError) {
      return handleGetDocAddressType();
    }
    const prevError = getState().orderDocs.fetchStatus.error;

    dispatch(getDocTypes.request(operatorId));

    if (prevError?.failedDocWayOfGetting?.length > 0) {
      return getDocWayOfGettingAnsAddressType(prevError.failedDocWayOfGetting);
    }
    return ApiClient.orderDocs.getDocTypes({ channel: requestChannel, contractNumber })
      .then(({ data }) => getDocWayOfGettingAnsAddressType(data))
      .catch((error) => {
        dispatch(getDocTypes.failure(error));
        dispatch(showFailedToLoadData({
          active: true,
          onRetry: () => dispatch(getDocTypesAsync(operatorId, contractNumber)),
          asyncStatusReducerName: 'fetchStatus',
          reducersNS: 'orderDocs',
          withPageTitle: true,
          title: 'Заказать документы',
        }));
      });
  };
}

export const sendDocRequest = createAsyncAction(
  'ORDER_DOCS_SEND_DOC_REQUEST_REQUEST',
  'ORDER_DOCS_SEND_DOC_REQUEST_SUCCESS',
  'ORDER_DOCS_SEND_DOC_REQUEST_FAIL',
)();

export function sendDocRequestAsync(data) {
  return (dispatch, getState) => {
    const { requestChannel } = getState().app;
    dispatch(sendDocRequest.request());

    ApiClient.orderDocs.sendDocRequest({ ...data, requestChannel })
      .then((response) => dispatch(sendDocRequest.success(response, data)))
      .catch((error) => dispatch(sendDocRequest.failure(error)));
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import RosbankLoader from '../../../ui-kit/icons/RosbankLoader';

function LoaderOrChildren(props) {
  const { loading, className, children } = props;

  if (loading) {
    return (
      <div className="full-height-loader">
        <RosbankLoader className={className} />
      </div>
    );
  }

  return children;
}

LoaderOrChildren.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default LoaderOrChildren;

import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import CollapsibleElement from '../../../common/CollapsibleElement';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import ServiceCard from './ServiceCard';
import useServices from './hooks/useServices';
import ChevronIcon from '../../../../assets/svg/chevron-right.svg';
import ActivationNotAvailableModal from './modals/ActivationNotAvailableModal';
import ServiceRefuseModal from './modals/ServiceRefuseModal';

function ServicesMainPage({ history, match }) {
  const { params: { creditId } } = match;
  const { connected, recommended, archived } = useServices();
  const [openedConnectedServices, setOpenedConnectedServices] = useState(true);
  const isIos = window.sessionStorage.getItem('isIos');
  const [modalDescription, setModalDescription] = useState('');
  const [serviceRefuseConfig, setServiceRefuseConfig] = useState(null);
  const [goToChatLoading, setGoToChatLoading] = useState(false);

  const onCloseModal = useCallback(() => {
    setModalDescription('');
  }, []);

  const onCloseRefuseModal = useCallback(() => {
    setServiceRefuseConfig('');
  }, []);

  const goToServiceClick = useCallback((key) => history.push(`${match.url}/${key}`), []);

  const goToService = (service) => {
    const {
      id, restrictLink, statusCode, restActivationCount, isActivationAvailable, activationAvailableMessage, type, key,
    } = service;

    if (id === 1000) {
      const link = document.createElement('a');
      link.href = restrictLink;
      if (!isIos) link.target = '_blank';
      return link.click();
    }
    if (statusCode === 'FUNCTIONAL' && restActivationCount > 0 && type === 'registred') {
      if (isActivationAvailable) {
        return history.push(`${match.url}/${key}/activation`);
      }
      return setModalDescription(activationAvailableMessage);
    }
    return history.push(`${match.url}/${key}`);
  };

  const handleRefuseClick = (service) => {
    const { statusCode, type, isAccountClosed, cancelRefundAmount, isCancelAvailable } = service;
    const canBeRefuse = (type === 'registred' && (statusCode === 'FUNCTIONAL' || statusCode === 'PAID')) || type === 'prolongation';

    if ((!isAccountClosed || (isAccountClosed && !cancelRefundAmount)) && canBeRefuse) setServiceRefuseConfig(service);
    if (isCancelAvailable && isAccountClosed && cancelRefundAmount > 0 && canBeRefuse) history.push(`${match.url}/${service.key}/refuse`);
  };

  const handleToggleConnectedServices = () => {
    setOpenedConnectedServices((prev) => !prev);
  };

  const goToArchived = () => {
    history.push(`${match.url}/archived`);
  };

  useEffect(() => {
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
  }, []);

  return (
    <LoaderOrChildren loading={goToChatLoading}>
      <div className="page-wrapper">
        {!!connected.length && (
          <div className="service-group _connected">
            <button
              className={classnames('list-title-button', { _opened: openedConnectedServices })}
              onClick={handleToggleConnectedServices}
            >
              <span className="list-title">Подключенные</span>
              <ChevronIcon className="chevron-icon" />
            </button>
            <CollapsibleElement active={openedConnectedServices}>
              <div className="service-group-list">
                {connected.map((service) => (
                  <ServiceCard
                    onClick={() => goToService(service)}
                    key={service.key}
                    service={service}
                    canBeDeleted
                    isRefuseModalActive={!!serviceRefuseConfig}
                    onRefuseClick={() => handleRefuseClick(service)}
                  />
                ))}
              </div>
            </CollapsibleElement>
          </div>
        )}
        {!!recommended.length && (
          <div className="service-group _recommended">
            <span className="list-title">Предложения для вас</span>

            <div className="service-group-list">
              {recommended.map((service) => (
                <ServiceCard onClick={() => goToService(service)} key={service.key} service={service} design="transparent" />
              ))}
            </div>
          </div>
        )}
        {!!archived.length && (
          <button className="archived-services-button" onClick={goToArchived}>
            Архив услуг и опций
            <div className="archived-services-right-content">
              <span className="archived-services-counter">{archived.length}</span>
              <ChevronIcon className="chevron-icon" />
            </div>
          </button>
        )}
      </div>

      <ActivationNotAvailableModal
        modalDescription={modalDescription}
        onClose={onCloseModal}
      />

      <ServiceRefuseModal
        creditId={creditId}
        serviceRefuseConfig={serviceRefuseConfig}
        onClose={onCloseRefuseModal}
        goToServiceClick={goToServiceClick}
        setGoToChatLoading={setGoToChatLoading}
      />
    </LoaderOrChildren>
  );
}

export default ServicesMainPage;

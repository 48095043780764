import React from 'react';
import BottomSheet from '../../../../common/BottomSheet';
import { Button } from '../../../../../ui-kit';
import DomHolder from '../../../../common/utils/DomHolder';

const ActivationNotAvailableModal = ({ modalDescription, onClose }) => (
  <BottomSheet
    open={!!modalDescription}
    onDismiss={onClose}
  >
    <DomHolder>
      {!!modalDescription && (
        <div className="activation-not-available-modal">
          <div className="modal-content">
            <h1 className="title">Активация недоступна</h1>
            <p className="description">{modalDescription}</p>
          </div>

          <Button
            className="close-button"
            design="grey"
            size="l"
            onClick={onClose}
          >
            Понятно
          </Button>
        </div>
      )}
    </DomHolder>
  </BottomSheet>
);

export default ActivationNotAvailableModal;

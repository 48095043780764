import { useField } from 'formik';
import React, { forwardRef, memo, useCallback } from 'react';
import WheelDatePicker from '../../../../ui-kit/date-picker/WheelDatePicker';

const WheelDatePickerField = forwardRef(({ hasError, ...props }, ref) => {
  const [field, meta] = useField(props.name);
  const handleChange = useCallback((eventOrValue) => {
    const event = typeof eventOrValue === 'object' ? eventOrValue : {
      target: {
        name: props.name,
        value: eventOrValue,
      },
    };
    field.onChange(event);
    if (props.onChange) {
      props.onChange(event);
    }
  }, [field.onChange, props.onChange]);

  return (
    <WheelDatePicker
      {...field}
      state={(meta.error && meta.touched) || hasError ? 'invalid' : undefined}
      {...props}
      onChange={handleChange}
      ref={ref}
    />
  );
});

export default memo(WheelDatePickerField);

import React, { PureComponent, useEffect } from 'react';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { transition } from '../styles/mixins';
import Alert from './Alert';

const UiAlertManager = styled.div`
  // TODO: alert position
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 1000;

  .alert-list {
    width: 360px;
  }

  .alert-container {
    margin-top: 20px;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }

    &.transition {
      &-enter {
        opacity: 0;
      }
      &-enter-active {
        opacity: 1;
        transition: opacity ${transition.normal};
      }
      &-exit {
        opacity: 1;
      }
      &-exit-active {
        opacity: 0;
        transition: opacity ${transition.normal};
      }
    }
  }
`;

class AlertManager extends PureComponent {
  static propTypes = {
    alerts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.oneOf(['error', 'success']).isRequired,
        message: PropTypes.node.isRequired,
        timeout: PropTypes.number,
      }).isRequired,
    ).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  handleClose(alert) {
    const { alerts, onClose } = this.props;

    if (!alerts.some((item) => item.id === alert.id)) {
      return;
    }

    onClose(alert);
  }

  renderItem(alert) {
    const { id, timeout, type, message } = alert;
    const handleClose = () => this.handleClose(alert);

    return (
      <CSSTransition key={id} timeout={300} classNames="transition">
        <TimeoutContainer timeout={timeout} onTimeout={handleClose}>
          <div className="alert-container">
            <Alert type={type} message={message} onClose={handleClose} />
          </div>
        </TimeoutContainer>
      </CSSTransition>
    );
  }

  render() {
    const { alerts } = this.props;
    const items = alerts.map((item) => this.renderItem(item));

    return (
      <UiAlertManager>
        <TransitionGroup className="alert-list">
          {items}
        </TransitionGroup>
      </UiAlertManager>
    );
  }
}

function TimeoutContainer(props) {
  const { timeout, onTimeout, children } = props;
  useEffect(
    () => {
      const timeoutId = timeout ?
        setTimeout(onTimeout, timeout) :
        undefined;

      return () => clearTimeout(timeoutId);
    },
    [],
  );

  return children;
}

TimeoutContainer.propTypes = {
  timeout: PropTypes.number,
  onTimeout: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default AlertManager;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { color, resetButton } from '../styles/mixins';
import CloseIcon from './assets/circle-close.svg';

const tagSizeStyle = {
  s: css`
    height: 20px;
    font-size: 12px;
    line-height: 16px;
  `,
  m: css`
    height: 30px;
    font-size: 14px;
    line-height: 18px;
  `,
  l: css`
    height: 40px;
    font-size: 14px;
    line-height: 18px;
  `,
};

const UiTag = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${color.success};
  padding: 0 5px;
  box-sizing: border-box;

  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  
  ${(p) => tagSizeStyle[p.size]}

  .ui-tag-btn {
    ${resetButton};
    color: inherit;
    margin-left: 5px;
  
    > .ui-tag-icon {
      display: block;
    }
  }
`;

const List = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -3px;

  > ${UiTag} {
    margin: 3px;
  }
`;

List.propTypes = {
  children: PropTypes.node,
};

const Tag = forwardRef((props, ref) => {
  const { className, size, children, onRemove } = props;

  return (
    <UiTag ref={ref} className={className} size={size}>
      {children}
      {onRemove && (
        <button type="button" className="ui-tag-btn" onClick={onRemove}>
          <CloseIcon className="ui-tag-icon" />
        </button>
      )}
    </UiTag>
  );
});

Tag.displayName = 'Tag';

Tag.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  children: PropTypes.node,
  onRemove: PropTypes.func,
};

Tag.defaultProps = {
  size: 'm',
};

Tag.List = List;

export default Tag;

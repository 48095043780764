import { useField } from 'formik';
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import FileInput from '../../../../ui-kit/file-input/FileInput';

function FileInputField(props) {
  const [field, meta, helpers] = useField(props.name);
  const handleChange = useCallback((value) => {
    helpers.setTouched(true);
    field.onChange({
      target: {
        name: props.name,
        value,
      },
    });
  }, []);

  return (
    <FileInput
      {...field}
      {...props}
      state={meta.error && meta.touched ? 'invalid' : undefined}
      onChange={handleChange}
    />
  );
}

FileInputField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(FileInputField);

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const sortByServiceOrder = (a, b) => +(a.serviceOrder ?? 0) - +(b.serviceOrder ?? 0);
const sortByProlongationType = (a, b) => {
  if (a.type === 'prolongation' && b.type !== 'prolongation') return -1;
  if (a.type === 'prolongation' && b.type === 'prolongation') return 0;
  if (a.type !== 'prolongation' && b.type === 'prolongation') return 1;
  return 0;
};

export default function useServices() {
  const servicesByKey = useSelector((state) => state.services.byKey);

  return useMemo(() => {
    const services = Object.values(servicesByKey);
    const sortedServices = services.sort(sortByServiceOrder).sort(sortByProlongationType);
    return sortedServices.reduce((acc, service) => {
      if (service.name && service.description) {
        if (service.type === 'prolongation') {
          acc.connected.push(service);
        }
        if (service.type === 'registred') {
          if (['FINISHED', 'COMPLETED', 'CANCELED'].includes(service.statusCode)) {
            acc.archived.push(service);
          } else {
            acc.connected.push(service);
          }
        }
        if (service.type === 'available') {
          acc.recommended.push(service);
        }
      }

      return acc;
    }, { connected: [], recommended: [], archived: [] });
  }, [servicesByKey]);
}

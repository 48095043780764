import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const UiTab = styled.div`
  margin-top: 8px;
`;

function Tab(props) {
  const { children, className } = props;

  if (!children) {
    return null;
  }

  return (
    <UiTab className={className}>
      {children}
    </UiTab>
  );
}

Tab.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  tabBtnClassName: PropTypes.string,
};

export default Tab;

import React, { memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Button } from '../../ui-kit';
import postToNativeApp from '../../utils/postToNativeApp';
import UfoIcon from '../../assets/svg/ufo.svg';
import { useAsyncStatus } from './hooks';

const StyledRoot = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1E2530;
  padding: 56px 16px 24px;
  z-index: 5;
`;

const StyledHeader = styled.h1`
  font-size: 20px;
  line-height: 24px;
  margin-top: 33px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
  color: #FFF;
`;

const StyledParagraph = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  color: #FFF;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: auto;
  text-transform: none;

  &:first-of-type {
    margin-bottom: 12px;
  }

  &.design-grey {
    background: #E5E7EA;
    .content {
      color: #000C1A;
    }
  }

  &:disabled span {
    opacity: 0.6;
  }
`;

const StyledText = styled.p`
  margin: 12px 0;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: #647081;
`;

const StyledLink = styled.a`
  color: #0072FF;
`;

const white = '#FFF';

const FatalErrorScreen = ({ onReload, creditId, loading }) => {
  const history = useHistory();
  const { loading: servicesLoadingStatus } = useAsyncStatus((state) => state.financialServices.servicesFinProtect);
  const { loading: servicesRefuseStatus } = useAsyncStatus((state) => state.financialServices.servicesFinProtectRefuseFetchStatus);
  const { loading: activationLoadingStatus } = useAsyncStatus((state) => state.financialServices.servicesFinProtectActivationFetchStatus);
  const { loading: prepaymentLoadingStatus } = useAsyncStatus((state) => state.financialServices.prePaymentScheduleFetchStatus);
  const { loading: getEarlyRepaySumByDateStatus } = useAsyncStatus((state) => state.credits.getEarlyRepaySumByDateStatus);
  const { loading: getEarlyPartialRepaymentCalcStatus } = useAsyncStatus((state) => state.earlyPayment.getEarlyPartialRepaymentCalcStatus);
  const { loading: getFerAvailabilityStatus } = useAsyncStatus((state) => state.earlyPayment.getFerAvailabilityStatus);
  const { loading: getCheckFactorStatus } = useAsyncStatus((state) => state.financialServices.getCheckFactorStatus);
  const { loading: getNotSignedSupAgreementLoading } = useAsyncStatus((state) => state.financialServices.getNotSignedSupAgreementStatus);

  const buttonLoading = loading || servicesLoadingStatus || activationLoadingStatus || servicesRefuseStatus ||
    prepaymentLoadingStatus || getEarlyRepaySumByDateStatus || getEarlyPartialRepaymentCalcStatus ||
    getFerAvailabilityStatus || getCheckFactorStatus || getNotSignedSupAgreementLoading;

  const clickBackButtonHandler = () => {
    postToNativeApp({ type: 'close' });
    history.push(`/credits/${creditId}`);
  };

  const handleReload = useCallback(() => {
    onReload({});
  }, []);

  useEffect(() => {
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
  }, []);

  return (
    <StyledRoot>
      <UfoIcon />
      <StyledHeader>Что-то пошло не так</StyledHeader>
      <StyledParagraph>При загрузке данных произошла ошибка. Попробуйте выполнить операцию ещё раз</StyledParagraph>
      <StyledButtonsContainer>
        <StyledButton
          design="red"
          size="l"
          loading={buttonLoading}
          onClick={handleReload}
        >
          <span>Попробовать ещё раз</span>
        </StyledButton>
        <StyledButton
          design="grey"
          size="l"
          onClick={clickBackButtonHandler}
          style={{ color: `${white}` }}
          disabled={buttonLoading}
        >
          <span>Вернуться к кредиту</span>
        </StyledButton>
      </StyledButtonsContainer>
      <StyledText>Если проблема не решается, пожалуйста,<br />свяжитесь с нашей <StyledLink href="#">технической поддержкой</StyledLink></StyledText>
    </StyledRoot>
  );
};

export default memo(FatalErrorScreen);

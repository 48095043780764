import React, { cloneElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CollapsePanel from './CollapsePanel';

const UiCollapse = styled.div``;

function Collapse(props) {
  const { className, activePanels, children, onChange } = props;
  const onClick = useCallback(
    (id) => {
      if (activePanels.includes(id)) {
        onChange(activePanels.filter((activeId) => activeId !== id));
      } else {
        onChange([...activePanels, id]);
      }
    },
    [activePanels, onChange],
  );
  const panels = React.Children.map(children, (panel) => {
    const active = activePanels.includes(panel.props.id);

    return cloneElement(panel, { active, onClick });
  });

  return (
    <UiCollapse className={className}>
      {panels}
    </UiCollapse>
  );
}

Collapse.Panel = CollapsePanel;

Collapse.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  activePanels: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Collapse;

import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { creditsSelector } from '../../../selectors/credits';
import { CREDIT_CLOSED_FLAG, CREDIT_ACTIVE_FLAG } from '../../../utils/constants';
import Tabs from '../../../ui-kit/tabs/Tabs';
import Tab from '../../../ui-kit/tabs/Tab';
import { sendLogs } from '../../../utils/sendLogs';
import PageTitle from '../../common/PageTitle';
import CreditListItem from '../../../ui-kit/credit-list-item/CreditListItem';

const renderCredits = (credits, flag) => credits
  .filter((cred) => cred.is_closed === flag || (flag === CREDIT_CLOSED_FLAG && cred.is_assignment === true))
  .sort((a, b) => {
    if (flag === CREDIT_ACTIVE_FLAG) {
      const { contract_type_code: prevType, additionalInfo: { [prevType === 'C' ? 'total_debt' : 'debt_outstanding_sum']: prevDebtOutstandingSum } } = a;
      const { contract_type_code: nextType, additionalInfo: { [nextType === 'C' ? 'total_debt' : 'debt_outstanding_sum']: nextDebtOutstandingSum } } = b;
      return prevDebtOutstandingSum > nextDebtOutstandingSum ? -1 : prevDebtOutstandingSum < nextDebtOutstandingSum ? 1 : 0;
    }
    if (flag === CREDIT_CLOSED_FLAG) {
      // contract_date | credit_date
      const { contract_type_code: prevType, additionalInfo: { [prevType === 'C' ? 'contract_date' : 'credit_date']: prevCreditDate } } = a;
      const { contract_type_code: nextType, additionalInfo: { [nextType === 'C' ? 'contract_date' : 'credit_date']: nextCreditDate } } = b;

      const prevNumberDate = moment(prevCreditDate, 'DD.MM.YYYY').valueOf();
      const nextNumberDate = moment(nextCreditDate, 'DD.MM.YYYY').valueOf();

      return nextNumberDate - prevNumberDate;
    }
    return 0;
  })
  .map((credit) => (
    <CreditListItem
      key={credit.contract_number}
      credit={credit}
      showDebt
    />
  ));

const CreditList = (props) => {
  const { match, history } = props;
  const credits = useSelector(creditsSelector);
  const changeActiveCreditType = useCallback((creditType) => history.push(`/credits/${creditType}`), []);
  const zeroActiveCredits = useMemo(() => credits.every((credit) => credit.is_closed === CREDIT_CLOSED_FLAG), [credits]);
  const zeroClosedCredits = useMemo(() => credits.every((credit) => credit.is_closed === CREDIT_ACTIVE_FLAG), [credits]);

  const closeWindow = () => window.close();

  useEffect(() => {
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
  }, []);

  return (
    <div className="page-credit-list">
      <PageTitle title="Мои кредиты" onBackClick={closeWindow} />
      <Tabs tab={match.params.status} onChange={changeActiveCreditType} className="tab-switch">
        <Tab id="active" disabled={zeroActiveCredits} title="Открытые кредиты">
          {renderCredits(credits, CREDIT_ACTIVE_FLAG)}
        </Tab>
        <Tab id="closed" disabled={zeroClosedCredits} title="Закрытые кредиты">
          {renderCredits(credits, CREDIT_CLOSED_FLAG)}
        </Tab>
      </Tabs>
    </div>
  );
};

export default memo(CreditList);

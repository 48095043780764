import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, resetButton, transition } from '../styles/mixins';
import Page from './Page';
import ArrowIcon from './assets/sort-arrow.svg';

const UiPagination = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;

  .ui-pagination-prev,
  .ui-pagination-next {
    ${resetButton};
    flex: 0 0 auto;
    display: block;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid ${color.black.rgba(0.08)};
    color: #c1c1c1;
    transition: ${transition.fast};
    transition-property: border-color, color;
    
    &:hover {
      color: ${color.primary};
      border-color: ${color.primary};
    }
    
    &:active {
      color: ${color.primary.darken(0.1)};
      border-color: ${color.primary.darken(0.1)};
    }
    
    .arrow-icon {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  .ui-pagination-prev {
    margin-right: 7px;
    
    .arrow-icon {
      transform: rotate(90deg);
      margin-left: 3px;
    }
  }

  .ui-pagination-next {
    margin-left: 7px;
    
    .arrow-icon {
      transform: rotate(-90deg);
      margin-left: 4px;
    }
  }

  .ui-pagination-gap {
    box-sizing: border-box;
    flex: 0 0 auto;
    height: 30px;
    width: 20px;
    padding-top: 6px;
    font-family: inherit;
    font-size: 16px;
    text-align: center;
    color: ${color.black.rgba(0.5)};

    &:after {
      content: '...';
      display: inline;
    }
  }

  .ui-pagination-last {
    ${resetButton};
    margin-left: 14px;
    font-family: inherit;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${color.black.rgba(0.5)};
    transition: ${transition.fast};
    transition-property: color;
    
    &:hover {
      color: ${color.primary};
    }
    
    &:active {
      color: ${color.primary.darken(0.1)};
    }
  }
`;

class Pagination extends PureComponent {
  handlePrev = () => {
    const { page, onPageChange } = this.props;

    if (page > 0) {
      onPageChange(page - 1);
    }
  };

  handleNext = () => {
    const { page, pageCount, onPageChange } = this.props;

    if (page < pageCount - 1) {
      onPageChange(page + 1);
    }
  };

  handleLast = () => {
    const { page, pageCount, onPageChange } = this.props;

    if (page < pageCount - 1) {
      onPageChange(pageCount - 1);
    }
  };

  handlePageChange = (page) => {
    const { onPageChange } = this.props;

    onPageChange(page);
  };

  renderPages() {
    const { page, pageCount } = this.props;
    const pages = [];

    if (page < 9) {
      for (let i = 0; i < pageCount && i < 10; i++) {
        pages.push(<Page key={i} page={i} active={i === page} onClick={this.handlePageChange} />);
      }
    } else {
      const last = Math.min(pageCount - 1, page + 5);
      const first = Math.min(last - 6, page - 1);

      pages.push(<Page key={0} page={0} active={false} onClick={this.handlePageChange} />);
      pages.push(<div key="gap" className="ui-pagination-gap" />);

      for (let i = first; i <= last; i++) {
        pages.push(<Page key={i} page={i} active={i === page} onClick={this.handlePageChange} />);
      }
    }

    return pages;
  }

  render() {
    const { className, pageCount } = this.props;

    return (
      <UiPagination className={className}>
        <button className="ui-pagination-prev" onClick={this.handlePrev}>
          <ArrowIcon className="arrow-icon" />
        </button>
        {this.renderPages()}
        <button className="ui-pagination-next" onClick={this.handleNext}>
          <ArrowIcon className="arrow-icon" />
        </button>
        {pageCount > 10 && (
          <button className="ui-pagination-last" onClick={this.handleLast}>
            Последняя
          </button>
        )}
      </UiPagination>
    );
  }
}

Pagination.propTypes = {
  className: PropTypes.string,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  page: 1,
  pageCount: 1,
};

export default Pagination;

import React from 'react';
import styled from 'styled-components';
import BottomSheet from '../../../common/BottomSheet';

const Content = styled.div`
  margin: 0 16px ${(p) => (p.isPledge ? 70 : 24)}px;

  .modal-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #000C1A;
    margin-bottom: 24px;
    margin-top: 12px;
  }

  .description {
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;
    margin-bottom: 12px;

    &.list-item {
      margin-bottom: 8px;

      .name {
        font-weight: 700;
      }
    }
  }
`;

const configByType = {
  pledgee: {
    title: 'Залогодержатель',
    description: 'Юридическое лицо, принявшее имущество (транспортное средство) в&nbsp;залог.',
    list: [
      { name: 'ПАО РОСБАНК', description: 'если Залогодержателем в&nbsp;полисе указан ПАО РОСБАНК' },
      { name: 'Иное', description: 'если Залогодержатель в&nbsp;полисе не&nbsp;указан или указан не&nbsp;ПАО&nbsp;РОСБАНК' },
    ],
  },
  beneficiary: {
    title: 'Выгодоприобретатель',
    description: 'Физическое или юридическое лицо, которое по&nbsp;договору страхования получит страховую выплату. ',
    list: [
      { name: 'ПАО РОСБАНК', description: 'если Выгодоприобретателем в&nbsp;полисе указан ПАО&nbsp;РОСБАНК по&nbsp;всем страховым рискам или&nbsp;указаны ПАО&nbsp;РОСБАНК и&nbsp;Клиент (каждый в&nbsp;своей части)' },
      { name: 'Клиент', description: 'если Выгодоприобретателем в&nbsp;полисе указан Клиент по&nbsp;всем страховым рискам' },
      { name: 'Иное', description: 'если Выгодоприобретатель в&nbsp;полисе не&nbsp;указан или&nbsp;указано иное лицо, кроме Клиента и&nbsp;ПАО&nbsp;РОСБАНК' },
    ],
  },
};

function InfoModal({ onClose, type }) {
  const config = configByType[type] ?? {};

  return (
    <BottomSheet
      open={!!type}
      onDismiss={onClose}
    >
      <Content isPledge={type === 'pledgee'}>
        <h2 className="modal-title">{config.title}</h2>
        <p className="description" dangerouslySetInnerHTML={{ __html: config.description }} />
        <p className="description">Выберите из предложенных вариантов:</p>

        {config.list?.map(({ name, description }) => (
          <p key={name + description} className="description list-item">
            <span className="name">{name}</span> - <span dangerouslySetInnerHTML={{ __html: description }} />
          </p>
        ))}
      </Content>
    </BottomSheet>
  );
}

export default InfoModal;

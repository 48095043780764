import classnames from 'classnames';
import { ellipsis } from 'polished';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import memoizeOne from 'memoize-one';
import OnClickOutside from '../click-outside/OnClickOutside';
import withFormControl from '../form-control/withFormControl';
import { DirectedArrow } from '../icons';
import AutoScroller from '../scrollbar/AutoScroller';
import Scrollbar from '../scrollbar/Scrollbar';
import { color, resetInput, transition } from '../styles/mixins';
import SearchIcon from './assets/search.svg';
import SelectTagInput from './SelectTagInput';

const UiSelect = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  outline: 0;
  -webkit-appearance: none;

  // overlap workaround ¯\\_(ツ)_/¯
  &.ui-select {
    &-exit,
    &-exit-active {
      z-index: 3!important;
    }
  }

  &.opened {
    z-index: 4;
  }

  > .ui-select-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 0;
    padding-top: 48px;
    border-radius: 8px;
    box-shadow: 0 16px 32px 0 ${color.black.rgba(0.09)};
    z-index: 1;

    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
      transition: opacity ${transition.fast};
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transition: opacity ${transition.fast};
    }

    .size-s& {
      //padding-top: 30px;
      border-radius: 6px;
    }

    .size-m& {
      //padding-top: 40px;
      border-radius: 8px;
    }

    .size-l& {
      //padding-top: 50px;
      border-radius: 10px;
    }

    .multiple& {
      padding-top: 0;
    }

    .ui-select-options {
      padding: 16px 4px;
      // border-top: 1px solid ${color.black.rgba(0.08)};
    }

    .ui-select-options-scroll {
      max-height: 160px;

      .simplebar-content-wrapper {
        overscroll-behavior: contain;
      }
    }

    .ui-select-options-empty {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 20px 0;
    }

    .ui-select-options-empty-label {
      font-family: inherit;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
      color: ${color.black};
      margin-top: 11px;
    }

    .ui-select-option {
      font-family: inherit;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      color: ${color.black};
      padding: 7px 11px;
      border-radius: 6px;
      cursor: pointer;
      margin-top: 4px;

      &:first-child {
        margin-top: 0;
      }

      &:hover,
      &.focused {
        background-color: ${color.black.rgba(0.05)};
      }

      //.size-s& {
      //  font-size: 12px;
      //  font-weight: 400;
      //  line-height: 1.17;
      //}
      //
      //.size-m& {
      //  font-size: 16px;
      //  font-weight: 400;
      //  line-height: normal;
      //}
      //
      //.size-l& {
      //  font-size: 18px;
      //  font-weight: 400;
      //  line-height: normal;
      //}
    }
  }

  > .ui-select-container {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 48px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    box-shadow: none;
    -webkit-appearance: none;
    outline: 0;
    margin: 0;
    border: 1px solid #DADADA;
    border-radius: 8px;
    // border-radius: 4px;
    // border: 1px solid ${color.black.rgba(0.08)};
    background: #fff;
    padding-left: 16px;
    padding-right: 40px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: ${transition.fast};
    transition-property: background-color, border-color, color;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
      outline: 0;
    }

    &:hover {
      border-color: ${color.black.rgba(0.5)};
    }

    .disabled& {
      color: ${color.black.rgba(0.5)};
      border-color: ${color.black.rgba(0.08)};
      background-color: ${color.black.rgba(0.05)};
      cursor: not-allowed;
    }

    //.size-s& {
    //  height: 30px;
    //  font-size: 12px;
    //  font-weight: 400;
    //  line-height: 1.17;
    //}
    //
    //.size-m& {
    //  height: 48px;
    //  font-size: 16px;
    //  font-weight: 400;
    //  line-height: normal;
    //}
    //
    //.size-l& {
    //  height: 50px;
    //  font-size: 18px;
    //  font-weight: 400;
    //  line-height: normal;
    //}

    .multiple& {
      height: auto;
      padding-left: 5px;
    }

    .state-valid& {
      // TODO: input valid
    }

    .state-invalid& {
      color: ${color.error};
      //border-color: ${color.error};
    }

    .ui-select-arrow {
      position: absolute;
      top: calc(50% - 4px);
      right: 20px;
    }

    .ui-select-placeholder {
      ${ellipsis('100%')};
      font-family: inherit;
      font-size: 14px;
      line-height: 16px;
      color: #A6AAB1;
    }

    .ui-select-label {
      ${ellipsis('100%')};
    }

    .ui-select-search-input {
      ${resetInput};
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 10px 0;
      font-family: inherit;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${color.black};

      &::placeholder {
        font-family: inherit;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #A6AAB1;
      }
    }
  }

  &:focus > .ui-select-container {
    border-color: ${color.black};
  }

  &.opened > .ui-select-container {
    border-color: ${color.black.rgba(0)};
  }
`;

class Select extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['s', 'm', 'l']),
    state: PropTypes.oneOf(['default', 'valid', 'invalid']),
    placeholder: PropTypes.node,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.node.isRequired,
      }).isRequired,
    ),
    value: PropTypes.any,
    multiple: PropTypes.bool,
    renderValue: PropTypes.func,
    emptyPlaceholder: PropTypes.string,
    search: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    options: [],
    size: 'm',
    state: 'default',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { options, multiple, search } = nextProps;
    const { inputValue } = prevState;

    const state = {
      options,
    };

    if (multiple || search) {
      const str = inputValue.trim().toLowerCase();

      if (str) {
        state.options = options.filter((option) => (option.search ?? option.label).toLowerCase().includes(str));
      }
    }

    return state;
  }

  input = React.createRef();
  tagInput = React.createRef();
  dropdown = React.createRef();
  tagsSelector = memoizeOne((options, value) => {
    const map = options.reduce((map, option) => map.set(option.value, option), new Map());
    return value.map((value) => map.get(value));
  });

  state = {
    options: [],
    inputValue: '',
    inputFocused: false,
    opened: false,
    focusedItem: -1,
  };

  componentDidMount() {
    const { options, value } = this.props;

    if (value && !options.some((item) => item.value === value)) {
      this.handleChange('');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { multiple, value, options } = this.props;
    const { inputValue, opened } = this.state;

    if (multiple && opened && (opened !== prevState.opened || inputValue !== prevState.inputValue || value !== prevProps.value)) {
      const height = this.tagInput.current.clientHeight;

      this.dropdown.current.style.paddingTop = `${height - 2}px`; // 2px borders
    }

    if (options !== prevProps.options || value !== prevProps.value) {
      if (value && !options.some((item) => item.value === value)) {
        this.handleChange('');
      }
    }
  }

  getTags = () => this.tagsSelector(this.props.options, this.props.value);

  getInput = () => {
    const { search, multiple } = this.props;

    if (search) {
      return this.input.current;
    }

    if (multiple) {
      return this.tagInput.current?.getElementsByTagName('input').item(0);
    }

    return null;
  };

  setOpened = (opened) => {
    const { disabled, value } = this.props;
    const { options, opened: currentOpened } = this.state;

    if (disabled) {
      return;
    }

    if (opened === currentOpened) {
      return;
    }

    let focusedItem = -1;

    if (opened) {
      focusedItem = options.findIndex((item) => item.value === value);
    }

    this.setState({ opened, focusedItem });

    if (opened) {
      this.handleFocus();
    } else {
      this.handleBlur();
    }
  };

  handleTagRemove = (tag) => {
    const { value: currentValue } = this.props;
    const newValue = currentValue.filter((value) => value !== tag.value);

    this.handleChange(newValue);
  };

  handleOptionSelect = (value) => {
    const { multiple, search, options, value: currentValue } = this.props;

    if (multiple) {
      if (currentValue.includes(value)) {
        return;
      }

      value = [...currentValue, value]; // eslint-disable-line no-param-reassign
    }

    this.handleChange(value);

    if (!multiple || document.activeElement !== this.getInput()) {
      this.setState({ opened: false });
    }

    if (search) {
      const option = options.find((item) => item.value === value);

      if (option) {
        this.setState({ inputValue: option.label });
      }
    }
  };

  handleChange = (value) => {
    const { name, onChange } = this.props;

    if (onChange) {
      onChange({
        target: { name, value },
      }, value);
    }
  };

  handleFocus = () => {
    const { name, onFocus } = this.props;

    if (onFocus) {
      onFocus({
        target: { name },
      });
    }
  };

  handleBlur = () => {
    const { name, onBlur } = this.props;

    if (onBlur) {
      onBlur({
        target: { name },
      });
    }
  };

  handleSelectBlur = () => {
    const { search, multiple } = this.props;
    const { opened } = this.state;

    if (!search && !multiple && opened) {
      this.setOpened(false);
    }
  };

  handleToggle = () => {
    const { search, multiple } = this.props;
    const { opened } = this.state;

    if ((search || multiple) && !opened) {
      this.getInput().focus();
    } else {
      this.setOpened(!opened);
    }
  };

  handleClickOutside = () => this.setOpened(false);

  handleKeyDown = (e) => {
    const { multiple, search } = this.props;
    const { options, opened, focusedItem } = this.state;

    // multiple opened selects bug workaround
    if (e.key === 'Tab' && opened) {
      this.setOpened(false);
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();

      this.setState({ focusedItem: Math.max(focusedItem - 1, 0), opened: true });
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();

      this.setState({ focusedItem: Math.min(focusedItem + 1, options.length - 1), opened: true });
    }

    if (e.key === 'Enter' || (e.key === ' ' && !multiple && !search)) {
      e.preventDefault();

      if (opened) {
        if (focusedItem !== -1) {
          this.handleOptionSelect(options[focusedItem].value);
        }
      } else {
        this.handleToggle();
      }
    }
  };

  handleInputKeyDown = (e) => {
    const { focusedItem } = this.state;

    if (e.key === 'Enter' && focusedItem !== -1) {
      this.setState({ inputValue: '' });
    }
  };

  handleInputChange = (e) => this.setState({
    inputValue: e.target.value,
    focusedItem: -1,
  });

  handleInputFocus = () => {
    this.setOpened(true);
  };

  handleInputBlur = () => {
    // this.setState({ inputValue: '' }); // causes bugs
    // this.setOpened(false);
  };

  handleSearchInputFocus = () => {
    this.setState({ inputValue: '', inputFocused: true });
    this.setOpened(true);
  };

  handleSearchInputBlur = () => {
    this.setState({ inputFocused: false });
  };

  handleSearchInputChange = (e) => {
    const { opened } = this.state;

    this.setState({
      inputValue: e.target.value,
      focusedItem: -1,
    });

    if (!opened) {
      this.setOpened(true);
    }
  };

  handleSearchInputClick = (e) => e.stopPropagation();

  renderOptions() {
    const { emptyPlaceholder } = this.props;
    const { focusedItem, options } = this.state;

    const items = options.map((item, i) => (
      <div
        key={i.toString()}
        className={classnames('ui-select-option', { focused: focusedItem === i })}
        onClick={() => this.handleOptionSelect(item.value)}
      >
        {item.label}
      </div>
    ));

    return (
      <div className="ui-select-options">
        <AutoScroller selector=".ui-select-option.focused">
          {(ref) => (
            <Scrollbar className="ui-select-options-scroll" ref={ref}>
              {items.length !== 0 ?
                items :
                (
                  <span className="ui-select-options-empty">
                    <SearchIcon />
                    <div className="ui-select-options-empty-label">{emptyPlaceholder ?? 'Результаты отсутствуют'}</div>
                  </span>
                )}
            </Scrollbar>
          )}
        </AutoScroller>
      </div>
    );
  }

  renderSingleValue() {
    const { placeholder, options, value, renderValue, search } = this.props;
    const { inputValue, inputFocused } = this.state;
    const option = options.find((item) => item.value === value);

    if (search) {
      const label = option?.label ?? '';

      return (
        <input
          ref={this.input}
          className="ui-select-search-input"
          placeholder="Поиск"
          value={inputFocused ? inputValue : label}
          onClick={this.handleSearchInputClick}
          onFocus={this.handleSearchInputFocus}
          onBlur={this.handleSearchInputBlur}
          onChange={this.handleSearchInputChange}
        />
      );
    }

    if (!value || !option) {
      return (
        <div className="ui-select-placeholder">{placeholder}</div>
      );
    }

    if (value && !option) {
      console.error(`[Select] Option with value "${JSON.stringify(value)}" not found`);
    }

    return (
      <span className="ui-select-label">{renderValue ? renderValue(option.label) : option.label}</span>
    );
  }

  renderMultipleValue() {
    const { size } = this.props;
    const { inputValue } = this.state;

    return (
      <SelectTagInput
        ref={this.tagInput}
        size={size}
        tags={this.getTags()}
        value={inputValue}
        onChange={this.handleInputChange}
        onFocus={this.handleInputFocus}
        onBlur={this.handleInputBlur}
        onKeyDown={this.handleInputKeyDown}
        onTagRemove={this.handleTagRemove}
      />
    );
  }

  renderContainer() {
    const { multiple } = this.props;
    const { opened } = this.state;

    return (
      <div className="ui-select-container" onClick={this.handleToggle}>
        <DirectedArrow className="ui-select-arrow" color="gray" direction={opened ? 'up' : 'down'} />
        {multiple ? this.renderMultipleValue() : this.renderSingleValue()}
      </div>
    );
  }

  render() {
    const { className, disabled, size, state, multiple, search } = this.props;
    const { opened } = this.state;

    return (
      <OnClickOutside onClickOutside={this.handleClickOutside} active={opened}>
        {(ref) => (
          <CSSTransition classNames="ui-select" in={opened} timeout={150}>
            <UiSelect
              ref={ref}
              className={classnames(className, `size-${size}`, `state-${state}`, { opened, disabled, multiple })}
              tabIndex={(multiple || search) ? undefined : '0'}
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleSelectBlur}
            >
              {this.renderContainer()}

              <CSSTransition
                classNames="ui-select-dropdown"
                timeout={150}
                in={opened}
                mountOnEnter
                unmountOnExit
              >
                <div className="ui-select-dropdown" ref={this.dropdown}>
                  {this.renderOptions()}
                </div>
              </CSSTransition>
            </UiSelect>
          </CSSTransition>
        )}
      </OnClickOutside>
    );
  }
}

export default withFormControl(Select);

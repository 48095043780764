import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChangeRateConditionListAsync } from '../../../../actions/rate-history';
import ChevronIcon from '../../../../assets/svg/chevron-right.svg';
import postToNativeApp from '../../../../utils/postToNativeApp';
import FailedToLoadData from '../../../common/FailedToLoadData';
import HtmlText from '../../../common/utils/HtmlText';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';

const rbHref = 'https://www.rosbank-auto.ru/obshie-usloviya-kreditovaniya/';
const handleImgError = ({ currentTarget }) => {
  currentTarget.onerror = null;
  currentTarget.src = '/rate-services/default.svg';
};

function RulesChangingRatesPage({ match, fetchStatus, onOpenModal }) {
  const dispatch = useDispatch();
  const { creditId } = match.params;
  const {
    loading: rateConditionLoading, error: rateConditionError, initial: rateConditionInitial,
  } = fetchStatus;
  const { changeRateConditionList = [] } = useSelector((state) => state.rateHistory.rateConditionList);

  const getRateConditionList = useCallback(() => {
    dispatch(getChangeRateConditionListAsync({ contractNumber: creditId }));
  }, [creditId]);

  useEffect(() => {
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'goBack' } });
  }, []);

  return (
    <LoaderOrChildren loading={rateConditionInitial}>
      <div className="page-wrapper rules-changing-rates">
        <h2 className="screen-title">Правила изменения ставки</h2>

        <div className="description">
          Мы&nbsp;пересмотрим процентную ставку, если вы&nbsp;не&nbsp;сможете выполнить условия договора
        </div>
        <a href={rbHref} className="external-link" target="_blank" rel="noreferrer">
          Общие условия кредитования
        </a>
        <div className="description">
          Меняя ставку, мы&nbsp;учитываем все события, которые на&nbsp;неё влияют
        </div>

        <h3 className="title">Что влияет на мою ставку?</h3>

        {rateConditionError || rateConditionLoading ? (
          <FailedToLoadData loading={rateConditionLoading} onRetry={getRateConditionList} design="small" />
        ) : (
          <>
            {changeRateConditionList.length ? (
              <div className="rate-condition-list-wrap">
                {changeRateConditionList.map((item) => (
                  <button className="rate-condition-item" onClick={() => onOpenModal(item)} key={item.typeCode}>
                    <div className="left-content">
                      <img
                        src={`/rate-services/${item.typeCode}.svg`}
                        alt={item.typeCode}
                        className="rate-condition-icon"
                        onError={handleImgError}
                      />
                      <HtmlText tag="span" className="rate-condition-name">{item.name}</HtmlText>
                    </div>

                    <ChevronIcon className="chevron-icon" />
                  </button>
                ))}
              </div>
            ) : (
              <p className="empty-changes">
                Сейчас у вас нет сервисов и услуг,<br />которые влияют на процентную ставку
              </p>
            )}
          </>
        )}
      </div>
    </LoaderOrChildren>
  );
}

export default RulesChangingRatesPage;

import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import { Route } from 'react-router-dom';
import {
  getNotSignedSupAgreementAsync,
  getServiceFinProtectAsync,
  resetServices, setNotSignedSupAgreement,
} from '../../../../actions/services';
import { useAsyncStatus } from '../../../common/hooks';
import usePage from '../../../common/hooks/usePage';
import PageTitle from '../../../common/PageTitle';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import ArchivedServicesPage from './ArchivedServicesPage';
import ServicesInformer from './ServicesInformer';
import ServicesMainPage from './ServicesMainPage';
import ServiceActionsSection from './service-actions/ServiceActionsSection';

function ServicesSection({ history, match: { params: { creditId } } }) {
  const { path } = useRouteMatch();
  usePage({ className: 'services-and-options' });
  const isIos = window.sessionStorage.getItem('isIos');
  const dispatch = useDispatch();
  const notSignedServices = useSelector((state) => state.services.notSignedServices);
  const pageTitleProps = useSelector((state) => state.app.pageTitleProps);
  const notSignedSupServiceParams = JSON.parse(window.sessionStorage.getItem('notSignedSupServiceParams'));

  const {
    loading: notSignedLoading, error: notSignedError, success: notSignedSuccess, initial: notSignedInitial,
  } = useAsyncStatus((state) => state.services.getNotSignedSupAgreementStatus);
  const {
    loading: finProtectLoading, error: finProtectError, initial: finProtectInitial,
  } = useAsyncStatus((state) => state.services.fetchStatus);

  useEffect(() => {
    if (!notSignedSupServiceParams) {
      dispatch(getNotSignedSupAgreementAsync(creditId));
    } else {
      dispatch(setNotSignedSupAgreement(notSignedSupServiceParams));
      dispatch(getServiceFinProtectAsync(creditId, true));
      history.push(`/credits/${creditId}/services/${notSignedSupServiceParams.id}/connection-document?isSigned=true`);
    }

    return () => {
      dispatch(resetServices());
    };
  }, []);

  useEffect(() => {
    if (notSignedSuccess) {
      dispatch(getServiceFinProtectAsync(creditId, true));

      if (notSignedServices.length > 0) {
        history.push(`/credits/${creditId}/services/${notSignedServices[0].id}/connection-document?isSigned=true`);
      }
    }
  }, [notSignedSuccess]);

  return (
    <div className="services-and-options-wrapper">
      <PageTitle title="Услуги и опции" version="2" {...pageTitleProps} />

      <LoaderOrChildren
        loading={notSignedLoading || !!notSignedError || finProtectLoading || !!finProtectError || notSignedInitial || finProtectInitial}
      >
        <div className={classnames('services-and-options-content', { 'is-ios': isIos })}>
          <Switch>
            <Route
              path={path}
              exact
              render={(props) => <ServicesMainPage {...props} />}
            />

            <Route
              path={`${path}/archived`}
              exact
              render={(props) => <ArchivedServicesPage {...props} />}
            />

            <Route
              path={`${path}/:serviceKey`}
              render={(props) => <ServiceActionsSection {...props} />}
            />

            <Redirect to={path} />
          </Switch>
        </div>
      </LoaderOrChildren>

      <ServicesInformer creditId={creditId} />
    </div>
  );
}

export default ServicesSection;

import { number } from 'currency-codes';
import moment from 'moment';

const phone = /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/;

// TODO: libphonenumber-js
export function formatPhone(value) {
  if (!value) {
    return '';
  }

  if (phone.test(value)) {
    return value.replace(phone, '+$1 ($2) $3-$4-$5');
  }

  return value;
}

export function formatCurrency(value, options = {}) {
  const {
    currency = 'RUB',
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    exp = 0,
  } = options;
  const formatter = new Intl.NumberFormat('ru', {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formatter.format(value / (10 ** exp));
}

export function formatFileSize(bytes) {
  if (typeof bytes !== 'number') {
    return '';
  }
  if (bytes >= 1000000) {
    return `${(bytes / 1000000).toFixed(bytes % 1000000 === 0 ? 0 : 1)}&nbsp;МБ`;
  }

  return `${(bytes / 1000).toFixed(0)}&nbsp;КБ`;
}

/**
 * @param {MoneyAmount} moneyAmount
 * @returns {string}
 */
export function formatMoneyAmount(moneyAmount) {
  const {
    amount,
    currency = 643,
    exp = 2,
  } = moneyAmount;

  return formatCurrency(amount, { exp, currency: number(currency.toString())?.code });
}

export function moneyFormat(sum, options = {}) {
  return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', ...options }).format(sum);
}

export function moneyFormatWithTrailingZeroStripIfInteger(sum = 0) {
  // NumberFormat.options.trailingZeroDisplay: 'stripIfInteger' has bad support in iOS, so we have to do this way

  const strippedZeroFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatter = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 2 });

  return sum % 1 === 0 ? strippedZeroFormatter.format(sum) : formatter.format(sum);
}

export function formatNumber(value, options) {
  const formatter = new Intl.NumberFormat('ru', options);

  return formatter.format(value);
}

const defaultPercentOptions = { maximumFractionDigits: 2, minimumFractionDigits: 0 };
export function percentFormat(value, options) {
  const numberValue = typeof value === 'string' ? +(value?.replace(',', '.')) : (value || 0);
  return `${parseFloat(numberValue).toLocaleString('ru-RU', options ?? defaultPercentOptions)}%`;
}

const translitMap = new Map([
  ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'], ['д', 'd'], ['е', 'e'], ['ё', 'e'], ['ж', 'zh'], ['з', 'z'],
  ['и', 'i'], ['й', 'i'], ['к', 'k'], ['л', 'l'], ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'],
  ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'], ['х', 'h'], ['ц', 'c'], ['ч', 'ch'], ['ш', 'sh'], ['щ', 'sh'],
  ['ъ', ''], ['ы', 'i'], ['ь', ''], ['э', 'e'], ['ю', 'yu'], ['я', 'ya'], [' ', '_'],
]);

/**
 * @param {string} str
 * @returns {string}
 */
export function transliterate(str) {
  const normalized = str.trim().toLowerCase();
  const result = new Array(normalized.length);
  let char;

  for (let i = 0; i < normalized.length; i++) {
    char = normalized[i];

    if (translitMap.has(char)) result[i] = translitMap.get(char);
    else result[i] = char;
  }

  return result.join('');
}

const momentCalendarFormat = {
  sameDay: '[Сегодня]',
  nextDay: '[Завтра]',
  nextWeek: 'dddd',
  lastDay: '[Вчера]',
  lastWeek: 'dddd',
  // sameElse: 'DD.MM.YYYY',
  sameElse(now) {
    // if (this.isSameOrBefore(now.subtract(1, 'year')) || this.isSameOrAfter(now.add(1, 'year'))) {
    if (this.year() === now.year()) {
      return 'DD MMMM';
    }
    return 'DD MMMM, YYYY';
  },
};

export function formatCalendarDate(momentDate) {
  return momentDate.calendar(null, momentCalendarFormat);
}

export function getNoun(number, one, two, five, returnWithNumber) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return returnWithNumber ? `${number} ${five}` : five;
  }
  n %= 10;
  if (n === 1) {
    return returnWithNumber ? `${number} ${one}` : one;
  }
  if (n >= 2 && n <= 4) {
    return returnWithNumber ? `${number} ${two}` : two;
  }
  return returnWithNumber ? `${number} ${five}` : five;
}

export function formatDate(date, format = 'D MMMM YYYY') {
  const momentDate = moment(date, 'DD.MM.YYYY');

  return momentDate.isValid() ? momentDate.format(format) : 'дата неизвестна';
}

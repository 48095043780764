import { parse } from 'qs';
import { useCallback, useContext, useMemo } from 'react';
import { __RouterContext } from 'react-router'; // eslint-disable-line import/no-extraneous-dependencies
import { updateQuery } from '../../../utils/url';

function useQueryParams() {
  const { history, location } = useContext(__RouterContext);
  const params = useMemo(
    () => parse(location.search, { ignoreQueryPrefix: true }),
    [location.search],
  );
  const handleUpdate = useCallback(
    (params) => {
      if (typeof params === 'undefined') {
        history.push({ search: '' });
      } else {
        history.push({
          search: updateQuery(history.location.search, params),
        });
      }
    },
    [],
  );

  return [params, handleUpdate];
}

export default useQueryParams;

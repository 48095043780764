/**
 * @returns {boolean}
 */
import { useEffect, useRef } from 'react';

function useMountedRef() {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
}

export default useMountedRef;

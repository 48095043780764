import { useField } from 'formik';
import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from '../../../../ui-kit/autocomplete/AutoComplete';

function BankSearch({ banks, name, initialValue, onBankSelect, onChange }) {
  const [dataset, setDataset] = useState([]);
  const [field, meta] = useField(name);

  const handleChange = useCallback((e) => {
    if (onChange) {
      onChange(e);
    }

    field.onChange(e);
  }, [onChange, field.onChange]);

  const handleSelect = useCallback(({ bank }) => {
    onBankSelect(bank);
  }, []);
  const handleSearch = useCallback((value) => {
    setDataset(banks.filter((bank) => bank.bic.startsWith(value) || bank.bank_name.includes(value)).map((bank) => ({
      id: bank.bic,
      label: bank.bic,
      bank,
    })));
  }, [banks]);
  const renderItem = useCallback((item, highlight) => (
    <div className="select-bank">
      <div className="bank-bic">{highlight(item.bank.bic)}</div>
      <div className="bank-name">{highlight(item.bank.bank_name)}</div>
    </div>
  ), []);

  return (
    <AutoComplete
      initialValue={initialValue}
      name={name}
      placeholder="Введите первые 3 символа"
      dataset={dataset}
      state={meta.error && meta.touched ? 'invalid' : undefined}
      renderItem={renderItem}
      onSearch={handleSearch}
      onSelect={handleSelect}
      onChange={handleChange}
      onBlur={field.onBlur}
    />
  );
}

BankSearch.propTypes = {
  banks: PropTypes.array.isRequired,
  onBankSelect: PropTypes.func.isRequired,
};

export default memo(BankSearch);

import React from 'react';
import useQueryParams from '../hooks/useQueryParams';

function withQueryParams(WrappedComponent) {
  const WithQueryParams = (props) => {
    const [params, updateParams] = useQueryParams();

    return React.createElement(WrappedComponent, { ...props, params, updateParams });
  };

  WithQueryParams.displayName = `WithQueryParams(${getDisplayName(WrappedComponent)})`;

  return WithQueryParams;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withQueryParams;

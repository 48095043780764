import classnames from 'classnames';
import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { loginAsync } from '../../actions/auth';
import { authViaAgreementConfirmationAsync, userSmsResendRequestAsync } from '../../actions/user';
import { Hint, Button, Input, FormControl, Checkbox } from '../../ui-kit';
import compose from '../../utils/compose';

const validationSchema = yup.object().shape({
  username: yup.string().required('Required'),
  password: yup.string().required('Required'),
  agreement: yup.bool().required('Required').oneOf([true]),
});

class SignInForm extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    authViaAgreementConfirmationFetchStatus: PropTypes.object,
    login: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    initialError: this.props.authViaAgreementConfirmationFetchStatus.error,
  };

  queryChapter = window.sessionStorage.getItem('chapter');

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (prevProps.user.login !== user.login) {
      this.props.setFieldValue('username', prevProps.user.login);
    }
  }

  showAgreement = () => {
    this.props.history.push(`/auth/agreement${this.props.location.search}`);
  };

  requestForNewSms = () => {
    this.props.userSmsResendRequestAsync();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { values } = this.props;
    this.props.authViaAgreementConfirmationAsync(values.password);
  };

  render() {
    const { initialError } = this.state;
    const {
      values, user, authViaAgreementConfirmationFetchStatus: { error, loading },
      errors, touched, isValid,
    } = this.props;
    const hasError = error && error !== initialError;
    const pwdLabel = (
      <>
        <span className="pwd-label">Пароль из смс</span>
        {/*<Link to="/auth/remember" className="remember-link" tabIndex="-1">{t('forgot')}</Link>*/}
      </>
    );

    return (
      <Form onSubmit={this.handleSubmit} className={classnames('sign-in-form', { 'has-error': hasError })}>
        {!this.queryChapter && <h1 className="sign-in-title">Авторизация</h1>}
        <div className="sign-in-helper-text">Для перехода в Личный кабинет Росбанк Авто введите код, отправленный на ваш номер телефона.</div>

        {hasError && (
          <Hint type="error" className="field-error">
            Пароль указан неверно
          </Hint>
        )}
        <FormControl label="Логин">
          <Field name="username">
            {({ field }) => (
              <Input
                {...field}
                type="text"
                size="l"
                disabled={!!user.login}
                autoComplete="username"
                state={errors[field.name] && touched[field.name] ? 'invalid' : undefined}
              />
            )}
          </Field>
        </FormControl>

        <FormControl className="pwd-form-control" label={pwdLabel}>
          <Field name="password">
            {({ field }) => (
              <Input
                {...field}
                type="password"
                size="l"
                autoComplete="current-password"
                state={errors[field.name] && touched[field.name] ? 'invalid' : undefined}
              />
            )}
          </Field>
        </FormControl>

        <div className="auth-agreement-wrapper">
          <FormControl>
            <Field name="agreement">
              {({ field }) => (
                <Checkbox
                  {...field}
                  size="l"
                  checked={field.value}
                  className="no-decoration"
                >
                  <span className="no-transform">
                    Я принимаю
                    <span className="auth-agreement" onClick={this.showAgreement}> условия дистанционного
                      банковского обслуживания
                      физических&nbsp;лиц
                    </span>
                  </span>
                </Checkbox>
              )}
            </Field>
          </FormControl>
        </div>

        <div className="sign-in-footer">
          <Button
            className="sign-in-submit"
            design="red"
            disabled={!values.agreement || !isValid}
            size="l"
            loading={loading}
          >
            Подтвердить
          </Button>

          <Button
            className="sign-in-resend-sms"
            design="secondary"
            type="button"
            size="l"
            onClick={this.requestForNewSms}
            disabled={!user.requestSmsAvailable}
            loading={loading}
          >
            Отправить код повторно
          </Button>
          {/*<Link to="/auth/reg" className="registration-link">
            <Trans i18nKey="registration-link" ns="auth" />
          </Link>*/}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.data,
  authViaAgreementConfirmationFetchStatus: state.user.authViaAgreementConfirmationFetchStatus,
});

const mapDispatchToProps = {
  login: loginAsync,
  authViaAgreementConfirmationAsync,
  userSmsResendRequestAsync,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ user }) => ({
      username: user?.login || '',
      password: '',
      agreement: true,
    }),
    validationSchema,
  }),
)(SignInForm);

import { useMemo } from 'react';

const getNextPaymentKeysByContractTypeCode = (contractTypeCode) => {
  switch (contractTypeCode) {
    case 'C':
      return { sum: 'required_payment_sum', date: 'required_payment_date' };
    default:
      return { sum: 'payment_sum', date: 'payment_date' };
  }
};


function useNextPayment(credit) {
  return useMemo(() => {
    const { additionalInfo } = credit;
    const keys = getNextPaymentKeysByContractTypeCode(credit.contract_type_code);
    return { sum: additionalInfo?.[keys.sum], date: additionalInfo?.[keys.date] };
  }, [credit]);
}

export default useNextPayment;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Button } from '../../../../../ui-kit';
import AlertIcon from '../../../../../assets/svg/alert-icon-16x16.svg';
import ChevronIcon from '../../../../../assets/svg/chevron-right.svg';
import useServiceById from '../hooks/useServiceById';
import AdditionalInfoList from '../utils/AdditionalInfoList';
import ServiceInfoModal from '../modals/ServiceInfoModal';
import AlertBlock from '../../../../common/AlertBlock';
import { useAsyncStatus, useFixedFooter, useOpenNewPage } from '../../../../common/hooks';
import { moneyFormatWithTrailingZeroStripIfInteger } from '../../../../../utils/format';
import ActionSheet from '../../../../common/ActionSheet';
import { isCurrentVersionNewerThan } from '../../../../../utils/isCurrentVersionNewerThan';
import CancelEarlyRepaymentModal from '../modals/CancelEarlyRepaymentModal';
import { downloadURI } from '../../../../../utils/downloadURI';
import { finProtectRegistrationAsync, getSupAgreementAsync } from '../../../../../actions/services';
import ServicePageHeader from '../utils/ServicePageHeader';

const ActionSheetFooter = ({ onCancel }) => (
  <div className="footer action-sheet-design">
    <Button className="action-button" tag="a" href="https://www.rosbank.ru/newapp" design="red" size="l">Обновить</Button>
    <Button className="action-button cancel" onClick={onCancel} design="grey" size="l">Отмена</Button>
  </div>
);

const finServices = [222, 223, 224, 225];

function ServiceActionPage({ history, match }) {
  const { params: { creditId, serviceKey } } = match;
  const dispatch = useDispatch();
  const [modalType, setModalType] = useState('');
  const [cancelEarlyRepaymentModalConfig, setCancelEarlyRepaymentModalConfig] = useState(null);

  const getSupAgreementStatus = useAsyncStatus((state) => state.services.getSupAgreementStatus);
  const supAgreement = useSelector((state) => state.services.supAgreementResult);
  const finProtectRegistrationStatus = useAsyncStatus((state) => state.services.finProtectRegistrationStatus);
  const currentService = useServiceById(serviceKey) ?? {};
  const serviceId = currentService.id;

  const {
    name, statusCode, additionalInfo, activationConditionList,
    discountRate, optionBenefit = {}, cost, tariffId, type, isPurchaseAvailable,
    activationRestrictionText, isActiveEarlyRepaymentRequest, isProlongation, purchaseAvailabilityMessage,
  } = currentService || {};
  const { netBenefit, overpaymentWithOption, overpaymentWithoutOption, tariffAmount } = optionBenefit;

  const onCloseModal = useCallback(() => {
    setModalType('');
  }, []);

  const onCloseEarlyRepaymentCancellationModal = useCallback(() => {
    setCancelEarlyRepaymentModalConfig(null);
  }, []);

  const footerRef = useRef();
  useFixedFooter(footerRef, 'services-and-options-content', []);

  const footerButtonText = useMemo(() => {
    if (type === 'prolongation') {
      return 'Продлить';
    }
    if (type === 'available') {
      return 'Купить';
    }

    return '';
  }, [type]);

  const hasErrorInSupAgr = useMemo(() => getSupAgreementStatus.error, [getSupAgreementStatus.error]);
  const hasErrorInFinReg = useMemo(() => finProtectRegistrationStatus.error, [finProtectRegistrationStatus.error]);

  const handleSupAgreement = useCallback(() => {
    const dataToSend = {
      contractNumber: creditId,
      hasFacsimile: 0,
      name,
      id: serviceId,
    };
    dispatch(getSupAgreementAsync(dataToSend));
  }, [creditId, name, serviceId]);

  const handleSendRegistrationRequest = useCallback((data) => {
    dispatch(finProtectRegistrationAsync(data));
  }, []);

  const onSubmit = useCallback(() => {
    const isWeb = window.sessionStorage.getItem('isWeb') === 'true';
    const isNewVersion = isCurrentVersionNewerThan(finServices.includes(serviceId) ? '4.6.0' : '5.1.0');

    if (isNewVersion || isWeb) {
      const dataToSend = {
        contractNumber: creditId,
        name,
        id: serviceId,
        tariffId,
        cost,
        requestChannel: 'RBMOBILE',
        isActiveEarlyRepaymentRequest,
        isProlongation: +isProlongation,
      };

      if (serviceId !== 234 || (serviceId === 234 && !isActiveEarlyRepaymentRequest)) {
        handleSendRegistrationRequest(dataToSend);
      }
      if (serviceId === 234 && isActiveEarlyRepaymentRequest) {
        setCancelEarlyRepaymentModalConfig(dataToSend);
      }
    } else {
      setModalType('error');
    }
  }, [serviceId, creditId, currentService]);

  useEffect(() => {
    if (finProtectRegistrationStatus.success) {
      history.push(`/credits/${creditId}/services/${serviceKey}/connection-document`);
      onCloseEarlyRepaymentCancellationModal();
    }
  }, [finProtectRegistrationStatus.success]);

  useEffect(() => {
    if (getSupAgreementStatus.success) {
      downloadURI(supAgreement.request_document_mobile_url, 'sample_additional_agreement.pdf');
    }
  }, [getSupAgreementStatus.success]);

  useOpenNewPage();

  return (
    <div className="current-service-wrapper">
      <div className={classnames('current-service-content', { loading: finProtectRegistrationStatus.loading })}>

        <ServicePageHeader service={currentService} />

        {serviceId === 233 && !['FINISHED', 'CANCELED'].includes(statusCode) && netBenefit > 0 && overpaymentWithOption && overpaymentWithoutOption && tariffAmount && (
          <div className="loan-benefit-wrapper">
            <div className="benefit-amount">
              <span className="amount">{moneyFormatWithTrailingZeroStripIfInteger(netBenefit)}</span>
              <span className="description">Экономия по кредиту</span>
            </div>
            <button className="benefit-info-button" onClick={() => setModalType('info')}>
              Подробнее
            </button>
          </div>
        )}

        <AdditionalInfoList additionalInfo={additionalInfo} />

        {!['FINISHED', 'COMPLETED', 'CANCELED'].includes(statusCode) && activationConditionList?.some((list) => list.conditionTypeName && list?.conditionList.some((item) => item.conditionIconName && item.conditionText)) && (
          <div
            className="how-service-works-button"
            onClick={() => setModalType('info')}
          >
            <div className="left-part">
              <AlertIcon className="alert-icon" />
              {[234, 240].includes(serviceId) && <span>Как работает услуга</span>}
              {[222, 223, 233, 224, 225, 251].includes(serviceId) && <span>Как работает опция</span>}
            </div>
            <ChevronIcon className="chevron-icon" />
          </div>
        )}

        {cost && (
          <div className="price-wrapper">
            {[234, 251].includes(serviceId) && <span className="name">Единоразовая оплата за год</span>}
            {[222, 223, 224, 225, 240].includes(serviceId) && <span className="name">Единоразовая оплата</span>}
            <span className="price">{moneyFormatWithTrailingZeroStripIfInteger(cost)}</span>
          </div>
        )}
        {serviceId === 233 && !['FINISHED', 'CANCELED'].includes(statusCode) && (discountRate > 0) && (
          <AlertBlock text={`При отказе от опции ставка по&nbsp;кредиту может увеличиться на&nbsp;${discountRate}%`} status="warning" margin="12px 0" />
        )}

      </div>

      {['available', 'prolongation'].includes(type) && (
        <div className="current-service-footer-wrapper" ref={footerRef}>

          {(hasErrorInFinReg || hasErrorInSupAgr) && (
            <AlertBlock
              text={`Не удалось загрузить ${hasErrorInSupAgr ? 'файл' : 'данные'}.<br />Попробуйте повторить попытку`}
              status={hasErrorInFinReg ? 'fatal-error' : 'warning'}
              margin={hasErrorInFinReg ? '16px 0' : '12px 0 24px'}
              compact
            />
          )}

          {['available', 'prolongation'].includes(type) && purchaseAvailabilityMessage && (
            <AlertBlock
              text={purchaseAvailabilityMessage}
              status="warning"
              margin="12px 0 24px"
              compact
            />
          )}

          <Button
            className="sign-in-submit"
            design="red"
            size="l"
            type="submit"
            loading={finProtectRegistrationStatus.loading}
            disabled={!isPurchaseAvailable}
            onClick={onSubmit}
          >
            {footerButtonText}
          </Button>

          {finServices.includes(serviceId) && type === 'available' && (
            <p className="additional-agreement">
              Нажимая на кнопку, я подтверждаю, что<br />
              ознакомлен с <button className="sup-agreement" onClick={handleSupAgreement}>допсоглашением</button> к договору
            </p>
          )}
        </div>
      )}

      <ServiceInfoModal
        active={modalType === 'info'}
        optionBenefit={optionBenefit}
        id={serviceId}
        activationConditionList={activationConditionList}
        activationRestrictionText={activationRestrictionText}
        onClose={onCloseModal}
      />

      <CancelEarlyRepaymentModal
        submitData={cancelEarlyRepaymentModalConfig}
        onClose={onCloseEarlyRepaymentCancellationModal}
        onSubmit={handleSendRegistrationRequest}
      />

      <ActionSheet
        withLogo
        active={modalType === 'error'}
        onClose={onCloseModal}
        header="Доступна новая версия приложения"
        footer={<ActionSheetFooter onCancel={onCloseModal} />}
      >
        Обновите приложение, чтобы&nbsp;воспользоваться этой функцией
      </ActionSheet>
    </div>
  );
}

export default ServiceActionPage;

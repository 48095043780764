import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import { ellipsis } from 'polished';
import styled from 'styled-components';
import OnClickOutside from '../click-outside/OnClickOutside';
import Input from '../input/Input';
import AutoScroller from '../scrollbar/AutoScroller';
import Scrollbar from '../scrollbar/Scrollbar';
import { color, transition } from '../styles/mixins';

const UiAutocompleteInput = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  outline: 0;
  -webkit-appearance: none;

  // overlap workaround ¯\\_(ツ)_/¯
  &.ui-autocomplete-input {
    &-exit,
    &-exit-active {
      .ui-input-component {
        z-index: 3!important;
      }
    }
  }

  &.active {
    .ui-input-component {
      z-index: 4;
      input {
        border-color: ${color.black.rgba(0)};
      }
    }
  }

  > .ui-autocomplete-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 0;
    padding-top: 48px;
    border-radius: 8px;
    box-shadow: 0 16px 32px 0 ${color.black.rgba(0.09)};
    z-index: 1;

    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
      transition: opacity ${transition.fast};
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transition: opacity ${transition.fast};
    }

    //.size-s& {
    //  padding-top: 30px;
    //}
    //
    //.size-m& {
    //  padding-top: 40px;
    //}
    //
    //.size-l& {
    //  padding-top: 50px;
    //}

    .ui-autocomplete-items {
      padding: 0 4px;
    }

    .ui-autocomplete-scroll-wrap {
      padding: 10px 0;
      //border-top: 1px solid ${color.black.rgba(0.08)};
    }

    .ui-autocomplete-scroll {
      max-height: 360px;

      .simplebar-content-wrapper {
        overscroll-behavior: contain;
      }
    }

    .ui-autocomplete-items-empty {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      padding: 10px 0;
    }

    .ui-autocomplete-items-empty-label {
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
      padding: 0 11px;
      color: ${color.black};
      //margin-top: 11px;

      //.size-s& {
      //  font-size: 12px;
      //}
      //.size-m& {
      //  font-size: 16px;
      //}
      //.size-l& {
      //  font-size: 18px;
      //}
    }

    .ui-autocomplete-item {
      font-family: inherit;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      color: ${color.black};
      padding: 7px 11px;
      box-sizing: border-box;
      border-radius: 6px;
      cursor: pointer;
      ${ellipsis('100%')};
      display: block;

      .highlighted {
        font-weight: 500;
      }

      &:hover,
      &.focused {
        background-color: ${color.black.rgba(0.05)};
      }

      //.size-s& {
      //  font-size: 12px;
      //  font-weight: 400;
      //  line-height: 1.17;
      //}
      //
      //.size-m& {
      //  font-size: 16px;
      //  font-weight: 400;
      //  line-height: normal;
      //}
      //
      //.size-l& {
      //  font-size: 18px;
      //  font-weight: 400;
      //  line-height: normal;
      //}
    }
  }
`;

const inputPropNames = ['size', 'state', 'prefix', 'suffix', 'id', 'name', 'placeholder', 'disabled'];

class AutoComplete extends PureComponent {
  static propTypes = {
    initialValue: PropTypes.string,
    className: PropTypes.string,
    dataset: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    size: PropTypes.oneOf(['s', 'm', 'l']),
    state: PropTypes.oneOf(['valid', 'invalid']),
    prefix: PropTypes.node,
    suffix: PropTypes.node,
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    renderItem: PropTypes.func,
    onChange: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    size: 'm',
    onChange: () => {},
    onBlur: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue || '',
      activeItemId: '',
      opened: false,
    };
  }

  highlight = (text) => {
    const { value } = this.state;
    const escaped = value.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regexp = new RegExp(escaped.split(' ').join('|'), 'ig');
    const html = text.replace(regexp, '<span class="highlighted">$&</span>'); // TODO: potential vulnerability

    return <span dangerouslySetInnerHTML={{ __html: html }} />;
  };

  handleClickOutside = () => this.setState({ opened: false });

  handleChange = (e) => {
    const { onChange, onSearch } = this.props;
    const { value } = e.target;

    this.setState({ value, opened: true });

    onChange(e);

    if (value.trim().length >= 3) {
      onSearch(value);
    }
  };

  handleFocus = () => {
    const { onSearch } = this.props;
    const { value } = this.state;

    this.setState({ opened: true });

    if (value.trim().length >= 3) {
      onSearch(value);
    }
  };

  handleBlur = (e) => {
    this.setState({ opened: false });
    this.props.onBlur(e);
  }

  handleKeyDown = (e) => {
    const { dataset } = this.props;
    const { value, activeItemId } = this.state;

    if (value.trim().length < 3) {
      return;
    }

    // arrowup || arrowdown || esc || enter
    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 27 || e.keyCode === 13) {
      const activeIndex = activeItemId ? dataset.findIndex((item) => item.id === activeItemId) : -1;

      switch (e.keyCode) {
        case 27:
          e.preventDefault();
          this.setState({ value: '', activeItemId: '', opened: false });
          break;

        case 38:
          e.preventDefault();

          if (activeIndex > 0) {
            this.setState({ activeItemId: dataset[activeIndex - 1].id, opened: true });
          } else {
            this.setState({ opened: true });
          }
          break;

        case 40:
          e.preventDefault();

          if (activeIndex < dataset.length - 1) {
            this.setState({ activeItemId: dataset[activeIndex + 1].id, opened: true });
          } else {
            this.setState({ opened: true });
          }
          break;

        case 13:
          const item = dataset[activeIndex];

          if (item) {
            if (item.id !== value) {
              e.preventDefault();
            }
            this.handleItemSelect(item);
          }
          break;

        default:
          break;
      }
    }
  };

  handleItemClick = (e) => {
    const { dataset } = this.props;
    const { id } = e.currentTarget.dataset;
    const item = dataset.find((item) => item.id === id);

    this.handleItemSelect(item);
  };

  handleItemSelect = (item) => {
    const { onSelect } = this.props;

    onSelect(item);
    this.setState({ value: item.label, opened: false });
  };

  handleItemMouseEnter = (e) => this.setState({ activeItemId: e.target.dataset.id });

  handleItemMouseLeave = () => this.setState({ activeItemId: '' });

  renderItem = ({ label }, highlight) => highlight(label);

  renderItems = () => {
    const {
      dataset,
      renderItem = this.renderItem,
    } = this.props;
    const { value, activeItemId } = this.state;

    if (value.trim().length < 3) { // TODO: length < 3 hardcode
      return null;
    }

    if (dataset.length === 0) {
      return (
        <div className="ui-autocomplete-items-empty">
          <div className="ui-autocomplete-items-empty-label">Ничего не найдено</div>
        </div>
      );
    }

    return dataset.map((item) => (
      <div
        key={item.id}
        data-id={item.id}
        className={classnames('ui-autocomplete-item', { focused: activeItemId === item.id })}
        onClick={this.handleItemClick}
        onMouseEnter={this.handleItemMouseEnter}
        onMouseLeave={this.handleItemMouseLeave}
      >
        {renderItem(item, this.highlight)}
      </div>
    ));
  };

  render() {
    const { className, size } = this.props;
    const { value, opened } = this.state;
    const inputProps = inputPropNames.reduce((props, name) => {
      props[name] = this.props[name];
      return props;
    }, {});
    const active = opened && value.trim().length > 2;

    return (
      <OnClickOutside onClickOutside={this.handleClickOutside} active={active}>
        {(ref) => (
          <CSSTransition classNames="ui-autocomplete-input" in={active} timeout={150}>
            <UiAutocompleteInput ref={ref} className={classnames(className, `size-${size}`, { active })}>
              <Input
                {...inputProps}
                className="ui-input-component"
                value={value}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onKeyDown={this.handleKeyDown}
              />
              <CSSTransition
                classNames="ui-autocomplete-dropdown"
                timeout={150}
                in={active}
                mountOnEnter
                unmountOnExit
              >
                <div className="ui-autocomplete-dropdown">
                  <div className="ui-autocomplete-scroll-wrap">
                    <AutoScroller selector=".ui-autocomplete-item.focused">
                      {(ref) => (
                        <Scrollbar className="ui-autocomplete-scroll" ref={ref}>
                          <div className="ui-autocomplete-items">
                            {this.renderItems()}
                          </div>
                        </Scrollbar>
                      )}
                    </AutoScroller>
                  </div>
                </div>
              </CSSTransition>
            </UiAutocompleteInput>
          </CSSTransition>
        )}
      </OnClickOutside>
    );
  }
}

// export default withFormControl(AutoComplete);
export default AutoComplete;

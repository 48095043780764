import { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function AutoScroller({ selector, children }) {
  const ref = useRef();
  const elRef = useRef();

  useLayoutEffect(() => {
    const scroll = ref.current?.getScrollElement();
    const element = scroll?.querySelector(selector);

    if (!element || elRef.current === element) return;

    elRef.current = element;

    if (element.offsetTop + element.clientHeight > scroll.scrollTop + scroll.clientHeight) {
      scroll.scrollTo(0, element.offsetTop + element.clientHeight - scroll.clientHeight);
    } else if (element.offsetTop < scroll.scrollTop) {
      scroll.scrollTo(0, element.offsetTop);
    }
  });

  return children(ref);
}

AutoScroller.propTypes = {
  selector: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

export default AutoScroller;

import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { getCreditCardOperationsAsync } from '../../../../actions/credits';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import { useUser } from '../../../common/hooks';
import usePeriodFilter from '../../../common/hooks/usePeriodFilter';
import PageTitle from '../../../common/PageTitle';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import PeriodFilter from '../../../../ui-kit/period-filter/PeriodFilter';
import CreditCardOperationsGroup from './CreditCardOperationsGroup';
import EmptyDataBlock from '../../../common/EmptyDataBlock';

function CreditCardOperations() {
  const dispatch = useDispatch();
  const { creditId } = useParams();
  const user = useUser();
  const [dates, setDates] = useState([moment().subtract('10', 'year'), moment()]);
  const { creditsById, cardOperationsFetchStatus } = useSelector((state) => ({
    creditsById: state.credits.byId,
    cardOperationsFetchStatus: state.credits.cardOperationsFetchStatus,
  }));
  const { cardOperations = [] } = creditsById[creditId];
  const filteredCardOperations = usePeriodFilter(dates, cardOperations);

  useEffect(() => {
    const { operator_id: operatorId } = user;
    dispatch(getCreditCardOperationsAsync(creditId, operatorId));
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
  }, []);

  useEffect(() => {
    if (cardOperations.length > 0) {
      setDates([moment(cardOperations[cardOperations.length - 1].date, 'DD.MM.YYYY'), moment()]);
    }
  }, [cardOperationsFetchStatus.loading]);

  return (
    <div className="page page-credit-card-operations">
      <PageTitle title="Операции по карте" />
      <LoaderOrChildren loading={cardOperationsFetchStatus.loading}>
        <PeriodFilter value={dates} onChange={setDates} inputSize="s" />
        {filteredCardOperations.length > 0 && filteredCardOperations.map((item) => <CreditCardOperationsGroup key={item.date} {...item} />)}
        {filteredCardOperations.length === 0 && <EmptyDataBlock message="В выбранном периоде отсутствуют операции по карте" />}
      </LoaderOrChildren>
    </div>
  );
}

export default memo(CreditCardOperations);

import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { getChangeRateConditionListAsync, getChangeRateHistoryListAsync } from '../../../../actions/rate-history';
import { useAsyncStatus } from '../../../common/hooks';
import usePage from '../../../common/hooks/usePage';
import PageTitle from '../../../common/PageTitle';
import RateHistoryMainPage from './RateHistoryMainPage';
import RateInfoModal from './RateInfoModal';
import RulesChangingRatesPage from './RulesChangingRatesPage';

function RateHistoryPage({ match: { params: { creditId } } }) {
  const { path } = useRouteMatch();
  usePage({ className: 'rate-history' });
  const isIos = window.sessionStorage.getItem('isIos');
  const dispatch = useDispatch();
  const rateHistoryFetchStatus = useAsyncStatus((state) => state.rateHistory.getChangeRateHistoryFetchStatus, true);
  const rateConditionalFetchStatus = useAsyncStatus((state) => state.rateHistory.getChangeRateConditionListFetchStatus, true);
  const [modalConfig, setModalConfig] = useState(null);

  const handleOpenModal = useCallback((config) => {
    setModalConfig(config);
  }, []);

  useEffect(() => {
    dispatch(getChangeRateHistoryListAsync({ contractNumber: creditId }));
    dispatch(getChangeRateConditionListAsync({ contractNumber: creditId }));
  }, []);

  return (
    <div className="rate-history-wrapper">
      <PageTitle title="История ставки" version="2" />

      <div className={classnames('rate-history-content', { 'is-ios': isIos })}>
        <Switch>
          <Route
            path={path}
            exact
            render={(props) => (
              <RateHistoryMainPage
                {...props}
                fetchStatus={rateHistoryFetchStatus}
                onOpenModal={handleOpenModal}
              />
            )}
          />
          <Route
            path={`${path}/rules-changing-rates`}
            render={(props) => (
              <RulesChangingRatesPage
                {...props}
                fetchStatus={rateConditionalFetchStatus}
                onOpenModal={handleOpenModal}
              />
            )}
          />

          <Redirect to={path} />
        </Switch>
      </div>

      <RateInfoModal config={modalConfig} creditId={creditId} onClose={() => setModalConfig(null)} />
    </div>
  );
}

export default RateHistoryPage;

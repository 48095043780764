import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { mergeByProperty } from '../utils/mergeByProperty';
import { asyncReducerFactory } from '../utils/redux-utils';
import * as actions from '../actions/financial-services';

const financialServiceReducer = createReducer({ services: [], cached: false })
  // .handleAction(actions.getServiceFinProtect.request, () => ({ services: [] }))
  .handleAction(actions.getServiceFinProtect.success, (state, { payload, meta }) => {
    const { registredFinProtecServiceList = [], availableFinServiceList = [] } = payload;
    const oldRegistredFinProtecServiceList = state.services.filter((item) => item.type === 'regFinServ');
    const oldAvailableFinServiceList = state.services.filter((item) => item.type === 'availableFinServ');

    const registredFinProtecItems = registredFinProtecServiceList?.map((item) => ({
      ...(oldRegistredFinProtecServiceList.find((el) => el.id === item.id)),
      ...item,
      type: 'regFinServ',
    }));
    const availableFinServiceItems = availableFinServiceList?.map((item) => ({
      ...(oldAvailableFinServiceList.find((el) => el.id === item.id)),
      ...item,
      type: 'availableFinServ',
    }));
    const services = registredFinProtecItems.length > 0 || availableFinServiceItems.length > 0 ?
      mergeByProperty([registredFinProtecItems, availableFinServiceItems]) : [];

    return ({
      ...state,
      services,
      cached: true,
      status: meta.status,
      creditId: meta.creditId,
    });
  })
  // .handleAction(actions.getServiceFinProtect.failure, () => ({ error: true }))
  .handleAction(actions.getServiceFinProtectActAvail.request, (state, { payload }) => {
    const newState = state;
    newState.services = state.services.map((service) => {
      if (service.id === payload.serviceId) {
        return { ...service, isLoaded: false, loading: true, actAvailError: null };
      }
      return service;
    });
    return newState;
  })
  .handleAction(actions.getServiceFinProtectActAvail.success, (state, { payload }) => {
    const newState = state;
    newState.services = state.services.map((service) => {
      if (service.id === payload.serviceId) {
        return { ...service, ...payload.data, loading: false, error: false, isLoaded: true, cached: true };
      }

      return service;
    });
    return newState;
  })
  .handleAction(actions.getServiceFinProtectActAvail.failure, (state, { payload }) => {
    const newState = state;
    newState.services = state.services.map((service) => {
      if (service.id === payload.serviceId) {
        return { ...service, isLoaded: true, loading: false, error: true, actAvailError: payload.error };
      }
      return service;
    });
    return newState;
  })
  .handleAction(actions.getNotSignedSupAgreement.success, (state, { payload }) => (
    {
      ...state,
      notSignedServices: payload.notSignedSupAgrList.sort((a, b) => a.id - b.id),
      cached: true,
    }
  ))
  .handleAction(actions.resetCacheFinServices, (state) => {
    const newState = { ...state };
    // TODO: maybe just return services: [] ? or these services even with 'cached: false' needed somewhere?
    newState.services = state.services.map((service) => {
      if (service.error) {
        return { ...service, cached: false, isLoaded: false, error: false, actAvailError: null };
      }
      if (service.cached) {
        return { ...service, cached: false, isLoaded: false };
      }
      return service;
    });
    return { ...newState, cached: false };
  });
  // .handleAction(actions.servicesReset, (state) => ({ ...state, services: [] }));

const finProtectActAvailSuccessReducer = createReducer(false)
  .handleAction(actions.getServiceFinProtectActAvail.success, () => true);

const servicesFinProtectRefuseReducer = createReducer({})
  .handleAction(actions.finProtectRefuse.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

const servicesFinProtectActivationReducer = createReducer({})
  .handleAction(actions.finProtectActivation.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

const prePaymentScheduleReducer = createReducer({})
  .handleAction(actions.prepaymentSchedule.request, () => ({}))
  .handleAction(actions.prepaymentScheduleReset, () => ({}))
  .handleAction(actions.prepaymentSchedule.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

const getSupAgreementReducer = createReducer({})
  .handleAction(actions.getSupAgreement.request, () => ({}))
  .handleAction(actions.getSupAgreement.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

const finProtectRegistrationReducer = createReducer({})
  .handleAction(actions.finProtectRegistration.request, () => ({}))
  .handleAction(actions.finProtectRegistration.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }));

const serviceConfigReducer = createReducer({})
  .handleAction(actions.setServiceConfig, (state, { payload }) => payload);

const calcNextPaymentDateReducer = createReducer('')
  .handleAction(actions.calcNextPaymentDate.success, (state, { payload }) => payload.nextPaymentDate);

export default combineReducers({
  servicesFinProtectResult: financialServiceReducer,
  servicesFinProtectRefuse: servicesFinProtectRefuseReducer,
  servicesFinProtectActivation: servicesFinProtectActivationReducer,
  prePaymentScheduleResult: prePaymentScheduleReducer,
  supAgreementResult: getSupAgreementReducer,
  finProtectRegResult: finProtectRegistrationReducer,
  serviceConfig: serviceConfigReducer,
  finProtectActAvailSuccess: finProtectActAvailSuccessReducer,
  nextPaymentDate: calcNextPaymentDateReducer,
  servicesFinProtect: asyncReducerFactory(actions.getServiceFinProtect),
  servicesFinProtectRefuseFetchStatus: asyncReducerFactory(actions.finProtectRefuse),
  servicesFinProtectActivationFetchStatus: asyncReducerFactory(actions.finProtectActivation),
  prePaymentScheduleFetchStatus: asyncReducerFactory(actions.prepaymentSchedule),
  prePaymentSchedule: asyncReducerFactory(actions.prepaymentSchedule),
  getNotSignedSupAgreementStatus: asyncReducerFactory(actions.getNotSignedSupAgreement),
  getSupAgreementStatus: asyncReducerFactory(actions.getSupAgreement),
  sendSupAgreementStatus: asyncReducerFactory(actions.sendSupAgreement),
  finProtectRegistrationStatus: asyncReducerFactory(actions.finProtectRegistration),
  getCheckFactorStatus: asyncReducerFactory(actions.getCheckFactor),
  sendCheckFactorStatus: asyncReducerFactory(actions.sendCheckFactor),
  calcNextPaymentDateStatus: asyncReducerFactory(actions.calcNextPaymentDate),
});

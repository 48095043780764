import React, { memo, useMemo } from 'react';
import moment from 'moment';
import { formatCalendarDate } from '../../../../utils/format';
import AcceptedIcon from '../../../../assets/svg/operation-log/accepted-icon.svg';
import DoneIcon from '../../../../assets/svg/operation-log/done-icon.svg';
import ErrorIcon from '../../../../assets/svg/operation-log/error-icon.svg';
import WarningIcon from '../../../../assets/svg/operation-log/warning-icon.svg';
import ProcessingIcon from '../../../../assets/svg/operation-log/processing-icon.svg';
import ChevronIcon from '../../../../assets/svg/chevron-right.svg';

function AppealItem({ appeal, setModalConfig }) {
  const { name, status, requestStateCode } = appeal;

  const { icon, color } = useMemo(() => {
    switch (requestStateCode) {
      case 'INWORK':
      case 'INHANDWORK':
      case 'PAY_PROC':
      case 'UNDEFINED':
        return { icon: <ProcessingIcon />, color: '#647081' };
      case 'SYSERROR':
      case 'N/A':
        return { icon: <WarningIcon />, color: '#E59335' };
      case 'RECALLED':
      case 'REJECTED':
      case 'ERROR':
      case 'PAY_DECL':
        return { icon: <ErrorIcon />, color: '#E40038' };
      case 'COMPLETED':
      case 'PART_PAID':
        return { icon: <DoneIcon />, color: '#00AB5B' };
      case 'REGISTERED':
      case 'SENT':
      default:
        return { icon: <AcceptedIcon />, color: '#647081' };
    }
  }, [requestStateCode]);

  return (
    <button className="appeal-item-button" onClick={() => setModalConfig({ ...appeal, icon, color })}>
      <div className="appeal-item-wrapper">
        <div className="appeal-name">{name}</div>
        <div className="appeal-status">
          {icon}
          <span className="status-label" style={{ color }}>{status}</span>
        </div>
      </div>

      <ChevronIcon className="chevron-icon" />
    </button>

  );
}

function OperationsGroupByDate({ date, log, setModalConfig }) {
  return (
    <div className="appeals-group">
      <div className="appeals-group-date-block">
        {formatCalendarDate(moment(date, 'DD.MM.YYYY'))}
      </div>
      <div className="appeals-group-date-list">
        {log.map((appeal) => (
          <AppealItem
            appeal={appeal}
            key={appeal.orderNo}
            setModalConfig={setModalConfig}
          />
        ))}
      </div>
    </div>
  );
}

export default memo(OperationsGroupByDate);

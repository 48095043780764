import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const login = createAsyncAction(
  'AUTH_SIGNIN_REQUEST',
  'AUTH_SIGNIN_SUCCESS',
  'AUTH_SIGNIN_FAIL',
)();

export function loginAsync(data) {
  return (dispatch) => {
    dispatch(login.request());

    ApiClient.auth.login(data)
      .then(() => dispatch(login.success()))
      .catch((error) => dispatch(login.failure(error, data)));
  };
}

export const logout = createAsyncAction(
  'AUTH_LOGOUT_REQUEST',
  'AUTH_LOGOUT_SUCCESS',
  'AUTH_LOGOUT_FAIL',
)();

export function logoutAsync() {
  return (dispatch) => {
    dispatch(logout.request());

    ApiClient.auth.logout()
      .then(() => {
        dispatch(logout.success());
        dispatch(unauthorized());
      })
      .catch((error) => dispatch(logout.failure(error)));
  };
}

export const registration = createAsyncAction(
  'AUTH_REGISTRATION_REQUEST',
  'AUTH_REGISTRATION_SUCCESS',
  'AUTH_REGISTRATION_FAIL',
)();

export function registrationAsync(data) {
  return (dispatch) => {
    dispatch(registration.request());

    ApiClient.auth.registration(data)
      .then(() => dispatch(registration.success()))
      .catch((error) => dispatch(registration.failure(error)));
  };
}

export const unauthorized = createAction('AUTH_UNAUTHORIZED')();

import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { getCreditRepaymentHistoryAsync } from '../../../../actions/credits';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import { useContractGid, useUser } from '../../../common/hooks';
import { formatCurrency } from '../../../../utils/format';
import Collapse from '../../../../ui-kit/collapse/Collapse';
import PeriodFilter from '../../../../ui-kit/period-filter/PeriodFilter';
import LabelValue from '../../../../ui-kit/label-value/LabelValue';
import EmptyDataBlock from '../../../common/EmptyDataBlock';
import usePeriodFilter from '../../../common/hooks/usePeriodFilter';
import PageTitle from '../../../common/PageTitle';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import IncomeIcon from '../../../../assets/svg/repayment-history/income-icon.svg';
import ChargeOffIcon from '../../../../assets/svg/repayment-history/charge-off-icon.svg';

const typeToIcon = {
  D: <ChargeOffIcon />,
  C: <IncomeIcon />,
};

function CreditRepaymentHistory() {
  const dispatch = useDispatch();
  const { creditId } = useParams();
  const user = useUser();
  const contractGid = useContractGid(creditId);
  const [activePanels, setActive] = useState([]);
  const [dates, setDates] = useState([moment().subtract('10', 'year'), moment()]);
  const { creditsById, repaymentHistoryFetchStatus } = useSelector((state) => ({
    creditsById: state.credits.byId,
    repaymentHistoryFetchStatus: state.credits.repaymentHistoryFetchStatus,
  }));
  const { repaymentHistory = [] } = creditsById[creditId];
  const filteredRepaymentHistory = usePeriodFilter(dates, repaymentHistory);

  useEffect(() => {
    const { operator_id: operatorId } = user;
    dispatch(getCreditRepaymentHistoryAsync(creditId, contractGid, operatorId));
    postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
  }, []);

  useEffect(() => {
    if (repaymentHistory.length > 0) {
      setDates([moment(repaymentHistory[repaymentHistory.length - 1].date, 'DD.MM.YYYY'), moment()]);
    }
  }, [repaymentHistoryFetchStatus.loading]);

  return (
    <div className="page page-credit-repayment-history">
      <PageTitle title="История погашений" />
      <LoaderOrChildren loading={repaymentHistoryFetchStatus.loading}>
        <PeriodFilter value={dates} onChange={setDates} inputSize="s" />
        {filteredRepaymentHistory.length > 0 && (
          <Collapse activePanels={activePanels} onChange={setActive}>
            {filteredRepaymentHistory.map((item) => (
              <Collapse.Panel key={item.date} id={item.date} header={moment(item.date, 'DD.MM.YYYY').format('DD MMMM YYYY')} size="l">
                {item.repayments.map(({ purpose, type, credit_sum: creditSum, debet_sum: debetSum }, i) => (
                  <LabelValue key={`${item.date}-${purpose}-${i + 1}`} label={purpose} value={formatCurrency(type === 'C' ? creditSum : debetSum)} icon={typeToIcon[type]} />
                ))}
              </Collapse.Panel>
            ))}
          </Collapse>
        )}
        {filteredRepaymentHistory.length === 0 && <EmptyDataBlock message="В выбранном периоде отсутствует история погашений" />}
      </LoaderOrChildren>
    </div>
  );
}

export default memo(CreditRepaymentHistory);

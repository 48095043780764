import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

const UiLabelValue = styled.div`
  display: flex;
  align-items: flex-start;

  .icon {
    padding: 2px;
    margin-top: 8px;
    margin-right: 18px;
  }

  .label {
    font-size: 14px;
    line-height: 16px;
    color: #647081;
  }

  .value {
    margin-top: 4px;
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;
  }

  .hint {
    margin-top: 2px;
    font-style: italic;
    background-color: inherit;
    padding-left: 0px;
  }
`;

function LabelValue(props) {
  const { className, label, value, hint, icon } = props;

  return (
    <UiLabelValue className={classnames('label-value', className)}>
      {icon && (
        <div className="icon">
          {icon}
        </div>
      )}
      <div className="label-with-value">
        <div className="label" dangerouslySetInnerHTML={{ __html: label }} />
        <div className="value" dangerouslySetInnerHTML={{ __html: value }} />
        {hint && <div className="hint" dangerouslySetInnerHTML={{ __html: hint }} />}
      </div>
    </UiLabelValue>
  );
}

LabelValue.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
};

export default LabelValue;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useServiceById(id) {
  const servicesByKey = useSelector((state) => state.services.byKey);

  return useMemo(() => {
    if (servicesByKey[id]) return servicesByKey[id];

    const filteredServices = Object.values(servicesByKey).filter(({ key }) => key.startsWith(id)) ?? [];
    if (filteredServices.length > 1) {
      return filteredServices.find(({ statusCode }) => !(['FINISHED', 'COMPLETED', 'CANCELED'].includes(statusCode)));
    }

    return filteredServices[0];
  }, [id, servicesByKey]);
}

import { useField } from 'formik';
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '../../../../ui-kit';

function CheckboxField(props) {
  const [field, meta] = useField(props.name);

  const handleChange = useCallback((e) => {
    if (props.onChange) {
      props.onChange(e);
    }

    field.onChange(e);
  }, [props.onChange, field.onChange]);

  return (
    <Checkbox
      {...field}
      {...props}
      checked={field.value}
      onChange={handleChange}
    />
  );
}

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(CheckboxField);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const UiFormControlGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 20px -10px 0 -10px;

  &:first-child {
    margin-top: 0;
  }

  .control-group {
    flex: 1 1 0px;
    margin: 0 10px;
  }
`;

function FormControlGroup(props) {
  const { children } = props;

  return (
    <UiFormControlGroup>
      {React.Children.map(children, (child) => (
        <div className="control-group">{child}</div>
      ))}
    </UiFormControlGroup>
  );
}

FormControlGroup.propTypes = {
  children: PropTypes.node,
};

export default FormControlGroup;

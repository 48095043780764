import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/app';

const initialState = {

};

const appReducer = createReducer(initialState);
// .handleAction(actions.showNotification, (state, { payload }) => ({
//   ...state,
//   nId: state.nId + 1,
//   notifications: state.notifications.concat({
//     ...payload,
//     id: state.nId,
//   }),
// }))
// .handleAction(actions.hideNotification, (state, { payload }) => ({
//   ...state,
//   notifications: state.notifications.filter((item) => item.id !== payload.id),
// }))
// .handleAction(actions.setCompanyId, (state, { payload }) => ({
//   ...state,
//   currentCompanyId: payload,
// }));

const versionReducer = createReducer('0')
  .handleAction(actions.setVersion, (state, { payload }) => (payload));

const confirmLeaveModalActiveReducer = createReducer(false)
  .handleAction(actions.toggleConfirmLeaveModal, (state, { payload }) => payload);

const failedToLoadDataReducer = createReducer({})
  .handleAction(actions.showFailedToLoadData, (state, { payload }) => payload);

const requestChannelReducer = createReducer('RBWEB')
  .handleAction(actions.setRequestChannel, (state, { payload }) => (payload === 'ios' || payload === 'android' ? 'RBMOBILE' : 'RBWEB'));

const pageTitlePropsReducer = createReducer({})
  .handleAction(actions.setPageTitleProps, (state, { payload }) => payload);

export default combineReducers({
  appReducer,
  version: versionReducer,
  confirmLeaveModalActive: confirmLeaveModalActiveReducer,
  failedToLoadDataConfig: failedToLoadDataReducer,
  pageTitleProps: pageTitlePropsReducer,
  requestChannel: requestChannelReducer,
});

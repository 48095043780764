import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import * as actions from '../actions/order-docs';
import { asyncReducerFactory } from '../utils/redux-utils';
import normalize from '../utils/normalize';

const orderDocsReducer = createReducer({})
  .handleAction(actions.getDocTypes.success, (state, { payload }) => normalize(payload, 'request_type_short_name'))
  .handleAction(actions.getDocTypes.failure, (state, { payload }) => ({
    ...state, ...normalize(payload.data ?? [], 'request_type_short_name'),
  }));

const docAddressTypesReducer = createReducer([])
  .handleAction(actions.getDocAddressType.success, (state, { payload }) => payload);

const docUrlsReducer = createReducer([])
  .handleAction(actions.sendDocRequest.success, (state, { payload }) => payload);

export default combineReducers({
  byId: orderDocsReducer,
  docAddressTypes: docAddressTypesReducer,
  docUrls: docUrlsReducer,
  fetchStatus: asyncReducerFactory(actions.getDocTypes),
  fetchDocAddressTypeStatus: asyncReducerFactory(actions.getDocAddressType),
  sendDocRequestFetchStatus: asyncReducerFactory(actions.sendDocRequest),
});

import { yupToFormErrors } from 'formik';
import { useCallback } from 'react';

let handle = 0;

const useValidate = (schema, customValidate, context) => useCallback((values) => new Promise((resolve) => {
  const run = window.requestIdleCallback ?? window.requestAnimationFrame;
  const cancel = window.cancelIdleCallback ?? window.cancelAnimationFrame;

  cancel(handle);
  const errors = customValidate(values);
  handle = run(() => {
    try {
      schema.validateSync(values, { abortEarly: false, context });
      resolve({ ...errors });
    } catch (e) {
      resolve(yupToFormErrors({ ...e, ...errors }));
    }
  });
}), [schema, context, customValidate]);

export default useValidate;

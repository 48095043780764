import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import * as actions from '../actions/bank';
import { asyncReducerFactory } from '../utils/redux-utils';
import normalize from '../utils/normalize';

const banksReducer = createReducer({})
  .handleAction(actions.getBanks.success, (state, { payload }) => normalize(payload, 'bic'));

const loadedReducer = createReducer(false)
  .handleAction(actions.getBanks.success, () => true);

const incomeAccountReducer = createReducer(null)
  .handleAction(actions.getBankIncomeAccount.success, (state, { payload }) => {
    return payload;
  });

const incomeAccountLoadedReducer = createReducer(false)
  .handleAction(actions.getBankIncomeAccount.success, () => true);

export default combineReducers({
  byId: banksReducer,
  loaded: loadedReducer,
  fetchStatus: asyncReducerFactory(actions.getBanks),
  incomeAccount: incomeAccountReducer,
  incomeAccountLoaded: incomeAccountLoadedReducer,
  incomeAccountFetchStatus: asyncReducerFactory(actions.getBankIncomeAccount),
});

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatCalendarDate, formatCurrency } from '../../../../utils/format';
import IncomeIcon from '../../../../assets/svg/repayment-history/income-icon.svg';
import ChargeOffIcon from '../../../../assets/svg/repayment-history/charge-off-icon.svg';

function CreditCardOperationsGroup({ date, operations }) {
  return (
    <div className="card-operations-group">
      <div className="card-operations-group-title calendar-date-block">
        {formatCalendarDate(moment(date, 'DD.MM.YYYY'))}
      </div>
      <div className="card-operations-group-list">
        {operations.map(({ name, amount, place }) => (
          <div className="card-operations-group-list-item" key={`${date}-${name}-${amount}`}>
            <div className="operation-name-and-place">
              <div className="operation-name">{name}</div>
              {place && <div className="operation-place">{place}</div>}
            </div>
            <div className="operation-amount">
              <div className="operation-amount-icon">
                {amount >= 0 && <IncomeIcon />}
                {amount < 0 && <ChargeOffIcon />}
              </div>
              <div className="operation-amount-text">
                {formatCurrency(Math.abs(amount))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

CreditCardOperationsGroup.propTypes = {
  date: PropTypes.string.isRequired,
  operations: PropTypes.array.isRequired,
};

export default memo(CreditCardOperationsGroup);

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import classnames from 'classnames';
import styled, { css } from 'styled-components';
import { color, transition } from '../styles/mixins';

const inputAffix = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  > svg {
    display: block;
  }
`;

const UiPeriodFilter = styled.div`
  padding: 16px 16px 12px;

  .period-filter-label {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #1E2025;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .period-filter-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .date-input-wrap {
    flex: 1 1 auto;
    width: calc(50% - 7px);
    position: relative;
  }

  .dates-divider {
    flex-shrink: 0;
    margin: 0 5px;
    width: 4px;
    height: 1px;
    background-color: var(--ui-on-white-inputs, #DADADA);
  }

  input {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    min-width: 0;
    font-family: inherit;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid var(--ui-on-white-inputs, #DADADA);
    background: #fff;
    font-weight: 400;
    color: ${color.black};
    box-shadow: none;
    -webkit-appearance: none;
    outline: 0;
    margin: 0;
    padding: 10px 10px 12px 15px;
    //text-align: center;
    line-height: 24px;
    transition: ${transition.fast};
    transition-property: background-color, border-color, color, box-shadow;

    &::placeholder {
      font-family: inherit;
      font-size: 16px;
      color: #A6AAB1;
    }

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
      outline: 0;
    }

    &::-webkit-calendar-picker-indicator {
      display: none;
    }

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }

    &:hover {
      border-color: ${color.black.rgba(0.5)};
    }

    &:focus {
      border-color: ${color.black};
    }

    &[disabled] {
      border-color: ${color.black.rgba(0.08)};
      background-color: ${color.black.rgba(0.05)};
      cursor: not-allowed;
    }

    &.size-s {
      height: 30px;

      &::-moz-placeholder {
        line-height: 30px;
      }
    }

    &.size-m {
      height: 40px;

      &::-moz-placeholder {
        line-height: 40px;
      }
    }

    &.size-l {
      height: 48px;

      &::-moz-placeholder {
        line-height: 48px;
      }
    }

    &.input-size-s {
      border-radius: 6px;
    }

    &.input-size-m {
      border-radius: 8px;
    }

    &.input-size-l {
      border-radius: 10px;
    }

    &.has-prefix {
      padding-left: 40px;
    }

    &.has-suffix {
      padding-right: 40px;
    }

    &.state-valid {
      border-color: ${color.success};
    }

    &.state-invalid {
      border-color: ${color.error};
    }
  }

  .ui-input-prefix {
    ${inputAffix};
    left: 10px;
  }

  .ui-input-suffix {
    ${inputAffix};
    right: 10px;
  }
`;

const PeriodFilter = (props) => {
  const {
    className,
    label,
    size,
    inputSize,
    state,
    prefix,
    suffix,
    value,
    idPrefix,
    namePrefix,
    onChange,
  } = props;

  const cls = classnames('ui-input', `size-${size}`, `input-size-${inputSize}`, `state-${state}`, {
    'has-prefix': !!prefix,
    'has-suffix': !!suffix,
  });

  // TODO: make something better ¯\_(ツ)_/¯ (without switching controled/uncontrolled if possible)
  const onDateFromChange = useCallback((e) => onChange([moment(e.target.value).isValid() ? moment(e.target.value) : undefined, value[1]]), [value, onChange]);
  const onDateToChange = useCallback((e) => onChange([value[0], moment(e.target.value).isValid() ? moment(e.target.value) : undefined]), [value, onChange]);

  return (
    <UiPeriodFilter className={classnames('period-filter', className)}>
      <div className="period-filter-label">{label}</div>
      <div className="period-filter-inputs">
        <div className="date-input-wrap">
          {
            prefix && (<div className="ui-input-prefix">{prefix}</div>)
          }
          <input
            type="date"
            value={value[0] && value[0].format('YYYY-MM-DD')}
            className={cls}
            id={`${idPrefix}-date-from-input`}
            name={`${namePrefix}-date-from`}
            onChange={onDateFromChange}
          />
          {
            suffix && (<div className="ui-input-suffix">{suffix}</div>)
          }
        </div>
        <div className="dates-divider" />
        <div className="date-input-wrap">
          {
            prefix && (<div className="ui-input-prefix">{prefix}</div>)
          }
          <input
            type="date"
            value={value[1] && value[1].format('YYYY-MM-DD')}
            className={cls}
            id={`${idPrefix}-date-to-input`}
            name={`${namePrefix}-date-to`}
            onChange={onDateToChange}
          />
          {
            suffix && (<div className="ui-input-suffix">{suffix}</div>)
          }
        </div>
      </div>
    </UiPeriodFilter>
  );
};

PeriodFilter.displayName = 'PeriodFilter';

PeriodFilter.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
  label: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l']),
  inputSize: PropTypes.oneOf(['s', 'm', 'l']),
  state: PropTypes.oneOf(['default', 'valid', 'invalid']),
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  idPrefix: PropTypes.string,
  namePrefix: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

PeriodFilter.defaultProps = {
  label: 'Период',
  size: 'l',
  inputSize: 'm',
  state: 'default',
};

export default PeriodFilter;

import React, { Children } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const offset = {
  xxs: 12,
  xs: 24,
  s: 40,
  m: 80,
  l: 80, // ?
};

const UiFormFooter = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -10px 0 -10px;
  margin-top: ${(p) => offset[p.offset]}px;

  .footer-item {
    margin: 0 10px;
  }

  ${({ align }) => align === 'full-width' && css`
    .footer-item {
      flex: 1 1 0px;
    }
  `}

  ${({ align }) => align === 'left' && css`
    justify-content: flex-start;

    .footer-item {
      flex: 0 0 auto;
    }
  `}

  ${({ align }) => align === 'right' && css`
    justify-content: flex-end;

    .footer-item {
      flex: 0 0 auto;
    }
  `}
`;

function FormFooter({ align, offset, children, className }) {
  return (
    <UiFormFooter align={align} offset={offset} className={className}>
      {Children.map(children, (item) => (
        <div className="footer-item">
          {item}
        </div>
      ))}
    </UiFormFooter>
  );
}

FormFooter.propTypes = {
  className: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right', 'full-width']),
  offset: PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l']),
  children: PropTypes.node.isRequired,
};

FormFooter.defaultProps = {
  align: 'full-width',
  offset: 'm',
};

export default FormFooter;

import React, { memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Button, Portal } from '../../../../ui-kit';
import AlertIcon from '../../../../assets/svg/status/status-error-v2.svg';
import SuccessIcon from '../../../../assets/svg/status/status-success-v2.svg';
import { transition } from '../../../../ui-kit/styles/mixins';
import postToNativeApp from '../../../../utils/postToNativeApp';
import PageTitle from '../../../common/PageTitle';
import DomHolder from '../../../common/utils/DomHolder';

const UiInformer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background-color: #FFFFFF;
  outline: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 24px;

  &.ui-informer-transition {
    &-enter {
      opacity: 0;
      transform: translateY(-100%);
    }
    &-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: ${transition.normal};
      transition-property: opacity, transform;
    }
  }
`;

const StyledInformerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 16px 0;

  &._failed-to-load-data {
    margin-top: 0;
    height: 100%;
    justify-content: center;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;
    text-align: center;
  }
  .description {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #8B95A4;
    margin: 8px 0 24px;
  }
  .reload-button {
    width: auto;
    min-width: 197px;
    height: auto;
    min-height: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000C1A;
    padding: 0 24px;

    .content {
      justify-content: center;
    }

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: #7d8899;
      }
    }
  }
`;

const StyledTitle = styled.h1`
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: center;
  line-height: 24px;
  color: #000C1A;
`;

const StyledParagraph = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  color: #647081;
`;

const StyledButtonGroup = styled.div`
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 16px 0;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 48px !important;
  text-transform: unset;
  margin-bottom: 12px;
  line-height: 20px !important;
  font-weight: 500 !important;
  &:last-of-type {
    margin-bottom: 0;
  }
  &.design-secondary {
    color: #1E2025;
    background-color: #EDEFF1 !important;
    border: none !important;
  }
`;
const Informer = ({ config, loading, onClose, onGotoInquiries }) => {
  const {
    active, type, header, paragraph, onReload, withGotoInquiries,
  } = config;
  const transitionProps = {
    classNames: 'ui-informer-transition',
    timeout: 300,
    in: active,
    mountOnEnter: true,
    unmountOnExit: true,
  };

  const isError = useMemo(() => type?.startsWith('error'), [type]);

  useEffect(() => {
    if (active) {
      postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
    }
  }, [active]);

  return (
    <Portal id="__informer_container">
      <CSSTransition {...transitionProps}>
        <DomHolder>
          {active && (
            <UiInformer>
              {type === 'failedLoadData' ? (
                <>
                  <PageTitle title="Сервисы банка" onBackClick={onClose} />
                  <StyledInformerBody className="informer-body _failed-to-load-data">
                    <div className="title">Не удалось загрузить данные</div>
                    <div className="description">
                      Попробуйте обновить страницу<br />или&nbsp;зайти позже
                    </div>
                    <Button
                      className="reload-button"
                      design="grey"
                      size="m"
                      onClick={onReload}
                      loading={loading}
                    >
                      Обновить
                    </Button>
                  </StyledInformerBody>
                </>
              ) : (
                <>
                  <StyledInformerBody>
                    {isError ? (<AlertIcon />) : (<SuccessIcon />) }
                    {!header && <StyledTitle>{isError ? 'Операция отклонена' : 'Заявка принята'}</StyledTitle>}
                    {header && <StyledTitle>{header}</StyledTitle>}
                    <StyledParagraph dangerouslySetInnerHTML={{ __html: paragraph }} />
                  </StyledInformerBody>

                  <StyledButtonGroup>
                    {(type !== 'error' || withGotoInquiries) && (
                      <StyledButton design="red" size="m" onClick={onGotoInquiries}>Перейти в журнал обращений</StyledButton>
                    )}
                    <StyledButton
                      design={type === 'error' && !withGotoInquiries ? 'red' : 'secondary'}
                      size="m"
                      type="button"
                      onClick={onClose}
                    >
                      {type === 'error' ? 'Вернуться к кредиту' : 'Закрыть'}
                    </StyledButton>
                  </StyledButtonGroup>
                </>
              )}
            </UiInformer>
          )}
        </DomHolder>
      </CSSTransition>
    </Portal>
  );
};

export default memo(Informer);

import {
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import * as reducers from '../reducers';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { createLogger } = require('redux-logger');

  middlewares.push(createLogger({
    collapsed: true,
  }));
}

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

const reducer = combineReducers(reducers);

const store = createStore(
  (state, action) => {
    if (action.type === 'AUTH_UNAUTHORIZED') {
      return reducer(undefined, action);
    }

    return reducer(state, action);
  },
  composeEnhancers(applyMiddleware(...middlewares)),
);

if (module.hot) {
  module.hot.accept('../reducers', () => {
    // eslint-disable-next-line global-require
    const reducers = require('../reducers');
    store.replaceReducer(combineReducers(reducers));
  });
}

export default store;

import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, resetButton, transition } from '../styles/mixins';

const UiTabs = styled.div`
  .ui-tabs-container {
    display: flex;
    justify-content: space-between;
    // border-bottom: 1px solid ${color.black.rgba(0.08)};
      ${props => props.spaceBetween && `
        justify-content: space-between;
    `}
  }

  .ui-tab {
    ${resetButton};
    box-sizing: border-box;
    padding: 0 9px 12px 7px;
    font-family: inherit;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #979797;
    border-bottom: 4px solid ${color.black.rgba(0)};
    transition: ${transition.fast};
    transition-property: border-color, color;
    min-width: 130px;

    &.active {
      color: #000;
      border-color: #E60028;
    }

    &[disabled] {
      opacity: .5;
      cursor: not-allowed;
    }
  }
`;

function Tabs(props) {
  const { className, tab, children, onChange, spaceBetween } = props;
  const tabs = React.Children.map(children, (child) => {
    const { id, title, disabled, tabBtnClassName } = child.props;

    return (
      <button
        key={id}
        className={classnames('ui-tab', tabBtnClassName, { active: id === tab })}
        disabled={disabled}
        onClick={() => onChange(id)}
        type="button"
      >
        {title}
      </button>
    );
  });
  const current = React.Children.toArray(children).find((child) => child.props.id === tab);

  return (
    <UiTabs className={className} spaceBetween={spaceBetween}>
      <div className="ui-tabs-container">
        {tabs}
      </div>
      {current}
    </UiTabs>
  );
}

Tabs.propTypes = {
  className: PropTypes.string,
  tab: PropTypes.string,
  children: PropTypes.node,
  spaceBetween: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Tabs;

import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import LoaderIcon from './assets/loader.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled(LoaderIcon)`
  display: block;
  width: 24px;
  height: 24px;
  animation: ${rotate} 1s linear infinite;

  ${(p) => p.size === 'lg' && css`
    width: 62px;
    height: 62px;
    margin: 48px auto auto;
  `}

  &.auth-loader,
  &.credits-loader {
    margin: 88px auto auto;
  }
`;

const Loader = ({ size, className, loaderIcon }) => (
  <StyledLoader className={className} size={size} as={loaderIcon || LoaderIcon} />
);

export default Loader;

/* eslint-disable max-len */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IMaskMixin } from 'react-imask';
import { connect, useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Field, Form, Formik, withFormik } from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import { getBankIncomeAccountAsync, getBanksAsync } from '../../../../actions/bank';
import {
  confirmCreditRemittanceRequestAsync,
  createCreditRemittanceRequestAsync,
  getCreditRemittanceAccountsAsync,
  getCreditRemittanceAmountAsync,
  getCreditRemittanceTypesAsync,
  confirmCreditRemittanceRequest,
} from '../../../../actions/credits';
import WarningIcon from '../../../../assets/svg/status/status-warning-new.svg';
import { banksSelector } from '../../../../selectors/bank';
import { Button, Hint, Input, LabelValue } from '../../../../ui-kit';
import FormControl from '../../../../ui-kit/form-control/FormControl';
import FormFooter from '../../../../ui-kit/form-footer/FormFooter';
import Modal from '../../../../ui-kit/modal/Modal';
import compose from '../../../../utils/compose';
import decodeHtml from '../../../../utils/decodeHtml';
import { formatCurrency } from '../../../../utils/format';
import postToNativeApp from '../../../../utils/postToNativeApp';
import { sendLogs } from '../../../../utils/sendLogs';
import CollapsibleErrorHint from '../../../common/CollapsibleErrorHint';
import CheckboxField from '../../../common/forms/fields/CheckboxField';
import MaskInputOldField from '../../../common/forms/fields/MaskInputOldField';
import SelectField from '../../../common/forms/fields/SelectField';
import { useContractGid, useUser } from '../../../common/hooks';
import useAsyncStatus from '../../../common/hooks/useAsyncStatus';
import PageTitle from '../../../common/PageTitle';
import LoaderOrChildren from '../../../common/utils/LoaderOrChildren';
import BankSearch from './BankSearch';
import TimerButton from './TimerButton';

const StyledParagraph = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  color: #6E7279;
  margin-top: 12px;
  margin-bottom: 28px;

  &:last-of-type {
    margin-bottom: 24px;
  }
`;

const pageTitleByStep = {
  form: 'Подать реквизиты',
  confirmation: 'Подтверждение',
};

const RUSFINANCE_BIC = '043601975';
const RUSFINANCE_KS = '30101810622023601975';
const RUSFINANCE_BANK_NAME = 'Филиал РОСБАНК Авто ПАО РОСБАНК';
const KS_PATTERN = /40817810|40820810|423\d{2}810|426\d{2}810/;

const confirmationFormInitialValues = {
  confirmationCode: '',
};

const confirmationFormValidationSchema = yup.object().shape({
  confirmationCode: yup.string()
    .length(4, 'Код может состоять только из 4 цифр')
    .required('Обязательное поле'),
});

const MaskInput = IMaskMixin(({ inputRef, ...props }) => (
  <Input
    type="text"
    size="l"
    placeholder="00000 000 0 00000000000"
    ref={inputRef}
    {...props}
  />
));

const CreditSubmitRequisites = (props) => {
  const dispatch = useDispatch();
  const user = useUser();
  const { mobile_phone: userPhone, operator_id: operatorId } = user;
  const {
    match: { params: { creditId, step } },
    history,
    values,
    touched,
    errors,
    isValid,
    setValues,
    setFieldValue,
    submitForm,
    handleSubmit,
    setFieldError,
  } = props;
  const {
    creditsById,
    bankIncomeAccount,
    bankIncomeAccountFetchStatus,
    remittanceAmountFetchStatus,
    remittanceTypesFetchStatus,
    remittanceAccountsFetchStatus,
  } = useSelector((state) => ({
    creditsById: state.credits.byId,
    bankIncomeAccount: state.bank.incomeAccount,
    bankIncomeAccountFetchStatus: state.bank.incomeAccountFetchStatus,
    remittanceAmountFetchStatus: state.credits.remittanceAmountFetchStatus,
    remittanceTypesFetchStatus: state.credits.remittanceTypesFetchStatus,
    remittanceAccountsFetchStatus: state.credits.remittanceAccountsFetchStatus,
  }));
  const remittanceCreateRequestFetchStatus = useAsyncStatus((state) => state.credits.remittanceCreateRequestFetchStatus); // forcing initial
  const remittanceConfirmRequestFetchStatus = useAsyncStatus((state) => state.credits.remittanceConfirmRequestFetchStatus);
  const credit = creditsById[creditId];
  const { remittanceAmount, remittanceTypes = [], remittanceAccounts = [], additionalInfo: { account_number: accountNumber } } = credit;
  const contractGid = useContractGid(creditId);
  const banks = useSelector(banksSelector);
  const [modalConfig, setModalConfig] = useState({
    opened: false,
    title: 'Уважаемый клиент',
    content: '',
    buttonLabel: 'Ок',
    action: 'goBack',
  });
  const [selectedBic, setSelectedBic] = useState(values.bic);
  const [isChosenBicFromModal, setIsChosenBicFromModal] = useState(false);
  const [resendActiveTime, setResendActiveTime] = useState(Date.now() + 60 * 1000);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const hasBackButton = !window.sessionStorage.getItem('chapter');
  const isIos = window.sessionStorage.getItem('isIos');
  const receiverName = `${user.last_name ?? ''} ${user.first_name ?? ''} ${user.patronymic ?? ''}`.trim();

  const handleModalClose = useCallback(() => {
    const { action } = modalConfig;
    switch (action) {
      case 'goToLog':
        postToNativeApp({ type: 'titleChanged', payload: { chapter: 'INQUIRIES' } });
        history.replace(`/credits/${creditId}/operation-log?reload=force`);
        break;
      case 'goBack':
        postToNativeApp({ type: 'close' });
        history.replace(`/credits/${creditId}`);
        break;
      default:
        setModalConfig({ opened: false });
    }
  }, [modalConfig.action]);

  const handleBankSelect = useCallback(({ city, ks, bank_name, bic }) => {
    setSelectedBic(bic);
    setIsChosenBicFromModal(true);
    setValues({ ...values, city, ks, bank_name, bic });
  }, [values]);

  const handleChangeBic = useCallback(() => {
    setIsChosenBicFromModal(false);
  }, []);

  const handleConfirmationFormSubmit = useCallback((values) => {
    dispatch(confirmCreditRemittanceRequestAsync(values, creditId));
  }, []);

  const handleResendConfirmationCodeClick = useCallback(() => {
    submitForm();
  }, []);

  const handleBackClick = useCallback(() => {
    if (step === 'form') {
      history.push(`/credits/${creditId}/form`);
    } else {
      handleChangeStep('form');
    }
  }, [step]);

  const handleFormikSubmit = useCallback((event) => {
    event.preventDefault();
    const rosbankBics = [
      '040507871', // ДАЛЬНЕВОСТОЧНЫЙ ФИЛИАЛ ПАО РОСБАНК
      '040349744', // КРАСНОДАРСКИЙ ФИЛИАЛ ПАО РОСБАНК
      '044525256', // МОСКОВСКИЙ ФИЛИАЛ ПАО РОСБАНК
      '042202747', // ПРИВОЛЖСКИЙ ФИЛИАЛ ПАО РОСБАНК
      '044030778', // СЕВЕРО-ЗАПАДНЫЙ ФИЛИАЛ ПАО РОСБАНК
      '040407388', // СИБИРСКИЙ ФИЛИАЛ ПАО РОСБАНК
      '046577903', // УРАЛЬСКИЙ ФИЛИАЛ ПАО РОСБАНК
      '046015239', // ЮЖНЫЙ ФИЛИАЛ ПАО РОСБАНК
      '044525354', // ФИЛИАЛ ПАО РОСБАНК «РОСБАНК ДОМ»
      '044525454', // ФИЛИАЛ РОСБАНК АВТО ПАО РОСБАНК
    ];
    if (remittanceAmount && remittanceAmount.accountRestFact <= 100 && values.remittanceRequestTypeCode === 'ANOTHERBANKCLIENT' && !rosbankBics.includes(selectedBic)) {
      setModalConfig({
        opened: true,
        action: 'goBack',
        content: 'Предоставление реквизитов для перевода остатка на счёт в другом банке невозможно, если остаток меньше или равен минимальному размеру комиссии за перевод',
      });
    } else {
      handleSubmit();
    }
  }, [remittanceAmount, values.remittanceRequestTypeCode, selectedBic]);

  const handleChangeStep = (step) => history.push(`/credits/${creditId}/submit-requisites/${step}`);

  useEffect(() => {
    dispatch(getCreditRemittanceAmountAsync(creditId));
    dispatch(getCreditRemittanceTypesAsync(contractGid, creditId, operatorId));
  }, []);

  useEffect(() => {
    sendLogs({
      '': 'Open new page',
      url: window.location.href,
    });
    switch (step) {
      case 'form':
        postToNativeApp({ type: 'backActionChanged', payload: { action: 'close' } });
        break;
      case 'confirmation':
      default:
        postToNativeApp({ type: 'backActionChanged', payload: { action: 'goBack' } });
        break;
    }
  }, [step]);

  useEffect(() => {
    if (remittanceAmount) {
      if (!remittanceAmount.remittanceConditionPassed) {
        setModalConfig((prevCfg) => ({ ...prevCfg, opened: true, title: 'Внимание!', content: remittanceAmount.errorMessage }));
      } else if (remittanceAmount.accountRestFact === 0) {
        setModalConfig((prevCfg) => ({ ...prevCfg, opened: true, title: 'Внимание!', content: 'Уважаемый клиент, приносим свои извинения, в настоящий момент сервис недоступен.' }));
      } else {
        setFieldValue('operatorId', operatorId);
        setFieldValue('contractGid', contractGid);
        setFieldValue('receiverName', receiverName);
      }
    }
  }, [remittanceAmount]);

  useEffect(() => {
    if (values.remittanceRequestTypeCode === 'BANKINCOME') {
      dispatch(getBankIncomeAccountAsync());
    } else if (values.remittanceRequestTypeCode === 'BETWEENOWNACCOUNT') {
      dispatch(getCreditRemittanceAccountsAsync(contractGid, creditId, operatorId));
    } else if (values.remittanceRequestTypeCode === 'ANOTHERBANKCLIENT') {
      dispatch(getBanksAsync());
    }
  }, [values.remittanceRequestTypeCode]);

  useEffect(() => {
    if (bankIncomeAccountFetchStatus.success) {
      setFieldValue('bankIncomeAccount', bankIncomeAccount);
    }
  }, [bankIncomeAccountFetchStatus.success]);

  useEffect(() => {
    if (remittanceCreateRequestFetchStatus.success) {
      setResendActiveTime(Date.now() + 60 * 1000);
      handleChangeStep('confirmation');
    }
  }, [remittanceCreateRequestFetchStatus.success]);

  useEffect(() => {
    if (remittanceCreateRequestFetchStatus.error) {
      setModalConfig((prevCfg) => ({ ...prevCfg, opened: true, title: 'Внимание!', content: remittanceCreateRequestFetchStatus.error.error_description }));
    }
  }, [remittanceCreateRequestFetchStatus.error]);

  useEffect(() => {
    if (remittanceConfirmRequestFetchStatus.success) {
      setModalConfig((prevCfg) => ({ ...prevCfg, opened: true, title: 'Уважаемый клиент,<br />Ваша заявка принята', content: '', action: 'goToLog' }));
      if (credit?.remittanceConfirmData?.request_user_mobile_url) {
        const anchor = document.createElement('a');
        anchor.href = credit?.remittanceConfirmData?.request_user_mobile_url;
        anchor.click();
      }
    }
  }, [remittanceConfirmRequestFetchStatus.success]);

  useEffect(() => {
    const { error } = remittanceConfirmRequestFetchStatus;
    if (error && !!error?.error_code) {
      setIsDisabledButton(true);
    }
    if (error && error?.error_code === 3) {
      setModalConfig((prevCfg) => ({ ...prevCfg, opened: true, title: 'Ваша заявка уже зарегистрирована', content: '', action: 'goToLog' }));
    } else if (error?.error_code === 1003) {
      setModalConfig({ opened: true, title: error.error_description, content: '', action: 'goBack' });
    }
  }, [remittanceConfirmRequestFetchStatus.error]);

  return (
    <div className="page page-credit-submit-requisites">
      <PageTitle title={pageTitleByStep[step]} onBackClick={handleBackClick} />
      <LoaderOrChildren loading={remittanceAmountFetchStatus.loading}>
        {remittanceAmount?.remittanceConditionPassed && (
          <div className={classnames('padded-page-content', { '_confirmation-step': step === 'confirmation', hasBackButton })}>
            {step === 'form' && (
              <Form
                onSubmit={handleFormikSubmit}
                className={classnames('padded-form', { 'is-ios': isIos })}
              >
                <div className="form-content">
                  {/*<div className="credit-remittance-hint">
                  В данном разделе Вы можете предоставить реквизиты для перевода остатка денежных средств сотрудником Банка
                </div>*/}
                  <Hint type="info">
                    В данном разделе Вы можете предоставить реквизиты для перевода остатка денежных средств сотрудником Банка.<br />
                    Обращаем внимание, что за перевод денежных средств в ПАО РОСБАНК комиссия не взимается.
                  </Hint>
                  <LabelValue label="Номер договора" value={creditId} />
                  <FormControl label="Тип Запроса">
                    <SelectField disabled={remittanceTypesFetchStatus.loading} name="remittanceRequestTypeCode" placeholder="Выберите из списка" options={remittanceTypes} />
                  </FormControl>
                  <LabelValue label="Счет списания денежных средств" value={accountNumber} />
                  <LabelValue label="Сумма остатка на счёте" value={formatCurrency(remittanceAmount.accountRestFact ?? 0)} />

                  {values.remittanceRequestTypeCode === 'BANKINCOME' && (
                    <>
                      <LabelValue label="Номер счёта получателя" value={bankIncomeAccount || 'Загрузка...'} />
                      <FormControl semiCompact>
                        <CheckboxField name="acceptBankIncome" size="l" className="checkbox-with-margin">
                          Прошу перечислить остаток денежных средств, находящийся на моем счёте, в доходы Филиала РОСБАНК Авто ПАО РОСБАНК.
                        </CheckboxField>
                      </FormControl>
                    </>
                  )}

                  {values.remittanceRequestTypeCode === 'BETWEENOWNACCOUNT' && (
                    <FormControl label="Счет зачисления денежных средств" className="label-value">
                      <SelectField disabled={remittanceAccountsFetchStatus.loading} name="account" placeholder="Выберите из списка" options={remittanceAccounts} />
                      <CollapsibleErrorHint active={!!(touched.account && errors.account)} message={errors.account} />
                    </FormControl>
                  )}

                  {values.remittanceRequestTypeCode === 'ANOTHERBANKCLIENT' && (
                    <>
                      <LabelValue label="Наименование получателя" value={receiverName} />
                      <FormControl label="БИК банка получателя">
                        <BankSearch banks={banks} name="bic" initialValue={values.bic} onBankSelect={handleBankSelect} onChange={handleChangeBic} />
                        {/*<CollapsibleErrorHint active={!!(touched.bic && errors.bic)} message={errors.bic} />*/}
                      </FormControl>
                      {values.ks && <LabelValue label="Корр. счёт банка получателя" value={values.ks} />}
                      {values.bank_name && <LabelValue label="Банк получателя" value={values.bank_name} />}
                      {values.city && <LabelValue label="Город" value={values.city} />}
                      <FormControl label="Номер счёта получателя">
                        <Field name="receiverAccount">
                          {({ field }) => (
                            <MaskInput
                              mask="00000 000 0 00000000000"
                              onInput={(e) => setFieldValue('receiverAccount', e.target.value)}
                              state={errors.receiverAccount ? 'invalid' : 'default'}
                              disabled={!values.bic || !selectedBic || !isChosenBicFromModal}
                              {...field}
                              onBlur={(e) => {
                                if (e.target.value.replace(/ /g, '').length !== 20) {
                                  setFieldError('receiverAccount', 'Счет может состоять только из 20 цифр');
                                  return;
                                }
                                field.onBlur(e);
                              }}
                            />
                          )}
                        </Field>

                        <StyledParagraph>
                          Укажите 20 цифр счёта в одном из форматов:<br />40817, 40820, 423ХХ, 426ХХ
                        </StyledParagraph>

                        {!!(touched.receiverAccount && errors.receiverAccount) && (
                          <div className="hint service-hint">
                            <WarningIcon />
                            {errors.receiverAccount}
                          </div>
                        )}
                      </FormControl>
                      <FormControl semiCompact>
                        <CheckboxField name="acceptCommission" size="l" className="checkbox-with-margin">
                          Настоящим я уведомлен(а),
                          что за перевод денежных средств взимается комиссия
                          в соответствии действующими тарифами Филиала РОСБАНК Авто ПАО РОСБАНК.
                        </CheckboxField>
                      </FormControl>
                      <FormControl compact>
                        <CheckboxField name="acceptLaw" size="l" className="checkbox-with-margin">
                          Уведомлен(а), что документ имеет
                          равную юридическую силу
                          с&nbsp;документами, составленными
                          на&nbsp;бумажных носителях,
                          подписанными собственноручной
                          подписью Клиента и является
                          основанием для совершения
                          банковских операций.
                        </CheckboxField>
                      </FormControl>
                    </>
                  )}
                </div>
                {values.remittanceRequestTypeCode && (
                  <FormFooter offset="s" className="credit-submit-footer">
                    <Button
                      type="submit"
                      design="red"
                      loading={bankIncomeAccountFetchStatus.loading || remittanceAccountsFetchStatus.loading || remittanceCreateRequestFetchStatus.loading}
                      disabled={!isValid}
                      size="l"
                    >
                      продолжить
                    </Button>
                  </FormFooter>
                )}
              </Form>
            )}

            {step === 'confirmation' && (
              <Formik
                initialValues={confirmationFormInitialValues}
                validationSchema={confirmationFormValidationSchema}
                onSubmit={handleConfirmationFormSubmit}
              >
                {({ values, errors, touched, isValid, submitCount }) => (
                  <Form onChange={(event) => {
                    if (event.target.value.length === 4) {
                      dispatch(confirmCreditRemittanceRequest.failure({}));
                      setIsDisabledButton(false);
                    }
                  }}
                  >
                    <div className="credit-remittance-hint">{`На Ваш телефон ${userPhone.substr(0, 4)} *** *** ${userPhone.substr(-1)} отправлено сообщение с кодом подтверждения.`}</div>
                    <div className="credit-remittance-hint-mini">Если у Вас изменился номер телефона, обратитесь в отделение Банка.</div>
                    <FormControl label="Код из смс">
                      <MaskInputOldField name="confirmationCode" size="l" maxLength={4} className={classnames({ 'has-api-error': submitCount > 0 && remittanceConfirmRequestFetchStatus.error })} mask="0000" />
                      <CollapsibleErrorHint
                        active={!!(touched.confirmationCode && errors.confirmationCode) || (submitCount > 0 && remittanceConfirmRequestFetchStatus.error && ![3, 1003].includes(remittanceConfirmRequestFetchStatus.error.error_code))}
                        message={errors.confirmationCode || remittanceConfirmRequestFetchStatus.error?.error_description}
                      />
                    </FormControl>
                    <div className="confirmation-buttons-wrap">
                      <Button
                        type="submit"
                        design="red"
                        loading={remittanceConfirmRequestFetchStatus.loading}
                        disabled={!isValid || !values.confirmationCode || isDisabledButton}
                        size="l"
                      >
                        Подтвердить
                      </Button>
                      <TimerButton type="button" design="red" loading={remittanceConfirmRequestFetchStatus.loading || remittanceCreateRequestFetchStatus.loading} end={resendActiveTime} onClick={handleResendConfirmationCodeClick} />
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        )}
        {!remittanceAmount?.remittanceConditionPassed && null}
      </LoaderOrChildren>
      <Modal
        header={modalConfig.title}
        active={modalConfig.opened}
        onClose={handleModalClose}
      >
        <div className="modal-content" dangerouslySetInnerHTML={{ __html: decodeHtml(modalConfig.content) }} />
        <div className="modal-buttons">
          <Button
            design="red"
            size="l"
            onClick={handleModalClose}
          >
            готово
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.remittanceRequestTypeCode) {
    errors.remittanceRequestTypeCode = 'Обязательное поле';
  }

  if (values.remittanceRequestTypeCode === 'BANKINCOME' && !values.acceptBankIncome) {
    errors.acceptBankIncome = 'Обязательное поле';
  }

  if (values.remittanceRequestTypeCode === 'BETWEENOWNACCOUNT') {
    if (!values.account) {
      errors.account = 'Обязательное поле';
    }
  }

  if (values.remittanceRequestTypeCode === 'ANOTHERBANKCLIENT') {
    if (!values.acceptCommission) {
      errors.acceptCommission = 'Обязательное поле';
    }
    if (!values.acceptLaw) {
      errors.acceptLaw = 'Обязательное поле';
    }
    if (!values.bic) {
      errors.bic = 'Обязательное поле';
    }
    if (!values.receiverAccount) {
      errors.receiverAccount = 'Обязательное поле';
    }
    if (values.bic && /[^0-9]/.test(values.bic)) {
      errors.bic = 'БИК может состоять только из цифр';
    }
    if (values.bic && values.bic.length !== 9) {
      errors.bic = 'БИК может состоять только из 9 цифр';
    }

    const receiverAccount = values.receiverAccount.replace(/ /g, '');

    if (receiverAccount) {
      const correctStart = ['40817810', '40820810', '423', '426'].some((num) => num.startsWith(receiverAccount) || receiverAccount.startsWith(num));
      if (!correctStart) {
        errors.receiverAccount = 'Указан неверный формат счёта';
        return errors;
      }

      if (receiverAccount.length > 5 && receiverAccount.length <= 8 && !['8  ', '81 ', '810'].some((num) => receiverAccount.padEnd(8).endsWith(num))) {
        errors.receiverAccount = 'Указан неверный формат счёта';
        return errors;
      }

      if (receiverAccount.length >= 8 && receiverAccount.search(KS_PATTERN) === -1) {
        errors.receiverAccount = 'Указан неверный формат счёта';
        return errors;
      }

      if (receiverAccount.length === 20) {
        const bicRs = values.bic.toString().slice(-3) + receiverAccount;
        const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
        const checksum = coefficients.reduce((sum, x, i) => sum + x * (bicRs[i] % 10), 0);
        if (checksum % 10 !== 0) {
          errors.receiverAccount = 'Этот счёт не принадлежит выбранному вами банку. Проверьте правильность введённых данных';
          errors.bic = errors.receiverAccount;
        }
      }
    }
  }

  return errors;
};

export default compose(
  memo,
  connect(
    (state) => ({ credits: state.credits.byId }),
    { createCreditRemittanceRequest: createCreditRemittanceRequestAsync },
  ),
  withFormik({
    validate,
    mapPropsToValues: () => ({
      contractGid: '',
      remittanceRequestTypeCode: '',
      account: '',
      bic: '',
      acceptBankIncome: false,
      receiverAccount: '',
      acceptCommission: false,
      acceptLaw: false,
    }),
    handleSubmit: ({
      operatorId,
      contractGid,
      remittanceRequestTypeCode,
      account,
      bankIncomeAccount,
      receiverAccount,
      receiverName,
      bic: receiverBic,
      ks,
      bank_name: bankName,
    }, formikBag) => {
      const data = {
        contractGid,
        remittanceRequestTypeCode,
        receiverAccount: account,
        receiverName,
        receiverBic: RUSFINANCE_BIC,
        ks: RUSFINANCE_KS,
        bankName: RUSFINANCE_BANK_NAME,
        operatorId,
        requestChannel: 'RBMOBILE',
      };

      if (remittanceRequestTypeCode === 'BANKINCOME') {
        data.receiverAccount = bankIncomeAccount;
      }

      if (remittanceRequestTypeCode === 'ANOTHERBANKCLIENT') {
        Object.assign(data, {
          receiverAccount: receiverAccount.replace(/ /g, ''),
          receiverBic,
          ks,
          bankName,
        });
      }
      formikBag.props.createCreditRemittanceRequest(data);
    },
  }),
)(CreditSubmitRequisites);
